

//PS_50, PS_51, PS_52, PS_54
import { client } from "../Client/Client";
import { BE_URL, profile } from "../Common/Constant";



export const getClientData = async (data: any) => {

    try {
        const config: any = {
            method: 'POST',
           url: BE_URL + profile.getClientData,
        //   url : 'http://localhost:8081/profile/getClientData',
            headers: { 'Content-Type': 'application/json' },
            data: data,
        };

        const response: any = await client(config);
        ////console.log("response from Api", data);
        return response;
    }
    catch (error: any) {
        throw error
    }
};
export const updateProfile = async (data: any) => {
    debugger
    try {
        const config: any = {
            method: 'POST',
           url: BE_URL + profile.updateProfile,
            //  url : 'http://localhost:8081/profile/updateprofile',
            headers: { 'Content-Type': 'application/json' },
            data: data,
        };

        const response: any = await client(config);
        ////console.log("response from Api", data);
        return response;
    } catch (error: any) {
        throw error
    }
};
export const handleSecondaryUser = async (data: any) => {
    debugger
    try {
        const config: any = {
            method: 'POST',
            //   url :'http://localhost:8081/profile/handlesecondaryuser',
            url: BE_URL + profile.handleuser,
            headers: { 'Content-Type': 'application/json' },
            data: data,
        };

        const response: any = await client(config);
        ////console.log("response from Api", data);
        return response;
    } catch (error: any) {
        throw error
    }
};
export const updatePassword = async (data: any) => {
    debugger
    try{
    const config: any = {
        method: 'POST',
      url: BE_URL + profile.updatePassword,
        //url :'http://localhost:8081/profile//updatepassword',
        headers: { 'Content-Type': 'application/json' },
        data: data,
    };

    const response: any = await client(config);
    ////console.log("response from Api", data);
    return response;
}
catch(error: any) {
    throw error
}}
export const sendInvitemail = async (data: any) => {
    debugger
    try {
        const config: any = {
            method: 'POST',
            url: BE_URL + profile.sendmail,
            headers: { 'Content-Type': 'application/json' },
            data: data,
        };

        const response: any = await client(config);
        ////console.log("response from Api", data);
        return response;
    } catch (error: any) {
        throw error
    }
};