//PS_04 to PS_10
import { BE_URL, commonErrorMsgs } from '../../Common/Constant';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { cancelAction, retryAssessment, getSummary } from "../../Services/assessmentGridService";
import { taskActionApi, updateLastVisitedPage } from "../../Services/taskService";
import moment from "moment";
import { useHistory } from 'react-router-dom';
import Loader from "../Common/Loader";
import { decodeSessionDetails } from "../../Services/CommonServices";
//import { w3cwebsocket as WebSocket } from 'websocket';
import objCryptoJS from "crypto-js";
// PS_08: Import interfaces providing types such as Task, Filters, SortCriteria.
// Assuming these types are imported from a file named 'interfaces'.
import { sessionResponse } from "../../Common/Interface";
import jwt from 'jwt-decode'
import { getSession } from '../../Services/manageClientAPI';

export default function AssessmentInprogressComponent() {
    //PS_11 to PS_29

    const history = useHistory();
    let location: any = useLocation().state;

    const currentDate = new Date();
    const formattedCurrentDate: any = currentDate.toISOString().split('T')[0];

    interface AppData {
        Count: number;
        Size: string;
        Completion: string;
        TotalCount: number;
        status: string;
    }

    // const [Date, setDate] = useState(formattedCurrentDate)
    const data: AppData = {
        Count: 0,
        Size: '',
        Completion: '',
        TotalCount: 0,
        status: '',
    };
    interface AppsDataState {
        [key: string]: AppData;
    }
    const [appsData, setAppsData] = useState<AppsDataState>({});


    const [gmail, setGmail] = useState<boolean>(false);
    const [gmailData, setGmailData] = useState<AppData>(data);
    const [gdrive, setMydrive] = useState<boolean>(false);
    const [gdriveData, setMydriveData] = useState<AppData>(data);
    const [teams, setTeamsChannel] = useState<boolean>(false);
    const [teamsData, setTeamsChannelData] = useState<AppData>(data);
    const [sharepoint, setSharepoint] = useState<boolean>(false);
    const [sharepointData, setSharepointData] = useState<AppData>(data);
    const [outlook, setOutlook] = useState<boolean>(false);
    const [outlookData, setOutlookData] = useState<AppData>(data);



    const getStatusCSS = (status: string) => {
        switch (status) {
            case "failed":
                return "danger";
            case "queued":
                return "draft";
            case "draft":
                return "draft";
            case "cancelled":
                return "draft";
            case "scheduled":
                return "scheduled";
            case "inprogress":
                return "warning";
            case "in progress":
                return "warning";
            case "completed":
                return "success";
            case "paused":
                return "paused";
            default:
                return "";
        }
    };


    const [loading, setLoading] = useState(false);
    const [areAllCompletionRates100, setareAllCompletionRates100] = useState(false);

    const [taskId, setTaskId] = useState<number>(location?.state?.taskId);

    //const [taskId, setTaskId] = useState<number>(2);

    const [time, settime] = useState('');

    const [cancelFilter, SetCancelFilter] = useState(false);
    const [isFetching, setIsFetching] = useState(false)

    const stage = 4;

    let client: any = ''


    const rn_secretKey = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY || '';
    const a_secretKey = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY || '';
    const c_secretKey = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY || '';

    const [objAdmin, setObjAdmin] = useState({})
    const [objClient, setObjClient] = useState({})

    let sessionDetails = { UserName: "", CompanyName: "", EmailAddress: "", ID: 0, Role: "" }
    let [clientName, setclientName] = useState('');
    let [companyName, setCompanyName] = useState('');
    let [emailAddress, setEmailAddress] = useState('');
    let [isAdmin, setIsAdmin] = useState(false);
    let [adminName, setAdminName] = useState('');
    let [adminEemailAddress, setAdminEmailAddress] = useState('');
    let [adminrole, setAdminRole] = useState('');
    const [role, setRole] = useState<string>('');
    const [alert, setAlert] = useState<string>('');
    let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
    const [isSessionEpired, setisSessionEpired] = useState(false);
    // //debugger
    let InActiveTimeout: NodeJS.Timeout;


    ////PS_30 to PS_31
    useEffect(() => {

        sessionTokenProperties()
        resetTimer();
        window.addEventListener('keydown', resetTimer);
        window.addEventListener('click', resetTimer);
        // fetchAssessmentApps("initiaload")

        if (!isFetching) {
            setLoading(true);
            const intervalId = setInterval(() => {
                fetchAssessmentApps().catch(console.error); // Handle any errors from the async call
            }, 15000);
            //const intervalId = setInterval(fetchDataToBind("initiaload"), 5000); // Call every 5 seconds

            return () => {
                clearInterval(intervalId);
                window.removeEventListener('click', resetTimer);
                window.removeEventListener('keydown', resetTimer);
            };
        }
        return () => {
            window.removeEventListener('click', resetTimer);
            window.removeEventListener('keydown', resetTimer);
        };


    }, []);

    let resetTimer = async () => {
        try {
            // debugger;
            ////console.log("Reset Timer Start");

            let sessionResponse: string = await resetSessionTimer(InActiveTimeout, SessionEpiredDateTime);
            ////console.log("sessionResponse value :", sessionResponse);

            // //debugger
            if (sessionResponse == 'expired') {
                debugger
                sessionStorage.removeItem("c_at");
                setisSessionEpired(true)
                setAlert('Your Session has Expired please login again to continue');
            }
            else if (sessionResponse == 'updateToken') {
                if (emailAddress == "" || emailAddress == undefined) {
                    await sessionTokenProperties();
                }
                debugger
                let token = await getToken()
                let payload = {
                    mailId: emailAddress,
                    isAdmin: false,
                    token: token
                };
                let getsessionToken = await getSession(payload);
                const rn_secretKey: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY
                const cs_secretKey: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;

                const c_byteValue = objCryptoJS.AES.decrypt(getsessionToken?.data?.data.sessionToken, rn_secretKey);
                const c_decryptedValue = c_byteValue.toString(objCryptoJS.enc.Utf8);
                let updatedToken = objCryptoJS.AES.encrypt(c_decryptedValue, cs_secretKey).toString();
                await sessionStorage.removeItem("c_at");
                await sessionStorage.setItem('c_at', updatedToken);
                let updatedobjClient: any = jwt(c_decryptedValue);
                SessionEpiredDateTime = updatedobjClient.CreatedAt;
                setSessionEpiredDateTime(SessionEpiredDateTime);
            }
            ////console.log("Reset Timer End");
        }
        catch (error) {
            ////console.log("Reset Timer Catch");
            setAlert('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
            setLoading(false);
        }
    }

    const resetSessionTimer = async (InActiveTimeout: any, SessionExpirationDateTime: any) => {
        try {
            // debugger
            ////console.log("Reset Session Timer Start");
            // Assuming InActiveTimeout is a number representing the ID of the current timeout

            if (typeof window !== 'undefined') {
                ////console.log("Reset Session Timer Inside");
                clearTimeout(InActiveTimeout);
                const tokenExpiryCheckInterval = Number(process.env.REACT_APP_TABIDLESCENARIO);

                InActiveTimeout = setTimeout(() => {
                    ////console.log("Expired");
                    sessionStorage.removeItem("c_at");
                    ////console.log("removed session");
                    setisSessionEpired(true)
                    setAlert('Your Session has Expired please login again to continue');
                    // return 'expired'
                }, tokenExpiryCheckInterval);

                ////console.log("Reset Session Timer checking");

                const currentDateTime = new Date().toISOString();
                const sessionExpiredDateTime = new Date(SessionExpirationDateTime).toISOString();
                ////console.log("sessionExpiredDateTime value :", sessionExpiredDateTime);

                const diffInMilliseconds = Math.abs(new Date(sessionExpiredDateTime).getTime() - new Date(currentDateTime).getTime());
                ////console.log("diffInMilliSeconds values:", diffInMilliseconds);

                const minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
                ////console.log("minutes : ", minutes);

                const sessionUpdateTimeGap = Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP);
                ////console.log("Reset Session Timer Differece : ", sessionUpdateTimeGap);

                if (minutes > sessionUpdateTimeGap) {

                    ////console.log("Reset Session Timer need to update");
                    return 'updateToken'
                }

                ////console.log("Reset Session Timer End");
                return 'NoActionRequired'
            }
            return 'NoActionRequired'
        }
        catch (ex) {
            ////console.log("Reset Session Timer Catch");
            throw ex
        }
    };



    let sessionTokenProperties = async () => {
        try {
            const tokenResponse: any = await decodeSessionDetails();
            debugger
            if (tokenResponse.clientDetails) {
                setObjClient(tokenResponse.clientDetails)
                setclientName(tokenResponse.clientDetails.Name);
                setCompanyName(tokenResponse.clientDetails.CompanyName)
                emailAddress = tokenResponse.clientDetails.Email;
                setEmailAddress(emailAddress);
                setRole(tokenResponse.clientDetails.Role);
                setSessionEpiredDateTime(tokenResponse.clientDetails.CreatedAt);
            }
            else {
                history.push("/Login")
            }
            //#region only for required Modules
            if (tokenResponse.adminDetails) {
                setObjAdmin(tokenResponse.adminDetails)
                setAdminName(tokenResponse.adminDetails.Name);
                setAdminEmailAddress(tokenResponse.adminDetails.Email);
                setRole(tokenResponse.adminDetails.Role);
            }
            // else {
            //     history.push("/Admin/Login")
            // }
            //#endregion
        } catch (error) {
            setAlert('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
            console.error('Failed in main method', error);
        }
    };



    const getToken = async () => {
        let clientToken: string;
        let adminToken: string;
        let token: { clientToken?: string; adminToken?: string } = {};
        debugger
        try {
            if (objClient != undefined) {
                let sessionToken: any = sessionStorage.getItem('c_at')
                if (sessionToken) {
                    sessionToken = objCryptoJS.AES.decrypt(sessionToken, c_secretKey)
                    var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
                    clientToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
                    token['clientToken'] = clientToken

                }


            }
            if (objAdmin != undefined) {
                let sessionToken: any = sessionStorage.getItem('a_at')
                if (sessionToken) {
                    sessionToken = objCryptoJS.AES.decrypt(sessionToken, a_secretKey)
                    var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
                    adminToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();

                    token['adminToken'] = adminToken

                }
            }
            return token
        } catch (error) {
            throw error
        }
    }

    //PS_44 to PS_75
    const fetchAssessmentApps = async () => {
        // Assumed getToken() and getSummary() functions exist
        let token = await getToken();

        try {
            let payload = {
                taskId: taskId,
                token: token,
                page: 'inprogress',
            };
            //console.log("The payload", payload);
            debugger
            let summaryResponse: any = await getSummary(payload);
            debugger
            //console.log("The summary", summaryResponse);
   if(summaryResponse.status === 200){
    setLoading(false)
            // Assuming you have a setter method like `setTime` for a time value in your state
            settime(summaryResponse?.data?.data?.time);

            debugger

            let newAppsData:any = {};

            summaryResponse?.data?.data?.overalldata?.forEach((item:any) => {
                const appKey = Object.keys(item)[0];
                const appData = item[appKey];
                 //console.log("the size of the data",appData.TotalSize);
                 
                newAppsData[appKey] = {
                    Count: appData.Count || 0,
                    Size: typeof appData.TotalSize === 'string' ? appData.TotalSize : formatSize(appData.TotalSize),
                    Completion: appData.PercentageCompletion || '0%',
                    TotalCount: appData.TotalCount || 0,
                    status: appData.Status || 'Not Started',
                };
            });
            
            // Update the state with all app data at once
            setAppsData(newAppsData);
            settime(summaryResponse?.data?.data?.time);
        }
        } catch (error) {
            console.error('Error in fetchAssessmentApps:', error);
            setAlert('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
        } finally {
            // Set loading state to false if you are tracking it, e.g., setLoading(false)
        }
    };
    const formatSize = (sizeInBytes:any) => {
        if (sizeInBytes < 1024 * 1024) {
            return `${(sizeInBytes / 1024).toFixed(2)} KB`;
        } else if (sizeInBytes < 1024 * 1024 * 1024) {
            return `${(sizeInBytes / (1024 * 1024)).toFixed(2)} MB`;
        } else {
            return `${(sizeInBytes / (1024 * 1024 * 1024)).toFixed(2)} GB`;
        }
    };
    //PS_77 to PS_93
    const handlecancel = async (appName: string, action: string) => {
        try {

            debugger
            let token = await getToken()

            setLoading(true);



            let updateAction: any

            if (appName == 'task') {
                let payload = {
                    "token": token,
                    "taskId": taskId,
                    "status": action
                }
                updateAction = await taskActionApi(payload)
            }

            else {
                let payload = {
                    "token": token,
                    "appName": appName,
                    "taskId": taskId
                }

                updateAction = await cancelAction(payload)
            }


            if (updateAction.data.statusCode != 200) {
                setAlert(updateAction.data.statusMessage)
            }

            if (appName == 'task') {
                history.push("/task")
            }

        }
        catch (error) {
            ////console.log(error)
            setAlert('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
        }
        finally {
            setLoading(false)
        }
    }


    const insertLastVisitedPageValue = async (lastVisited: string) => {
        try {
            // setLoading(true);

            let token = await getToken()

            let payload = {
                "token": token,
                "lastVisited": lastVisited,
                "taskId": taskId
            }

            let updateAction: any = await updateLastVisitedPage(payload)
            // setLoading(false)
            debugger
            if (updateAction.data.statusCode != 200) {
                setAlert(updateAction.data.statusMessage)
            }

            //history.push("/task") // navigate to page you want          

        }
        catch (error) {
            ////console.log(error)
            setAlert('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
        }
        finally {
            setLoading(false)
        }
    }


    const retry = async (appName:string) => {
        try {
            setLoading(true);

            let token = await getToken()

            let payload = {
                "token": token,
                "taskId": taskId,
                "applicationName":appName
            }

            let updateAction: any = await retryAssessment(payload)

            setLoading(false)
            if (updateAction.data.statusCode != 200) {
                setAlert(commonErrorMsgs.generalError)
            }
        }
        catch (error) {
            ////console.log(error)
            setAlert('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
        }
        finally {
            setLoading(false)
        }
    }

    //console.log(appsData, "datatocheck")


    return (
        <>
            <div className="container-fluid container-height">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card px-4 py-4 mt-3 mb-3 border-0 rounded-3">
                            {/* bread crumb starts here */}
                            <ul className="breadcrumb-item mb-4 ps-0">
                                <li>
                                    <a
                                        onClick={() => {
                                            history.push("/task");
                                        }}
                                        className="pe-0">
                                        Task
                                    </a>
                                </li>
                                <li className="pe-2">Task 1</li>
                            </ul>
                            {/* bread crumb ends here */}
                            <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap gap-3 mt-2">
                                <h5 className="primary-header mb-0 d-flex align-items-center">
                                    <a onClick={() => {
                                        history.push("/selectassessmentapp", {
                                            state: {
                                                applicationName: location?.state?.applicationName,
                                                applicationId: location?.state?.applicationId,
                                                taskName: location?.state?.taskName,
                                                taskType: location?.state?.taskType,
                                            },
                                        });
                                    }}>
                                        <img
                                            src="/images/head-back-arrow.svg"
                                            alt="back-arrow"
                                            width={19}
                                            className="me-2"
                                        />
                                    </a>
                                    {location?.state?.taskName || "alftred"} - Assessment
                                    {/* User Migration icon */}
                                    {/* <img src="/images/user-migration-icon.svg" alt="User migration" width="23" class="ms-2"> */}
                                    {/* Team logo */}
                                    {/* <img src="/images/teams-logo.svg" alt="teams-logo" width="29" class="ms-2"> */}
                                    {/* Sharepoint logo */}
                                    {/* <img src="/images/Microsoft-SharePoint-Logo.svg" alt="Sharepoint-logo" width="28" class="ms-2"> */}
                                    {/* Outlook logo */}
                                    {/* <img src="/images/ms-outlook-logo.svg" alt="outlook-logo" width="28" class="ms-2"> */}
                                </h5>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card p-md-4 border-0 rounded-3">
                                        <div className="row justify-content-center">
                                            {/* Wizard structure starts here */}
                                            <div className="col-md-7">
                                                <div className="d-flex justify-content-between align-items-center step-wizard">
                                                    <div className="d-flex align-items-center gap-1 bg-white z-index-1 cursor-pointer"
                                                        onBlur={(e) => {
                                                            e.stopPropagation();
                                                        }}
                                                        onMouseDown={() => {
                                                            if (location?.state?.taskId && location?.state?.isEdit) {
                                                                return history.push('/SelectApps', {
                                                                    state: {
                                                                        ...location?.state,
                                                                        pageType: 'selectapps',
                                                                        isEdit: true,
                                                                        stage: ((location?.state?.stage) && (location?.state?.stage > stage)) ? location?.state?.stage : stage
                                                                    }
                                                                })

                                                            }
                                                            else {

                                                                history.push("/SelectApps", {
                                                                    state: {
                                                                        // applicationName: location?.state?.applicationName,
                                                                        // applicationId: location?.state?.applicationId,
                                                                        ...location?.state,
                                                                        isEdit: true,
                                                                        pageType: 'selectapps',
                                                                        stage: ((location?.state?.stage) && (location?.state?.stage > stage)) ? location?.state?.stage : stage
                                                                    },
                                                                });
                                                            }
                                                        }
                                                        }>
                                                        <div className="wizard-step-num d-flex justify-content-center align-items-center font-bold font-14"
                                                        >
                                                            1
                                                        </div>
                                                        <span className="bg-white me-1 font-14 font-semibold d-none d-xl-block wizard-step-text">
                                                            Select App
                                                        </span>
                                                    </div>
                                                    <div className="d-flex align-items-center gap-1 bg-white z-index-1 cursor-pointer"
                                                        onClick={() => {
                                                            history.push('/Connectors', {
                                                                state: {
                                                                    isEdit: true, ...location?.state,
                                                                    stage: ((location?.state?.stage) && (location?.state?.stage > stage)) ? location?.state?.stage : stage
                                                                }
                                                            })
                                                        }}>
                                                        <div className="wizard-step-num d-flex justify-content-center align-items-center font-bold font-14 ">
                                                            2
                                                        </div>
                                                        <span className="bg-white me-1 font-14 font-semibold d-none d-xl-block wizard-step-text">
                                                            Connectors
                                                        </span>
                                                    </div>
                                                    <div className="d-flex align-items-center gap-1 bg-white z-index-1 cursor-pointer"
                                                        onClick={() => {
                                                            history.push('/AssessmentConfigurations', {
                                                                state: {
                                                                    isEdit: true,
                                                                    ...location?.state,
                                                                    stage: ((location?.state?.stage) && (location?.state?.stage > stage)) ? location?.state?.stage : stage
                                                                }
                                                            })
                                                        }}>
                                                        <div className="wizard-step-num d-flex justify-content-center align-items-center font-bold font-14 ">
                                                            3
                                                        </div>
                                                        <span className="bg-white me-1 font-14 font-semibold d-none d-xl-block wizard-step-text">
                                                            Configurations
                                                        </span>
                                                    </div>
                                                    <div className="d-flex align-items-center gap-1 bg-white z-index-1">
                                                        <div className="wizard-step-num d-flex justify-content-center align-items-center font-bold font-14 active">
                                                            4
                                                        </div>
                                                        <span className="bg-white me-1 font-14 font-semibold d-none d-xl-block wizard-step-text active">
                                                            Assessment
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Wizard structure ends here */}
                                            <div className="col-lg-9 mt-4 mt-md-5">
                                                <div className="d-md-flex justify-content-between mb-4">
                                                    <h3 className="m-0 font-22 font-bold pb-2">Overall Summary</h3>
                                                    <div className="d-flex align-items-center me-4">
                                                        <p className="font-14 font-regular m-0">
                                                            <span className="font-semibold font-14 cust-role-color">
                                                                Assessment Start Date & Time :
                                                            </span>{" "}
                                                            {time?.replace(" ", " & ")}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    {Object.entries(appsData)?.map(([appName, data]) => (
                                                        <div key={appName} className="col-md-4 col-lg-4 col-xl-3 mb-4">
                                                            <div className="border-radius-8 border p-3 cust-app-cards h-100">
                                                                <div className="d-flex justify-content-between">
                                                                    <span>
                                                                        <img
                                                                            src={`/images/${appName.toLowerCase()}.svg`}
                                                                            alt={`${appName}-migration`}
                                                                            className="mb-2 me-2 cust-card-img"
                                                                        />
                                                                        <span className="font-semibold font-14 mb-3 color-black">
                                                                            {appName === 'outlook' ? 'User Mailbox' :
                                                                                appName === 'sharepoint' ? 'SharePoint' :
                                                                                    appName === 'teamschats' ? 'Teams Channel' :
                                                                                        appName === 'gmail' ? 'GMail' :
                                                                                            appName === 'mydrive' ? 'My Drive' :
                                                                                                appName}
                                                                        </span>
                                                                    </span>
                                                                    {data.status.toLowerCase() === 'inprogress' &&
                                                                        <span className="d-none cust-hover-cross-icon cursor-pointer">
                                                                            <img
                                                                                src="/images/cross-red-icon.svg"
                                                                                alt="Delete"
                                                                                className="mb-3"
                                                                                onClick={() => handlecancel(appName, 'cancelled')}
                                                                            />
                                                                        </span>
                                                                    }
                                                                </div>
                                                                <div className="row mb-2 pb-2">
                                                                    <div className="col-5 cust-count-line position-relative pe-0">
                                                                        <p className="mb-0 font-10 font-regular cust-gray-color">Count</p>
                                                                        <span className="font-semibold font-10">{`${data.Count}`} out of {`${data.TotalCount}`}</span>
                                                                    </div>
                                                                    <div className="col-6 pe-0">
                                                                        <p className="mb-0 font-10 font-regular cust-gray-color">Size</p>
                                                                        <span className="font-semibold font-20">{data.Size}</span>
                                                                    </div>
                                                                </div>
                                                                {data.status.toLowerCase() === 'inprogress' ? (
                                                                    <div className="align-items-center position-relative">
                                                                        <div className="d-flex justify-content-between mb-2">
                                                                            <p className="mb-0 font-10 font-regular cust-gray-color">
                                                                                {data.status.toLowerCase() === "inprogress" ? "Progress" : data.status}
                                                                            </p>
                                                                            <p className="mb-0 font-10 font-semibold primary-color">{data.Completion}</p>
                                                                        </div>
                                                                        <div className="progress custom-progress cust-new-progress w-100 p-0">
                                                                            <div
                                                                                className="progress-bar custom-progress-bar progress-bar-bg position-relative"
                                                                                role="progressbar"
                                                                                style={{ width: data.Completion }}
                                                                                aria-valuenow={parseInt(data.Completion)}
                                                                                aria-valuemin={0}
                                                                                aria-valuemax={100}
                                                                            ></div>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <div className="align-items-center position-relative">
                                                                        <p className="font-regular font-25 form-label-color mb-1 d-flex">
                                                                            <span
                                                                                className={`mt-1 table-status ${getStatusCSS(data.status.toLowerCase())} d-inline-block me-2`}
                                                                            ></span>
                                                                            {`${data.status.toLowerCase() === 'failed'?"Failed":""} (${data.Completion})`}
                                                                            {data.status.toLowerCase() === 'failed' && (
                                                                                <button
                                                                                    style={{ cursor: 'pointer' }}
                                                                                    title="Retry"
                                                                                    className="btn action-rounded-btn retry-action d-flex align-items-center justify-content-center ms-auto"
                                                                                    data-bs-toggle="tooltip"
                                                                                    data-bs-title="Retry"
                                                                                    onClick={() => retry(appName)}
                                                                                >
                                                                                    <img src="images/refresh-icon-2.svg" alt="retry" />
                                                                                </button>
                                                                            )}
                                                                        </p>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className="d-md-flex d-grid w-100 gap-3 align-items-center justify-content-unset justify-content-md-between mt-5 ">
                                                    <button className="btn primary-outline-btn font-14 w-md-100 font-semibold flex-nowrap d-flex align-items-center justify-content-center order-2 order-md-0"
                                                        onClick={() => {
                                                            insertLastVisitedPageValue('AssessmentConfigurations');
                                                            history.push('/AssessmentConfigurations', {
                                                                state: {
                                                                    isEdit: true,
                                                                    ...location?.state,
                                                                    stage: ((location?.state?.stage) && (location?.state?.stage > stage)) ? location?.state?.stage : stage
                                                                }
                                                            })
                                                        }}>
                                                        <img
                                                            src="/images/left-arw-blue.svg"
                                                            alt="back"
                                                            width={12}
                                                        />
                                                        <span className="ms-1" >Back</span>
                                                    </button>
                                                    <div className="d-md-flex d-grid gap-3">
                                                        <button className="btn secondary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center order-2 order-md-0" onClick={() => {

                                                            //userTaskAction(location.taskId, 'Cancelled')
                                                            insertLastVisitedPageValue('Inprogress');
                                                            SetCancelFilter(true);

                                                        }}>
                                                            Cancel
                                                        </button>
                                                        <button className="btn primary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center"
                                                            onClick={() => { history.push('/task'); insertLastVisitedPageValue('Inprogress'); }}
                                                        >
                                                            Finish
                                                        </button>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {loading && <Loader />}
                {cancelFilter ? (
                    <div
                        className="modal fade show"
                        style={{
                            display: "block",
                            backgroundColor: "rgba(0,0,0,0.5)",
                        }}
                        id="Alert-popup"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false"
                        tabIndex={-1}
                        aria-labelledby="Alert-popupLabel"
                        aria-hidden="true"
                    >
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content popup-brd-radius">
                                <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
                                    <span className="position-absolute">
                                        <img
                                            src="/images/failure-popup-icon.svg"
                                            alt="Alert-popup-icon"
                                        />
                                    </span>
                                </div>
                                <div className="modal-body border-0 text-center">
                                    <h5
                                        className="modal-title mb-3 primary-header"
                                        id="Alert-popupLabel"
                                    >
                                        Alert
                                    </h5>
                                    <p className="primary-data font-16">
                                        The progress initiated in assessment will not be
                                        saved. Are you sure you want to cancel?
                                    </p>
                                </div>
                                <div className="modal-footer border-0 pb-4 justify-content-center">
                                    <button
                                        onClick={() => {
                                            SetCancelFilter(false);
                                        }}
                                        type="button"
                                        className="btn secondary-btn font-14 px-4 font-semibold me-2"
                                        data-bs-dismiss="modal"
                                    >
                                        No
                                    </button>
                                    <button
                                        onClick={() => {
                                            handlecancel('task', 'cancelled')
                                            SetCancelFilter(false);

                                        }}
                                        type="button"
                                        className="btn primary-btn font-14 px-4 font-semibold"
                                        data-bs-dismiss="modal"
                                    >
                                        Yes
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
                {/*Failure Popup starts here */}
                {alert ? <div
                    className="modal fade show"
                    tabIndex={-1}
                    style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content popup-brd-radius">
                            <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
                                <span className="position-absolute">
                                    <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />
                                </span>
                            </div>
                            <div className="modal-body border-0 text-center">
                                <h5
                                    className="modal-title mb-3 primary-header"
                                    id="Failure-popupLabel"
                                >
                                    { }
                                </h5>
                                <p className="primary-data">
                                    {alert}
                                </p>
                            </div>
                            <div className="modal-footer border-0 pb-4 justify-content-center">
                                <button
                                    type="button"
                                    className="btn primary-btn font-14 px-4 font-semibold"
                                    onClick={() => {
                                        if (isSessionEpired) {
                                            history.push('/Login')
                                        }
                                        setAlert('')
                                    }}
                                >
                                    Ok
                                </button>
                            </div>
                        </div>
                    </div>
                </div> : null}
                {/*Failure Popup ends here */}
            </div>

        </>

    )

}