
// import { ADMIN_BE_URL, adminEndpoint, adminLoginEndpoint } from "../../Constant"

import { request } from "http";
import { BE_URL, goLiveURL, taskGrid } from '../Common/Constant';
import { client } from "../Client/Client";


export async function fetchTaskData(data: object) {//PS_AD-43
    let config = {//PS_AD-44
        method: 'POST',
        //url : "http://localhost:8080/tasks/taskDetails",
        // url: ADMIN_BE_URL + adminLoginEndpoint.getAdminCardsDataUrl,
         url: BE_URL + taskGrid.getTaskData,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    const response = await client(config);//PS_AD-45
    return response;//PS_AD-50
};

export async function taskActionApi(data: object) {//PS_AD-43
    let config = {//PS_AD-44
        method: 'POST',
        // url: ADMIN_BE_URL + adminLoginEndpoint.getAdminCardsDataUrl,
        // url: 'http://localhost:8080/tasks/updateTaskAction',
        url: BE_URL + taskGrid.updateTaskAction,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    const response = await client(config);//PS_AD-45
    return response;//PS_AD-50
};


export async function deltaMigrationService(data: object) {//PS_AD-43
    let config = {//PS_AD-44
        method: 'POST',
        url: BE_URL + taskGrid.deltaMigration,
        // url: "http://localhost:8080/task/initiateDelta",
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    const response = await client(config);//PS_AD-45
    return response;//PS_AD-50
};



export async function updateLastVisitedPage(data: object) {//PS_AD-43
    let config = {//PS_AD-44
        method: 'POST',
        // url: ADMIN_BE_URL + adminLoginEndpoint.getAdminCardsDataUrl,
        url: BE_URL + taskGrid.updateLastVisitedPage,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    const response = await client(config);//PS_AD-45
    return response;//PS_AD-50
};



export async function updateModifiedDate(data: object) {//PS_AD-43
    let config = {//PS_AD-44
        method: 'POST',
        // url: ADMIN_BE_URL + adminLoginEndpoint.getAdminCardsDataUrl,
        url: BE_URL + taskGrid.updateModifiedDate,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    const response = await client(config);//PS_AD-45
    return response;//PS_AD-50
};

export async function clientTaskCount(data: object) {
    let config = {
        method: 'POST',
        // url: BE_URL + '/registration/registration/sendUrl',
        //   url: "http://localhost:8083/tasks/getTaskCount",

        url: BE_URL + taskGrid.clientTaskCOunt,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    const response = await client(config);
    ////console.log(config, "uyhgfdcv");
    return response;
};

export async function goLiveService(data: object, type: string) {
    try {
        let url
        switch (type) {
            case "sharepoint":
                url =  goLiveURL.sharepoint
                break
            case "teamschannel":
                url = goLiveURL.teamschannel
                break
            case "gmail":
                url = goLiveURL.gmail
                break
            case "mydrive":
            case "onedrive":
                url = goLiveURL.mydrive
                break
            case "outlook":
                url = goLiveURL.outlook
                break
        }
        let config = {
            method: 'POST',
            url: BE_URL as string + url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        }
        const response = await client(config);
        return response;
    } catch (error) {
        throw error
    }
}