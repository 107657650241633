import { AddVideo } from '../Common/Interface';
import { BE_URL, HelpAndDocumentation } from '../Common/Constant';
import { client } from '../Client/Client'; // Hypothetical HTTP client
import { AxiosResponse } from 'axios';

// PC_EX_51 - PC_EX_62 
// Service for getGuideAndVideoService
export async function getGuideAndVideoService(search: { guideSearchText: string; videoSearchText: string; token? : object }) {
  try {
    // Assuming data will be an object composed of guideSearchText and videoSearchText
    const data = search;
    const response: AxiosResponse | undefined = await client({
      method: 'post',
      url: BE_URL + HelpAndDocumentation.getGuideAndVideoUrl,
      data: data,
    });
    return response;
  } catch (error) {
    throw error;
  }
}

// PC_EX_110 - PC_EX_121 
// Service for deleteGuideOrVideoService
export async function deleteGuideOrVideoService(data: any) {
  try {
    let response: AxiosResponse | undefined;
    response = await client({
      method: 'post',
      url: BE_URL + HelpAndDocumentation.deleteGuideOrVideoUrl,
      data: data,
    });
    return response;
  } catch (error) {
    throw error;
  }
}

// PC_EX_176 - PC_EX_188 
// Service for uploadGuideToBlobService
export async function uploadGuideToBlobService(formData: FormData) {
  try {
    const response: AxiosResponse | undefined = await client({
      method: 'post',
      url: BE_URL + HelpAndDocumentation.uploadGuideToBlobUrl,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

// PC_EX_213 - PC_EX_224 
// Service for addOrEditVideoService
export async function addOrEditVideoService(data: AddVideo | any) {
  try {
    const response: AxiosResponse | undefined = await client({
      method: 'post',
      url: BE_URL + HelpAndDocumentation.addVideoOrEditVideoUrl,
      data: data,
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export async function getBlobAccessTokenService(data = {}) {
  try {
    const response: AxiosResponse | undefined = await client({
      method: 'post',
      url: BE_URL + HelpAndDocumentation.blobTokenUrl,
      data: data,
    });
    return response;
  } catch (error) {
    throw error;
  }
}