import { BE_URL, assessmentGrid } from '../Common/Constant';
import { client } from "../Client/Client";


export async function fetchApplicationNames(data:object) {//PS_AD-43
    let config = {//PS_AD-44
        method: 'POST',
     // url: 'http://localhost:8081/viewAssesment/fetchApplicationNames',
        url: BE_URL + assessmentGrid.fetchApplicationNames,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    const response = await client(config);//PS_AD-45
    return response;//PS_AD-50
};


export async function getAppsData(data:object) {//PS_AD-43
    let config = {//PS_AD-44
        method: 'POST',
        // url: ADMIN_BE_URL + adminLoginEndpoint.getAdminCardsDataUrl,
        url: BE_URL + assessmentGrid.getUserMailboxData,
      // url: 'http://localhost:8080/viewAssesment/getUserMailboxData',
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    const response = await client(config);//PS_AD-45
    return response;//PS_AD-50
};


export async function userAppDetailsInfo(data:object) {//PS_AD-43
    let config = {//PS_AD-44
        method: 'POST',
        // url: ADMIN_BE_URL + adminLoginEndpoint.getAdminCardsDataUrl,
        url: BE_URL + assessmentGrid.getUserAppDetailsData,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    const response = await client(config);//PS_AD-45
    return response;//PS_AD-50
};

export async function getSummary(data:object) {//PS_AD-43
    let config = {//PS_AD-44
        method: 'POST',
        // url: ADMIN_BE_URL + adminLoginEndpoint.getAdminCardsDataUrl,
        url: BE_URL + assessmentGrid.getSummary,
       //  url : `http://localhost:8081/viewAssesment/getSummary`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    const response = await client(config);//PS_AD-45
    return response;//PS_AD-50
};



export async function cancelAction(data:object) {//PS_AD-43
    try{
        let config = {//PS_AD-44
            method: 'POST',
            // url : `http://localhost:8081/viewAssesment/cancelAction`,
            // url: ADMIN_BE_URL + adminLoginEndpoint.getAdminCardsDataUrl,
           url: BE_URL + assessmentGrid.cancelAction,
          
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        }
        const response = await client(config);//PS_AD-45
        return response;//PS_AD-50
    }
    catch(error:any){
        ////console.log(error.message);
        
    }
  
};




export async function retryAssessment(data:object) {//PS_AD-43
    try{
        let config = {//PS_AD-44
            method: 'POST',
            // url: ADMIN_BE_URL + adminLoginEndpoint.getAdminCardsDataUrl,
          url: BE_URL + assessmentGrid.retryAction,
          //  url : `http://localhost:8081/viewAssesment/retryAsssessment`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        }
        const response = await client(config);//PS_AD-45
        return response;//PS_AD-50
    }
    catch(error:any){
        ////console.log(error.message);
        
    }
  
};