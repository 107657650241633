import { client } from "../Client/Client";
import { BE_URL, BE_URL_CreationandMapping, contactus } from "../Common/Constant";


/**PS_EX_27 to PS_EX_29 will be executed*/
export const getTeamsandChannelsApi = async (getBtnType: string, token: string, taskId: string, MigrationType: string) => {
    const config = {
      method: 'post',
      url: `${BE_URL_CreationandMapping}/CustomCreationandMapping/getTeamsChannels`,
      headers: {
        'Authorization': `Bearer ${token}`
      },
      data: {
        taskId,
        MigrationType,
        getBtnType,
        token
      }
    };
  
    return await client(config);
  };
  
  export const getSitesApi = async (getBtnType: string, token: string, taskId: string) => {
    const config = {
      method: 'post',
      url: `${BE_URL_CreationandMapping}/CustomCreationandMapping/getSites`,
      headers: {
        'Authorization': `Bearer ${token}`
      },
      data: {
        taskId,
        getBtnType,
        token
      }
    };
  
    return await client(config);
  };


  // Function to download team channel details (mock implementation)
export const getTeamChannelsDetails = async (downloadBtnType: string, token: object, taskId: string, MigrationType:string) => {
    const config = {
        method: 'POST',
        url: `${BE_URL_CreationandMapping}/CustomCreationandMapping/downloadTeamsChannels`,
        headers: { 'content-type': 'application/json' },
        data: { 
            downloadBtnType,
            taskId,
            MigrationType,
            token
        } // Adjust MigrationType as per needs
    };
    return await client(config);
};

// Function to download site details (mock implementation)
export const getSiteDetails = async (downloadBtnType: string, token: { clientToken?: string; adminToken?: string; }, taskId: string) => {
    const config = {
        method: 'POST',
        url: `${BE_URL_CreationandMapping}/CustomCreationandMapping/downloadSites`,
        headers: { 'content-type': 'application/json' },
        data: { 
          downloadBtnType,
          taskId,
          token
      } 
    };
    return await client(config);
};

export const createTeams = async (token: object,MigrationType: string,teamChannelCreationData:any,taskId:string,isDirectMigration:string) => {
  debugger
    const config = {
        method: 'POST',
        url: `${BE_URL_CreationandMapping}/CustomCreationandMapping/TeamChannelCreation`,
        headers: { 'content-type': 'application/json' },
        data: { 
            taskId,
            MigrationType,
            teamChannelCreationData,
            token,
            isDirectMigration
        } 
    }
    return await client(config);
};

export const createChannel = async (token: object,MigrationType: string,teamChannelCreationData:any,taskId:string,isDirectMigration:string) => {
    const config = {
        method: 'POST',
        url: `${BE_URL_CreationandMapping}/CustomCreationandMapping/TeamChannelCreation`,
        headers: { 'content-type': 'application/json' },
        data: { 
            taskId,
            MigrationType,
            teamChannelCreationData,
            token,
            isDirectMigration
        } 
    }
    return await client(config);
};

export const createSites = async (token: object, siteCreationData:any, createBtnType:string, taskId:string) => {
  const config = {
    method: 'POST',
    url: `${BE_URL_CreationandMapping}/CustomCreationandMapping/CreateSites`,
    headers: { 'content-type': 'application/json' },
    data: { 
        taskId,
        siteCreationData,
        createBtnType,
        token
    } 
}
    return await client(config);
};

// Mapping Services

export const SubmitTeamChannelMapping = async (pageType: string, token: object, taskId: string, teamChannelMappingData: any, MigrationType: string, isMappingTempSaved:boolean) => {
  const data = { pageType, token, taskId, teamChannelMappingData, MigrationType, isMappingTempSaved }; // Assuming the structure of payload
  const Config = { // CC_PS_176
      method: 'POST',
      url: `${BE_URL_CreationandMapping}/CustomCreationandMapping/ValidateSourceTargetMapping`, // Assuming the full path of the endpoint
      headers: { 'content-type': 'application/json' },
      data
  };
  return client(Config); // CC_PS_177
};

export const SubmitSiteMapping = async (pageType: string, token: object, taskId: string, siteMappingData: any, isMappingTempSaved:boolean) => {
  const data = { pageType, token, taskId, siteMappingData, isMappingTempSaved }; // Assuming the structure of payload
  const Config = { // CC_PS_193
      method: 'POST',
      url: `${BE_URL_CreationandMapping}/CustomCreationandMapping/ValidateSourceTargetSiteMapping`, // Assuming the full path of the endpoint
      headers: { 'content-type': 'application/json' },
      data
  };
  return client(Config); // CC_PS_194
};

export const saveTeamChannelMapping = async (PageType: string, token: object, taskId: string, teamChannelMappingData: any, MigrationType: string) => {
  const config = {
    method: 'POST',
    url: `${BE_URL_CreationandMapping}/CustomCreationandMapping/saveTeamChannelMapping`,
    headers: { 'content-type': 'application/json' },
    data: { PageType, token, taskId, teamChannelMappingData, MigrationType }
  };

  const response = await client(config);
  return response;
};

export const saveSiteMapping = async (PageType: string, token: object, taskId: string, siteMappingData: any) => {
  const config = {
    method: 'POST',
    url: `${BE_URL_CreationandMapping}/CustomCreationandMapping/saveSiteMapping`,
    headers: { 'content-type': 'application/json' },
    data: { PageType, token, taskId, siteMappingData }
  };

  const response = await client(config);
  return response;
};

export const deleteMappingService = async (pageType: string, token: object, Id: any) => {
  debugger
  const config = {
    method: 'POST',
    url: `${BE_URL_CreationandMapping}/CustomCreationandMapping/DeleteMapping`,
    headers: { 'content-type': 'application/json' },
    data: { pageType, token, Id }
  };

  const response = await client(config);
  return response;

}

export const cancelMappingService = async (pageType: string, token: object, taskId: any) => {
  debugger
  const config = {
    method: 'POST',
    url: `${BE_URL_CreationandMapping}/CustomCreationandMapping/cancelMapping`,
    headers: { 'content-type': 'application/json' },
    data: { pageType, token, taskId }
  };

  const response = await client(config);
  return response;

}