// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html, body, div, form, fieldset, legend, label, p, button
{
 margin: 0;
 padding: 0; 
}
table
{
 border-collapse: collapse;
 border-spacing: 0;
}
th, td
{
 text-align: left;
 vertical-align: top;
}
h1, h2, h3, h4, h5, h6, th, td, caption { font-weight:normal; }
img { border: 0; }

.black-placeholder::placeholder {
    color: black;
    opacity: 1;
    /* For Firefox */
}

/* For Internet Explorer 10-11 */
.black-placeholder:-ms-input-placeholder {
    color: black;
}

/* For Microsoft Edge */
.black-placeholder::-ms-input-placeholder {
    color: black;
}


/* Target the specific input */
input[type="search"].form-control {
    transition: all 0.3s ease-in-out;
}

/* Focus state */
/* input[type="search"].form-control:focus {
    border-color: #464775 !important;
    box-shadow: 0 0 0 0.2rem #464775 !important;
    outline: none !important;
} */

/* Hover state */
/* input[type="search"].form-control:hover {
    border-color: #80bdff;
}
 */
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;;CAEC,SAAS;CACT,UAAU;AACX;AACA;;CAEC,yBAAyB;CACzB,iBAAiB;AAClB;AACA;;CAEC,gBAAgB;CAChB,mBAAmB;AACpB;AACA,0CAA0C,kBAAkB,EAAE;AAC9D,MAAM,SAAS,EAAE;;AAEjB;IACI,YAAY;IACZ,UAAU;IACV,gBAAgB;AACpB;;AAEA,gCAAgC;AAChC;IACI,YAAY;AAChB;;AAEA,uBAAuB;AACvB;IACI,YAAY;AAChB;;;AAGA,8BAA8B;AAC9B;IACI,gCAAgC;AACpC;;AAEA,gBAAgB;AAChB;;;;GAIG;;AAEH,gBAAgB;AAChB;;;EAGE","sourcesContent":["html, body, div, form, fieldset, legend, label, p, button\n{\n margin: 0;\n padding: 0; \n}\ntable\n{\n border-collapse: collapse;\n border-spacing: 0;\n}\nth, td\n{\n text-align: left;\n vertical-align: top;\n}\nh1, h2, h3, h4, h5, h6, th, td, caption { font-weight:normal; }\nimg { border: 0; }\n\n.black-placeholder::placeholder {\n    color: black;\n    opacity: 1;\n    /* For Firefox */\n}\n\n/* For Internet Explorer 10-11 */\n.black-placeholder:-ms-input-placeholder {\n    color: black;\n}\n\n/* For Microsoft Edge */\n.black-placeholder::-ms-input-placeholder {\n    color: black;\n}\n\n\n/* Target the specific input */\ninput[type=\"search\"].form-control {\n    transition: all 0.3s ease-in-out;\n}\n\n/* Focus state */\n/* input[type=\"search\"].form-control:focus {\n    border-color: #464775 !important;\n    box-shadow: 0 0 0 0.2rem #464775 !important;\n    outline: none !important;\n} */\n\n/* Hover state */\n/* input[type=\"search\"].form-control:hover {\n    border-color: #80bdff;\n}\n */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
