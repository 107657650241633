import { useHistory, useLocation } from 'react-router';
import React, { useState, useEffect, useRef } from 'react';
import { ExportData, ImportData, Popup, Toaster, Task, sessionResponse, Apps, selectedApps, ValidateImport, outlookTemplate, SelectedEntityApps } from '../../Common/Interface';
import { appDescripions, commonErrorMsgs, importMsgs, exportTemplate, sharePointExportTemplate, teamschannelExportTemplate } from '../../Common/Constant';
import { decodeSessionDetails } from '../../Services/CommonServices';
import { getSession } from '../../Services/manageClientAPI';
import { insertLastVisitedPageValueApi } from '../../Services/MigrationAndManageMigrationServices';
import cryptoJs from 'crypto-js';
import { customExportAndImportService, getAppsService, getSelectedAppsForAssessmentService, startAssessmentService } from '../../Services/SelectAppsService';
// import { getAppsService } from '../Services/SelectAppsService';
import { AxiosResponse } from 'axios';
import objCryptoJS from "crypto-js";
import { saveAs } from 'file-saver';
import jwt from 'jwt-decode'

export const SelectApps: React.FC = () => {
    let [errorDataForExcel, setErrorDataForExcel] = useState()
    const tokenExpiryCheckInterval = 20 * 60 * 1000; // 20 minutes
    let sessionDetails = { UserName: "", CompanyName: "", EmailAddress: "", ID: 0, Role: "" }
    let [clientName, setclientName] = useState('');
    let [companyName, setCompanyName] = useState('');
    let [emailAddress, setEmailAddress] = useState('');
    let [role, setRole] = useState('');
    const rn_secretKey = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY || '';
    const a_secretKey = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY || '';
    const c_secretKey = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY || '';
    let [adminName, setAdminName] = useState('');
    let [adminEemailAddress, setAdminEmailAddress] = useState('');
    let [adminrole, setAdminRole] = useState('');
    let history = useHistory()
    const location: any = useLocation().state

    // const location: any = {
    //     taskId: 1,
    //     environment: "tenant"
    // }
    const [taskId, setTaskId] = useState<any>(location?.state?.taskId || "");
    const [environment, setenvironment] = useState<any>(location?.state?.environment || "");
    interface ImportResult {
        type: string;
        data: any;
    }

    const [importResults, setImportResults] = useState<ImportResult[]>([]);

    const [fileInputValue, setFileInputValue] = useState("")
    const [apps, setApps] = useState<Apps[]>([]);


    const [selectedApps, setSelectedApps] = useState<selectedApps[]>(location?.state?.applicationId ? location?.state.applicationId.map((id: any) => ({ applicationId: id })) : []);
    const [importData, setImportData] = useState<ImportData[]>([]);
    const [objAdmin, setObjAdmin] = useState({})
    const [objClient, setObjClient] = useState({})
    const [currentTask, setTask] = useState<Task>(() => {
        const taskName: string = location?.state?.taskName || "Task Migration"
        const taskType: any = location?.state?.taskType || "assessment";
        const taskId: any = location?.state?.taskId
        const environmentType: any = location?.state?.environmentType
        const pageType: any = location?.state?.pageType
        console.log(pageType, "pagetype ra");




        return { taskType, taskId, taskName, environmentType, pageType } as Task; // Assuming task has taskType and taskId properties
    });

    console.log(location, "loc in select apps");

    const [pageType, setPageType] = useState<string>(location?.state?.pageType || "export");
    const [chosenApp, setChosenApp] = useState<string>("");
    const [selectedItems, setSelectedItems] = useState<number[]>(location?.state?.applicationId && pageType != 'export' ? location?.state?.applicationId : []);
    const [validateImport, setValidateImport] = useState<ValidateImport>({
        message: "",
        status: false
    });
    const [invalidData, setInvalidData] = useState([])
    const [loader, setLoader] = useState<boolean>(true);
    const [popup, setPopup] = useState<Popup>({
        errorMessage: "",
        successMessage: ""
    });
    // const [toasterState, setToaster] = useState<Toaster>({
    //     success: false,
    //     failure: false,
    //     message: ""
    // });

    // Client session variables
    const [ctoken, setCToken] = useState<null | string>(null);
    const [tokenExpiration, setTokenExpiration] = useState<Date>(new Date());

    // Admin session variables
    const [atoken, setAToken] = useState<null | string>(null);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [alert, setAlert] = useState<string>('');
    let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
    const [isSessionEpired, setisSessionEpired] = useState(false);
    const [subApps, setSubApps] = useState(false);
    const [selectedEntityApps, setSelectedEntityApps] = useState<SelectedEntityApps>({
        outlook: false,
        shared: false,
        onedrive: false
    });


    // //debugger
    let InActiveTimeout = useRef<NodeJS.Timeout | null>(null);

    const pageTypee = location?.state?.taskType;
    let stage = 4;

    // Set stage based on pageType
    // if (pageTypee === 'migration') {
    //     stage = 4; // Set stage to 0 if pageType is 'selectapps'
    // } else {
    //     stage = location?.state?.stage; // Otherwise, use the existing stage
    // }
    // //console.log(stage, "idhe ra stage ante");

    useEffect(() => {
        debugger
        // window.addEventListener('keydown', resetSessionTimer);
        // window.addEventListener('click', resetSessionTimer);
        // debugger
        sessionTokenProperties();
        if (location?.state?.taskId & location?.state?.isEdit) {
            getSelectedAppsForAssessment()
        }
        resetTimer();
        if (pageType == "selectapps") {
            getApps()
        }
        else {
            getSelectedAppsForAssessment()
        }
        window.addEventListener('keydown', resetTimer);
        window.addEventListener('click', resetTimer);

        return () => {
            if (InActiveTimeout.current) {
                console.log("Select Apps Unmount Time Clear");  
                clearTimeout(InActiveTimeout.current);
            }
            window.removeEventListener('click', resetTimer);
            window.removeEventListener('keydown', resetTimer);
        };

    }, []);

    let resetTimer = async () => {
        try {
            // debugger;
            ////console.log("Reset Timer Start");

            let sessionResponse: string = await resetSessionTimer(InActiveTimeout, SessionEpiredDateTime);
            ////console.log("sessionResponse value :", sessionResponse);

            // //debugger
            if (sessionResponse == 'expired') {
                debugger
                sessionStorage.removeItem("c_at");
                setisSessionEpired(true)
                setAlert('Your Session has Expired please login again to continue');
            }
            else if (sessionResponse == 'updateToken') {
                if (emailAddress == "" || emailAddress == undefined) {
                    await sessionTokenProperties();
                }
                debugger
                let token = await getToken()
                let payload = {
                    mailId: emailAddress,
                    isAdmin: false,
                    token: token
                };
                let getsessionToken = await getSession(payload);
                const rn_secretKey: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY
                const cs_secretKey: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;

                const c_byteValue = objCryptoJS.AES.decrypt(getsessionToken?.data?.data.sessionToken, rn_secretKey);
                const c_decryptedValue = c_byteValue.toString(objCryptoJS.enc.Utf8);
                let updatedToken = objCryptoJS.AES.encrypt(c_decryptedValue, cs_secretKey).toString();
                await sessionStorage.removeItem("c_at");
                await sessionStorage.setItem('c_at', updatedToken);
                let updatedobjClient: any = jwt(c_decryptedValue);
                SessionEpiredDateTime = updatedobjClient.CreatedAt;
                setSessionEpiredDateTime(SessionEpiredDateTime);
            }
            ////console.log("Reset Timer End");
        }
        catch (error) {
            ////console.log("Reset Timer Catch");
            setAlert('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
            setLoader(false);
        }
    }

    const resetSessionTimer = async (InActiveTimeout: any, SessionExpirationDateTime: any) => {
        try {
            // debugger
            ////console.log("Reset Session Timer Start");
            // Assuming InActiveTimeout is a number representing the ID of the current timeout

            if (typeof window !== 'undefined') {
                ////console.log("Reset Session Timer Inside");
                if (InActiveTimeout.current) {
                    clearTimeout(InActiveTimeout.current);
                }
                const tokenExpiryCheckInterval = Number(process.env.REACT_APP_TABIDLESCENARIO);

                InActiveTimeout.current = setTimeout(() => {
                    ////console.log("Expired");
                    sessionStorage.removeItem("c_at");
                    ////console.log("removed session");
                    setisSessionEpired(true)
                    setAlert('Your Session has Expired please login again to continue');
                    // return 'expired'
                }, tokenExpiryCheckInterval);

                ////console.log("Reset Session Timer checking");

                const currentDateTime = new Date().toISOString();
                const sessionExpiredDateTime = new Date(SessionExpirationDateTime).toISOString();
                ////console.log("sessionExpiredDateTime value :", sessionExpiredDateTime);

                const diffInMilliseconds = Math.abs(new Date(sessionExpiredDateTime).getTime() - new Date(currentDateTime).getTime());
                ////console.log("diffInMilliSeconds values:", diffInMilliseconds);

                const minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
                ////console.log("minutes : ", minutes);

                const sessionUpdateTimeGap = Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP);
                ////console.log("Reset Session Timer Differece : ", sessionUpdateTimeGap);

                if (minutes > sessionUpdateTimeGap) {

                    ////console.log("Reset Session Timer need to update");
                    return 'updateToken'
                }

                ////console.log("Reset Session Timer End");
                return 'NoActionRequired'
            }
            return 'NoActionRequired'
        }
        catch (ex) {
            ////console.log("Reset Session Timer Catch");
            throw ex
        }
    };

    let sessionTokenProperties = async () => {
        try {
            const tokenResponse: any = await decodeSessionDetails();
            // debugger
            if (tokenResponse.adminDetails) {
                if (tokenResponse.adminDetails) {
                    setObjAdmin(tokenResponse.adminDetails)
                    setAdminName(tokenResponse.adminDetails.Name);
                    setAdminEmailAddress(tokenResponse.adminDetails.Email);
                    setAdminRole(tokenResponse.adminDetails.Role);
                }
                else {
                    if (window.location.pathname.toString()?.toLowerCase().includes("admin")) {
                        history.push("/Admin/Login")
                    }
                }
            }
            else {
                if (tokenResponse.clientDetails) {
                    setObjClient(tokenResponse.clientDetails)
                    setclientName(tokenResponse.clientDetails.Name);
                    setCompanyName(tokenResponse.clientDetails.CompanyName)
                    emailAddress = tokenResponse.clientDetails.Email;
                    setEmailAddress(emailAddress);
                    setRole(tokenResponse.clientDetails.Role);
                    setSessionEpiredDateTime(tokenResponse.clientDetails.CreatedAt);
                }
                else {
                    history.push("/Login")
                }
            }
            //#endregion
        } catch (error) {
            console.error('Failed in main method', error);
        }
    };

    const getToken = async () => {
        let clientToken: string;
        let adminToken: string;
        let token: { clientToken?: string; adminToken?: string } = {};
        // debugger
        try {
            if (objClient != undefined) {
                let sessionToken: any = sessionStorage.getItem('c_at')
                if (sessionToken) {
                    sessionToken = objCryptoJS.AES.decrypt(sessionToken, c_secretKey)
                    var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
                    clientToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
                    token['clientToken'] = clientToken

                }


            }
            if (objAdmin != undefined) {
                let sessionToken: any = sessionStorage.getItem('a_at')
                if (sessionToken) {
                    sessionToken = objCryptoJS.AES.decrypt(sessionToken, a_secretKey)
                    var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
                    adminToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();

                    token['adminToken'] = adminToken

                }
            }
            return token
        } catch (error) {
            throw error
        }
    }

    // PC_EX_23 - 40
    // THis is to the get the apps from DB
    const getApps = async () => {
        try {
            //debugger
            setLoader(true);

            let token = await getToken()
            let selectAppsPayload = {
                token: token,
                environmentType: location?.state?.environment

            }


            const appData: AxiosResponse | undefined = await getAppsService(selectAppsPayload);

            //console.log("the selected Apps",);

            if (appData?.data.statusCode == 200) {
                ////console.log(appData.data.data[0], "this is the app list");

                setApps(appData?.data.data[0]);

            }
            else {
                setPopup({ ...popup, errorMessage: appData?.data.statusMessage });

            }
        } catch (error) {
            setPopup({ ...popup, errorMessage: commonErrorMsgs.generalError });
        } finally {
            setLoader(false);
        }
    }
    const getSelectedAppsForAssessment = async () => {
        setLoader(true);
        try {
            debugger
            let token = await getToken()
            const response: AxiosResponse = await getSelectedAppsForAssessmentService(token, taskId); // Replace with actual API call
            if (response.data.statusCode == 200) {
                if (location?.state?.taskId & location?.state?.isEdit) {
                    setSelectedItems([response.data?.data[0][0].applicationId]);
                }
                setSelectedApps(response.data?.data[0]);
            }
            else {
                setPopup({ ...popup, errorMessage: response.data.statusMessage });
            }
        } catch (error) {
            setPopup({ ...popup, errorMessage: commonErrorMsgs.generalError });
        } finally {
            setLoader(false);
        }
    };
    const handleErrorMessage = (message: any) => {
        if (message === "You don't have enough license, Please upgrade plan to proceed further") {
            return (
                <p className="primary-data">
                You don't have enough license, Please <a href="/UpgradePlan" style={{ textDecoration: 'none', cursor: 'pointer' }}>upgrade plan</a> to proceed further.
            </p>
            );
        }
        return message;
    };    // PC_EX_204 - 235
    // This handler is used to handle Select and Select All
    const handleImport = async (e: any) => {
        debugger
        setChosenApp("")
        setFileInputValue("")
        setImportData([])
        setLoader(true);
        try {
            debugger
            let token = await getToken()
            const response: AxiosResponse = await customExportAndImportService({ token: token, taskId: taskId, type: `${chosenApp}Import`, users: importData });
            //console.log(response, 'response.dataresponse.data');
            debugger
            if ((response?.data?.users?.errors && response?.data?.users?.errors?.length > 0) || response?.data === "Failed Importing User list") {
                // Toaster.message = response.statusMessage;
                // Toaster.success = true;

                let errors = response?.data?.users?.errors;
                if (errors && errors.length > 0) {
                    // Dynamically determine the keys for the error data
                    const errorKeys = new Set<string>();
                    errors.forEach((error: any) => {
                        Object.keys(error).forEach((key: string) => errorKeys.add(key));
                    });

                    // Prepare error data for CSV
                    const errorData = errors.map((error: any) => {
                        const formattedError: { [key: string]: string } = {};
                        errorKeys.forEach((key: string) => {
                            // Convert key to a more readable format
                            const formattedKey = key
                                .replace(/([A-Z])/g, ' $1')
                                .replace(/^./, (str: string) => str.toUpperCase());
                            formattedError[formattedKey] = error[key];
                        });
                        return formattedError;
                    });

                    // Generate CSV data
                    const headers = Object.keys(errorData[0]);
                    const rows = [headers, ...errorData.map((obj: any) => headers.map(header => obj[header] || 'N/A'))];
                    const csvContent = rows.map(row => row.join(',')).join('\n');

                    // Create Blob and trigger download
                    const csvData = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
                    saveAs(csvData, `${chosenApp}_ImportErrors.csv`);

                    // You can still set this error data to a state if needed
                    setErrorDataForExcel(errorData);
                    setInvalidData(response.data.data)
                    // Update popup with warning message
                    setPopup(prevPopup => ({
                        ...prevPopup,
                        warningMessage: `Import completed with ${errors.length} errors. Error report has been downloaded.`
                    }));
                }
            }
            else if (response?.data?.users?.processedData ? (response?.data?.users?.processedData?.length != 0 && response?.data?.users?.errors?.length == 0) : (response?.data?.users?.length != 0)) {
                let transformedProcessedData: any;
                if (chosenApp === 'sharepoint') {
                    transformedProcessedData = response?.data?.users?.processedData.map((item: any) => ({
                        "Site Name": item.SourceSPSiteName,
                        "Site Url": item.SourceSPSiteURL
                    }));
                } else if (chosenApp === 'teamschannel') {
                    transformedProcessedData = response?.data?.users?.processedData.map((item: any) => ({
                        "Team Name": item.SourceTeamName,
                        "Team ID": item.SourceTeamID,
                        "Channel Name": item.SourceChannelName,
                        "Channel ID": item.SourceChannelID
                    }));
                }

                else if (chosenApp === 'outlook') {
                    transformedProcessedData = response?.data?.users.map((item: any) => ({
                        "User Name": item['User Name'],
                        "User Email": item['User Email']
                    }));
                }

                else if (chosenApp === 'entity') {
                    transformedProcessedData = response?.data?.users.map((item: any) => ({
                        "User Name": item['User Name'],
                        "User Email": item['User Email']
                    }));
                }
                //console.log("the transformed data",transformedProcessedData);

                // setImportData(transformedProcessedData);
                setPopup({ ...popup, successMessage: "Imported Successfully" });
                setChosenApp("")
                ////console.log("HEREEEEEEEEEEE");
                setImportResults(prevResults => [
                    ...prevResults,
                    ({
                        type: `${chosenApp}Import`,
                        data: transformedProcessedData
                    })
                ]);

                // Toaster.message = response.statusMessage;
                // Toaster.failure = true;

            } else {
                setLoader(false)
                setPopup({ ...popup, errorMessage: commonErrorMsgs.generalError });
            }
        } catch (error) {
            //console.log("The error in the Import", error);

        } finally {
            setLoader(false);
            //console.log("the results of import Results",importResults);

            //console.log("the importData", importData);

        }
    };
    const handleButtons = async (e: any) => {
        //console.log("the import 1 data",importData);

        try {
            debugger
            let applicationName: string[] = []
            let applicationId: number[] = []
            switch (e.currentTarget.id) {
                case 'assessmentnext':
                    applicationName = apps.filter((element) => selectedItems.includes(element.applicationId)).map((item) => item.applicationName)
                    applicationId = selectedItems
                    history.push('/Connectors', { state: { ...location?.state, applicationName, applicationId } });


                    break;
                case 'assessmentcancel':
                    history.push('/Task');
                    break
                case 'assessmentback':
                    history.push('/Task');
                    break
                case 'migrationcancel':
                    history.push('/Task');
                    break
                case 'assessmentexportimportcancel':
                    // Navigate to task grid
                    history.push('/Task');
                    break;
                case 'assessmentexportimportback':
                    // Navigate to task grid
                    history.push('/AssessmentConfigurations', { state: { applicationName, applicationId, selectedApps, ...location?.state } });
                    break;
                case 'importbacknavigationarrow':
                    // Navigate to task grid
                    history.push('/Task');
                    break;
                case 'backnavigationarrow':
                    // Navigate to task grid
                    history.push('/Task');
                    break;
                case 'migrationnext':
                    // Navigate to Connectors with selectedItems, task.taskType in location
                    applicationId = selectedItems
                    applicationName = apps.filter((element) => selectedItems.includes(element.applicationId)).map((item) => item.applicationName)
                    history.push('/Connectors', { state: { ...location?.state, applicationName, applicationId, selectedApps } });


                    break;
                case 'startassessment':
                    // Set the loader to true
                    debugger
                    setLoader(true);
                    //console.log("the import data", importData);

                    // Simulate the assessment process and check statusCode
                    let token: object = await getToken()
                    let appsAssessment: any = selectedApps.filter((element) => selectedItems.includes(element.applicationId)).map((item) => {
                        let data = {
                            applicationName: item.applicationName,
                            applicationId: item.applicationId
                        }
                        return data
                    })
                    const preparedImportResults: any = {
                        outlook: null,
                        gmail: null,
                        mydrive: null,
                        teamschannel: null,
                        sharepoint: null,
                        entity: null
                    };


                    // Get the application names that have been selected in selectedEntityApps
                    let selectedEntityNames: any = Object.entries(selectedEntityApps)
                        .filter(([appName, isSelected]) => isSelected)
                        .map(([appName, _]) => ({ applicationName: appName }));

                    // Combine the currently selected apps with the new selections
                    appsAssessment = [...appsAssessment, ...selectedEntityNames];

                    appsAssessment = appsAssessment.filter((obj: { applicationName: string; }) =>
                        !(obj.applicationName.toLowerCase() === 'outlook' && 'applicationId' in obj)
                    );

                    let hasOutlook = appsAssessment.some((obj: { applicationName: string; }) => obj.applicationName.toLowerCase() === 'outlook');
                    let hasShared = appsAssessment.some((obj: { applicationName: string; }) => obj.applicationName.toLowerCase() === 'shared');

                    // If both exist, remove the 'shared' object
                    if (hasOutlook && hasShared) {
                        appsAssessment = appsAssessment.filter((obj: { applicationName: string; }) => obj.applicationName.toLowerCase() !== 'shared');
                    }

                    //console.log(appsAssessment)

                    importResults.forEach(result => {
                        // Remove 'Import' from the type string to get the app name
                        const appName = result.type.replace('Import', '').toLowerCase();
                        if (preparedImportResults.hasOwnProperty(appName)) {
                            preparedImportResults[appName] = result.data;
                        }
                    });
                    if (importData.length > 0) {
                        preparedImportResults.sharepoint = importData;
                    }
                    ////console.log({ token: token, taskId: currentTask.taskId, apps: appsAssessment });
                    //console.log("the payload for the start assessment,", token, taskId, appsAssessment);
                    //console.log("the payload for the start assessmnet ", ({ token: token, taskId: taskId, apps: appsAssessment, environment: environment }));
                    //console.log("thr imported data ", importResults);

                    let result: any = await startAssessmentService({ token: token, taskId: taskId, apps: appsAssessment, environment: environment, importData: preparedImportResults, hasOutlook, hasShared })
                    console.log("the response", result);
debugger
                    if (result.data.statusCode == 200 && result?.data?.statusMessage=="Assessment initiated Successfully") {
                        setLoader(false);
                        history.push('/Inprogress', { ...location });
                    } else if(result?.data?.statusCode==403) {
                        setLoader(false);
                        setPopup({ ...popup, errorMessage: result.data.statusMessage });
                        if (result.data.statusMessage == "You don't have enough license, Please upgrade plan to proceed further") {
                            let message = await handleErrorMessage(result.data.statusMessage)
                            setPopup({ ...popup, errorMessage: message });
                        }
                    }
                    else{
                        setLoader(false);
                        setPopup({ ...popup, errorMessage: commonErrorMsgs.generalError });


                    }
                    break;

            }
        } catch (error) {
            // If an error occurs during button handling, Show Error popup
            setLoader(false)
            setPopup({ ...popup, errorMessage: commonErrorMsgs.generalError });
        }
    };


    const insertLastVisitedPageValue = async (page: string) => {
        try {
            //console.log(page, "This is the last inserted pageeeee");

            let token = await getToken()

            const payload = {
                token: token,
                taskId: currentTask.taskId,
                lastVisitedValue: page
            };

            ////console.log(payload, 'last visited');

            // Insert only newly entered records into the database
            const response: any = await insertLastVisitedPageValueApi(payload);

            if (!response) {
                ////console.log('The current page value failed to insert');
            } else {
                ////console.log('The current page value inserted successfully');

            }
        } catch (error) {
            console.error('Error inserting records:', error);
        }
    };

    // PC_EX_190 - 202
    // This handler is used to handle Select and Select All
    const handleSelectAndSelectAll = (e: any) => {
        debugger
        try {
            if (currentTask.taskType?.toLowerCase() == "migration") {
                setSelectedItems([parseInt(e.currentTarget?.id?.split('?')[1])])
                // ////console.log("Pushed Item : ",items);

                //setSelectedItems([...selectedItems]);
            }
            else {
                if (e.target.id.startsWith("all")) {
                    if (!e.currentTarget.checked) {
                        setSelectedItems([])
                        setSelectedEntityApps({
                            outlook: false,
                            shared: false,
                            onedrive: false
                        });
                    }
                    else {
                        if (pageType == "selectapps") {
                            let appId: number[] = apps.map((item) => item.applicationId)
                            setSelectedItems(appId)

                        }
                        else {
                            let appId: number[] = selectedApps.map((item) => item.applicationId)
                            setSelectedItems(appId)
                            setSelectedEntityApps({
                                outlook: true,
                                shared: true,
                                onedrive: true
                            });
                        }
                    }
                }
                else {
                    if (e.currentTarget.checked) {
                        debugger
                        // let appId = apps.filter((element)=> element.applicationId == e.currentTarget?.id?.split('?')[1])
                        // let items = selectedItems
                        // items.push(parseInt(e.currentTarget?.id?.split('?')[1]))
                        // setSelectedItems(items)
                        // selectedItems.push(parseInt(e.currentTarget?.id?.split('?')[1]))
                        // setSelectedItems(selectedItems)
                        ////console.log(selectedItems);
                        ////console.log(e.currentTarget?.id?.split('?')[1]);
                        ////console.log(parseInt(e.currentTarget?.id?.split('?')[1]));
                        // let items = selectedItems
                        selectedItems.push(parseInt(e.currentTarget?.id?.split('?')[1]))
                        // ////console.log("Pushed Item : ",items);

                        setSelectedItems([...selectedItems]);
                        ////console.log(parseInt(e?.currentTarget?.id?.split('?')[1]));
                        ////console.log(parseInt(e?.currentTarget?.id?.split('?')[1]));

                    }
                    else {
                        let appId = selectedItems
                        appId = appId.filter((item) => item != e.currentTarget?.id?.split('?')[1])
                        setSelectedItems(appId)
                        if (e.currentTarget?.id?.split('?')[1] == '1') {
                            setSelectedEntityApps({
                                outlook: false,
                                shared: false,
                                onedrive: false
                            });
                        }
                    }
                }
                // setSelectedItems((pre) => [e.target.id, ...pre])
            }
        } catch (error) {
            // Show the Error popup
            setPopup({ ...popup, errorMessage: commonErrorMsgs.generalError });
        }
    };


    // PS_EX_132 - PS_EX_163
    // This handler is used to handle Download and upload of expor import file
    const handleDownloadUpload = async (e: any) => {

        setLoader(true);
        const templates: { [key: string]: any } = {
            User_Mailbox: exportTemplate,
            Entity: exportTemplate,
            Gmail: exportTemplate,     // Assuming you have a Gmail template
            MyDrive: exportTemplate,   // Assuming you have a GDrive template
            SharePoint: sharePointExportTemplate,
            TeamsChannel: teamschannelExportTemplate,     // Assuming you have a Teams template
        };
        //console.log(chosenApp, ' ---chosenApp');

        try {
            debugger
            if (e.currentTarget.id === 'download' && (chosenApp === 'entity' || chosenApp === 'outlook' || chosenApp === 'gmail' || chosenApp === 'mydrive' || chosenApp === 'teamschannel' || chosenApp === 'sharepoint')) {
                const templateMapping = {
                    'entity': 'Entity',
                    'outlook': 'Entity',
                    'gmail': 'Gmail',
                    'mydrive': 'MyDrive',
                    'teamschannel': 'TeamsChannel',
                    'sharepoint': 'SharePoint'
                };
                const templateName = templateMapping[chosenApp] || chosenApp;
                const template = templates[templateName];
                //console.log("the application", template);

                if (template) {
                    const headers = Object.keys(template);
                    const rows = [headers]; // Headers as the first row of the CSV
                    // Add the template values as the second row of the CSV
                    rows.push(headers.map((header: any) => template[header]).filter(Boolean))

                    // Generate CSV data
                    const finalExportData = rows.map(row => row.join(',')).join('\n');
                    const csvData = new Blob([finalExportData], { type: 'text/csv;charset=utf-8;' });

                    // Trigger file download
                    saveAs(csvData, `${templateName}_Template.csv`);
                } else {
                    // Handle the case where the template doesn't exist for chosen app
                    console.error(`No template found for ${chosenApp}`);
                    setPopup({ ...popup, errorMessage: "No template found for the selected application." });
                }
                setLoader(false);
            }
            else if (e.target.id === 'upload') {
                setLoader(true);

                if (!e.target.files?.[0].name.endsWith('.csv')) {
                    setImportData([]);
                    setFileInputValue("");
                    setValidateImport({ status: true, message: importMsgs.invalidFile });
                } else {
                    const file = e.target.files[0];
                    const reader = new FileReader();

                    reader.onload = (event) => {
                        const csvContent = event.target?.result?.toString();
                        if (csvContent) {
                            debugger
                            const rows = csvContent.split('\n').filter(row => row.trim() !== '');
                            const [headersRow, ...dataRows] = rows;
                            const headers: string[] = headersRow.split(',').map((header: string) => header.trim());

                            let jsonData: any = dataRows.map((rowString: string) => {
                                // Split the row, respecting quoted values
                                const row = rowString.match(/"([^"]*)"|[^,]+/g) || [];
                                const obj: { [key: string]: string } = {};
                                headers.forEach((header: string, index: number) => {
                                    let value = row[index]?.trim() || '';
                                    // Remove quotes if present
                                    if (value.startsWith('"') && value.endsWith('"')) {
                                        value = value.slice(1, -1);
                                    }
                                    obj[header] = value;
                                });
                                return obj;
                            });

                            debugger

                            const requiredFieldsByApp: { [key: string]: string[] } = {
                                entity: ['Entity Name', 'Entity Email Address', 'Entity Type'],
                                outlook: ['Entity Name', 'Entity Email Address', 'Entity Type'],
                                gmail: ['User Name', 'User Email'],
                                mydrive: ['User Name', 'User Email'],
                                sharepoint: ['Site Name', 'Site Url'],
                                teamschannel: ['Team Name', 'Team ID', 'Channel Name', 'Channel ID']
                            };

                            // Convert CSV content to an array of objects based on the CSV headers
                            // Validation logic for each chosen application, assuming it needs to check for non-empty required fields
                            debugger // look this
                            if (requiredFieldsByApp[chosenApp]?.every(field => headers.includes(field))) {
                                // Ensure there are no empty values in required fields
                                const isEmptyFieldPresent = jsonData.some((obj: any) =>
                                    requiredFieldsByApp[chosenApp].some(field => !obj[field]?.toString().trim())
                                );

                                const areAllFieldsEmpty = jsonData.every((obj: any) =>
                                    requiredFieldsByApp[chosenApp].every(field => !obj[field]?.toString().trim())
                                );

                                if (areAllFieldsEmpty || isEmptyFieldPresent) {
                                    setImportData([]);
                                    setFileInputValue('');
                                    setValidateImport({ status: true, message: areAllFieldsEmpty ? importMsgs.emptyFile : importMsgs.emptyValues });
                                } else {
                                    // Assume import data is valid and set state accordingly
                                    if (chosenApp.toLowerCase() == 'outlook') {
                                        jsonData = jsonData.map((obj: { [x: string]: any; }) => ({
                                            "User Name": obj["Entity Name"],
                                            "User Email": obj["Entity Email Address"],
                                            "Resource Type": obj["Entity Type"]
                                        }));
                                    }

                                    setImportData(jsonData);
                                    setFileInputValue(file.name);
                                    setValidateImport({ status: false, message: "" });
                                }
                            } else {
                                // If a required header is missing, validation fails
                                setFileInputValue('');
                                setValidateImport({ status: true, message: importMsgs.invalidColumns });
                            }

                            // Reset the file input value to allow selecting the same file again
                            e.target.value = null;
                        }
                    };

                    reader.onerror = () => {
                        setValidateImport({ status: true, message: importMsgs.invalidFile });
                    };

                    reader.readAsText(file);
                }

                setImportData([]);
                setFileInputValue("");
                setValidateImport({ status: false, message: '' });
                setLoader(false);
            }
        } catch (error) {
            setPopup({ ...popup, errorMessage: commonErrorMsgs.generalError });
        } finally {
            setLoader(false);
        }
    };

    // PC_EX_159- 187
    // This handler is used to handle import of csv file and provide with the invalid data if failed



    // PS_EX_115- 129
    // This handler is used to handle export of csv file and provide with the export data 
    const handleExport = async (appName: string) => {
        setLoader(true);
        debugger
        try {
            let token = await getToken();
            const exportDataResponse = await customExportAndImportService({
                token: token,
                taskId: taskId,
                type: `${appName == 'entity' ? 'outlook' : appName}Export`
            });
            //console.log("the payload", exportDataResponse);

            //console.log("the response for the export scenario", exportDataResponse);
            //console.log("the response for the Array scenario", exportDataResponse.data);


            if (exportDataResponse.status === 200) {

                debugger
                //console.log("the statuscode", exportDataResponse.status)
                //console.log("the statuscode for the response", exportDataResponse.data)
                let exportData = Array.isArray(exportDataResponse.data) ? exportDataResponse.data : [];
                //console.log("the exportData", exportData);
                const templateMapping: any = {
                    'outlook': 'Entity',
                    'gmail': 'Gmail',
                    'mydrive': 'MyDrive',
                    'teamschannel': 'TeamsChannel',
                    'sharepoint': 'SharePoint',
                    'entity': 'Entity'
                };
                const templateName = templateMapping[appName] || appName;
                exportData = exportData.map((item: any) => {
                    // Add object mappings for each application's export format
                    const objMappings: { [key: string]: (item: any) => { [key: string]: string } } = {
                        'outlook': (item: any) => ({ "Entity Name": item.displayName, "Entity Email Address": item.mailId, "Entity Type": item.resourceType }),
                        'entity': (item: any) => ({ "User Name": item.displayName, "User Email": item.mailId, "Resource Type": item.resourceType }),
                        'gmail': (item: any) => ({ "User Name": item.displayName, "User Email": item.mailId }),
                        'mydrive': (item: any) => ({ "User Name": item.displayName, "User Email": item.mailId }),
                        'sharepoint': (item: any) => ({ "Site Name": item.SiteName, "Site Url": item.SiteUrl }),
                        'teamschannel': (item: any) => ({ "Team Name": item.TeamName, "Team ID": item.TeamId, "Channel Name": item.TeamChannelName, "Channel ID": item.TeamChannelId }),
                    };

                    // //console.log("the sharepoint", objMappings);

                    // Return the mapped object using the correct format for the appName
                    return objMappings[appName] ? objMappings[appName](item) : {};
                });

                if (exportData?.length > 0) {
                    const headers = Object.keys(exportData[0]);
                    //console.log("Headers:", headers);
                    let rows = exportData.map((obj: any) =>
                        headers.map(header => {
                            const value = obj[header];
                            //console.log(`Processing ${header}: ${value}`);
                            return `"${(value || '').toString().replace(/"/g, '""')}"`;
                        })
                    );
                    //console.log("Rows:", rows);
                    rows.unshift(headers);
                    let finalExportData = rows.map((row: any) => row.join(',')).join('\n');
                    //console.log("Final Export Data:", finalExportData);
                    let csvData = new Blob([finalExportData], { type: 'text/csv;charset=utf-8;' });
                    saveAs(csvData, `${templateName}_Export.csv`);
                }
            } else {
                // Handle failure case, maybe by showing the message in a toaster or popup
                const errorMessage = exportDataResponse.data.message;
                setPopup({ ...popup, errorMessage: commonErrorMsgs.generalError });
            }

        } catch (error) {
            // Handle any errors that occur during the export process
            // setPopup({ ...popup, errorMessage: commonErrorMsgs.generalError });
        } finally {
            // Loader is turned off after the export process completes or fails
            setLoader(false);
        }
    };



    function handleInvalidDataDownload() {
        try {
            debugger
            const headers = Object.keys(invalidData[0]);
            let rows = invalidData.map((obj: any) => headers.map(header => obj[header] || ''));
            rows.unshift(headers);
            let invalid = rows.map((row: any) => row.join(',')).join('\n');
            let csvData = new Blob([invalid], { type: 'text/csv;charset=utf-8;' });
            saveAs(csvData, `Invalid_Data.csv`);
            setInvalidData([])

        } catch (error) {
            setInvalidData([])
            //  setPopup({ ...popup, errorMessage: commonErrorMsgs.generalError });
        }
    }


    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        setSelectedEntityApps(prev => ({ ...prev, [name]: checked }));
    };

    //console.log(selectedEntityApps, "    selectedEntityApps")

    //console.log(selectedItems, " ----------selectedItems")

    //console.log(importResults, "test")

    //console.log(selectedApps, "appss")
    return (
        <>
            {currentTask.taskType?.toLowerCase() == "migration" && <div className="container-fluid container-height">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card px-4 py-4 mt-3 mb-3 border-0 rounded-3">
                            {/* bread crumb starts here */}
                            <ul className="breadcrumb-item mb-4 ps-0">
                                <li>
                                    <a onClick={() => history.push('/Task')} className="pe-0">
                                        Task
                                    </a>
                                </li>
                                <li className="pe-2">{currentTask.taskName}</li>
                            </ul>
                            {/* bread crumb ends here */}
                            <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap gap-3 mt-2">
                                <h2 className="primary-header mb-0">
                                    <a onClick={() => history.push('/Task')} className="pe-0">
                                        <img
                                            src="/images/head-back-arrow.svg"
                                            alt="back-arrow"
                                            width={19}
                                            className="me-2"
                                        />
                                    </a>
                                    {currentTask.taskName} - Migration
                                </h2>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card p-md-4 border-0 rounded-3">
                                        <div className="row justify-content-center">
                                            {/* Wizard structure starts here */}
                                            <div className="col-md-7">
                                                <div className="d-flex justify-content-between align-items-center step-wizard">
                                                    <div className="d-flex align-items-center gap-1 bg-white z-index-1">
                                                        <div className="wizard-step-num d-flex justify-content-center align-items-center font-bold font-14 active">
                                                            1
                                                        </div>
                                                        <span className="bg-white me-1 font-14 font-semibold d-none d-xl-block wizard-step-text active">
                                                            Select App
                                                        </span>
                                                    </div>
                                                    <div className="d-flex align-items-center gap-1 bg-white z-index-1 cursor-pointer">
                                                        <div className="wizard-step-num d-flex justify-content-center align-items-center font-bold font-14"
                                                            onClick={() => {
                                                                if (location?.state?.stage && location?.state?.stage >= 2) {

                                                                    history.push('/Connectors', {
                                                                        state: {
                                                                            ...location?.state,
                                                                            isEdit: true,
                                                                            stage: (location?.state?.stage && location?.state?.stage > stage) ? location?.state?.stage : stage
                                                                        }
                                                                    });
                                                                }
                                                            }}
                                                        >
                                                            2
                                                        </div>
                                                        <span className="bg-white me-1 font-14 font-semibold d-none d-xl-block wizard-step-text"
                                                            onClick={() => {
                                                                if (location?.state?.stage && location?.state?.stage >= 2) {

                                                                    history.push('/Connectors', {
                                                                        state: {
                                                                            isEdit: true,
                                                                            ...location?.state,
                                                                            stage: (location?.state?.stage && location?.state?.stage > stage) ? location?.state?.stage : stage
                                                                        }
                                                                    });
                                                                }
                                                            }}
                                                        >
                                                            Connectors
                                                        </span>
                                                    </div>
                                                    <div className="d-flex align-items-center gap-1 bg-white z-index-1 cursor-pointer"

                                                        onClick={() => {
                                                            if (location?.state?.stage && location?.state?.stage >= 3) {
                                                                history.push("/Configurations", {
                                                                    state: {
                                                                        ...location?.state
                                                                    },
                                                                })
                                                            }


                                                        }}>
                                                        <div className="wizard-step-num d-flex justify-content-center align-items-center font-bold font-14 " >
                                                            3
                                                        </div>
                                                        <span className="bg-white me-1 font-14 font-semibold d-none d-xl-block wizard-step-text" >
                                                            Configurations
                                                        </span>
                                                    </div>

                                                    <div className="d-flex align-items-center gap-1 bg-white z-index-1 cursor-pointer"
                                                        onClick={() => {
                                                            if (location?.state?.taskId && location?.state?.isEdit && location?.state?.stage >= 4) {
                                                                location?.state?.taskType?.toLowerCase() == "assessment" ?
                                                                    history.push("/Assessment", {
                                                                        state: {
                                                                            ...location.state,
                                                                            taskId: currentTask.taskId,
                                                                            taskName: location?.state?.taskName,
                                                                            applicationName: location?.state?.applicationName,
                                                                            isEdit: true,
                                                                            stage: ((location?.state?.stage) && (location?.state?.stage > stage)) ? location?.state?.stage : stage
                                                                        },
                                                                    })
                                                                    :
                                                                    history.push("/Migration", {
                                                                        state: {
                                                                            ...location.state,
                                                                            taskId: currentTask.taskId,
                                                                            taskName: location?.state?.taskName,
                                                                            applicationName: location?.state?.applicationName,
                                                                            isEdit: true,
                                                                            stage: ((location?.state?.stage) && (location?.state?.stage > stage)) ? location?.state?.stage : stage
                                                                        },
                                                                    })
                                                            }
                                                        }}>
                                                        <div className="wizard-step-num d-flex justify-content-center align-items-center font-bold font-14">
                                                            4
                                                        </div>
                                                        <span className="bg-white me-1 font-14 font-semibold d-none d-xl-block wizard-step-text">
                                                            Migration
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Wizard structure ends here */}
                                            <div className="col-md-9 mt-4 mt-md-5">
                                                <h3 className="m-0 font-22 font-bold mb-3">Select App</h3>
                                                <div className="p-3 mb-4 d-flex align-items-start gap-2 border-radius-8 info-div-bg">
                                                    <img src="/images/tooltip.svg" alt="tooltip" width={11} />
                                                    <span className="lh-1 font-12 cust-role-color">
                                                        Choose any one specific app to proceed with migration
                                                    </span>
                                                </div>
                                                <div className="row" style={{
                                                    cursor: location.state?.taskId ? 'not-allowed' : 'pointer',
                                                    pointerEvents: location.state?.taskId ? 'none' : 'auto', // Prevent interactions
                                                }}>
                                                    {/* Cards */}
                                                    {/* <div className="col-md-3 mb-4 mb-md-5">
                                                            <div className="card h-100 py-4 ps-4 pe-3 border-radius-8 position-relative app-card card-hover cursor-pointer card-selected">
                                                                <img
                                                                    className="mb-2"
                                                                    src="/images/user-migration-icon.svg"
                                                                    alt="Group migration"
                                                                    width={39}
                                                                />
                                                                <h4 className="font-14 font-semibold label-color m-0 mt-3">
                                                                    Groups
                                                                </h4>
                                                                <p
                                                                    className="font-regular label-color font-12 m-0 mt-2"
                                                                    data-bs-toggle="tooltip"
                                                                    data-bs-title="Tooltip content"
                                                                >
                                                                    This allows users to connect with like-minded
                                                                    individuals, creating a sense of community ...
                                                                </p>
                                                                <img
                                                                    src="/images/selected-icon.svg"
                                                                    alt="selected"
                                                                    className="position-absolute app-card-selected"
                                                                />
                                                            </div>
                                                        </div> */}
                                                    {/*Cards*/}
                                                    {/*Cards*/}
                                                    {apps?.map((item: Apps) => {
                                                        return (
                                                            <>
                                                                <div className="col-md-4 col-lg-3 mb-4 mb-md-5">
                                                                    <div className={selectedItems.includes(item.applicationId) ? "app-card card h-100 py-4 ps-4 pe-3 border-radius-8 card-hover cursor-pointer card-selected" : "app-card card h-100 py-4 ps-4 pe-3 border-radius-8 card-hover cursor-pointer"} onClick={(e) => { handleSelectAndSelectAll(e) }} id={`${item.applicationName}?${item.applicationId}`}>
                                                                        <img
                                                                            className="mb-2"
                                                                            src={`/images/${item.applicationName}.svg`}
                                                                            alt={item.applicationName}
                                                                            width={46}
                                                                        />
                                                                        <h4 className="font-14 font-semibold label-color m-0 mt-3">
                                                                            {item.applicationName == "outlook" ? "Entity" : item.applicationName == "entity" ? "Entity" : item.applicationName == "sharedmailbox" ? "Shared Mailbox" : item.applicationName == "roomsandequipments" ? "Rooms & Equipment's" : item.applicationName == "teamschannel" ? "Teams Channel" : item.applicationName == "teamschat" ? "Teams Chat" : item.applicationName == "onedrive" ? "OneDrive" : item.applicationName == "sharepoint" ? "SharePoint" : item.applicationName === "gmail" ? "G-Mail" : item.applicationName === "mydrive" ? "My Drive" : item.applicationName === "shareddrive" ? "Shared Drive" : item.applicationName == "ggroups" ? "Groups" : item.applicationName == "sites" ? "Sites" : item.applicationName == "chats" ? "Chats" : item.applicationName == "shared drive" ? "Shared Drive" : null}
                                                                        </h4>
                                                                        <p
                                                                            className="font-regular label-color font-12 m-0 mt-2"
                                                                            data-bs-toggle="tooltip"
                                                                            data-bs-placement="right"
                                                                            data-bs-title="App Description"
                                                                            title={appDescripions[`${item.applicationName}`]}
                                                                        >
                                                                            {appDescripions[`${item.applicationName}`]}
                                                                        </p>
                                                                        <img src="/images/selected-icon.svg" alt="selected" className="position-absolute app-card-selected" />

                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    })}
                                                    {/*Cards*/}
                                                    {/*Cards*/}
                                                    {/* <div className="col-md-3 mb-4 mb-md-5">
                                                            <div className="card h-100 py-4 ps-4 pe-3 border-radius-8 card-hover cursor-pointer">
                                                                <img
                                                                    className="mb-2"
                                                                    src="/images/shared-mailbox-icon.svg"
                                                                    alt="Shared Mailbox"
                                                                    width={38}
                                                                />
                                                                <h4 className="font-14 font-semibold label-color m-0 mt-3">
                                                                    Shared Mailbox
                                                                </h4>
                                                                <p
                                                                    className="font-regular label-color font-12 m-0 mt-2"
                                                                    data-bs-toggle="tooltip"
                                                                    data-bs-title="Tooltip content"
                                                                >
                                                                    It is a is a collaborative email inbox in Microsoft
                                                                    365 or Office 365 that multiple users can ...
                                                                </p>
                                                            </div>
                                                        </div> */}
                                                    {/*Cards*/}
                                                    {/*Cards*/}
                                                    {/* <div className="col-md-3 mb-4 mb-md-5">
                                                            <div className="card h-100 py-4 ps-4 pe-3 border-radius-8 card-hover cursor-pointer">
                                                                <img
                                                                    className="mb-2"
                                                                    src="/images/rooms-n-equipment-icon.svg"
                                                                    alt="Rooms & Equipment's"
                                                                    width={39}
                                                                />
                                                                <h4 className="font-14 font-semibold label-color m-0 mt-3">
                                                                    Rooms &amp; Equipment's
                                                                </h4>
                                                                <p
                                                                    className="font-regular label-color font-12 m-0 mt-2"
                                                                    data-bs-toggle="tooltip"
                                                                    data-bs-title="Tooltip content"
                                                                >
                                                                    Ts is streamline booking and management of meeting
                                                                    spaces and resources within ...
                                                                </p>
                                                            </div>
                                                        </div> */}
                                                    {/*Cards*/}
                                                    {/*Cards*/}
                                                    {/* <div className="col-md-3 mb-4 mb-md-5">
                                                            <div className="card h-100 py-4 ps-4 pe-3 border-radius-8 card-hover cursor-pointer">
                                                                <img
                                                                    className="mb-2"
                                                                    src="/images/teams-logo.svg"
                                                                    alt="Teams Channel"
                                                                    width={43}
                                                                />
                                                                <h4 className="font-14 font-semibold label-color m-0 mt-3">
                                                                    Teams Channel
                                                                </h4>
                                                                <p
                                                                    className="font-regular label-color font-12 m-0 mt-2"
                                                                    data-bs-toggle="tooltip"
                                                                    data-bs-title="Tooltip content"
                                                                >
                                                                    It is a collaborative space within Microsoft Teams
                                                                    where team members can ...
                                                                </p>
                                                            </div>
                                                        </div> */}
                                                    {/*Cards*/}
                                                    {/*Cards*/}
                                                    {/* <div className="col-md-3 mb-4 mb-md-5">
                                                            <div className="card h-100 py-4 ps-4 pe-3 border-radius-8 card-hover cursor-pointer">
                                                                <img
                                                                    className="mb-2"
                                                                    src="/images/teams-logo.svg"
                                                                    alt="Teams Chat"
                                                                    width={43}
                                                                />
                                                                <h4 className="font-14 font-semibold label-color m-0 mt-3">
                                                                    Teams Chat
                                                                </h4>
                                                                <p
                                                                    className="font-regular label-color font-12 m-0 mt-2"
                                                                    data-bs-toggle="tooltip"
                                                                    data-bs-title="Tooltip content"
                                                                >
                                                                    This allows users to connect with like-minded
                                                                    individuals, creating a sense of community ...
                                                                </p>
                                                            </div>
                                                        </div> */}
                                                    {/*Cards*/}
                                                    {/*Cards*/}
                                                    {/* <div className="col-md-3 mb-4 mb-md-5">
                                                            <div className="card h-100 py-4 ps-4 pe-3 border-radius-8 card-hover cursor-pointer">
                                                                <img
                                                                    className="mb-2"
                                                                    src="/images/Microsoft_Office_onedrive-logo.svg"
                                                                    alt="One Drive"
                                                                    width={56}
                                                                />
                                                                <h4 className="font-14 font-semibold label-color m-0 mt-3">
                                                                    One Drive
                                                                </h4>
                                                                <p
                                                                    className="font-regular label-color font-12 m-0 mt-2"
                                                                    data-bs-toggle="tooltip"
                                                                    data-bs-title="Tooltip content"
                                                                >
                                                                    It is a cloud storage service that allows users to
                                                                    securely store, share, and access ...
                                                                </p>
                                                            </div>
                                                        </div> */}
                                                    {/*Cards*/}
                                                    {/*Cards*/}
                                                    {/* <div className="col-md-3 mb-4 mb-md-5">
                                                            <div className="card h-100 py-4 ps-4 pe-3 border-radius-8 card-hover cursor-pointer">
                                                                <img
                                                                    className="mb-2"
                                                                    src="/images/Microsoft-SharePoint-Logo.svg"
                                                                    alt="SharePoint"
                                                                    width={42}
                                                                />
                                                                <h4 className="font-14 font-semibold label-color m-0 mt-3">
                                                                    SharePoint
                                                                </h4>
                                                                <p
                                                                    className="font-regular label-color font-12 m-0 mt-2"
                                                                    data-bs-toggle="tooltip"
                                                                    data-bs-title="Tooltip content"
                                                                >
                                                                    It is a s a web-based platform by Microsoft that
                                                                    enables organizations to create ...
                                                                </p>
                                                            </div>
                                                        </div> */}
                                                    {/*Cards*/}
                                                </div>
                                                <div className="d-md-flex justify-content-end mt-5 mt-lg-0">
                                                    <button className="btn secondary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center me-3 mb-3 mb-md-0" id='migrationcancel' onClick={(e) => { handleButtons(e) }}>
                                                        Cancel
                                                    </button>
                                                    <button className="btn primary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center mb-3 mb-md-0" id='migrationnext' disabled={selectedItems?.length == 0} onClick={(e) => { handleButtons(e) }}>
                                                        Next
                                                        <img src="/images/right-arw.svg" alt="next" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            {(currentTask.taskType?.toLowerCase() == "assessment" && pageType == "selectapps") && <div className="container-fluid container-height">
                <div className=" row pb-5 mb-5 pb-lg-0 mb-lg-0">
                    <div className="col-md-12">

                        <div className="card px-4 py-4 mt-3 mb-3 border-0 rounded-3">
                            {/* bread crumb starts here */}
                            <ul className="breadcrumb-item mb-4 ps-0">
                                <li>
                                    <a onClick={() => { history.push('/Task') }} className="pe-0">
                                        Task
                                    </a>
                                </li>
                                <li className="pe-2">{currentTask.taskName}</li>
                            </ul>
                            {/* bread crumb ends here */}
                            <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap gap-3 mt-2">
                                <h5 className="primary-header mb-0 d-flex align-items-center">
                                    <a className="pe-0" onClick={() => { history.push('/Task') }}>
                                        <img
                                            src="/images/head-back-arrow.svg"
                                            alt="back-arrow"
                                            width={19}
                                            className="me-2"

                                        />
                                    </a>
                                    {currentTask.taskName} - Assessment
                                    {/* User Migration icon */}
                                    {/* <img src="/images/user-migration-icon.svg" alt="User migration" width="23" class="ms-2"> */}
                                    {/* Team logo */}
                                    {/* <img src="/images/teams-logo.svg" alt="teams-logo" width="29" class="ms-2"> */}
                                    {/* Sharepoint logo */}
                                    {/* <img src="/images/Microsoft-SharePoint-Logo.svg" alt="Sharepoint-logo" width="28" class="ms-2"> */}
                                    {/* Outlook logo */}
                                    {/* <img src="/images/ms-outlook-logo.svg" alt="outlook-logo" width="28" class="ms-2"> */}
                                </h5>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card p-md-4 border-0 rounded-3">
                                        <div className="row justify-content-center">
                                            <div className="col-md-7">
                                                <div className="d-flex justify-content-between align-items-center step-wizard">
                                                    <div className="d-flex align-items-center gap-1 bg-white z-index-1">
                                                        <div className="wizard-step-num d-flex justify-content-center align-items-center font-bold font-14 active">
                                                            1
                                                        </div>
                                                        <span className="bg-white me-1 font-14 font-semibold d-none d-xl-block wizard-step-text active">
                                                            Select App
                                                        </span>
                                                    </div>
                                                    <div className="d-flex align-items-center gap-1 bg-white z-index-1 cursor-pointer">
                                                        <div className="wizard-step-num d-flex justify-content-center align-items-center font-bold font-14"
                                                            onClick={() => {
                                                                if (location?.state?.taskId && location?.state?.isEdit && location?.state?.stage >= 1) {
                                                                    history.push('/Connectors', {
                                                                        state: {
                                                                            ...location?.state,
                                                                            isEdit: true,
                                                                            stage: (location?.state?.stage && location?.state?.stage > stage) ? location?.state?.stage : stage
                                                                        }
                                                                    });
                                                                }
                                                            }}>
                                                            2
                                                        </div>
                                                        <span className="bg-white me-1 font-14 font-semibold d-none d-xl-block wizard-step-text"
                                                        >
                                                            Connectors
                                                        </span>
                                                    </div>
                                                    <div className="d-flex align-items-center gap-1 bg-white z-index-1 cursor-pointer">
                                                        <div className="wizard-step-num d-flex justify-content-center align-items-center font-bold font-14"
                                                            onClick={() => {
                                                                if (location?.state?.taskId && location?.state?.isEdit && location?.state?.stage >= 2) {
                                                                    location?.state?.taskType?.toLowerCase() == "assessment" ?
                                                                        history.push("/AssessmentConfigurations", {
                                                                            state: {
                                                                                ...location?.state
                                                                            },
                                                                        })
                                                                        :
                                                                        history.push("/Configurations", {
                                                                            state: {
                                                                                ...location?.state
                                                                            },
                                                                        })

                                                                }
                                                            }}>
                                                            3
                                                        </div>
                                                        <span className="bg-white me-1 font-14 font-semibold d-none d-xl-block wizard-step-text "

                                                        >
                                                            Configurations
                                                        </span>
                                                    </div>
                                                    <div className="d-flex align-items-center gap-1 bg-white z-index-1 cursor-pointer">
                                                        <div className="wizard-step-num d-flex justify-content-center align-items-center font-bold font-14"
                                                            onClick={() => {
                                                                if (location?.state?.taskId && location?.state?.isEdit && location?.state?.stage >= 3) {
                                                                    if (location?.state?.stage === 3) {
                                                                        history.push('/selectassessmentapp', {
                                                                            state: {
                                                                                ...location.state,
                                                                                isEdit: true,

                                                                                stage: (location?.state?.stage && location?.state?.stage > stage) ? location?.state?.stage : stage
                                                                            }
                                                                        });
                                                                    } else {
                                                                        history.push('/Inprogress', {
                                                                            state: {
                                                                                ...location.state,
                                                                                isEdit: true,

                                                                                stage: (location?.state?.stage && location?.state?.stage > stage) ? location?.state?.stage : stage
                                                                            }
                                                                        })
                                                                    }
                                                                }
                                                            }

                                                            }>
                                                            4
                                                        </div>
                                                        <span className="bg-white me-1 font-14 font-semibold d-none d-xl-block wizard-step-text"
                                                        >
                                                            Assessment
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-9 mt-4 mt-md-5">
                                                <div className="d-flex justify-content-between mb-4">
                                                    <h3 className="m-0 font-22 font-bold pb-2">Assessment</h3>
                                                </div>
                                                <div className="p-3 mb-4 d-flex align-items-start gap-2 border-radius-8 info-div-bg">
                                                    <img
                                                        src="/images/tooltip.svg"
                                                        alt="tooltip"
                                                        width={11}
                                                        data-bs-toggle="tooltip"
                                                        data-bs-placement="top"
                                                        data-bs-title="Tooltip content"
                                                        data-bs-original-title=""
                                                        title=""
                                                    />
                                                    <span className="lh-1 font-12 cust-role-color">
                                                        Choose apps that you need to proceed with assessment
                                                    </span>
                                                </div>
                                                <div className="form-check form-check-inline mb-4">
                                                    <input
                                                        className="form-check-input cus-check-input"
                                                        type="checkbox"
                                                        name="inlineRadioOptions"
                                                        id="all"
                                                        defaultValue="option1"
                                                        checked={selectedItems?.length == apps?.length}
                                                        disabled={location?.state?.isEdit}
                                                        onChange={(e) => { handleSelectAndSelectAll(e) }}
                                                    />
                                                    <label
                                                        className="form-check-label font-14 font-regular"
                                                        htmlFor="active"
                                                    >
                                                        Select All
                                                    </label>
                                                </div>
                                                <div className="row">
                                                    {apps?.map((item: any) => {
                                                        return (
                                                            <div className="col-md-3 cust-assessment-col mb-4">
                                                                <div className="card-hover border-radius-8 border p-3 cust-assessment-cards h-100">
                                                                    <div className="form-check form-check-inline">
                                                                        <input
                                                                            className="form-check-input cus-check-input"
                                                                            type="checkbox"
                                                                            name="inlineRadioOptions"
                                                                            id={`${item.applicationName}?${item.applicationId}`}
                                                                            // checked={selectedItems.includes(parseInt(item.applicationId)) || selectedApps.map((item1) => item1.applicationId).includes(parseInt(item.applicationId))}
                                                                            checked={selectedItems.includes(parseInt(item.applicationId)) ? true : false}
                                                                            onChange={(e) => handleSelectAndSelectAll(e)}
                                                                            disabled={location?.state?.isEdit}
                                                                        />
                                                                    </div>
                                                                    <div className="d-flex justify-content-center align-content-center flex-column text-center height-40">
                                                                        <div>
                                                                            <img
                                                                                src={`/images/${item.applicationName}.svg`}
                                                                                alt="user-migration"
                                                                                className="mb-3 cust-card-img"
                                                                            />
                                                                        </div>
                                                                        <p className="font-regular font-14 mb-3 color-black">
                                                                            {item.applicationName == "outlook" ? "Entity" : item.applicationName == "entity" ? "Entity" : item.applicationName == "sharedmailbox" ? "Shared Mailbox" : item.applicationName == "roomsandequipments" ? "Rooms & Equipment's" : item.applicationName == "teamschannel" ? "Teams Channel" : item.applicationName == "teamschat" ? "Teams Chat" : item.applicationName == "onedrive" ? "OneDrive" : item.applicationName == "sharepoint" ? "SharePoint" : item.applicationName === "gmail" ? "G-Mail" : item.applicationName == "mydrive" ? "My Drive" : item.applicationName == "shareddrive" ? "Shared Drive" : item.applicationName == "sites" ? "Sites" : item.applicationName == "chats" ? "Chats" : item.applicationName == "ggroups" ? "Groups" : null}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}

                                                </div>
                                                <div className="d-md-flex d-grid w-100 gap-3 align-items-center justify-content-end mt-5 pt-5 pt-lg-0">
                                                    {/* <button className="btn primary-outline-btn font-14 w-md-100 font-semibold flex-nowrap d-flex align-items-center justify-content-center order-2 order-md-0" id='assessmentback' onClick={(e) => { handleButtons(e) }}>
                                                        <img
                                                            src="/images/left-arw-blue.svg"
                                                            alt="back"
                                                            width={12}
                                                        />
                                                        <span className="ms-1">Back</span>
                                                    // </button> */}
                                                    {/* // <div className="d-md-flex d-grid gap-3"> */}
                                                    <button className="btn secondary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center order-2 order-md-0" id='assessmentcancel' onClick={(e) => { handleButtons(e) }}>
                                                        Cancel
                                                    </button>
                                                    <button className="btn primary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center" id='assessmentnext' disabled={selectedItems?.length == 0 && !location?.state?.isEdit} onClick={(e) => { handleButtons(e) }}>
                                                        Next
                                                        <img src="/images/right-arw.svg" alt="next" />
                                                    </button>
                                                    {/* </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            {(currentTask.taskType?.toLowerCase() == "assessment" && pageType == "export") && <div className="container-fluid container-height">
                <div className="row">
                    <div className="col-md-12">
                        {/* {toasterState.failure != '' && <div className="toast-container">
                                <div
                                    className="toast show custom-toast-failure"
                                    role="alert"
                                    aria-live="assertive"
                                    aria-atomic="true"
                                    style={{ position: 'fixed', top: '20px', right: '20px', zIndex: 9999 }}
                                >
                                    <div className="d-flex p-3 pb-0 border-0 align-items-center">
                                        <img
                                            src="/images/failure-toast-icon.svg"
                                            className="rounded me-2"
                                            alt="..."
                                        />
                                        <h1 className="me-auto font-14 font-semibold toast-header-color mb-0">
                                            Failure
                                        </h1>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-dismiss="toast"
                                            aria-label="Close"
                                            onClick={() => setfailure(false)}

                                        />
                                    </div>
                                    <div className="toast-body px-5 pt-0">
                                        {importexport == 'export' ? ` ${appName} data not has been exported`
                                            : importexport == 'importcheck' ? `Documents template does not match ` : ` ${appName} data has not been imported`}

                                    </div>
                                </div>
                            </div>} */}
                        <div className="card px-4 py-4 mt-3 mb-3 border-0 rounded-3">
                            {/* bread crumb starts here */}
                            <ul className="breadcrumb-item mb-4 ps-0">
                                <li>
                                    <a className="pe-0" onClick={() => { history.push('/Task') }}>
                                        Task
                                    </a>
                                </li>
                                <li className="pe-2">{currentTask.taskName}</li>
                            </ul>
                            {/* bread crumb ends here */}
                            <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap gap-3 mt-2">
                                <h5 className="primary-header mb-0 d-flex align-items-center">
                                    <a onClick={() => { history.push('/Task') }}>
                                        <img
                                            src="/images/head-back-arrow.svg"
                                            alt="back-arrow"
                                            width={19}
                                            className="me-2"
                                        />
                                    </a>
                                    {currentTask.taskName} - Assessment
                                    {/* User Migration icon */}
                                    {/* <img src="/images/user-migration-icon.svg" alt="User migration" width="23" class="ms-2"> */}
                                    {/* Team logo */}
                                    {/* <img src="/images/teams-logo.svg" alt="teams-logo" width="29" class="ms-2"> */}
                                    {/* Sharepoint logo */}
                                    {/* <img src="/images/Microsoft-SharePoint-Logo.svg" alt="Sharepoint-logo" width="28" class="ms-2"> */}
                                    {/* Outlook logo */}
                                    {/* <img src="/images/ms-outlook-logo.svg" alt="outlook-logo" width="28" class="ms-2"> */}
                                </h5>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card p-md-4 border-0 rounded-3">
                                        <div className="row justify-content-center">
                                            <div className="col-md-7">
                                                <div className="d-flex justify-content-between align-items-center step-wizard">
                                                    <div className="d-flex align-items-center gap-1 bg-white z-index-1 cursor-pointer"
                                                        onBlur={(e) => {
                                                            e.stopPropagation();
                                                        }}
                                                        onMouseDown={() => {
                                                            console.log("the location data cool",location);
                                                            
                                                            if (location?.state?.taskId && location?.state?.isEdit) {
                                                                return history.push('/SelectApps', {
                                                                    state: {
                                                                        ...location?.state,
                                                                        pageType: 'selectapps',
                                                                        isEdit: true,
                                                                        stage: ((location?.state?.stage) && (location?.state?.stage > stage)) ? location?.state?.stage : stage
                                                                    }
                                                                })

                                                            }
                                                            else {

                                                                history.push("/SelectApps", {
                                                                    state: {
                                                                        // applicationName: location?.state?.applicationName,
                                                                        // applicationId: location?.state?.applicationId,
                                                                        ...location?.state,
                                                                        isEdit: true,
                                                                        pageType: 'selectapps',
                                                                        stage: ((location?.state?.stage) && (location?.state?.stage > stage)) ? location?.state?.stage : stage
                                                                    },
                                                                });
                                                            }
                                                        }
                                                        }>
                                                        <div className="wizard-step-num d-flex justify-content-center align-items-center font-bold font-14"
                                                        >
                                                            1
                                                        </div>
                                                        <span className="bg-white me-1 font-14 font-semibold d-none d-xl-block wizard-step-text">
                                                            Select App
                                                        </span>
                                                    </div>
                                                    <div className="d-flex align-items-center gap-1 bg-white z-index-1 cursor-pointer">
                                                        <div className="wizard-step-num d-flex justify-content-center align-items-center font-bold font-14"
                                                            onClick={() => {
                                                                history.push('/Connectors', {
                                                                    state: {
                                                                        isEdit: true,
                                                                        ...location?.state,
                                                                        stage: (location?.state?.stage && location?.state?.stage > stage) ? location?.state?.stage : stage
                                                                    }
                                                                });
                                                            }}
                                                        >
                                                            2
                                                        </div>
                                                        <span className="bg-white me-1 font-14 font-semibold d-none d-xl-block wizard-step-text"
                                                            onClick={() => {
                                                                history.push('/Connectors', {
                                                                    state: {
                                                                        isEdit: true,
                                                                        ...location?.state,
                                                                        stage: (location?.state?.stage && location?.state?.stage > stage) ? location?.state?.stage : stage
                                                                    }
                                                                });
                                                            }}
                                                        >
                                                            Connectors
                                                        </span>
                                                    </div>

                                                    <div className="d-flex align-items-center gap-1 bg-white z-index-1 cursor-pointer"
                                                        onBlur={(e) => {
                                                            e.stopPropagation();
                                                        }}
                                                        onMouseDown={() => {

                                                            history.push("/AssessmentConfigurations", {
                                                                state: {
                                                                    ...location?.state
                                                                },
                                                            }

                                                            )
                                                        }}>
                                                        <div className="wizard-step-num d-flex justify-content-center align-items-center font-bold font-14 " >
                                                            3
                                                        </div>
                                                        <span className="bg-white me-1 font-14 font-semibold d-none d-xl-block wizard-step-text" >
                                                            Configurations
                                                        </span>
                                                    </div>
                                                    <div className="d-flex align-items-center gap-1 bg-white z-index-1">
                                                        <div className="wizard-step-num d-flex justify-content-center align-items-center font-bold font-14 active">
                                                            4
                                                        </div>
                                                        <span className="bg-white me-1 font-14 font-semibold d-none d-xl-block wizard-step-text active">
                                                            Assessment
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-9 mt-4 mt-md-5">
                                                <div className="d-flex justify-content-between mb-4">
                                                    <h3 className="m-0 font-22 font-bold pb-2">Assessment</h3>
                                                </div>
                                                <div className="p-3 mb-4 d-flex align-items-start gap-2 border-radius-8 info-div-bg">
                                                    <img
                                                        src="/images/tooltip.svg"
                                                        alt="tooltip"
                                                        width={11}
                                                        data-bs-toggle="tooltip"
                                                        data-bs-placement="top"
                                                        data-bs-title="Tooltip content"
                                                        data-bs-original-title=""
                                                        title=""
                                                    />
                                                    <span className="lh-1 font-12 cust-role-color">
                                                        Choose apps that you need to proceed with assessment
                                                    </span>
                                                </div>
                                                <div className="form-check form-check-inline mb-4">
                                                    <input
                                                        className="form-check-input cus-check-input"
                                                        type="checkbox"
                                                        name="inlineRadioOptions"
                                                        id="allassess"
                                                        defaultValue="option1"
                                                        onChange={(e) => { handleSelectAndSelectAll(e) }}
                                                        checked={selectedItems?.length == selectedApps?.length}
                                                    />
                                                    <label
                                                        className="form-check-label font-14 font-regular"
                                                        htmlFor="active"
                                                    >
                                                        Select All
                                                    </label>
                                                </div>
                                                <div className="row">
                                                    {selectedApps?.map((item) => {

                                                        if (selectedApps) {
                                                            ////console.log("Inside the gb");

                                                        }
                                                        return (
                                                            <>
                                                                <div className="col-md-3 cust-assessment-col mb-4">
                                                                    <div className="card-hover border-radius-8 border p-3 cust-assessment-cards h-100">
                                                                        <div className="form-check form-check-inline">
                                                                            <input
                                                                                className="form-check-input cus-check-input"
                                                                                type="checkbox"
                                                                                name="inlineRadioOptions"
                                                                                id={`1?${item.applicationId}`}
                                                                                defaultValue="option1"
                                                                                onChange={(e) => {
                                                                                    handleSelectAndSelectAll(e);

                                                                                    // Check if the app is 'entity' and the checkbox is checked
                                                                                    if (selectedApps.find(app => app.applicationId === item.applicationId)?.applicationName === 'outlook' && e.target.checked) {
                                                                                        setSubApps(true);
                                                                                    }
                                                                                    //  else if (apps.find(app => app.applicationId === item.applicationId)?.applicationName === 'entity' && !e.target.checked) {
                                                                                    //   setSubApps(false);
                                                                                    // }
                                                                                }}
                                                                                checked={selectedItems?.includes(item.applicationId)}
                                                                            />
                                                                        </div>
                                                                        <div className="d-flex justify-content-center align-content-center flex-column text-center height-40">
                                                                            <div>
                                                                                <img
                                                                                    src={`/images/${item.applicationName}.svg`}
                                                                                    alt="user-migration"
                                                                                    className="mb-3 cust-card-img"
                                                                                />
                                                                            </div>
                                                                            <p className="font-regular font-14 mb-3 color-black">
                                                                                {item.applicationName == "outlook" ? "Entity" : item.applicationName == "entity" ? "Entity" : item.applicationName == "sharedmailbox" ? "Shared Mailbox" : item.applicationName == "roomsandequipments" ? "Rooms & Equipment's" : item.applicationName == "teamschannel" ? "Teams Channel" : item.applicationName == "teamschat" ? "Teams Chat" : item.applicationName == "onedrive" ? "OneDrive" : item.applicationName == "sharepoint" ? "SharePoint" : item.applicationName === "gmail" ? "G-Mail" : item.applicationName == "mydrive" ? "My Drive" : item.applicationName == "shareddrive" ? "Shared Drive" : item.applicationName == "sites" ? "Sites" : item.applicationName == "chats" ? "Chats" : null}
                                                                            </p>
                                                                            <div className="d-flex justify-content-center gap-3 task-assess-btn">
                                                                                <button className="btn task-assessment-btn primary-outline-btn py-0 px-3 d-flex align-items-center justify-content-center" onClick={() => { handleExport(item.applicationName) }}>
                                                                                    <img
                                                                                        src="/images/excel-export-icon.svg"
                                                                                        alt="Export"
                                                                                    />
                                                                                </button>
                                                                                <button className="btn task-assessment-btn primary-outline-btn py-0 px-3 d-flex align-items-center justify-content-center" onClick={() => { setChosenApp(item.applicationName) }}>
                                                                                    <img src="/images/share-icon.svg" alt="Share" />
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    })}
                                                </div>
                                                <div className="d-md-flex d-grid w-100 gap-3 align-items-center justify-content-unset justify-content-md-between mt-4 ">
                                                    <button className="btn primary-outline-btn font-14 w-md-100 font-semibold flex-nowrap d-flex align-items-center justify-content-center order-2 order-md-0" id='assessmentexportimportback' onClick={(e) => { handleButtons(e) }}>
                                                        <img
                                                            src="/images/left-arw-blue.svg"
                                                            alt="back"
                                                            width={12}
                                                        />
                                                        <span className="ms-1">Back</span>
                                                    </button>
                                                    <div className="d-md-flex d-grid gap-3">
                                                        <button className="btn secondary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center order-2 order-md-0" id='assessmentexportimportcancel' onClick={(e) => { handleButtons(e) }}>
                                                            Cancel
                                                        </button>
                                                        <button className="btn primary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center" id='startassessment' disabled={selectedItems?.length == 0} onClick={(e) => { handleButtons(e); insertLastVisitedPageValue("Inprogress") }}>
                                                            Start Assessment
                                                            <img src="/images/right-arw.svg" alt="next" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            {loader && <div className="container-fluid">
                <div className="overlay">
                    <div className="position-absolute top-50 start-50 translate-middle">
                        <div
                            className="spinner-border Loader loader-color align-center "
                            role="status"
                        >
                            <span>
                                <span className="loader-inner-circle" />
                            </span>
                        </div>
                        <p className="loading-text font-16 font-semibold color-white-v2 mt-2">
                            Loading..
                        </p>
                    </div>
                </div>
            </div>}
            {chosenApp && <div
                className="modal fade show "
                id="TeamsChannelCreation"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="TeamsChannelCreationLabel"
                aria-hidden="true"
                style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
                hidden={false}
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content p-4">
                        <div className="modal-header border-0 p-0">
                            <h1
                                className="font-20 font-bold custom-header-color m-0"
                                id="TeamsChannelCreationLabel"
                            >Import {chosenApp == "outlook" ? "Entity" : chosenApp == "gmail" ? "Gmail" : chosenApp == "mydrive" ? "My Drive" : chosenApp == "teamschannel" ? "Teams and channels" : chosenApp == "sharepoint" ? "Share Point" : ""} Details

                            </h1>
                            <button onClick={() => { setChosenApp(""); setImportData([]); setFileInputValue(""); setValidateImport({ status: false, message: "" }) }}
                                type="button"
                                className="btn-close shadow-none"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>

                        <div className="modal-body border-0 p-0 pb-2">
                            <div className="row">

                                <div className="col-md-12 mt-3">
                                    <div className="mb-1">
                                        <label className="font-15 font-semibold label-color w-100 mb-2 pb-1">
                                            Import {chosenApp == "outlook" ? "Entity" : chosenApp == "gmail" ? "Gmail" : chosenApp == "mydrive" ? "My Drive" : chosenApp == "teamschannel" ? "Teams and channels" : chosenApp == "sharepoint" ? "Share Point" : ""} Details <span className="mandatory-color">*</span>
                                        </label>
                                        <div className="upload-btn-wrapper me-2 d-block cursor-pointer">
                                            <div className="input-group mb-1">
                                                <span className="cust-filter-btn font-14  font-medium primary-color upload-btn">
                                                    Browse
                                                    <input className="form-control" type="file" id="upload" onChange={(e: any) => { handleDownloadUpload(e) }} />
                                                </span>
                                                <input
                                                    type="text"
                                                    className="form-control file-input-field"
                                                    placeholder="No file chosen"
                                                    aria-label="Example text with button addon"
                                                    value={fileInputValue}
                                                    readOnly

                                                />
                                            </div>
                                            {validateImport.status && <span className="mandatory-color ">{validateImport.message}</span>}
                                        </div>
                                    </div>
                                    <button className="btn primary-outline-btn font-14 w-md-100 font-semibold flex-nowrap d-flex align-items-center justify-content-center order-2 order-md-0 mt-3" id='download' onClick={(e) => { handleDownloadUpload(e) }}>
                                        <img src="/images/download-icon.svg" alt="back" width={12} />
                                        <span className="ms-1">Download Template</span>
                                    </button>
                                    <div className="d-flex justify-content-end mt-4">
                                        <button type="button" className="btn primary-btn" disabled={importData?.length == 0 || validateImport.message != ''} onClick={(e) => { handleImport(e) }}>
                                            Import
                                        </button>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>}

            {(invalidData?.length != 0) && <div
                className="modal fade show"
                tabIndex={-1}
                style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content popup-brd-radius">
                        <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
                            <span className="position-absolute">
                                <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />
                            </span>
                        </div>
                        <div className="modal-body border-0 text-center">
                            <h5
                                className="modal-title mb-3 primary-header"
                                id="Failure-popupLabel"
                            >
                                { }
                            </h5>
                            <p className="primary-data">
                                Invalid Data provided
                            </p>
                        </div>
                        <div className="modal-footer border-0 pb-4 justify-content-center">
                            <button
                                type="button"
                                className="btn primary-btn font-14 px-4 font-semibold"
                                onClick={() => { handleInvalidDataDownload() }}
                            >
                                Ok
                            </button>
                        </div>
                    </div>
                </div>
            </div>}

            {(popup.errorMessage || popup.successMessage) && <div
                className="modal fade show"
                tabIndex={-1}
                style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content popup-brd-radius">
                        <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
                            <span className="position-absolute">{popup.successMessage ?
                                <img src="/images/success-popup-icon.svg" alt="success-popup-icon" /> : <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />}
                            </span>
                        </div>
                        <div className="modal-body border-0 text-center">
                            <h5
                                className="modal-title mb-3 primary-header"
                                id="Failure-popupLabel"
                            >
                                { }
                            </h5>
                            <p className="primary-data">
                                {popup.errorMessage || popup.successMessage}
                            </p>
                        </div>
                        <div className="modal-footer border-0 pb-4 justify-content-center">
                            <button
                                type="button"
                                className="btn primary-btn font-14 px-4 font-semibold"
                                onClick={() => { setPopup({ successMessage: '', errorMessage: '' }) }}
                            >
                                Ok
                            </button>
                        </div>
                    </div>
                </div>
            </div>}
            {alert ? <div
                className="modal fade show"
                tabIndex={-1}
                style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content popup-brd-radius">
                        <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
                            <span className="position-absolute">
                                <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />
                            </span>
                        </div>
                        <div className="modal-body border-0 text-center">
                            <h5
                                className="modal-title mb-3 primary-header"
                                id="Failure-popupLabel"
                            >
                                { }
                            </h5>
                            <p className="primary-data">
                                {alert}
                            </p>
                        </div>
                        <div className="modal-footer border-0 pb-4 justify-content-center">
                            <button
                                type="button"
                                className="btn primary-btn font-14 px-4 font-semibold"
                                onClick={() => {
                                    if (isSessionEpired) {
                                        history.push('/Login')
                                    }
                                    setAlert('')
                                }}
                            >
                                Ok
                            </button>
                        </div>
                    </div>
                </div>
            </div> : null}
            {/*Failure Popup ends here */}




            {subApps ? (
                <div
                    className="modal fade show"
                    style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content p-4">
                            <div className="modal-header border-0 p-0 cursor-pointer">
                                <h1
                                    className="font-20 font-bold custom-header-color m-0"
                                    id="newTaskLabel"
                                >
                                    Select Apps
                                </h1>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => {
                                        setSelectedEntityApps({
                                            outlook: false,
                                            shared: false,
                                            onedrive: false
                                        });
                                        setSubApps(false);
                                    }}
                                />
                            </div>

                            <div className="modal-body">
                                <div className="checkbox-group">
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input cus-check-input"
                                            id="outlookCheck"
                                            name="outlook"
                                            checked={selectedEntityApps.outlook}
                                            onChange={handleCheckboxChange}
                                        />
                                        <label className="form-check-label" htmlFor="outlookCheck">
                                            Outlook
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input cus-check-input"
                                            id="sharedCheck"
                                            name="shared"
                                            checked={selectedEntityApps.shared}
                                            onChange={handleCheckboxChange}
                                        />
                                        <label className="form-check-label" htmlFor="sharedCheck">
                                            Shared
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input cus-check-input"
                                            id="onedriveCheck"
                                            name="onedrive"
                                            checked={selectedEntityApps.onedrive}
                                            onChange={handleCheckboxChange}
                                        />
                                        <label className="form-check-label" htmlFor="onedriveCheck">
                                            OneDrive
                                        </label>
                                    </div>

                                </div>
                            </div>

                            <div className="modal-footer border-0 p-0">
                                <button
                                    type="button"
                                    style={{ padding: '5px 22px' }}
                                    className="btn secondary-btn"
                                    data-bs-dismiss="modal"
                                    onClick={() => {
                                        setSelectedEntityApps({
                                            outlook: false,
                                            shared: false,
                                            onedrive: false
                                        });
                                        setSubApps(false);
                                    }}
                                >
                                    Cancel
                                </button>

                                <button
                                    type="button"
                                    className="btn primary-btn"
                                    onClick={() => {
                                        setSubApps(false);
                                    }}
                                >
                                    Continue
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}

        </>

    )
};
