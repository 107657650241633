import { ChangeEvent, useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import { useHistory, useLocation } from 'react-router-dom';
import { commonErrorMsgs } from '../../Common/Constant';
import Loader from '../Common/Loader';
import { decodeSessionDetails } from '../../Services/CommonServices';
import objCryptoJS from "crypto-js";
import ViewMigrationGrid from './ViewMigrationGrid';
import { getOutlookData } from '../../Services/MigrationReportServices';
import jwt from 'jwt-decode'
import { getSession } from '../../Services/manageClientAPI';

const ViewMigrationMaster = () => {
  /**PS_01 to PS_21 will be executed*/
  const rn_secretKey = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY || '';
  const a_secretKey = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY || '';
  const c_secretKey = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY || '';

  let location: any = useLocation().state;
  //console.log(location, "locationnnnn");
  
  const history = useHistory();

  const [objAdmin, setObjAdmin] = useState({})
  const [objClient, setObjClient] = useState({})

  let [clientName, setclientName] = useState<string>('');
  let [companyName, setCompanyName] = useState<string>('');
  let [emailAddress, setEmailAddress] = useState<string>('');
  let [role, setRole] = useState<string>('');

  let [isAdmin, setIsAdmin] = useState<boolean>(false);
  let [adminName, setAdminName] = useState<string>('');
  let [adminEmailAddress, setAdminEmailAddress] = useState<string>('');
  let [adminrole, setAdminRole] = useState<string>('');

  let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
  const [isSessionEpired, setisSessionEpired] = useState(false);
  let InActiveTimeout: NodeJS.Timeout;

  const [taskName, setTaskName] = useState<string>(location?.state?.taskName);
  const [taskId, setTaskId] = useState<number>(location?.state?.taskId);
  const [applicationName, setApplicationName] = useState<string>(location?.state?.applicationName);
//console.log(applicationName, "app name in view master");

  const [searchValue, setSearchValue] = useState<string>("");
  const [searchClick, setSearchClick] = useState<string>("");

  const [filerApplied, setFilerApplied] = useState<string>('')
  const [filterValue, setFilterValue] = useState<string>("");

  const [loader, setLoader] = useState<boolean>(false);
  const [imageSource, setImageSource] = useState<string>('');

  const [alertMessage, setAlertMessage] = useState<string>('')
  const [alert, setAlert] = useState<boolean>(false);

  const [filterAction, setFilterAction] = useState<{ value: string, timestamp: number }>({ value: "", timestamp: 0 });
  const [searchAction, setSearchAction] = useState<{ value: string, timestamp: number }>({ value: "", timestamp: 0 });
  const [shouldExport, setShouldExport] = useState(false);

  const [itemCount, setItemCount] = useState<number>(0);

  const [currentApplicationName, setCurrentApplicationName] = useState<string>('user');

  const [render, setRender] = useState<boolean>(false);

  useEffect(() => {
    //console.log(location?.state, "this is the state");

    setApplicationImage();
    sessionTokenProperties();
    resetTimer();
    window.addEventListener('keydown', resetTimer);
    window.addEventListener('click', resetTimer);

    window.removeEventListener('click', resetTimer);
    window.removeEventListener('keydown', resetTimer);
  }, [currentApplicationName]);

  const getToken = async () => {
    let clientToken: string;
    let adminToken: string;
    let token: { clientToken?: string; adminToken?: string } = {};
    try {
      if (objClient != undefined) {
        let sessionToken: any = sessionStorage.getItem('c_at')
        if (sessionToken) {
          sessionToken = objCryptoJS.AES.decrypt(sessionToken, c_secretKey)
          var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
          clientToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
          token['clientToken'] = clientToken
        }
      }

      if (objAdmin != undefined) {
        let sessionToken: any = sessionStorage.getItem('a_at')
        if (sessionToken) {
          sessionToken = objCryptoJS.AES.decrypt(sessionToken, a_secretKey)
          var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
          adminToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();

          token['adminToken'] = adminToken
        }
      }

      return token
    } catch (error) {
      throw error
    }
  }

  let resetTimer = async () => {
    try {
      let sessionResponse: string = await resetSessionTimer(InActiveTimeout, SessionEpiredDateTime);
      if (sessionResponse == 'expired') {
        debugger
        sessionStorage.removeItem("c_at");
        setisSessionEpired(true)
        setAlert(true)
        setAlertMessage('Your Session has Expired please login again to continue');
      }
      else if (sessionResponse == 'updateToken') {
        if (emailAddress == "" || emailAddress == undefined) {
          await sessionTokenProperties();
        }
        debugger
        let token = await getToken()

        let payload = {
          mailId: emailAddress,
          isAdmin: false,
          token: token
        };
        let getsessionToken = await getSession(payload);
        const rn_secretKey: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY
        const cs_secretKey: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;

        const c_byteValue = objCryptoJS.AES.decrypt(getsessionToken?.data?.data.sessionToken, rn_secretKey);
        const c_decryptedValue = c_byteValue.toString(objCryptoJS.enc.Utf8);
        let updatedToken = objCryptoJS.AES.encrypt(c_decryptedValue, cs_secretKey).toString();
        await sessionStorage.removeItem("c_at");
        await sessionStorage.setItem('c_at', updatedToken);
        let updatedobjClient: any = jwt(c_decryptedValue);
        SessionEpiredDateTime = updatedobjClient.CreatedAt;
        setSessionEpiredDateTime(SessionEpiredDateTime);
      }
    }
    catch (error) {
      setLoader(false);
      setAlert(true)
      setAlertMessage('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
    }
  }

  const resetSessionTimer = async (InActiveTimeout: any, SessionExpirationDateTime: any) => {
    try {
      if (typeof window !== 'undefined') {
        clearTimeout(InActiveTimeout);

        const tokenExpiryCheckInterval = Number(process.env.REACT_APP_TABIDLESCENARIO);

        InActiveTimeout = setTimeout(() => {
          sessionStorage.removeItem("c_at");
          setisSessionEpired(true)
          setAlert(true)
          setAlertMessage('Your Session has Expired please login again to continue');
        }, tokenExpiryCheckInterval);

        const currentDateTime = new Date().toISOString();
        const sessionExpiredDateTime = new Date(SessionExpirationDateTime).toISOString();
        const diffInMilliseconds = Math.abs(new Date(sessionExpiredDateTime).getTime() - new Date(currentDateTime).getTime());
        const minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
        const sessionUpdateTimeGap = Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP);

        if (minutes > sessionUpdateTimeGap) {
          return 'updateToken'
        }

        return 'NoActionRequired'
      }
      return 'NoActionRequired'
    }
    catch (ex) {
      throw ex
    }
  };

  let sessionTokenProperties = async () => {
    try {
      const tokenResponse: any = await decodeSessionDetails();
      debugger
      if (tokenResponse.adminDetails) {
        if (tokenResponse.adminDetails) {
          setObjAdmin(tokenResponse.adminDetails)
          setAdminName(tokenResponse.adminDetails.Name);
          setAdminEmailAddress(tokenResponse.adminDetails.Email);
          setAdminRole(tokenResponse.adminDetails.Role);
        }
        else {
          history.push("/Admin/Login")
        }
      }
      else {
        if (tokenResponse.clientDetails) {
          setObjClient(tokenResponse.clientDetails)
          setclientName(tokenResponse.clientDetails.Name);
          setCompanyName(tokenResponse.clientDetails.CompanyName)
          emailAddress = tokenResponse.clientDetails.Email;
          setEmailAddress(emailAddress);
          setRole(tokenResponse.clientDetails.Role);
          setSessionEpiredDateTime(tokenResponse.clientDetails.CreatedAt);
        }
        else {
          history.push("/Login")
        }
      }
      //#endregion
    } catch (error) {
      console.error('Failed in main method', error);
    }
  };

  const setApplicationImage = () => {
    let updatedName= (applicationName.toLowerCase()=='outlook' && currentApplicationName !='user') ? (currentApplicationName=='onedrive') ? 'onedrive' : 'sharedmailbox' : applicationName.toLowerCase()
    switch (updatedName) {
      case "outlook":
        setImageSource("/images/ms-outlook-logo.svg");
        break;
      case "teamschannel":
        setImageSource("/images/teams-logo.svg");
        break;
      case "sharepoint":
        setImageSource("/images/sharepoint.svg");
        break;
      case "gmail":
        setImageSource("/images/gmail.svg");
        break;
      case "gdrive":
        setImageSource("/images/mydrive.svg");
        break;

      case "onedrive":
        setImageSource("/images/onedrive.svg");
        break;

      // Other applications
      case "sharedmailbox":
        setImageSource("/images/shared-mailbox-icon.svg");
        break;
      case "roomsandequipments":
        setImageSource("/images/rooms-n-equipment-icon.svg");
        break;
      case "groups":
        setImageSource("/images/user-groups.svg");
        break;
      default:
        setImageSource("");
        break;
    }
  };

  // Event handler for when an option is selected in the dropdown
  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    // Update state variable with the selected option value
    setCurrentApplicationName(event.target.value);

    setRender(!render)

    let selectedValue = event.target.value;
    // Check if the selected value is 'onedrive' and change it to 'outlook' if it is
    // if (selectedValue === 'onedrive') {
    //   setApplicationName('mydrive');
    // } else {
    //   // Otherwise, set the state variable to the selected option value
    //   setApplicationName('outlook');
    // }

  };

  const updateItemCount = (count: number) => {
    setItemCount(count);
  };

  const handleExportClick = () => {
    setShouldExport(true);
  };

  const handleExportComplete = () => {
    setShouldExport(false);
  };

  return (
    <> 
    
    <div className="container-fluid container-height">
      <div className="row">
        <div className="col-md-12">
          <div className="card px-4 py-4 mt-3 mb-3 border-0 rounded-3">

            {/* bread crumb starts here */}
            <ul className="breadcrumb-item mb-4 ps-0">
              <li>
                <a className="pe-0" onClick={() => {
                  history.push("/task", {
                    state: {
                      applicationName: location?.state?.applicationName,
                      applicationId: location?.state?.applicationId,
                      taskName: location?.state?.taskName,
                      taskType: location?.state?.taskType,
                    },
                  });
                }}>Task</a>
              </li>
              <li className="pe-2">{taskName}</li>
            </ul>
            {/* bread crumb ends here */}


            {(applicationName.toLowerCase()=='outlook') ?
       <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap gap-3 ">
       <div className="d-flex align-items-center">
         <label className="font-14 font-semibold label-color text-nowrap me-2">
           Select App
         </label>
       <select
       name="groupType"
       id="groupType"
       className="form-select custom-form w-100 h-100 m-0 font-14 font-regular cursor-pointer"
         value={currentApplicationName}
         onChange={handleChange}
       >
         <option value="user" className="cursor-pointer">Outlook</option>
         <option value="shared" className="cursor-pointer">Shared</option>
         <option value="onedrive" className="cursor-pointer">OneDrive</option>
       </select>
     </div>
     </div>
      : null}

            <div className=" d-flex align-items-center justify-content-between mb-3 flex-wrap gap-3 ">
              <h5 className="font-20 font-semibold mb-0">
                <a
                  onClick={() => {
                    history.push("/task", {
                      state: {
                        applicationName: location?.state?.applicationName,
                        applicationId: location?.state?.applicationId,
                        taskName: location?.state?.taskName,
                        taskType: location?.state?.taskType,
                      },
                    });
                  }}
                >
                  <img
                    src="/images/head-back-arrow.svg"
                    alt="back-arrow"
                    className="me-2"
                  />
                </a>
                {taskName} ({itemCount})
                <img
                  src={imageSource}
                  alt=""
                  className="ms-2"
                />
              </h5>
              <div className="d-flex gap-3 w-md-100 flex-wrap">
                <div className="d-flex gap-3 flex-row-reverse flex-md-row w-md-100">
                  <button
                    className="btn icon-swap secondary-outline-btn"
                    id="dropdownMenuLink"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src="/images/filter-active-icon.svg"
                      alt="filter-active-icon"
                    />
                    <img src="/images/filter-icon.svg" alt="filter-icon" />
                  </button>
                  {/*filter*/}
                  <div
                    className="dropdown-menu dropdown-menu-end custom-filter-width p-3 end-0"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <h5 className="font-bold font-20 mb-0 custom-header-color">
                        Advanced Filter
                      </h5>
                      <span>
                        <img
                          className="cursor-pointer"
                          src="/images/close-icon.svg"
                          alt="close"
                        />
                      </span>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-4">
                        <label
                          className="form-label font-14 font-semibold label-color "
                          htmlFor="environment"
                        >
                          Overall Status
                        </label>
                        <select
                          className="form-select custom-form"
                          aria-label="Default select example"
                          id="environment"
                          onChange={(e) => {
                            setFilterValue(e.target.options[e.target.selectedIndex].id);
                          }}
                        >
                          <option
                            id={""}
                            value={filterValue}
                            selected={filterValue == ""}
                            disabled
                          >
                            Select
                          </option>
                          <option id={"0"} value={filterValue}>
                            Warning
                          </option>
                          <option id={"1"} value={filterValue}>
                            Paused
                          </option>
                          <option id={"2"} value={filterValue}>
                            Cancelled
                          </option>
                          <option id={"3"} value={filterValue}>
                            Scheduled
                          </option>
                          <option id={"4"} value={filterValue}>
                            Queue
                          </option>
                          <option id={"5"} value={filterValue}>
                            In Progress
                          </option>
                          <option id={"6"} value={filterValue}>
                            Completed
                          </option>
                          <option id={"7"} value={filterValue}>
                            Failed
                          </option>
                        </select>
                      </div>
                      <div className="col-md-12">
                        <div className="d-flex justify-content-end align-items-center py-3">
                          <button
                            className="btn secondary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center py-2 me-3 px-4"
                            onClick={() => {
                              setFilterValue("");
                              setFilterAction({ value: "", timestamp: Date.now() });
                            }}
                          >
                            Clear
                          </button>
                          <button
                            className="btn primary-btn text-nowrap  w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center px-4"
                            onClick={() => setFilterAction({ value: filterValue, timestamp: Date.now() })}
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*filter*/}

                  <div className="input-group input-search">
                    <input
                      type="search"
                      className="form-control border-end-0 font-14 font-regular"
                      placeholder="Search"
                      onChange={(e) => {
                        setSearchValue(e.target.value);
                      }}
                      onKeyDown={(event) => {
                        if (event.key == "Enter") {
                          setSearchAction({ value: searchValue, timestamp: Date.now() });
                        }
                      }}
                    />
                    <button
                      className="btn border-start-0 d-flex align-items-center"
                      type="button"
                    >
                      <img
                        src="/images/search.svg"
                        alt="search"
                        onClick={() => setSearchAction({ value: searchValue, timestamp: Date.now() })}
                      />
                    </button>
                  </div>
                </div>

                <button
                  className="btn primary-btn text-nowrap  w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center"
                  onClick={() => handleExportClick()}
                >
                  <img
                    src="/images/excel-icon-white.svg"
                    alt="add-plus-icon"
                  />
                  Export
                </button>
              </div>
            </div>

            <ViewMigrationGrid
              filterAction={filterAction}
              searchAction={searchAction}
              exportAction={shouldExport}
              setShouldExport={setShouldExport}
              taskId={taskId}
              taskName={taskName}
              updateItemCount={updateItemCount}
              role={role}
              adminRole={adminrole}
              applicationName={applicationName.toLowerCase()}
              resourceType={currentApplicationName}
              render={render}
            />
          </div>
        </div>
      </div>
    </div>

      {loader && <Loader />}

      {/*Failure Popup starts here */}
      {alert ? <div
        className="modal fade show"
        tabIndex={-1}
        style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content popup-brd-radius">
            <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
              <span className="position-absolute">
                <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />
              </span>
            </div>
            <div className="modal-body border-0 text-center">
              <h5
                className="modal-title mb-3 primary-header"
                id="Failure-popupLabel"
              >
                {alertMessage}
              </h5>
              <p className="primary-data">
                {alert}
              </p>
            </div>
            <div className="modal-footer border-0 pb-4 justify-content-center">
              <button
                type="button"
                className="btn primary-btn font-14 px-4 font-semibold"
                onClick={() => {
                  if (isSessionEpired) {
                    history.push('/Login')
                  }
                  setAlert(false)
                }}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div> : null}
      {/*Failure Popup ends here */}
    </>
  );
};

export default ViewMigrationMaster;