

import objCryptoJS from "crypto-js";
import jwt from 'jwt-decode'
import { Admin, Client, sessionResponse } from "../Common/Interface";

export async function decodeSessionDetails(): Promise<sessionResponse> {
    try {
        const c_local = sessionStorage.getItem("c_at");
        const cs_secretKey = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;
        let objClient: Client | undefined;

        const a_local = sessionStorage.getItem("a_at");
        const as_secretKey = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY;
        let objAdmin: Admin | undefined;

        if (c_local && cs_secretKey) {
            const c_byteValue = objCryptoJS.AES.decrypt(c_local, cs_secretKey);
            const c_decryptedValue = c_byteValue.toString(objCryptoJS.enc.Utf8);
            objClient = jwt(c_decryptedValue) as Client;
        }

        if (a_local && as_secretKey) {
            const a_byteValue = objCryptoJS.AES.decrypt(a_local, as_secretKey);
            const a_decryptedValue = a_byteValue.toString(objCryptoJS.enc.Utf8);
            objAdmin = jwt(a_decryptedValue) as Admin;
        }


        const response: sessionResponse = {
            clientDetails: objClient,
            adminDetails: objAdmin
        };


        return response;
    } catch (error) {
        console.error('Failed to decode session details', error);
        // You might return a fallback response, or rethrow, depending on your needs
        throw error;
    }
}

