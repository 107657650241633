import React, { useState, useEffect, useRef } from "react";
// import fetchLicenseRenewalData from "../../Services/Service/licenseRenewalService"; // Import your API function

import { useHistory } from "react-router-dom"
import moment from "moment";
import { Filter, sessionResponse } from "../../Common/Interface";
import { getAdminLicenseRenewalData } from "../../Services/DashboardServices";
import { decodeSessionDetails } from "../../Services/CommonServices";
import objCryptoJS from "crypto-js";
import { commonErrorMsgs } from "../../Common/Constant";
import { getSession } from "../../Services/manageClientAPI";
import jwt from 'jwt-decode'
const LicenseRenewal = () => {

  //PS_DB_131 to PS_DB_138 - variable Initialization
  const rn_secretKey = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY || '';
  const a_secretKey = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY || '';
  const c_secretKey = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY || '';
  const [objAdmin, setObjAdmin] = useState({})
  const [objClient, setObjClient] = useState({})
  const [adminLicenseRenewal, setAdminLicenseRenewal] = useState<any[]>([]);
  const [alert, setAlert] = useState<boolean>(false)
  const [alertMessage, setAlertMessage] = useState<string>('')
  const [totalDataCount, setTotalDataCount] = useState(0)
  const [searchValue, setSearchValue] = useState<string>("");
  const [sortBy, setSortBy] = useState<string>('cl.licenseExpiredAt');
  const [sortOrder, setSortOrder] = useState<string>('desc');
  const [dropdownOptions, setDropdownOptions] = useState<any[]>([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [fromDateFilter, setFromDate] = useState("");
  const [toDateFilter, setToDate] = useState("");
  const [loading, setLoading] = useState(false); // Add loading state
  const [pageSize, setPageSize] = useState<number>(10);
  const [filter, setFilter] = useState<Filter>({
    planType: null,
    fromDate: null,
    toDate: null,
  });
  const [filtershow, setfiltershow] = useState(false)
  let history = useHistory()
  const [loader, setLoader] = useState<boolean>(false);
  const [gsuitePurchased, setGsuitePurchased] = useState<boolean>(false);
  const [tenantPurchased, setTenantPurchased] = useState<boolean>(false);
  const [lackPurchased, setSlackPurchased] = useState<boolean>(false);
  let [clientName, setclientName] = useState('');
  let [companyName, setCompanyName] = useState('');
  let [emailAddress, setEmailAddress] = useState('');
  let [role, setRole] = useState('');
  let [isAdmin, setIsAdmin] = useState(false);
  let [adminName, setAdminName] = useState('');
  let [adminEmailAddress, setAdminEmailAddress] = useState('');
  let [adminrole, setAdminRole] = useState('');
  let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
  const [isSessionEpired, setisSessionEpired] = useState(false);
  // //debugger
  let InActiveTimeout = useRef<NodeJS.Timeout | null>(null);


  useEffect(() => {
    sessionTokenProperties();
    resetTimer();
    fetchData();
    window.addEventListener('keydown', resetTimer);
    window.addEventListener('click', resetTimer);

    return () => {
      if (InActiveTimeout.current) {
        console.log("License Renewal Unmount Time Clear");  
        clearTimeout(InActiveTimeout.current);
      }
      window.removeEventListener('click', resetTimer);
      window.removeEventListener('keydown', resetTimer);
    };

  }, [sortBy, sortOrder, pageSize, filter]);

  let resetTimer = async () => {
    try {
      // debugger;
      ////console.log("Reset Timer Start");

      let sessionResponse: string = await resetSessionTimer(InActiveTimeout, SessionEpiredDateTime);
      ////console.log("sessionResponse value :", sessionResponse);

      // //debugger
      if (sessionResponse == 'expired') {
        debugger
        sessionStorage.removeItem("c_at");
        setisSessionEpired(true)
        setAlert(true)
        setAlertMessage('Your Session has Expired please login again to continue');
      }
      else if (sessionResponse == 'updateToken') {
        if (emailAddress == "" || emailAddress == undefined) {
          await sessionTokenProperties();
        }
        debugger
        let token = await getToken()
        let payload = {
          mailId: emailAddress,
          isAdmin: false,
          token: token
        };
        let getsessionToken = await getSession(payload);
        const rn_secretKey: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY
        const cs_secretKey: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;

        const c_byteValue = objCryptoJS.AES.decrypt(getsessionToken?.data?.data.sessionToken, rn_secretKey);
        const c_decryptedValue = c_byteValue.toString(objCryptoJS.enc.Utf8);
        let updatedToken = objCryptoJS.AES.encrypt(c_decryptedValue, cs_secretKey).toString();
        await sessionStorage.removeItem("c_at");
        await sessionStorage.setItem('c_at', updatedToken);
        let updatedobjClient: any = jwt(c_decryptedValue);
        SessionEpiredDateTime = updatedobjClient.CreatedAt;
        setSessionEpiredDateTime(SessionEpiredDateTime);
      }
      ////console.log("Reset Timer End");
    }
    catch (error) {
      ////console.log("Reset Timer Catch");
      setAlert(true)
      setAlertMessage('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
      setLoader(false);
    }
  }

  const resetSessionTimer = async (InActiveTimeout: any, SessionExpirationDateTime: any) => {
    try {
      // debugger
      ////console.log("Reset Session Timer Start");
      // Assuming InActiveTimeout is a number representing the ID of the current timeout

      if (typeof window !== 'undefined') {
        ////console.log("Reset Session Timer Inside");
        if (InActiveTimeout.current) {
          clearTimeout(InActiveTimeout.current);
        }
        const tokenExpiryCheckInterval = Number(process.env.REACT_APP_TABIDLESCENARIO);

        InActiveTimeout.current = setTimeout(() => {
          ////console.log("Expired");
          sessionStorage.removeItem("c_at");
          ////console.log("removed session");
          setisSessionEpired(true)
          setAlert(true)
          setAlertMessage('Your Session has Expired please login again to continue');
          // return 'expired'
        }, tokenExpiryCheckInterval);

        ////console.log("Reset Session Timer checking");

        const currentDateTime = new Date().toISOString();
        const sessionExpiredDateTime = new Date(SessionExpirationDateTime).toISOString();
        ////console.log("sessionExpiredDateTime value :", sessionExpiredDateTime);

        const diffInMilliseconds = Math.abs(new Date(sessionExpiredDateTime).getTime() - new Date(currentDateTime).getTime());
        ////console.log("diffInMilliSeconds values:", diffInMilliseconds);

        const minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
        ////console.log("minutes : ", minutes);

        const sessionUpdateTimeGap = Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP);
        ////console.log("Reset Session Timer Differece : ", sessionUpdateTimeGap);

        if (minutes > sessionUpdateTimeGap) {

          ////console.log("Reset Session Timer need to update");
          return 'updateToken'
        }

        ////console.log("Reset Session Timer End");
        return 'NoActionRequired'
      }
      return 'NoActionRequired'
    }
    catch (ex) {
      ////console.log("Reset Session Timer Catch");
      throw ex
    }
  };


  let sessionTokenProperties = async () => {
    try {
      const tokenResponse: sessionResponse = await decodeSessionDetails();
      debugger
      if (window.location.pathname.toString().toLowerCase().includes("admin")) {
        if (tokenResponse.adminDetails) {
          setObjAdmin(tokenResponse.adminDetails)
          setAdminName(tokenResponse.adminDetails.Name);
          setAdminEmailAddress(tokenResponse.adminDetails.Email);
          setAdminRole(tokenResponse.adminDetails.Role);
        }
        else {
          history.push("/Admin/Login")
        }
      }
      //#endregion
    } catch (error) {
      console.error('Failed in main method', error);
    }
  };



  const getToken = async () => {
    let clientToken: string;
    let adminToken: string;
    let token: { clientToken?: string; adminToken?: string } = {};
    debugger
    try {
      if (objClient != undefined) {
        let sessionToken: any = sessionStorage.getItem('c_at')
        if (sessionToken) {
          sessionToken = objCryptoJS.AES.decrypt(sessionToken, c_secretKey)
          var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
          clientToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
          token['clientToken'] = clientToken

        }


      }
      if (objAdmin != undefined) {
        let sessionToken: any = sessionStorage.getItem('a_at')
        if (sessionToken) {
          sessionToken = objCryptoJS.AES.decrypt(sessionToken, a_secretKey)
          var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
          adminToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();

          token['adminToken'] = adminToken

        }
      }
      return token
    } catch (error) {
      throw error
    }
  }
  //PS_DB_86 to PS_DB_92 will be executed for getAdminLicenseRenewalData
  const fetchData = async () => {//PS_AD-29
    try {
      let token = await getToken()


      setLoader(true)
      const result = await getAdminLicenseRenewalData({
        searchValue: searchValue.trim(),
        token: token,
        sortBy,
        sortOrder,
        pageSize,
        ...filter
      });//PS_AD-31


      if (result?.data?.statusCode === 200) {
        setLoader(false)
        ////console.log('aaaaa');
        setDropdownOptions(result?.data.data.dropDownData)
        setAdminLicenseRenewal(result?.data?.data?.licenseGrid);//PS_AD-32
        setTotalDataCount(result?.data?.data?.totalDataCount)

      }
      else {
        setLoader(false)
        setAlert(true)
        setAlertMessage(commonErrorMsgs.generalError)
      }



    } catch (error) {
      setLoader(false)
      setAlert(true)
      setAlertMessage(commonErrorMsgs.generalError)
    }
  };

  const handleClearFilter = () => {
    setFilter({
      planType: null,
      fromDate: null,
      toDate: null,
    });
    setSearchValue("");
    setSelectedOption("");
    setFromDate("")
    setToDate("")
    setfiltershow(false)
    // fetchData();
  };

  const handleApplyFilter = () => {
    ////console.log(selectedOption,"Selected option:");
    setAdminLicenseRenewal([])
    // Apply filter and fetch data
    setFilter({
      planType: selectedOption ? selectedOption : null,
      fromDate: fromDateFilter ? fromDateFilter : null,
      toDate: toDateFilter ? toDateFilter : null,
    });
    setSearchValue("");
    setfiltershow(false)
    // fetchData();
  };





  const handleSortChange = (column: string) => {
    if (sortBy === column) {
      // Toggle sort order
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      // Set new column to sort by and reset to ascending
      setSortBy(column);
      setSortOrder("asc");
    }
  };

  const handleLoadMore = () => {
    setPageSize(pageSize + 10); // Increase page size by 10
  };

  const handleFromDateChange = (event: any) => {
    const value = event.target.value;
    setFromDate(value)
  };

  const handleToDateChange = (event: any) => {
    const value = event.target.value;
    setToDate(value)
  };



  const bindData = () => {
    const data = adminLicenseRenewal
    if (!data || data.length === 0) {
      return (
        <tr>
          <td colSpan={3} className="text-center">
            <div className="pt-4 pb-3 text-center">
              <img
                src="/images/no-data-found-img.svg"
                alt="no-data-found-img"
                className="mb-3"
              />
              <h5 className="font-12 font-bold color-no-rec">No records</h5>
              <p className="font-10 font-regular color-no-rec">
                There is no record to show you right now
              </p>
            </div>
          </td>
        </tr>
      );
    }
    return data?.map((item) => {
      return (
        <tr key={item?.companyName}>
          <td><span
            style={{ cursor: 'pointer' }}
            title={item?.companyName.length > 22 ? `${item.companyName.slice(0, 22)}...` : item.companyName}
          >
            {item?.companyName.length > 22 ? `${item.companyName.slice(0, 22)}...` : item.companyName}
          </span></td>
          <td>{item.productType}</td>
          <td>{item.currentPlan}</td>

          <td>{moment(item.renewalDate).format('MM/DD/YYYY')}</td>
        </tr>
      );
    });
  };

  return (
    <>
      <div className="container-fluid container-height">
        <div className="row">
          <div className="col-md-12">
            <div className="card px-4 py-4 mt-3 mb-3 border-0 rounded-3">
              {/* bread crumb starts here */}
              <ul className="breadcrumb-item mb-4 ps-0">
                <li>
                  <a onClick={() => { history.push('/Admin/Dashboard') }} className="pe-0">
                    Dashboard
                  </a>
                </li>
                <li className="pe-2">License Renewal</li>
              </ul>
              {/* bread crumb ends here */}
              <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap gap-3 ">
                <h5 className="font-20 font-semibold mb-0">
                  <a>
                    <img
                      onClick={() => { history.push('/dashboard') }}
                      src="/images/head-back-arrow.svg"
                      alt="back-arrow"
                      className="me-2"
                    />
                  </a>
                  License Renewal ({totalDataCount})
                </h5>
                <div className="d-flex gap-3 w-md-100 flex-wrap">
                  <div className="d-flex gap-3 flex-row-reverse flex-md-row w-md-100">
                    <div className="dropdown">
                      <button
                        className="btn icon-swap secondary-outline-btn"
                        onClick={() => {
                          setfiltershow(true)
                        }}
                      >
                        <img
                          src="/images/filter-active-icon.svg"
                          alt="filter-active-icon"
                        />
                        <img src="/images/filter-icon.svg" alt="filter-icon" />
                      </button>
                      {/*filter*/}
                      {filtershow && <div className="dropdown-menu dropdown-menu-end custom-filter-width p-3 end-0 show">

                        <div className="d-flex justify-content-between align-items-center mb-4">
                          <h5 className="font-bold font-20 mb-0 custom-header-color">
                            Advanced Filter
                          </h5>
                          <span>
                            <img
                              className="cursor-pointer"
                              src="/images/close-icon.svg"
                              alt="close"
                              onClick={()=>{setSelectedOption("")
                                setFromDate("")
                                setToDate("")
                                setfiltershow(false)}}
                            />
                          </span>
                        </div>
                        <div className="row">
                          <div className="col-md-6 mb-4">
                            <label className="form-label font-14 font-semibold label-color" htmlFor="Type">
                              Plan
                            </label>
                            <select
                              className="form-select custom-form"
                              aria-label="Default select example"
                              value={selectedOption}
                              onChange={(e) => {
                                ////console.log("Selected option:", e.target.value);
                                setSelectedOption(e.target.value);
                              }}
                            >
                              <option value="">Select</option>
                              {/* Render dropdown options */}
                              {dropdownOptions?.map((option, index) => (
                                <option key={index} value={option.planName}>
                                  {option.planName}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="col-md-12 mb-2">
                            <label className="font-16 font-semibold custom-header-color">Renewal Date</label>
                          </div>
                          {/* <div className="col-md-6 mb-4">
                            <label className="form-label font-14 font-semibold label-color" htmlFor="from">From</label>
                            <input
                              className="form-control custom-form"
                              type="date"
                              id="from"
                              name="fromDate"
                              value={fromDateFilter}
                              onChange={handleFromDateChange}
                            />
                          </div> */}
                          <div className="col-md-6 mb-4">
                            <label className="form-label font-14 font-semibold label-color" htmlFor="from">
                              From
                            </label>
                            <div className="position-relative">
                              <input
                                className="form-control custom-form"
                                type="date"
                                id="from"
                                name="fromDate"
                                value={fromDateFilter}
                                onChange={handleFromDateChange}
                              />
                              <img
                                src="/images/date-icon.svg"
                                alt="date-icon"
                                className="position-absolute custom-input-icon"
                                style={{
                                  top: '50%',
                                  right: '10px',
                                  transform: 'translateY(-50%)',
                                  pointerEvents: 'none'
                                }}
                              />
                            </div>
                          </div>
                          {/* <div className="col-md-6 mb-4">
                            <label className="form-label font-14 font-semibold label-color" htmlFor="to">To</label>
                            <input
                              className="form-control custom-form"
                              type="date"
                              id="to"
                              name="toDate"
                              min={fromDateFilter}
                              value={toDateFilter}
                              onChange={handleToDateChange}
                            />
                          </div> */}

                          <div className="col-md-6 mb-4">
                            <label className="form-label font-14 font-semibold label-color" htmlFor="to">
                              To
                            </label>
                            <div className="position-relative">
                              <input
                                className="form-control custom-form"
                                type="date"
                                id="to"
                                name="toDate"
                                min={fromDateFilter}
                                value={toDateFilter}
                                onChange={handleToDateChange}
                              />
                              <img
                                src="/images/date-icon.svg"
                                alt="date-icon"
                                className="position-absolute custom-input-icon"
                                style={{
                                  top: '50%',
                                  right: '10px',
                                  transform: 'translateY(-50%)',
                                  pointerEvents: 'none'
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="d-flex justify-content-end align-items-center py-3">
                              <button
                                className="btn secondary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center py-2 me-3 px-4"
                                onClick={handleClearFilter}
                                disabled={!fromDateFilter && !toDateFilter && !selectedOption}
                              >
                                Clear
                              </button>
                              <button
                                className="btn primary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center px-4"
                                onClick={handleApplyFilter}
                                disabled={!fromDateFilter && !toDateFilter && !selectedOption}
                              >
                                Apply
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>}
                      {/*filter*/}                  </div>
                    <div className="input-group box-shadow-filter search-container float-start">
                      <input
                        type="search"
                        className="form-control custom-form font-14 font-regular"
                        placeholder="Search"
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)} // Attach onChange event to the input
                        onKeyDown={(event) => {
                          if (event.key == "Enter") {
                            setAdminLicenseRenewal([])
                            fetchData();
                          }
                        }}

                      />
                      <button
                        className="btn border d-flex align-items-center"
                        type="button"
                        onClick={() => { setAdminLicenseRenewal([]); fetchData() }} // Attach onClick event to the button
                      >
                        <img src="/images/search.svg" alt="search" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-responsive theme-table bg-white ">
                <table className="table table-borderless mb-0">
                  <colgroup>
                    <col span={1} width="30%" />
                    <col span={1} width="30%" />
                    <col span={1} width="30%" />
                    <col span={1} width="10%" />
                  </colgroup>

                  <thead>
                    <tr>
                      <th className="text-nowrap" onClick={() => {setAdminLicenseRenewal([]);handleSortChange("mc.companyName")}}>
                        Company Name
                        <span className="ms-1 cursor-pointer">
                          <img
                            src={`/images/sort-arrow-${sortBy === "mc.companyName" ? (sortOrder === "desc" ? "down" : "up") : "down"}.svg`}
                            alt={`sort-arrow-${sortOrder === "desc" ? "down" : "up"}`}
                          />
                        </span>
                      </th>
                      <th className="text-nowrap" onClick={() =>{setAdminLicenseRenewal([]); handleSortChange("ih.environmentType")}}>
                        Product Type
                        <span className="ms-1 cursor-pointer">
                          <img
                            src={`/images/sort-arrow-${sortBy === "ih.environmentType" ? (sortOrder === "desc" ? "down" : "up") : "down"}.svg`}
                            alt={`sort-arrow-${sortOrder === "desc" ? "down" : "up"}`}
                          />
                        </span>
                      </th>
                      <th className="text-nowrap" onClick={() => {setAdminLicenseRenewal([]);handleSortChange("ih.planType")}}>
                        Current Plan
                        <span className="ms-1 cursor-pointer">
                          <img
                            src={`/images/sort-arrow-${sortBy === "ih.planType" ? (sortOrder === "desc" ? "down" : "up") : "down"}.svg`}
                            alt={`sort-arrow-${sortOrder === "desc" ? "down" : "up"}`}
                          />
                        </span>
                      </th>
                      <th className="text-nowrap" onClick={() => {setAdminLicenseRenewal([]);handleSortChange("cl.licenseExpiredAt")}}>
                        Renewal Date
                        <span className="ms-1 cursor-pointer">
                          <img
                            src={`/images/sort-arrow-${sortBy === "cl.licenseExpiredAt" ? (sortOrder === "desc" ? "down" : "up") : "down"}.svg`}
                            alt={`sort-arrow-${sortOrder === "desc" ? "down" : "up"}`}
                          />
                        </span>
                      </th>
                    </tr>
                  </thead>

                  <tr>
                    <td colSpan={4} style={{ borderTop: '1px solid #dee2e6' }}></td>
                  </tr>

                  <tbody>{bindData()}</tbody>
                </table>
              </div>
              <div></div>
              <p className="text-start mb-3" style={{ color: 'black' }}>
                # Records of :<span style={{ fontWeight: 'bold' }}>{adminLicenseRenewal.length}</span> out of <span style={{ fontWeight: 'bold' }}>{totalDataCount}</span>
              </p>
              <div className="d-flex justify-content-center mt-5">
                {totalDataCount == adminLicenseRenewal.length || pageSize < 10 || adminLicenseRenewal.length < 10 ? null : < button
                  className="btn primary-outline-btn font-14 font-semibold"
                  onClick={handleLoadMore}
                // hidden={adminLicenseRenewal.length < pageSize || adminLicenseRenewal.length}
                >
                  Load More
                </button>}
              </div>
            </div>
          </div>
        </div>
      </div >
      {
        alert ? <div
          className="modal fade show"
          tabIndex={- 1
          }
          style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content popup-brd-radius">
              <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
                <span className="position-absolute">
                  <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />
                </span>
              </div>
              <div className="modal-body border-0 text-center">
                <h5
                  className="modal-title mb-3 primary-header"
                  id="Failure-popupLabel"
                >
                  {alertMessage}
                </h5>
                <p className="primary-data">
                  {alert}
                </p>
              </div>
              <div className="modal-footer border-0 pb-4 justify-content-center">
                <button
                  type="button"
                  className="btn primary-btn font-14 px-4 font-semibold"
                  onClick={() => {
                    if (isSessionEpired) {
                      history.push('/Login')
                    }

                    setAlert(false)
                  }}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div > : null}
      {
        loader && (
          <div className="overlay">
            <div className="position-absolute top-50 start-50 translate-middle">
              <div
                className="spinner-border Loader loader-color align-center "
                role="status"
              >
                <span>
                  <span className="loader-inner-circle" />
                </span>
              </div>
              <p className="loading-text font-16 font-semibold color-white-v2 mt-2">
                Loading..
              </p>
            </div>
          </div>
        )
      }
    </>


  );
};

export default LicenseRenewal;