

import React, { useState, useEffect } from 'react';
import { Onlymail, PasswordRegex, commonErrorMsgs, isValidPhoneNumber } from '../../Common/Constant';
import { submitRegistration, addClientPlanInfo } from '../../Services/RegistrationService';
import objCryptoJS from "crypto-js";
import { getDropDownConnections, getConnectionForTask, checkAndCreateTask, generateBlobSaasTokenApi } from '../../Services/ConnectionSevice';
import Tippy from '@tippyjs/react';
import { followCursor } from 'tippy.js';
import { saveAs } from 'file-saver';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/material.css';
import { decodeSessionDetails } from '../../Services/CommonServices';
import jwt from 'jwt-decode'
import { getSession } from '../../Services/manageClientAPI';
import { useLocation, useHistory } from 'react-router-dom';
import Loader from '../Common/Loader';
import CryptoJS from 'crypto-js';
import jwtDecode from "jwt-decode";

const ClientPlantInfoComponent: React.FC = () => {
    // State Initialization
    const [environmentType, setEnvironmentType] = useState<string>('');
   // const [planType, setPlanType] = useState<string>('');
    const [alert, setAlert] = useState<string>('');
    const [loader, setLoader] = useState<boolean>(false);
    //const path = window.location.pathname;

    // //console.log("path ", path)
    const history = useHistory();

    const rn_secretKey = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY || '';
    const a_secretKey = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY || '';
    const c_secretKey = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY || '';

    const [objAdmin, setObjAdmin] = useState({})
    const [objClient, setObjClient] = useState({})

    let sessionDetails = { UserName: "", CompanyName: "", EmailAddress: "", ID: 0, Role: "" }
    let [clientName, setclientName] = useState('');
    let [companyName, setCompanyName] = useState('');
    let [emailAddress, setEmailAddress] = useState('');
    let [isAdmin, setIsAdmin] = useState(false);
    let [adminName, setAdminName] = useState('');
    let [adminEemailAddress, setAdminEmailAddress] = useState('');
    let [adminrole, setAdminRole] = useState('');
    const [role, setRole] = useState<string>('');

    let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
    const [isSessionEpired, setisSessionEpired] = useState(false);
    // //debugger
    let InActiveTimeout: NodeJS.Timeout;


    const [endpoint, setEndpoint] = useState<string>('');


    useEffect(() => {
        // //debugger;
        sessionTokenProperties()
        resetTimer();
    
        window.addEventListener('keydown', resetTimer);
        window.addEventListener('click', resetTimer);

        return () => {
          window.removeEventListener('click', resetTimer);
          window.removeEventListener('keydown', resetTimer);
        };
      }, []);

    useEffect(() => {
        const path = window.location.pathname;
        const lastPart = path.substring(path.lastIndexOf('/') + 1);
        setEndpoint(lastPart);
    }, []);




    let resetTimer = async () => {
        try {
            // debugger;
            ////console.log("Reset Timer Start");

            let sessionResponse: string = await resetSessionTimer(InActiveTimeout, SessionEpiredDateTime);
            ////console.log("sessionResponse value :", sessionResponse);

            // //debugger
            if (sessionResponse == 'expired') {
                debugger
                sessionStorage.removeItem("c_at");
                setisSessionEpired(true)
                setAlert('Your Session has Expired please login again to continue');
            }
            else if (sessionResponse == 'updateToken') {
                if (emailAddress == "" || emailAddress == undefined) {
                    await sessionTokenProperties();
                }
                debugger
                let token = await getToken()
                let payload = {
                    mailId: emailAddress,
                    isAdmin: false,
                    token: token
                };
                let getsessionToken = await getSession(payload);
                const rn_secretKey: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY
                const cs_secretKey: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;

                const c_byteValue = objCryptoJS.AES.decrypt(getsessionToken?.data?.data.sessionToken, rn_secretKey);
                const c_decryptedValue = c_byteValue.toString(objCryptoJS.enc.Utf8);
                let updatedToken = objCryptoJS.AES.encrypt(c_decryptedValue, cs_secretKey).toString();
                await sessionStorage.removeItem("c_at");
                await sessionStorage.setItem('c_at', updatedToken);
                let updatedobjClient: any = jwt(c_decryptedValue);
                SessionEpiredDateTime = updatedobjClient.CreatedAt;
                setSessionEpiredDateTime(SessionEpiredDateTime);
            }
            ////console.log("Reset Timer End");
        }
        catch (error) {
            ////console.log("Reset Timer Catch");
            setAlert('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
            setLoader(false);
        }
    }

    const resetSessionTimer = async (InActiveTimeout: any, SessionExpirationDateTime: any) => {
        try {
            // debugger
            ////console.log("Reset Session Timer Start");
            // Assuming InActiveTimeout is a number representing the ID of the current timeout

            if (typeof window !== 'undefined') {
                ////console.log("Reset Session Timer Inside");
                clearTimeout(InActiveTimeout);
                const tokenExpiryCheckInterval = Number(process.env.REACT_APP_TABIDLESCENARIO);

                InActiveTimeout = setTimeout(() => {
                    ////console.log("Expired");
                    sessionStorage.removeItem("c_at");
                    ////console.log("removed session");
                    setisSessionEpired(true)
                    setAlert('Your Session has Expired please login again to continue');
                    // return 'expired'
                }, tokenExpiryCheckInterval);

                ////console.log("Reset Session Timer checking");

                const currentDateTime = new Date().toISOString();
                const sessionExpiredDateTime = new Date(SessionExpirationDateTime).toISOString();
                ////console.log("sessionExpiredDateTime value :", sessionExpiredDateTime);

                const diffInMilliseconds = Math.abs(new Date(sessionExpiredDateTime).getTime() - new Date(currentDateTime).getTime());
                ////console.log("diffInMilliSeconds values:", diffInMilliseconds);

                const minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
                ////console.log("minutes : ", minutes);

                const sessionUpdateTimeGap = Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP);
                ////console.log("Reset Session Timer Differece : ", sessionUpdateTimeGap);

                if (minutes > sessionUpdateTimeGap) {

                    ////console.log("Reset Session Timer need to update");
                    return 'updateToken'
                }

                ////console.log("Reset Session Timer End");
                return 'NoActionRequired'
            }
            return 'NoActionRequired'
        }
        catch (ex) {
            ////console.log("Reset Session Timer Catch");
            throw ex
        }
    };


    let sessionTokenProperties = async () => {
        try {
            const tokenResponse: any = await decodeSessionDetails();

            if (tokenResponse.clientDetails) {
                setObjClient(tokenResponse.clientDetails)
                setclientName(tokenResponse.clientDetails.Name);
                setCompanyName(tokenResponse.clientDetails.CompanyName)
                emailAddress = tokenResponse.clientDetails.Email;
                setEmailAddress(emailAddress);
                setRole(tokenResponse.clientDetails.Role);
                setSessionEpiredDateTime(tokenResponse.clientDetails.CreatedAt);
            }
            else {
                history.push("/Login")
            }
            //#region only for required Modules
            if (tokenResponse.adminDetails) {
                setObjAdmin(tokenResponse.adminDetails)
                setAdminName(tokenResponse.adminDetails.Name);
                setAdminEmailAddress(tokenResponse.adminDetails.Email);
                setRole(tokenResponse.adminDetails.Role);
            }
            // else {
            //     history.push("/Admin/Login")
            // }
            //#endregion
        } catch (error) {
            console.error('Failed in main method', error);
        }
    };

    const getToken = async () => {
        let clientToken: string;
        let adminToken: string;
        let token: { clientToken?: string; adminToken?: string } = {};

        try {
            if (objClient != undefined) {
                let sessionToken: any = sessionStorage.getItem('c_at')
                if (sessionToken) {
                    sessionToken = objCryptoJS.AES.decrypt(sessionToken, c_secretKey)
                    var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
                    clientToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
                    token['clientToken'] = clientToken

                }


            }
            if (objAdmin != undefined) {
                let sessionToken: any = sessionStorage.getItem('a_at')
                if (sessionToken) {
                    sessionToken = objCryptoJS.AES.decrypt(sessionToken, a_secretKey)
                    var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
                    adminToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();

                    token['adminToken'] = adminToken

                }
            }
            return token
        } catch (error) {
            throw error
        }
    }


    const handleGetStartedClick = async (planType:string): Promise<void> => {
        try {
            setLoader(true);

            debugger

            if (planType === 'custom') {
                // Custom form URL will be triggered
                const url = `https://forms.office.com/Pages/ResponsePage.aspx?id=nac7IlIctUOQtnvvz3mEg1mObFyCPLxPrPIVSzDIm8pUM1c1RjZKRlJUQUlSUVQyR1laRlpLUFVFWS4u`; // Example URL
               
                window.open(url, '_blank');
            } else {
                // Get Token (assuming there's a function getToken in planService)
                
                const token = await getToken();
                debugger
                const data = {
                    token: token,
                    environmentType: 'gsuite',
                    planType: 'free',
                    mailId: emailAddress,
                    isAdmin: false
                };

                const response: any = await addClientPlanInfo(data);
                if (response.data.statusCode === 200) {
                    let deccryptedToken = CryptoJS.AES.decrypt(response.data.data, rn_secretKey);
                    let encryptedToken: any = CryptoJS.AES.encrypt(deccryptedToken, c_secretKey);
                    sessionStorage.setItem("c_at", encryptedToken)
                    history.push("/task")
                } else {
                    setAlert('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
                }
            }
        } catch (error) {
            setAlert('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
        } finally {
            setLoader(false);
        }
    };

    const isNewPlan = endpoint.toLocaleLowerCase() === 'newplan';

    return (
        <div>
            

            <div>
                <div className="container-fluid">
                    <div className="row justify-content-center card-container plan-bg ">
                        <div className="col-md-12 col-sm-12 col-lg-10 my-4">
                            <div className="row">
                                <div className="col-md-12 col-lg-12 col-sm-12 text-center">

                                    <h4 className="font-bold font-26">Our flexible Price Plans</h4>

                                    <p className="font-regular font-14">All plans Start with a 7- Day Trial and are tas- deductable business write-off</p>
                                    {/* <!-- <p className="">AVMIGRATRON helps you to assess and to migrate all your data from Slack to MS Teams with ease.</p> --> */}
                                </div>



                                <div className="d-flex justify-content-center my-4">
                                    <ul className="nav nav-pills" role="tablist">
                                        <li className="nav-item">
                                            <span className="nav-link theme-tab active font-16" >Google Workspace</span></li>
                                        
                                    </ul>
                                </div>



                                <div className="col-md-12 col-lg-12 col-sm-12 col-12 mb-3">
                                    <div className=" contact-badge-bg justify-content-center d-flex align-items-center px-4 py-2 rounded-3 flex-md-col">
                                        <p className="mb-sm-3 mb-md-0 font-14 font-semibold">If you have any further queries or if you are interested in our
                                            custom plans, please feel free to contact us.</p>
                                    </div>
                                </div>


                                {/* <!-- plans grid starts here --> */}
                                <div className="col-md-12 col-lg-12 col-sm-12 col-12 ">
                                    <div className="table-responsive table-border ">
                                        <table className="table mt-4 bg-white plan-grid overflow">
                                            <tbody className="font-14 text-center">
                                                <tr>
                                                    <td className=""></td>
                                                    <td className='font-bold font-20'>Free</td>
                                                    <td className='font-bold font-20'>Custom</td>

                                                </tr>

                                                <tr>
                                                    <td className="text-start ">User Gmail</td>
                                                    <td className='font-regular'>1</td>
                                                    <td className='font-regular'>NA</td>

                                                </tr>
                                                <tr>
                                                    <td className="text-start ">User contact</td>
                                                    <td className='font-regular'>1</td>
                                                    <td className='font-regular'>NA</td>
                                                    
                                                </tr>
                                                <tr>
                                                    <td className="text-start ">User Tasks</td>
                                                    <td className='font-regular'>1</td>
                                                    <td className='font-regular'>NA</td>
                                                    
                                                </tr>
                                                <tr>
                                                    <td className="text-start ">User Calendar</td>
                                                    <td className="font-regular">1</td>
                                                    <td className='font-regular'>NA</td>
                                                </tr>
                                               
                                                <tr>
                                                    <td className="text-start ">User Drive</td>
                                                    <td className="font-regular">NA</td>
                                                    <td className="font-regular">NA</td>
                                                    
                                                </tr>
                                                <tr>
                                                    <td className="text-start ">Total Cost</td>
                                                    <td className='font-regular'>NA</td>
                                                    <td className='font-regular'>NA</td>
                                                </tr>

                                                <tr>
                                                    {/* <td className="border-0"> </td>
                                                    <td className="text-center  border-0"><a href="#" className="btn-plan">Get Started</a></td>
                                                    <td className="text-center  border-0"><a href="#" className="btn-plan">Buy Now</a></td>
                                                    <td className="text-center  border-0"><a href="#" className="btn-plan">Buy Now</a></td>
                                                    <td className="text-center  border-0"><a href="#" className="btn-plan">Buy Now</a></td>
                                                    <td className="text-center  border-0"><a href="#" className="btn-plan">Contact Us</a></td> */}
                                                    <td className="border-0"> </td>
                                                    
                                                    <td className="border-0"><button type="button" className="primary-btn btn rounded-pill font-14 font-semibold"   value="Free" onClick={()=>{handleGetStartedClick('free')}} disabled={!isNewPlan}>Get Started</button></td>
                                                    <td className="border-0"><button type="button" className="primary-btn btn rounded-pill font-14 font-semibold"   value="Free" onClick={()=>{handleGetStartedClick('custom')}}>contact us</button></td>

                                                    {/* <td className="text-center  border-0"><button type="button" className="common-btn-class btn-primary-theme btn btn-plan rounded-pill font-14 font-semibold " value="Lite" onClick={(e) => btnBuyNowClick(e)}>{liteplan == "" ? "Buy Now" : "Upgrade"}</button></td>
                                                    <td className="text-center  border-0"><button type="button" className="common-btn-class btn-primary-theme btn btn-plan rounded-pill font-14 font-semibold" value="Plus" onClick={(e) => btnBuyNowClick(e)}>{plusplan == "" ? "Buy Now" : "Upgrade"}</button></td>
                                                    <td className="text-center  border-0"><button type="button" className="common-btn-class btn-primary-theme btn btn-plan rounded-pill font-14 font-semibold" value="Elite" onClick={(e) => btnBuyNowClick(e)}>{eliteplan == "" ? "Buy Now" : "Upgrade"}</button></td>
                                                    <td className="text-center  border-0"><button type="button" className="common-btn-class btn-primary-theme btn btn-plan rounded-pill font-14 font-semibold" value="Pro" onClick={(e) => btnBuyNowClick(e)}>{proplan == "" ? "Buy Now" : "Upgrade"}</button></td> */}

                                                </tr>

                                                <tr>
                                                    <td className="text-start ">No of days free for resource utilization</td>
                                                    <td className="font-semibold font-14 text-dark">7 <span className='font-12 font-regular text-dark'>days</span></td>
                                                    <td className="font-regular">NA</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-start ">Additional cost for resource utilization
                                                        <Tippy
                                                            delay={[500, 0]}
                                                            arrow={true}
                                                            placement='right'
                                                            content={`The additional cost is for utilizing AVAMIGRATRON's cloud resources for the purpose of migration.`}
                                                        >
                                                        <span><img src="/images/info-icon.svg" className="slack-icon ps-2" alt="info-icon" data-for='toolTipSlack' data-place='right' /></span>
                                                        </Tippy>
                                                    </td>
                                                    <td className="font-regular font-14 text-dark">NA</td>
                                                    <td className="font-regular">NA</td>
                                                     </tr>

                                                     
                                            </tbody>
                                            <div>

                                            </div>
                                           

                                       
                                        </table>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>


</div>
</div>



                    {/*Failure Popup starts here */}
                    {alert ? <div
                        className="modal fade show"
                        tabIndex={-1}
                        style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
                    >
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content popup-brd-radius">
                                <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
                                    <span className="position-absolute">
                                        <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />
                                    </span>
                                </div>
                                <div className="modal-body border-0 text-center">
                                    <h5
                                        className="modal-title mb-3 primary-header"
                                        id="Failure-popupLabel"
                                    >
                                        { }
                                    </h5>
                                    <p className="primary-data">
                                        {alert}
                                    </p>
                                </div>
                                <div className="modal-footer border-0 pb-4 justify-content-center">
                                    <button
                                        type="button"
                                        className="btn primary-btn font-14 px-4 font-semibold"
                                        onClick={() => {
                                            if (isSessionEpired) {
                                                history.push('/Login')
                                            }
                                            setAlert('')
                                        }}
                                    >
                                        Ok
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div> : null}
                    {/*Failure Popup ends here */}

                    {loader && <Loader />}
                </div>
                );
};

export default ClientPlantInfoComponent;