import { Onlymail } from "../../Common/Constant"
import { useHistory, useLocation } from "react-router"
import { useState, useEffect } from "react"
import { sendResetLink } from "../../Services/RegistrationService"



export default function ForgotPassword() {
    const location: any = useLocation()

    const history: any = useHistory()



    let loginDataObj: any = {
        mailId: "",
        isAdmin: false
    }


    let errorobj: any = {
        erroremailAddress: "",
    }

    const [controldata, setcontroldata] = useState<any>(loginDataObj)
    const [errordata, seterrordata] = useState<any>(errorobj)
    const [buttonDisable, setButtonDisable] = useState<boolean>(false)
    const [loader, setLoader] = useState<boolean>(false)
    const [ShowPopup, setShowPopup] = useState<boolean>(false)
    const [PopUpMessage, setPopUpMessage] = useState<string>('Action blocked. Please try again or reach AVAMIGRATRON Support.')

    useEffect(() => {
        if (location.pathname.toLowerCase().includes('admin')) {
            setcontroldata({
                ...controldata,
                isAdmin: true
            });
        }


    }, []);

    //PS_18,PS_19,PS_20,PS_21

    const fieldValidation = (e: React.ChangeEvent<HTMLInputElement>): boolean => {
        const { id, value } = e.target;

        let validObj = {
            erroremailAddress: "",
        };
        let flag = true;
        setcontroldata((prev: any) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));

        seterrordata((prev: any) => ({
            ...prev,
            [e.target.name]: '',
        }));


        if (e.target.name === 'mailId') {
            if (value === "" || value == null) {
                validObj.erroremailAddress = "Email Address cannot be empty";
                flag = false;
            } else if (Onlymail(value)) {
                validObj.erroremailAddress = "Please enter Valid Email Address";
                flag = false;
            }

            seterrordata(validObj);
            setButtonDisable(flag)
        }

        return flag;
    };

    //PS_22,PS_23,PS_24,PS_25,PS_26,PS_27,PS_28,PS_29,PS_30,PS_31,PS_32,PS_33

    async function sendVerificationLink(e: any) {
        try {
            if (fieldValidation(e as any)) {
                debugger
                setLoader(true)
                ////console.log(controldata,"hello...hehe here");

                let result: any = await sendResetLink(controldata)
                setLoader(false)
                ////console.log(result, "result");
                if (result.data.statusCode != 200) {
                    if (result.data.statusMessage === 'User Not Found') {
                        seterrordata({ ...errorobj, erroremailAddress: "Please enter Valid Email ID" })
                    }

                    if (result?.data?.statusCode === 400) {
                        setShowPopup(true)
                        setPopUpMessage(result?.data?.statusMessage)
                        sessionStorage.clear()
                    }
                    if (result?.data?.statusMessage === "Please Use SSO Login") {
                        setShowPopup(true)
                        setPopUpMessage(result?.data?.statusMessage)
                        sessionStorage.clear()
                    }
                    
                }
                else {
                    window.location.href = '/Login';
                }
            }

        }

        catch (error) {
            console.error('Error:', error);
        }
    }
    //PS_13,PS_14,PS_15,PS_16,PS_17




    return (
        <>
            {/* Login page starts here */}
            <div className="bg-white">
                <div className="container-fluid">
                    <div className="row ">
                        {/*Left container starts here */}
                        <div className="col-xl-7 col-lg-12 col-md-12 col-sm-12 col-12 login-brd-left w-60">
                            <div className="row justify-content-center">
                                <div className="col-md-12 px-md-4 px-2 pt-4">
                                    <span>
                                        <a href="https://www.avamigratron.com" className='anchorAsPointer'>
                                            <img
                                                src="/images/brand-logo.svg"
                                                alt="brand-logo"
                                                className="login-logo-sty mb-3"
                                            />
                                        </a>
                                    </span>
                                </div>
                                <div className="col-xl-8 col-lg-9 col-md-9 col-sm-12 pt-0 pb-0 ">
                                    <div className="login-content-height w-100 d-flex">
                                        <div className="form-signup my-md-auto mt-0 w-100">
                                            <h5 className="mb-4 font-20 login-header font-bold">
                                                AVAMIGRATRON
                                            </h5>
                                            <p className="tertiary-header mb-2">Forgot Password?</p>
                                            <p className="mt-2 mb-4 font-14 font-regular text-gray">
                                                Please enter the registered email address. We'II send you a new passsword.
                                            </p>
                                            <div className="col-md-12 mb-3">
                                                <label
                                                    className="form-label font-14 font-semibold"
                                                    htmlFor="emailAddress"
                                                >
                                                    Email Address{" "}
                                                </label>
                                                <input
                                                    className="form-control custom-form"
                                                    type="text"
                                                    id="emailAddress"
                                                    name="mailId"
                                                    placeholder="Enter Email Address"
                                                    onChange={(e) => { fieldValidation(e) }}
                                                    onKeyDownCapture={(e) => {
                                                        if (e.key === 'Enter') {
                                                            sendVerificationLink(e)
                                                        }
                                                    }}
                                                    maxLength={320}

                                                />
                                                {errordata.erroremailAddress && (
                                                    <span className="font-12 font-semibold mandatory-color mt-2 mb-0 ">{errordata.erroremailAddress}</span>)}
                                            </div>
                                            <button
                                                type="button"
                                                onClick={sendVerificationLink}

                                                className="btn w-100 mb-3 font-14 font-semibold px-4 primary-btn"
                                                disabled={!buttonDisable}
                                            >
                                                Send Verification Link                                            </button>
                                            <p className="mt-0 mb-5 font-14 font-regular text-gray text-center">
                                                <a className="cursor-pointer font-semibold link-color" onClick={() => { history.push('/Login') }}>
                                                    Back to Login
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*Left container ends here */}
                        {/*Right container starts here */}
                        <div className=" col-xl-5 d-xl-block d-none p-0 w-40">
                            <div className="login-bg-height position-relative my-0">
                                <div className="login-bg-height login-bg-img text-center">
                                    <h5 className="login-header font-bold font-32 pt-5 mb-4">
                                        World's No 1 Enterprise-ready
                                    </h5>
                                    <p className="font-24 font-semibold primary-color">
                                        Migration Platform!
                                    </p>
                                </div>
                            </div>
                            {loader && (
                                <div className="overlay">
                                    <div className="position-absolute top-50 start-50 translate-middle d-flex justify-content-center align-items-center flex-column">
                                        <div
                                            className="spinner-border Loader loader-color align-center "
                                            role="status"
                                        >
                                            <span>
                                                <span className="loader-inner-circle" />
                                            </span>
                                        </div>
                                        <p className="font-16 font-semibold color-white-v2 mt-2">
                                            Sending Reset URL....
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>
                        {/*Right container ends here */}
                        {ShowPopup == true ? (
                            <div className="modal fade show" id="Failure-popup" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="Failure-popupLabel" aria-modal="true" role="dialog" style={{ display: "block", backgroundColor: "rgba(0,0,0,0.8)" }}>

                                <div className="modal-dialog modal-dialog-centered">

                                    <div className="modal-content popup-brd-radius">

                                        <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">

                                            <span className="position-absolute">

                                                <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />

                                            </span>

                                        </div>

                                        <div className="modal-body border-0 text-center">

                                            <h5 className="modal-title mb-3 primary-header" id="Failure-popupLabel">Failure</h5>

                                            <p className="primary-data">{PopUpMessage}</p>

                                        </div>

                                        <div className="modal-footer border-0 pb-4 justify-content-center">

                                            <button type="button" onClick={() => setShowPopup(false)} className="btn primary-btn font-14 px-4 font-semibold" data-bs-dismiss="modal">OK</button>

                                        </div>

                                    </div>


                                </div>

                            </div>) : null}
                    </div>
                </div>
                {/* Login page ends here */}
            </div>
        </>
    );





}