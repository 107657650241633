function Loader (){
 
    return (
        <div className="container-fluid z-index-3">
        <div className="overlay">
          <div className="position-absolute top-50 start-50 translate-middle d-flex flex-column align-items-center">
            <div
              className="spinner-border Loader loader-color align-center"
              role="status"
            >
              <span>
                <span className="loader-inner-circle" />
              </span>
            </div>
            <p className="loading-text font-16 font-semibold color-white-v2 mt-2 text-center">
              It will take time to load. Please wait until it loads
            </p>
          </div>
        </div>
      </div>
    )
  }
   
   
   
  export default Loader;