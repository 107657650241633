import { client } from "../Client/Client";
import { BE_URL, Admin } from "../Common/Constant";
//	PS117  	PS118  	PS119  	PS120  	PS121  	PS122  	PS123  	PS124  	PS125   	
//P126   	P127   	P128   	P129   	P130   	P131   	P132   	P133   	P134     P135     
//P136     P137     P138     P139     P140     P141     P142     P143     P144     P145
export const getAdminClient= async (data: any) => {

    try {
        const config: any = {
            method: 'POST',
            //  url: "http://localhost:8080/admin/getAdminClientData",
            url: BE_URL + Admin.getAdminClientpath,
            headers: { 'Content-Type': 'application/json' },
            data: data,
        };

        const response: any = await client(config);
        ////console.log("response from Api", data);
        return response;
    }
    catch (error: any) {
        throw error
    }
};

//P126   	P127   	P128   	P129   	P130   	P131   	P132   	P133   	P134     
//P135     P136     P137     P138     P139     P140     P141     P142     P143     P144     P145 
export const handleClient= async (data: any) => {

    try {
        const config: any = {
            method: 'POST',
            // url: "http://localhost:8080/admin/handleClient",
            url: BE_URL + Admin.handleClient,

            headers: { 'Content-Type': 'application/json' },
            data: data,
        };

        const response: any = await client(config);
        ////console.log("response from Api", data);
        return response;
    }
    catch (error: any) {
        throw error
    }
};
export async function sendOtp(data:any) {
    let config : any = {
        method: 'POST',
        url: BE_URL + '/adminbe/admin/sendOtp',
        // url:"http://localhost:8080/admin/sendOtp",
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    const response = await client(config);
    ////console.log(config,"uyhgfdcv");
    return response;
  };

  export async function updateServerDown(data:any) {
    ////console.log("camr", data);
    debugger
    let config = {
      method: 'POST',
      // url: 'http://localhost:8080/admin/updateServerdown',
      url: BE_URL + Admin.update,

      data: data
    }
    const response = await client(config);
    ////console.log("response from Api", data);
    return response;
  };
 
  export async function getSession(data:any) {
    ////console.log("camr", data);
    debugger
    let config = {
      method: 'POST',
    //   url: "http://localhost:8082/admin/redirecttoClient",
        url: BE_URL + Admin.getSession,
      data: data
    }
    const response = await client(config);
    ////console.log("response from Api", data);
    return response;
  };
  export async function revokeLicense(data:any) {
    ////console.log("camr", data);
    debugger
    let config = {
      method: 'POST',
      url: BE_URL + Admin.revokeLicense,
        // url: BE_URL + Admin.getSession,
      data: data
    }
    const response = await client(config);
    ////console.log("response from Api", data);
    return response;
  };
  export async function assignLicense(data:any) {
    debugger
    let config = {
      method: 'POST',
      //  url: "http://localhost:8081/admin/assignLicense",
      url: BE_URL + Admin.assignLicense,
        // url: BE_URL + Admin.getSession,
      data: data
    }
    const response = await client(config);
    //console.log("response from Api", data);
    return response;
  };
  export async function sendMailToClients(data:any) {
    ////console.log("camr", data);
    debugger
    let config = {
      method: 'POST',
      // url: 'http://localhost:8081/admin/sendMail',
      url: BE_URL + Admin.sendMail,
      data: data
    }
    const response = await client(config);
    ////console.log("response from Api", data);
    return response;
  };

export async function getServerStatus(){
  let config = {
    method: 'POST',
    // url: 'http://localhost:8888/getServerStatus',
    url: BE_URL + Admin.getServerStatus
  }
  const response = await client(config);
  ////console.log("response from Api", data);
  return response;
}