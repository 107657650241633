import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import * as XLSX from "xlsx";
import { OutlookTypeGrid, invokeOutlookReportApi } from "../../Services/MigrationReportServices";
import Loader from "../Common/Loader";
import objCryptoJS from "crypto-js";
import { commonErrorMsgs } from "../../Common/Constant";

interface MigrationTypeChildProps {
  searchClick: string;
  filterClick: string;
  applicationName: string;
  sourceMailId: string;
  targetMailId: string;
  totalCount: number;
}

const MigrationTypeChild: React.FC<MigrationTypeChildProps> = ({
  searchClick,
  filterClick,
  applicationName,
  sourceMailId,
  targetMailId,
  totalCount,
}) => {
  //console.log(applicationName, "indiappname");

  const location: any = useLocation()?.state;
  const [loading, setLoading] = useState<boolean>(false);
  const rn_secretKey = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY || '';
  const a_secretKey = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY || '';
  const c_secretKey = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY || '';
  const [objAdmin, setObjAdmin] = useState({})
  const [objClient, setObjClient] = useState({})
  const [alert, setAlert] = useState<boolean>(false)
  const [alertMessage, setAlertMessage] = useState<string>('')
  const [taskId, setTaskId] = useState<string | undefined>(
    location?.state?.taskId
  );
  const [userMappingId, setUserMappingId] = useState<string | undefined>(
    location?.state?.userMappingId
  );
  const [sourceDisplayName, setSourceDisplayName] = useState<string | undefined>(
    location?.state?.sourceDisplayName || applicationName
  );
  const [page, setPage] = useState<number>(10);
  const [sortBy, setSortBy] = useState<string>("migrationStartedAt");
  const [sortOrder, setSortOrder] = useState<string>("desc");
  const [getMigrationTypeGrid, setgetMigrationTypeGrid] = useState<any[]>([]);
  const [sortHide, setSortHide] = useState<string>("");
  const [success, setsuccess] = useState<boolean>(false);
  const [failure, setfailure] = useState<boolean>(false);


  let bindingObject: any = {
    outlook: [
      {
        header: "Migration Type",
        value: "migrationType",
        sort: "migrationType",
      },
      {
        header: "Scheduled At",
        value: "scheduledAt",
        sort: "scheduledAt",
      },
      {
        header: "Migration Started At",
        value: "migrationStartedAt",
        sort: "migrationStartedAt",
      },
      {
        header: "Migration Ended At",
        value: "migrationEndedAt",
        sort: "migrationEndedAt",
      },
      {
        header: "Status",
        value: "Status",
        sort: "status",
      },
      {
        header: "Report",
        value: "report",
        sort: null, // Assuming report cannot be sorted
      },
    ],
    gmail: [
      {
        header: "Migration Type",
        value: "migrationType",
        sort: "migrationType",
      },
      {
        header: "Scheduled At",
        value: "scheduledAt",
        sort: "scheduledAt",
      },
      {
        header: "Migration Started At",
        value: "migrationStartedAt",
        sort: "migrationStartedAt",
      },
      {
        header: "Migration Ended At",
        value: "migrationEndedAt",
        sort: "migrationEndedAt",
      },
      {
        header: "Status",
        value: "StatusText",
        sort: "StatusText",
      },
      {
        header: "Report",
        value: "report",
        sort: null, // Assuming report cannot be sorted
      },
    ],
    teamschannel: [
      {
        header: "Migration Type",
        value: "migrationType",
        sort: "migrationType",
      },
      {
        header: "Scheduled At",
        value: "scheduledAt",
        sort: "scheduledAt",
      },
      {
        header: "Migration Started At",
        value: "migrationStartedAt",
        sort: "migrationStartedAt",
      },
      {
        header: "Migration Ended At",
        value: "migrationEndedAt",
        sort: "migrationEndedAt",
      },
      {
        header: "Status",
        value: "StatusText",
        sort: "StatusText",
      },
      {
        header: "Report",
        value: "report",
        sort: null, // Assuming report cannot be sorted
      },
    ],
    sharepoint: [
      {
        header: "Migration Type",
        value: "migrationType",
        sort: "migrationType",
      },
      {
        header: "Scheduled At",
        value: "scheduledAt",
        sort: "scheduledAt",
      },
      {
        header: "Migration Started At",
        value: "migrationStartedAt",
        sort: "migrationStartedAt",
      },
      {
        header: "Migration Ended At",
        value: "migrationEndedAt",
        sort: "migrationEndedAt",
      },
      {
        header: "Status",
        value: "StatusText",
        sort: "StatusText",
      },
      {
        header: "Report",
        value: "report",
        sort: null, // Assuming report cannot be sorted
      },
    ],
    mydrive: [
      {
        header: "Migration Type",
        value: "migrationType",
        sort: "migrationType",
      },
      {
        header: "Scheduled At",
        value: "scheduledAt",
        sort: "scheduledAt",
      },
      {
        header: "Migration Started At",
        value: "migrationStartedAt",
        sort: "migrationStartedAt",
      },
      {
        header: "Migration Ended At",
        value: "migrationEndedAt",
        sort: "migrationEndedAt",
      },
      {
        header: "Status",
        value: "StatusText",
        sort: "StatusText",
      },
      {
        header: "Report",
        value: "report",
        sort: null, // Assuming report cannot be sorted
      },
    ],
  };

  useEffect(() => {
    fetchData();
    ////console.log(filterClick, "gfchgvjhkhjfhgjhkj");
  }, [searchClick, filterClick, sortOrder, sortBy, page]);
  const getToken = async () => {
    let clientToken: string;
    let adminToken: string;
    let token: { clientToken?: string; adminToken?: string } = {};
    debugger
    try {
      if (objClient != undefined) {
        let sessionToken: any = sessionStorage.getItem('c_at')
        if (sessionToken) {
          sessionToken = objCryptoJS.AES.decrypt(sessionToken, c_secretKey)
          var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
          clientToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
          token['clientToken'] = clientToken

        }


      }
      if (objAdmin != undefined) {
        let sessionToken: any = sessionStorage.getItem('a_at')
        if (sessionToken) {
          sessionToken = objCryptoJS.AES.decrypt(sessionToken, a_secretKey)
          var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
          adminToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();

          token['adminToken'] = adminToken

        }
      }
      return token
    } catch (error) {
      throw error
    }
  }

  //console.log('reposrt app',applicationName)
  const fetchData = async (): Promise<void> => {
    try {
      let token = await getToken()
      setLoading(true);
      debugger;
      let payload = {
        token: token,
        taskId: taskId,
        userMappingId: userMappingId,
        search: searchClick.trim(),
        filter: filterClick,
        sortBy: sortBy,
        sortOrder: sortOrder,
        pageSize: page,
        applicationName: applicationName,
      };
      let result = await OutlookTypeGrid(payload);
      if (result?.data?.statusCode == 200) {
        setgetMigrationTypeGrid(result?.data?.data?.final)
        //console.log(result?.data?.data?.final, 'final data');

      }
      else {
        setAlert(true)
        setAlertMessage(commonErrorMsgs.generalError)
      }

    } catch (error: any) {
      setAlert(true)
      setAlertMessage(commonErrorMsgs.generalError)
    } finally {
      setLoading(false);
    }
  };
  /**PS_233 to 
  PS_240 will be executed */
  const getStatusClass = (status: string): string => {
    //console.log(status, "statussssssss");
    switch (status?.toLowerCase().trim()) {
      case "completed":
        return "table-status success d-inline-block me-2";
      case "scheduled":
        return "table-status scheduled d-inline-block me-2";
      case "inprogress":
        return "table-status warning d-inline-block me-2";
      case "queued":
        return "table-status draft d-inline-block me-2";
      case "paused":
        return "table-status paused d-inline-block me-2";
      case "failed":
        return "table-status danger d-inline-block me-2";
      case "warning":
        return "table-status danger d-inline-block me-2";
      default:
        return `table-status ${status?.toLowerCase()} d-inline-block me-2`;
    }
  };

  const rendersuccesstoast = (): JSX.Element => {
    return (
      <>
        <div className="toast-container">
          <div
            className="toast show custom-toast-success"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
            style={{
              position: "fixed",
              top: "20px",
              right: "20px",
              zIndex: 9999,
            }}
          >
            <div className="d-flex p-3 pb-0 border-0 align-items-center">
              <img
                src="/images/success-toast-icon.svg"
                className="rounded me-2"
                alt="..."
              />
              <h1 className="me-auto font-14 font-semibold toast-header-color mb-0">
                Success
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="toast"
                aria-label="Close"
                onClick={() => setsuccess(false)}
              />
            </div>
            <div className="toast-body px-5 pt-0">
              {`data has been imported`}
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderfailuretoast = (): JSX.Element => {
    return (
      <>
        <div className="toast-container">
          <div
            className="toast show custom-toast-failure"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
            style={{
              position: "fixed",
              top: "20px",
              right: "20px",
              zIndex: 9999,
            }}
          >
            <div className="d-flex p-3 pb-0 border-0 align-items-center">
              <img
                src="/images/failure-toast-icon.svg"
                className="rounded me-2"
                alt="..."
              />
              <h1 className="me-auto font-14 font-semibold toast-header-color mb-0">
                Failure
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="toast"
                aria-label="Close"
                onClick={() => setfailure(false)}
              />
            </div>
            <div className="toast-body px-5 pt-0">
              {`data not has been exported`}
            </div>
          </div>
        </div>
      </>
    );
  };
  /**PS_161 to 
  PS_194 will be executed */
  const invokeDownload = async (dal: any): Promise<void> => {
    setLoading(true);
    try {
      const token = await getToken();
      const sourceEmail: string = sourceMailId;
      const targetEmail: string = targetMailId;
      let payload = {
        token: token,
        taskId: taskId,
        userMappingId: userMappingId,
        migrationType: dal?.migrationType,
        applicationName: applicationName,
      };

      let data = await invokeOutlookReportApi(payload);
      //console.log(data, "report data");

      let workbook = XLSX.utils.book_new();

      if (applicationName === 'outlook') {
        const headers = [
          "Source Email Address",
          "Target Email Address",
          "Source Entity Type",
          "Source Entity Count",
          "Target Entity Type",
          "Target Entity Count",
        ];

        const mailHeaders = [
          "Source Email Address",
          "Target Email Address",
          "Source Mail Folder Name",
          "Target Mail Folder Name",
          "Source Mail ID",
          "Target Mail ID",
          "Error Log - Mail",
        ];

        const calendarHeaders = [
          "Source Email Address",
          "Target Email Address",
          "Source Calendar Folder Name",
          "Target Calendar Folder Name",
          "Source Event ID",
          "Target Event ID",
          "Error Log - Calendar",
        ];

        const contactHeaders = [
          "Source Email Address",
          "Target Email Address",
          "Source Contact Folder Name",
          "Target Contact Folder Name",
          "Source Contact ID",
          "Target Contact ID",
          "Error Log - Contacts",
        ];

        const todoHeaders = [
          "Source Email Address",
          "Target Email Address",
          "Source ToDo Folder Name",
          "Target ToDo Folder Name",
          "Source Task ID",
          "Target Task ID",
          "Error Log - ToDo",
        ];

        const entityTypes = [
          "Mail",
          "Mail Folder",
          "Calendar Folder",
          "CalendarEvent",
          "Contact Folder",
          "Contacts",
          "ToDo Folder",
          "Task",
        ];

        let dataArray: any = [headers];
        let transformedDataArray: any = [mailHeaders];
        let calendarArray: any = [calendarHeaders];
        let contactArray: any = [contactHeaders];
        let todoArray: any = [todoHeaders];

        if (data?.data?.statusCode == 200) {
          if (data?.data?.data?.mail?.length > 0) {
            data?.data?.data?.mail?.forEach((item: any) => {
              const row = [
                sourceEmail,
                targetEmail,
                item.sourcefoldername,
                item.targetfoldername,
                item.sourcemessageid,
                item.targetmessageid,
                item.errorlog,
              ];
              transformedDataArray.push(row);
            });
          }

          if (data?.data?.data?.calendar?.length > 0) {
            data?.data?.data?.calendar?.forEach((item: any) => {
              const row = [
                sourceEmail,
                targetEmail,
                item.sourceFolderName,
                item.targetFolderName,
                item.sourceEventId,
                item.targetEventId,
                item.errorLog,
              ];
              calendarArray.push(row);
            });
          }

          if (data?.data?.data?.contacts?.length > 0) {
            data?.data?.data?.contacts?.forEach((item: any) => {
              const row = [
                sourceEmail,
                targetEmail,
                item.sourcefoldername,
                item.targetfoldername,
                item.sourceContactId,
                item.targetContactId,
                item.errorlog,
              ];
              contactArray.push(row);
            });
          }

          if (data?.data?.data?.todo?.length > 0) {
            data?.data?.data?.todo?.forEach((item: any) => {
              const row = [
                sourceEmail,
                targetEmail,
                item.sourcefoldername,
                item.targetfoldername,
                item.sourceTodoId,
                item.targetTodoId,
                item.errorlog,
              ];
              todoArray.push(row);
            });
          }

          data?.data?.data?.Count?.forEach((item: any) => {
            entityTypes.forEach((entityType) => {
              const row = [
                sourceEmail,
                targetEmail,
                entityType,
                item[`source${entityType.replace(/\s/g, "")}Count`] || 0,
                entityType,
                item[`target${entityType.replace(/\s/g, "")}Count`] || 0,
              ];
              dataArray.push(row);
            });
          });

          const worksheet = XLSX.utils.aoa_to_sheet(dataArray);
          XLSX.utils.book_append_sheet(workbook, worksheet, "overView");

          const worksheet1 = XLSX.utils.aoa_to_sheet(transformedDataArray);
          XLSX.utils.book_append_sheet(workbook, worksheet1, "Mail");

          const worksheet2 = XLSX.utils.aoa_to_sheet(calendarArray);
          XLSX.utils.book_append_sheet(workbook, worksheet2, "Calendar");

          const worksheet3 = XLSX.utils.aoa_to_sheet(contactArray);
          XLSX.utils.book_append_sheet(workbook, worksheet3, "Contacts");

          const worksheet4 = XLSX.utils.aoa_to_sheet(todoArray);
          XLSX.utils.book_append_sheet(workbook, worksheet4, "ToDo");
        } else {
          setAlert(true);
          setAlertMessage(commonErrorMsgs.generalError);
        }
      }
      else if (applicationName === 'teamschannel') {
        debugger;
        // Summary sheet
        const summaryHeaders = [
          "Type",
          "Source Count",
          "Migrated Count",
          "Status",
          "Error Log"
        ];

        let summaryData = [summaryHeaders];

        // Populate summary data
        const messageSummary = data?.data?.data?.messageSummary[0] || {};
        summaryData.push([
          "Messages",
          messageSummary?.SourceCount || 0,
          messageSummary?.MigratedCount || 0,
          messageSummary?.StatusText || '',
          messageSummary?.errorLog || '' // Include error log if available
        ]);

        const fileSummary = data?.data?.data?.fileSummary[0] || {};
        summaryData.push([
          "Files",
          fileSummary?.SourceCount || 0,
          fileSummary?.MigratedCount || 0,
          fileSummary?.Status || '',
          fileSummary?.errorLog || '' // Include error log if available
        ]);

        const summaryWorksheet = XLSX.utils.aoa_to_sheet(summaryData);
        XLSX.utils.book_append_sheet(workbook, summaryWorksheet, "Summary");

        // Message Details sheet
        const messageDetailsHeaders = [
          "Source Team Name",
          "Source Team Type",
          "Source Team ID",
          "Source Message Details",
          "Target Team Name",
          "Target Team Type",
          "Target Team ID",
          "Target Migrated Message Details",
          "Migrated Date and Time",
          "Status",
          "Error Log"
        ];

        let messageDetailsData = [messageDetailsHeaders];

        // Populate message details data
        data?.data?.data?.messageDetails.forEach((item: any) => {
          const mapping = data?.data?.data?.migrationMapping[0] || {};
          messageDetailsData.push([
            mapping?.SourceTeamName,
            mapping?.SourceTeamMode,
            mapping?.SourceTeamID,
            item?.SourceMessageLink,
            mapping?.TargetTeamName,
            mapping?.TargetTeamMode,
            mapping?.TargetTeamID,
            item?.TargetMessageLink,
            new Date().toISOString(), // Actual migration date not provided in the sample data
            item?.StatusText,
            item?.customError || '' // Include error log if available
          ]);
        });

        const messageDetailsWorksheet = XLSX.utils.aoa_to_sheet(messageDetailsData);
        XLSX.utils.book_append_sheet(workbook, messageDetailsWorksheet, "Message Details");

        // File Details sheet
        const fileDetailsHeaders = [
          "Source Team Name",
          "Source Team Type",
          "Source Team ID",
          "Source File Name",
          "Target Team Name",
          "Target Team Type",
          "Target Team ID",
          "Target File Name",
          "Source Size",
          "Target Size",
          "Migrated Date and Time",
          "Status",
          "Error Log"
        ];

        let fileDetailsData = [fileDetailsHeaders];

        // Populate file details data
        data?.data?.data?.fileDetails.forEach((item: any) => {
          const mapping = data?.data?.data?.migrationMapping[0] || {};
          fileDetailsData.push([
            mapping?.SourceTeamName,
            mapping?.SourceTeamMode,
            mapping?.SourceTeamID,
            item?.SourceFileName,
            mapping?.TargetTeamName,
            mapping?.TargetTeamMode,
            mapping?.TargetTeamID,
            item?.TargetFileName,
            item?.SourceFileSize,
            item?.TargetFileSize,
            new Date().toISOString(), // Actual migration date not provided in the sample data
            item?.Status ? 'Completed' : 'Failed', // Assuming null Status means failed
            item?.customError || '' // Include error log if available
          ]);
        });

        const fileDetailsWorksheet = XLSX.utils.aoa_to_sheet(fileDetailsData);
        XLSX.utils.book_append_sheet(workbook, fileDetailsWorksheet, "File Details");

      }
      else if (applicationName === 'sharepoint') {
        // Summary Sheet
        const summaryHeaders = [
          "Type",
          "Source Count",
          "Target Count",
          "Status"
        ];

        let summaryData = [summaryHeaders];
        const count = data?.data?.data?.Count[0];

        summaryData.push(["Document Library", count.sourceDocLibCount, count.targetDocLibCount, ""]);
        summaryData.push(["List", count.sourceListCount, count.targetListCount, ""]);
        summaryData.push(["Pages", data?.data?.data?.page.filter((item: any) => item.SourceItemName != null).length, data?.data?.data?.page.filter((item: any) => item.TargetItemName != null).length, ""]);
        summaryData.push(["Permission Levels", count.sourcePermissionLevelCount, count.targetPermissionLevelCount, ""]);
        summaryData.push(["People & Group", count.sourcePeopleGroupCount, count.targetPeopleGroupCount, ""]);

        const summaryWorksheet = XLSX.utils.aoa_to_sheet(summaryData);
        XLSX.utils.book_append_sheet(workbook, summaryWorksheet, "Summary");

        // Document Library Sheet
        const docLibHeaders = [
          "Source Document Library Name",
          "Source Item ID",
          "Source Item Size",
          "Target Document Library Name",
          "Target Item Id",
          "Target Item Size",
          "Date & Time",
          "Status",
          "Error Log"
        ];

        let docLibData = [docLibHeaders];
        data?.data?.data?.documentLibrary.forEach((item: any) => {
          docLibData.push([
            item.SourceItemName || "",
            item.SourceItemID || "",
            item.SourceItemSize || "",
            item.TargetItemName || "",
            item.TargetItemID || "",
            item.TargetItemSize || "",
            new Date().toISOString(),
            item.status == 5 ? 'In Progress' : item.status == 6 ? 'Completed' : item.status == 0 ? 'Warning' : 'Default',
            item.errorlog || ""
          ]);
        });

        const docLibWorksheet = XLSX.utils.aoa_to_sheet(docLibData);
        XLSX.utils.book_append_sheet(workbook, docLibWorksheet, "Document Library");

        // List Sheet
        const listHeaders = [
          "Source List Name",
          "Source List Item ID",
          "Target List Name",
          "Target List Item ID",
          "Date & Time",
          "Status",
          "Error Log"
        ];

        let listData = [listHeaders];
        data?.data?.data?.list.forEach((item: any) => {
          listData.push([
            item.SourceItemName || "",
            item.SourceItemID || "",
            item.TargetItemName || "",
            item.TargetItemID || "",
            new Date().toISOString(),
            item.status == 5 ? 'In Progress' : item.status == 6 ? 'Completed' : item.status == 0 ? 'Warning' : 'Default',
            item.errorlog || ""
          ]);
        });

        const listWorksheet = XLSX.utils.aoa_to_sheet(listData);
        XLSX.utils.book_append_sheet(workbook, listWorksheet, "List");

        // Pages Sheet
        const pagesHeaders = [
          "Source Pages Name",
          "Source Page Type",
          "Target Pages Name",
          "Target Page Type",
          "Date & Time",
          "Status",
          "Error Log"
        ];

        let pagesData = [pagesHeaders];
        data?.data?.data?.page.forEach((item: any) => {
          pagesData.push([
            item.SourceItemName || "",
            item.SourcePageType || "",
            item.TargetItemName || "",
            item.TargetPageType || "",
            new Date().toISOString(),
            item.status == 5 ? 'In Progress' : item.status == 6 ? 'Completed' : item.status == 0 ? 'Warning' : 'Default',
            item.errorlog || ""
          ]);
        });

        const pagesWorksheet = XLSX.utils.aoa_to_sheet(pagesData);
        XLSX.utils.book_append_sheet(workbook, pagesWorksheet, "Pages");

        // Permission Levels Sheet
        const permissionHeaders = [
          "Source Permission Level",
          "Target Permission Level",
          "Date & Time",
          "Status",
          "Error Log"
        ];

        let permissionData = [permissionHeaders];
        data?.data?.data?.permissionLevel.forEach((item: any) => {
          permissionData.push([
            item.SourceItemName || "",
            item.TargetItemName || "",
            new Date().toISOString(),
            item.Status || "",
            item.errorlog || ""
          ]);
        });

        const permissionWorksheet = XLSX.utils.aoa_to_sheet(permissionData);
        XLSX.utils.book_append_sheet(workbook, permissionWorksheet, "Permission Levels");

        // People & Group Sheet
        const peopleGroupHeaders = [
          "Source People & Group",
          "Source Type (People/Group)",
          "Source User Email",
          "Target People & Group",
          "Target Type (People/Group)",
          "Target User Email",
          "Date & Time",
          "Status",
          "Error Log"
        ];

        let peopleGroupData = [peopleGroupHeaders];
        data?.data?.data?.peopleGroup.forEach((item: any) => {
          peopleGroupData.push([
            item.SourceItemName || "",
            item.SourceType || "",
            item.SourceUserEmail || "",
            item.TargetItemName || "",
            item.TargetType || "",
            item.TargetUserEmail || "",
            new Date().toISOString(),
            item.Status || "",
            item.errorlog || ""
          ]);
        });

        const peopleGroupWorksheet = XLSX.utils.aoa_to_sheet(peopleGroupData);
        XLSX.utils.book_append_sheet(workbook, peopleGroupWorksheet, "People & Group");
      }
      else if (applicationName === 'mydrive') {
        // Summary Sheet
        const summaryHeaders = [
          "Type",
          "Source Count",
          "Migrated Count",
          "Status",
          "Error Log"
        ];

        let summaryData = [summaryHeaders];
        (data?.data?.data?.summaryData || []).forEach((item: any) => {
          summaryData.push([
            item.isFolder ? "Folder" : "File",
            item.SourceCount || 0,
            item.MigratedCount || 0,
            item.migrationStatus || "",
            item.errorLog || "" // Added error log field
          ]);
        });

        const summaryWorksheet = XLSX.utils.aoa_to_sheet(summaryData);
        XLSX.utils.book_append_sheet(workbook, summaryWorksheet, "Summary");

        // My Drive Details Sheet
        const myDriveHeaders = [
          "Source User MailId",
          "Target User MailId",
          "Source Folders Name",
          "Target Folders Name",
          "Source Folders Count",
          "Target Folders Count",
          "Source Files Count",
          "Target Files Count",
          "Source File Size",
          "Target File Size",
          "Source FileID",
          "Target FileID",
          "Version Count",
          "Version Size",
          "Error Log"
        ];

        let myDriveData = [myDriveHeaders];
        const userMigration = data?.data?.data?.userMigration[0] || {};
        const folderSummary = (data?.data?.data?.summaryData || []).find((item: any) => item.isFolder) || {};
        const fileSummary = (data?.data?.data?.summaryData || []).find((item: any) => !item.isFolder) || {};

        (data?.data?.data?.driveDetails || []).forEach((item: any) => {
          myDriveData.push([
            userMigration.SourceUserEmail || "",
            userMigration.TargetUserEmail || "",
            item.sourceName || "",
            item.targetName || "",
            item.isFolder ? (folderSummary.SourceCount || 0) : "",
            item.isFolder ? (folderSummary.MigratedCount || 0) : "",
            !item.isFolder ? (fileSummary.SourceCount || 0) : "",
            !item.isFolder ? (fileSummary.MigratedCount || 0) : "",
            item.sourceFileSize || "", // Added source file size field
            item.targetFileSize || "", // Added target file size field
            item.sourceItemId || "",
            item.targetItemId || "",
            item.versionCount || "", // Added version count field
            item.versionSize || "", // Added version size field
            item.customError || ""
          ]);
        });

        const myDriveWorksheet = XLSX.utils.aoa_to_sheet(myDriveData);
        XLSX.utils.book_append_sheet(workbook, myDriveWorksheet, "My Drive Details");
      }


      // Generate file name
      const fileName = `${sourceDisplayName}_${dal?.migrationType}_Report.xlsx`

      // Write the workbook to a file
      XLSX.writeFile(workbook, fileName);
      setLoading(false);
    } catch (error) {
      setAlert(true);
      setAlertMessage(commonErrorMsgs.generalError);
    } finally {
      setLoading(false);
    }
  };
  /*PS_201 to 
  PS_204 will be executed */
  const handleSort = (e: React.MouseEvent<HTMLImageElement>): void => {
    try {
      let sortID = e.currentTarget.id;
      let sortval = sortID.split("@");
      setSortBy(sortval[0]);
      setSortOrder(sortval[1]);
      setSortHide(sortID);
    } catch (e: any) {
      ////console.log(e.message);
    }
  };
  //console.log(applicationName, "name"); // Check the raw value.
  //console.log(applicationName?.toLowerCase().trim(), 'trimmedddd'); // Check the processed value.
  return (
    <>
      {loading && <Loader />}
      {success && rendersuccesstoast()}
      {failure && renderfailuretoast()}

      <div className="table-responsive theme-table bg-white">
        <table className="table table-borderless mb-0">
          <thead>
            <tr>
              {bindingObject[applicationName?.toLowerCase().trim()].map(
                ({ header, sort }: any, index: React.Key | null | undefined) => (
                  <th
                    key={index}
                    className={`text-nowrap ${header === "Report" ? "text-center" : ""
                      }`}
                  >
                    {header}
                    {header?.toLowerCase() !== "report" && (
                      <span className="ms-1 cursor-pointer">
                        <img
                          src={`/images/sort-arrow-up.svg`}
                          alt="sort-arrow"
                          style={{ cursor: "pointer" }}
                          id={`${sort}@ASC`}
                          hidden={sortHide === `${sort}@DESC` ? false : true}
                          onClick={handleSort}
                        />
                        <img
                          src={`/images/sort-arrow-down.svg`}
                          alt="sort-arrow"
                          id={`${sort}@DESC`}
                          style={{ cursor: "pointer" }}
                          hidden={sortHide === `${sort}@DESC` ? true : false}
                          onClick={handleSort}
                        />
                      </span>
                    )}
                  </th>
                )
              )}
            </tr>
          </thead>
          <tbody>
            {getMigrationTypeGrid?.length > 0 ? (
              getMigrationTypeGrid.map((data, index) => (
                <tr key={index}>
                  {bindingObject[applicationName?.toLowerCase().trim()].map(
                    ({ value }: any, index: React.Key | null | undefined) => (
                      <React.Fragment key={index}>
                        {value === "StatusText" ? (
                          <td>
                            <span className="text-nowrap">
                              <span
                                className={getStatusClass(data[value])}
                              ></span>
                              {data[value]}
                            </span>
                          </td>
                        ) : value === "report" ? (
                          <td className="d-flex align-items-center justify-content-center">
                            <span className="d-inline-flex gap-3">
                              <button
                                className="btn action-rounded-btn in-progress-action d-flex align-items-center justify-content-center"
                                data-bs-toggle="tooltip"
                                data-bs-placement="bottom"
                                data-bs-title="Download"
                                data-bs-original-title=""
                                title=""
                                onClick={() => {
                                  invokeDownload(data);
                                }}
                              >
                                <img
                                  src="/images/download-icon-filled.svg"
                                  alt="Download"
                                />
                              </button>
                            </span>
                          </td>
                        ) : (
                          <td>{data[value] || "-"}</td>
                        )}
                      </React.Fragment>
                    )
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={
                    bindingObject[applicationName?.toLowerCase().trim()].length
                  }
                >
                  <div className="text-center">
                    <div className="pt-4 pb-3 text-center">
                      <img
                        src="/images/no-data-found-img.svg"
                        alt="no-data-found-img"
                        className="mb-3"
                      />
                      <h5 className="font-12 font-bold color-no-rec">
                        No Records Found
                      </h5>
                      <p className="font-10 font-regular color-no-rec">
                        No Matching Results Found
                      </p>
                    </div>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div></div>
      <p className="text-start mb-3" style={{ color: 'black' }}>
        # Records of :<span style={{ fontWeight: 'bold' }}>{getMigrationTypeGrid?.length}</span> out of <span style={{ fontWeight: 'bold' }}>{totalCount}</span>
      </p>
      <div className="d-flex justify-content-center mt-5">

        {totalCount <= page ? (
          ""
        ) : (
          <button
            className="btn primary-outline-btn font-14 font-semibold"
            hidden={totalCount < page}
            onClick={() => {
              setPage(page + 10);
            }}
          >
            Load More
          </button>
        )}
      </div>
      {alert ? <div
        className="modal fade show"
        tabIndex={-1}
        style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content popup-brd-radius">
            <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
              <span className="position-absolute">
                <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />
              </span>
            </div>
            <div className="modal-body border-0 text-center">
              <h5
                className="modal-title mb-3 primary-header"
                id="Failure-popupLabel"
              >
                {alertMessage}
              </h5>
              <p className="primary-data">
                {alert}
              </p>
            </div>
            <div className="modal-footer border-0 pb-4 justify-content-center">
              <button
                type="button"
                className="btn primary-btn font-14 px-4 font-semibold"
                onClick={() => { setAlert(false) }}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div> : null}
    </>
  );
};

export default MigrationTypeChild;