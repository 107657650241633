import objCryptoJS from "crypto-js";
import { ChangeEvent, useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { decodeSessionDetails } from '../../Services/CommonServices';
import Loader from '../Common/Loader';
import ViewMigrationGrid from './ViewMigrationGrid';

const ViewMigrationMaster = () => {
  /**PS_01 to PS_21 will be executed*/
  const rn_secretKey = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY || '';
  const a_secretKey = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY || '';
  const c_secretKey = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY || '';

  let location: any = useLocation().state;
  //console.log(location, "locationnnnn");

  const history = useHistory();

  const [objAdmin, setObjAdmin] = useState({})
  const [objClient, setObjClient] = useState({})

  let [clientName, setclientName] = useState<string>('');
  let [companyName, setCompanyName] = useState<string>('');
  let [emailAddress, setEmailAddress] = useState<string>('');
  let [role, setRole] = useState<string>('');

  let [isAdmin, setIsAdmin] = useState<boolean>(false);
  let [adminName, setAdminName] = useState<string>('');
  let [adminEmailAddress, setAdminEmailAddress] = useState<string>('');
  let [adminrole, setAdminRole] = useState<string>('');

  const [taskName, setTaskName] = useState<string>(location?.state?.taskName);
  const [taskId, setTaskId] = useState<number>(location?.state?.taskId);
  const [applicationName, setApplicationName] = useState<string>(location?.state?.applicationName);
  //console.log(applicationName, "app name in view master");

  const [searchValue, setSearchValue] = useState<string>("");
  const [searchClick, setSearchClick] = useState<string>("");

  const [filerApplied, setFilerApplied] = useState<string>('')
  const [filterValue, setFilterValue] = useState<string>("");

  const [loader, setLoader] = useState<boolean>(false);
  const [imageSource, setImageSource] = useState<string>('');

  const [alertMessage, setAlertMessage] = useState<string>('')
  const [alert, setAlert] = useState<boolean>(false);

  const [filterAction, setFilterAction] = useState<{ value: string, timestamp: number }>({ value: "", timestamp: 0 });
  const [searchAction, setSearchAction] = useState<{ value: string, timestamp: number }>({ value: "", timestamp: 0 });
  const [shouldExport, setShouldExport] = useState(false);

  const [itemCount, setItemCount] = useState<number>(0);

  const [currentApplicationName, setCurrentApplicationName] = useState<string>('user');

  const [render, setRender] = useState<boolean>(false);
  const [filterPopup, setFilterPopup] = useState<boolean>(false)

  useEffect(() => {
    //console.log(location?.state, "this is the state");

    setApplicationImage();
  }, [currentApplicationName]);

  const getToken = async () => {
    let clientToken: string;
    let adminToken: string;
    let token: { clientToken?: string; adminToken?: string } = {};
    try {
      if (objClient != undefined) {
        let sessionToken: any = sessionStorage.getItem('c_at')
        if (sessionToken) {
          sessionToken = objCryptoJS.AES.decrypt(sessionToken, c_secretKey)
          var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
          clientToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
          token['clientToken'] = clientToken
        }
      }

      if (objAdmin != undefined) {
        let sessionToken: any = sessionStorage.getItem('a_at')
        if (sessionToken) {
          sessionToken = objCryptoJS.AES.decrypt(sessionToken, a_secretKey)
          var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
          adminToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();

          token['adminToken'] = adminToken
        }
      }

      return token
    } catch (error) {
      throw error
    }
  }

  const setApplicationImage = () => {
    let updatedName = (applicationName.toLowerCase() == 'outlook' && currentApplicationName != 'user') ? (currentApplicationName == 'onedrive') ? 'onedrive' : 'sharedmailbox' : applicationName.toLowerCase()
    switch (updatedName) {
      case "outlook":
        setImageSource("/images/ms-outlook-logo.svg");
        break;
      case "teamschannel":
        setImageSource("/images/teams-logo.svg");
        break;
      case "sharepoint":
        setImageSource("/images/sharepoint.svg");
        break;
      case "gmail":
        setImageSource("/images/gmail.svg");
        break;
      case "gdrive":
        setImageSource("/images/mydrive.svg");
        break;

      case "onedrive":
        setImageSource("/images/onedrive.svg");
        break;

      // Other applications
      case "sharedmailbox":
        setImageSource("/images/shared-mailbox-icon.svg");
        break;
      case "roomsandequipments":
        setImageSource("/images/rooms-n-equipment-icon.svg");
        break;
      case "groups":
        setImageSource("/images/user-groups.svg");
        break;
      default:
        setImageSource("");
        break;
    }
  };

  // Event handler for when an option is selected in the dropdown
  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    // Update state variable with the selected option value
    setCurrentApplicationName(event.target.value);

    setRender(!render)

    let selectedValue = event.target.value;
    // Check if the selected value is 'onedrive' and change it to 'outlook' if it is
    // if (selectedValue === 'onedrive') {
    //   setApplicationName('mydrive');
    // } else {
    //   // Otherwise, set the state variable to the selected option value
    //   setApplicationName('outlook');
    // }

  };

  const updateItemCount = (count: number) => {
    setItemCount(count);
  };

  const handleExportClick = () => {
    setShouldExport(true);
  };

  const handleExportComplete = () => {
    setShouldExport(false);
  };

  return (
    <>

      <div className="container-fluid container-height">
        <div className="row">
          <div className="col-md-12">
            <div className="card px-4 py-4 mt-3 mb-3 border-0 rounded-3">

              {/* bread crumb starts here */}
              <ul className="breadcrumb-item mb-4 ps-0">
                <li>
                  <a className="pe-0" onClick={() => {
                    history.push("/Task", {
                      state: {
                        applicationName: location?.state?.applicationName,
                        applicationId: location?.state?.applicationId,
                        taskName: location?.state?.taskName,
                        taskType: location?.state?.taskType,
                      },
                    });
                  }}>Task</a>
                </li>
                <li className="pe-2">{taskName}</li>
              </ul>
              {/* bread crumb ends here */}


              {(applicationName.toLowerCase() == 'outlook') ?
                <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap gap-3 ">
                  <div className="d-flex align-items-center">
                    <label className="font-14 font-semibold label-color text-nowrap me-2">
                      Select App
                    </label>
                    <select
                      name="groupType"
                      id="groupType"
                      className="form-select custom-form w-100 h-100 m-0 font-14 font-regular cursor-pointer"
                      value={currentApplicationName}
                      onChange={handleChange}
                    >
                      <option value="user" className="cursor-pointer">Outlook</option>
                      <option value="shared" className="cursor-pointer">Shared Mailbox</option>
                      <option value="onedrive" className="cursor-pointer">OneDrive</option>
                    </select>
                  </div>
                </div>
                : null}

              <div className=" d-flex align-items-center justify-content-between mb-3 flex-wrap gap-3 ">
                <h5 className="font-20 font-semibold mb-0">
                  <a
                    onClick={() => {
                      history.push("/Task", {
                        state: {
                          applicationName: location?.state?.applicationName,
                          applicationId: location?.state?.applicationId,
                          taskName: location?.state?.taskName,
                          taskType: location?.state?.taskType,
                        },
                      });
                    }}
                  >
                    <img
                      src="/images/head-back-arrow.svg"
                      alt="back-arrow"
                      className="me-2"
                    />
                  </a>
                  {taskName} ({itemCount})
                  <img
                    src={imageSource}
                    alt=""
                    className="ms-2"
                  />
                </h5>
                <div className="d-flex gap-3 w-md-100 flex-wrap">
                  <div className="d-flex gap-3 flex-row-reverse flex-md-row w-md-100">
                    <button
                      className="btn icon-swap secondary-outline-btn"
                      id="dropdownMenuLink"
                      // data-bs-toggle="dropdown"
                      // aria-expanded="false"
                      onClick={() => setFilterPopup(true)}
                    >
                      <img
                        src="/images/filter-active-icon.svg"
                        alt="filter-active-icon"
                      />
                      <img src="/images/filter-icon.svg" alt="filter-icon" />
                    </button>
                    {/*filter*/}
                    {/* <div class="dropdown-menu dropdown-menu-end custom-filter-width p-3 end-0 show" aria-labelledby="dropdownMenuLink" style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate(669px, 114px);" data-popper-placement="bottom-end"
                    > */}
                    {filterPopup && <div
                      className="dropdown-menu dropdown-menu-end custom-filter-width p-3 end-0 show"
                      // aria-labelledby="dropdownMenuLink"
                      style={{
                        position: "absolute",
                        inset: "0px auto auto 0px",
                        margin: 0,
                        transform: "translate(669px, 114px)"
                      }}
                    // data-popper-placement="bottom-end"
                    >
                      <div className="d-flex justify-content-between align-items-center mb-4">
                        <h5 className="font-bold font-20 mb-0 custom-header-color">
                          Advanced Filter
                        </h5>
                        <span>
                          <img
                            className="cursor-pointer"
                            src="/images/close-icon.svg"
                            alt="close"
                            onClick={() => {
                              setFilterValue("");
                              setFilterPopup(false)
                              // setFilterAction({ value: "", timestamp: Date.now() });
                            }}
                          />
                        </span>
                      </div>
                      <div className="row">
                        <div className="col-md-6 mb-4">
                          <label
                            className="form-label font-14 font-semibold label-color "
                            htmlFor="environment"
                          >
                            Overall Status
                          </label>
                          <select
                            className="form-select custom-form"
                            aria-label="Default select example"
                            id="environment"
                            value={filterValue}
                            onChange={(e) => {
                              setFilterValue(e.target.value);
                              console.log("the fiter value", filterValue);

                            }}
                          >
                            <option value="" disabled selected={filterValue === ""}>
                              Select
                            </option>
                            <option value="0">Warning</option>
                            <option value="1">Paused</option>
                            <option value="2">Cancelled</option>
                            <option value="3">Scheduled</option>
                            <option value="4">Queue</option>
                            <option value="5">In Progress</option>
                            <option value="6">Completed</option>
                            <option value="7">Failed</option>
                          </select>
                        </div>
                        <div className="col-md-12">
                          <div className="d-flex justify-content-end align-items-center py-3">
                            <button
                              className="btn secondary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center py-2 me-3 px-4"
                              onClick={() => {
                                setFilterValue("");
                                setFilterPopup(false)
                                setFilterAction({ value: "", timestamp: Date.now() });
                              }}
                            >
                              Clear
                            </button>
                            <button
                              disabled={filterValue == ""}
                              className="btn primary-btn text-nowrap  w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center px-4"
                              onClick={() => { setFilterAction({ value: filterValue, timestamp: Date.now() }); setFilterPopup(false); }}
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>}
                    {/*filter*/}

                    <div className="input-group box-shadow-filter search-container float-start">
                      <input
                        type="search"
                        className="form-control custom-form font-14 font-regular"
                        placeholder="Search"
                        onChange={(e) => {
                          setSearchValue(e.target.value);
                        }}
                        onKeyDown={(event) => {
                          if (event.key == "Enter") {
                            setSearchAction({ value: searchValue, timestamp: Date.now() });
                          }
                        }}
                      />
                      <button
                        className="btn border d-flex align-items-center"
                        type="button"
                      >
                        <img
                          src="/images/search.svg"
                          alt="search"
                          onClick={() => setSearchAction({ value: searchValue, timestamp: Date.now() })}
                        />
                      </button>
                    </div>
                  </div>

                  <button
                    className="btn primary-btn text-nowrap  w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center"
                    onClick={() => handleExportClick()}
                  >
                    <img
                      src="/images/excel-icon-white.svg"
                      alt="add-plus-icon"
                    />
                    Export
                  </button>
                </div>
              </div>

              <ViewMigrationGrid
                filterAction={filterAction}
                searchAction={searchAction}
                exportAction={shouldExport}
                setShouldExport={setShouldExport}
                taskId={taskId}
                taskName={taskName}
                updateItemCount={updateItemCount}
                role={role}
                adminRole={adminrole}
                applicationName={applicationName.toLowerCase()}
                resourceType={currentApplicationName}
                render={render}
              />
            </div>
          </div>
        </div>
      </div>

      {loader && <Loader />}

      {/*Failure Popup starts here */}
      {alert ? <div
        className="modal fade show"
        tabIndex={-1}
        style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content popup-brd-radius">
            <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
              <span className="position-absolute">
                <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />
              </span>
            </div>
            <div className="modal-body border-0 text-center">
              <h5
                className="modal-title mb-3 primary-header"
                id="Failure-popupLabel"
              >
                {alertMessage}
              </h5>
              <p className="primary-data">
                {alert}
              </p>
            </div>
            <div className="modal-footer border-0 pb-4 justify-content-center">
              <button
                type="button"
                className="btn primary-btn font-14 px-4 font-semibold"
                onClick={() => {
                  setAlert(false)
                }}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div> : null}
      {/*Failure Popup ends here */}
    </>
  );
};

export default ViewMigrationMaster;