
//PS_01, PS_02, PS_03, PS_04, PS_05, PS_06, PS_07, PS_08, PS_09, PS_10, PS_11, PS_15, PS_18
import React, { useState, useEffect } from 'react';
import { Onlymail, PasswordRegex, commonErrorMsgs, isValidPhoneNumber } from '../../Common/Constant';
import { submitRegistration } from '../../Services/RegistrationService';
import { countries, TCountries } from 'countries-list';
import { Country } from 'country-state-city';
import Select from "react-select"
import Tippy from '@tippyjs/react';
import { followCursor } from 'tippy.js';
import { SignupData, ErrorData, CountryData, MsalConfig } from '../../Common/Interface';
import { PublicClientApplication } from "@azure/msal-browser";
import Loader from '../Common/Loader';
import { useHistory } from 'react-router';
import CryptoJS from 'crypto-js';


// PS_19, PS_20, PS_21, 
//PS_22, PS_23, PS_24, PS_25, PS_26, PS_27, PS_28, PS_29, PS_30, PS_31, PS_32, PS_33, PS_34, PS_35, PS_36, PS_37, 
//PS_38, PS_39, PS_40, PS_41, PS_42, PS_43, PS_44, PS_45, PS_46, PS_47
export default function SignUp() {
  const [passwordVisibility, setPasswordVisibility] = useState<boolean>(false);
  const [permanentPasswordVisibility, setpermanentPasswordVisibility] = useState<boolean>(false);
  const [sucessPopup, setSucessPopup] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [basicCountryCodes, setBasicCountryCodes] = useState<string[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<string>("+1");
  const [sso, setSso] = useState<boolean>(false);
  const [form, setForm] = useState<boolean>(false);
  const [alert, setAlert] = useState<boolean>(false)

  let utm_source;

  let signupObj: SignupData = {
    userName: '',
    companyName: '',
    mailId: '',
    password: '',
    permanentPassword: '',
    countryCode: selectedCountry,
    phoneNumber: "",
    isSSOAuthentication: sso,
    sourceType: utm_source,
  }

  let errorEmptyData: ErrorData = {
    errorUserName: "",
    errorCompanyName: "",
    errorMailId: "",
    errorPassword: "",
    errorPermanentPassword: '',
    errorPhoneNumber: ''
  };
  const history = useHistory()
  let validObj: ErrorData = {
    errorUserName: '',
    errorCompanyName: '',
    errorMailId: '',
    errorPassword: '',
    errorPermanentPassword: '',
    errorPhoneNumber: '',
  };

  const [details, setDetails] = useState<SignupData>(signupObj);
  const [errorMessage, setErrorMessage] = useState<ErrorData>(validObj);
  const [buttonDisable, setButtonDisable] = useState<boolean>(false);

  const msalConfig: MsalConfig = {
    auth: {
      clientId: process.env.REACT_APP_Azure_Client_ID,
      // redirectUri: process.env.REACT_APP_FE_URL,
      redirectUri: process.env.REACT_APP_FE_URL,
    },
  }
  const msalInstance: any = new PublicClientApplication(msalConfig);
  msalInstance.initialize();

  // PS_48, PS_49, PS_50, PS_51, PS_52, PS_53, PS_54, PS_55
  useEffect(() => {

    debugger
    const params = new URLSearchParams(window.location.search);
    ////console.log("params : ", params);

    utm_source = params.get('utm_source');
    ////console.log(utm_source, "utmsource variable");

    if (!utm_source) {
      utm_source = 'Direct';
    }
    fetchBasicCountryCodes();
    ////console.log(process.env.REACT_APP_Azure_Client_ID, process.env.REACT_APP_FE_URL,);

  }, []);
  const fetchBasicCountryCodes = async () => {
    const allCountries = await Country.getAllCountries();
    const basicCodes: any = allCountries.filter(country => {
      // Define the criteria for basic country codes.
      // For example, you might consider countries with phone codes less than 100.
      return parseInt(country.phonecode) < 100;
    });
    // Sort the basic country codes alphabetically
    basicCodes.sort((a: any, b: any) => a.name.localeCompare(b.name));
    setBasicCountryCodes(basicCodes);
  };

  const encryptPassword = (password: any) => {
    ////console.log("inside encryptPass function");

    // Replace with your own secret key
    const secretKey: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY;

    // Encrypt the password using AES algorithm
    const encryptedPassword = CryptoJS.AES.encrypt(password, secretKey).toString();
    ////console.log(encryptPassword, "encryptedPasssssss");

    return encryptedPassword;
  };

  //PS_87, PS_88, PS_89, PS_90, PS_91, PS_92, PS_93, PS_94, PS_95, PS_96, PS_97, PS_98, PS_99, PS_100, PS_101, PS_102, PS_103, PS_104, PS_105, PS_106
  const fieldValidation = (e: React.ChangeEvent<HTMLInputElement>) => {
    debugger
    try {
      const { name, value } = e.target;


      const emailRegex = /^[a-zA-Z0-9._%+-]+$/;
      const nameRegex =/^[a-zA-Z]+$/;;
      let result: boolean = true;

      setDetails((prev) => ({
        ...prev,
        [name]: value,
      }));

      if (name === 'mailId') {
        if (value == '') {
          result = false;
          setErrorMessage((prev: any) => ({
            ...prev,
            errorMailId: 'Email ID cannot be empty',
          }));
        }
        else if (Onlymail(value)) {
          result = false;
          setErrorMessage((prev: any) => ({
            ...prev,
            errorMailId: 'The Entered Email ID is invalid',
          }));
        } else {
          setErrorMessage((prev: any) => ({
            ...prev,
            errorMailId: '',
          }));
        }
      }

      if (name === 'userName' || name === 'companyName') {
        if (value == '') {
          if (name === 'userName') {
            setErrorMessage((prev: any) => ({
              ...prev,
              errorUserName: 'Name cannot be empty',
            }));
          }
          else if (name === 'companyName') {
            setErrorMessage((prev: any) => ({
              ...prev,
              errorCompanyName: 'Company name cannot be Empty',
            }));
          }
        }
        else if (!emailRegex.test(value)) {
          result = false;
          if (name === 'userName') {
            setErrorMessage((prev: any) => ({
              ...prev,
              errorUserName: 'Invalid username provided',
            }));
            
          }  else if (!nameRegex.test(value)) {
            result = false;}
            else if (name === 'companyName') {
            if (value.trim().length === 0) {
              setErrorMessage((prev: any) => ({
                ...prev,
                errorCompanyName: 'Company name cannot be Empty',
              }));
            } else {
              setErrorMessage((prev: any) => ({
                ...prev,
                errorCompanyName: 'Invalid company name provided',
              }));
            }
          }
        } else {
          setErrorMessage((prev: any) => ({
            ...prev,
            errorUserName: '',
            errorCompanyName: '',
          }));
        }
      }

      if (name === 'phoneNumber') {
        if (value == '') {
          result = false;
          setErrorMessage((prev: any) => ({
            ...prev,
            errorPhoneNumber: '  Phone number cannot be empty',
          }));
        }
        else if (!isValidPhoneNumber(value)) {
          result = false;
          setErrorMessage((prev: any) => ({
            ...prev,
            errorPhoneNumber: 'Invalid phone number provided',
          }));
        } else {
          setErrorMessage((prev: any) => ({
            ...prev,
            errorPhoneNumber: '',
          }));
        }
      }

      if (!sso) {
        if (name === 'password') {
          if (value == '') {
            result = false;
            setErrorMessage((prev: any) => ({
              ...prev,
              errorPassword: 'Password cannot be empty',
            }));
          }
          else if(value.length>20){
            result = false;
            setErrorMessage((prev: any) => ({
              ...prev,
              errorPassword: 'Maximum allowed charcters exceeded',
            }));
          }
          else if (PasswordRegex(value)) {
            result = false;
            setErrorMessage((prev: any) => ({
              ...prev,
              errorPassword: 'Please enter a valid password',
            }));
          } else {
            setErrorMessage((prev: any) => ({
              ...prev,
              errorPassword: '',
            }));
          }
        } else if (name === 'permanentPassword') {
          if (value == '') {
            result = false;
            setErrorMessage((prev: any) => ({
              ...prev,
              errorPermanentPassword: 'Password cannot be empty',
            }));
          }
          else if(value.length>20){
            result = false;
            setErrorMessage((prev: any) => ({
              ...prev,
              errorPermanentPassword: 'Maximum allowed charcters exceeded',
            }));
          }
          else if (details.password !== value) {
            result = false;
            setErrorMessage((prev: any) => ({
              ...prev,
              errorPermanentPassword: 'Password does not match',
            }));
          } else {
            setErrorMessage((prev: any) => ({
              ...prev,
              errorPermanentPassword: '',
            }));
          }
        }
      }

      if (
        details.mailId !== '' &&
        details.companyName !== '' &&
        details.password !== '' &&
        details.permanentPassword !== '' &&
        details.phoneNumber !== '' &&
        details.userName !== ''
      ) {
        ////console.log(result, 'result of signupppp');
        setButtonDisable(result);
      }
      return result;
    }
    catch {
      setAlert(true)

    }
  };
  //PS_66, PS_67, PS_68, PS_69, PS_70, PS_83, PS_84, PS_85, PS_86
  const onSubmit = async (e: any) => {
    debugger



    if (fieldValidation(e as any)) {
      setLoader(true);
      try {
        const encryptedPassword = encryptPassword(details.password);
        const encryptedConfirmPassword = encryptPassword(details.permanentPassword);
        const submitDetails = {
          ...details,
          password: encryptedPassword,
          permanentPassword: encryptedConfirmPassword,
        };
        ////console.log(submitDetails, "submitDetailssssss");

        const submitForm = await submitRegistration(submitDetails);
        setLoader(false);
        if (submitForm.data.statusCode === 200) {
          ////console.log("entered.....");
          setSucessPopup(true);
        } else {
          if (submitForm.data.statusCode === 400 && submitForm.data.statusMessage === 'User already exists') {
            setErrorMessage({ ...errorEmptyData, errorMailId: 'The entered Email Id  already exist' });
          }
        }
      } catch (error) {
        console.error('Error during form submission:', error);
        setLoader(false);
        setAlert(true)
        setErrorMessage((prev) => ({
          ...prev,
          general: 'An unexpected error occurred.',
        }));
      }
    }
  };
  const countryOptions: { value: string, label: string }[] = Object.keys(countries).map((key) => ({
    value: '+' + countries[key as keyof TCountries].phone,
    label: `${countries[key as keyof TCountries].name} (+${countries[key as keyof TCountries].phone})`,
  }));
  // PS_107, PS_108, PS_109
  const handleCountryChange = (selectedOption: any) => {
    const countryCode = selectedOption.value;
    setDetails((prevDetails) => ({
      ...prevDetails,
      countryCode: countryCode,
    }));
  };

  //PS_57, PS_58, PS_59, PS_60, PS_61, PS_62, PS_63, PS_64, PS_65
  const handleSSOLogin = async () => {
    try {
      ////console.log("trigeered...................");

      const loginResponse = await msalInstance.loginPopup({
        scopes: ["User.Read"],
        prompt: "select_account"
      });

      if (loginResponse.account) {
        const userEmail = loginResponse.account.username; // Assuming 'username' is the email
        const userName = userEmail.split('@')[0];
        const mailAddress = userEmail;
        const companyName = userEmail.split('@')[1].split('.')[0]; // Assuming domain name is the company name

        setDetails({
          ...details,
          userName: userName,
          mailId: mailAddress,
          password: null,
          permanentPassword: null,
          isSSOAuthentication: true
        });
        setSso(true)
        setForm(true)
        // Navigate to Signup page with encrypted data
      } else {
        ////console.log("Login was successful, but could not retrieve user details.");
      }
    } catch (error) {
      setAlert(true)
    }
  };
  const RegistrationPage = () => {
    debugger
    return (
      <>
        {/* Login page starts here */}
        <div className="bg-white" >
          <div className="container-fluid">
            <div className="row ">
              {/*Left container starts here */}
              <div className="col-xl-7 col-lg-12 col-md-12 col-sm-12 col-12 login-brd-left w-60">
                <div className="row justify-content-center">
                  <div className="col-md-12 px-md-4 px-2 pt-4">
                    <span>
                      <a href="https://www.avamigratron.com" className='anchorAsPointer'>
                        <img
                          src="/images/brand-logo.svg"
                          alt="brand-logo"
                          className="login-logo-sty mb-3"
                        />
                      </a>
                    </span>
                  </div>
                  <div className="col-xl-8 col-lg-9 col-md-9 col-sm-12 pt-0 pb-0 ">
                    <div className="login-content-height w-100 d-flex">
                      <div className="form-signup my-auto w-100">
                        <h5 className="mb-4 font-20 login-header font-bold">
                          AVAMIGRATRON
                        </h5>
                        <p className="tertiary-header">Get started with your account</p>
                        <p className="mt-4 mb-4 pb-2 font-14 font-regular text-gray">
                          Please register to proceed further. Already have an account ? <a href="/Login" className="font-semibold link-color">
                            Sign In
                          </a>
                        </p>

                        <button
                          type="button"
                          onClick={(e) => { handleSSOLogin(); setSso(true) }}
                          className="btn w-100 mb-3 font-14 font-semibold px-4 secondary-btn d-flex align-items-center justify-content-center"
                        >
                          <img
                            src="/images/windows-icon.svg"
                            alt="windows-icon"
                            className="me-2"
                          />{" "}
                          Sign up with Microsoft
                        </button>
                        <button
                          type="button"
                          onClick={() => { setForm(true) }}
                          className="btn w-100 mb-3 font-14 font-semibold px-4 primary-btn d-flex align-items-center justify-content-center"
                        >
                          <img
                            src="/images/login-mail-icon.svg"
                            alt="login-mail-icon"
                            className="me-2"
                          />{" "}
                          Sign up with Email
                        </button>
                        <p className="mt-1 mb-5 font-14 font-regular text-gray">
                          By signing up, You agree to our <a href='https://www.avamigratron.com/terms-of-use/' className=" font-semibold link-color">
                            Terms of Use
                          </a> and <a href='https://www.avamigratron.com/privacy-policy/' className=" font-semibold link-color">
                            Privacy Policy
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*Left container ends here */}
              {/*Right container starts here */}
              <div className=" col-xl-5 d-xl-block d-none p-0 w-40">
                <div className="login-bg-height position-relative my-0">
                  <div className="login-bg-height login-bg-img text-center">
                    <h5 className="login-header font-bold font-32 pt-5 mb-4">
                      World's No 1 Enterprise-ready
                    </h5>
                    <p className="font-24 font-semibold primary-color">
                      Migration Platform!
                    </p>
                  </div>
                </div>
              </div>
              {/*Right container ends here */}
            </div>
          </div>
        </div>
        {/* Login page ends here */}
      </>
    );
  };
  const RegistrationForm = () => {
    return (
      <div>
        <div className="bg-white" >

          <div className="container-fluid">
            <div className="row ">
              {/*Left container starts here */}
              <div className="col-xl-7 col-lg-12 col-md-12 col-sm-12 col-12 login-brd-left w-60">
                <div className="row justify-content-center">
                  <div className="col-md-12 px-md-4 px-2 pt-4">
                    <span>
                      <img
                        src="/images/brand-logo.svg"
                        alt="brand-logo"
                        className="login-logo-sty mb-3"
                      />
                    </span>
                  </div>
                  <div className="col-xl-8 col-lg-9 col-md-9 col-sm-12 pt-0 pb-0 login-scroll">
                    <div className="login-content-height w-100 d-flex">
                      <div className="form-signup my-auto w-100">
                        <h5 className="mb-4 font-20 login-header font-bold">
                          AVAMIGRATRON
                        </h5>
                        <p className="tertiary-header mb-3">
                          Get started with your account
                        </p>
                        <p className="mt-3 mb-4 font-14 font-regular text-gray">
                          Please register to proceed further. Already have an account ? <a onClick={() => { history.push('/Login') }} className="font-semibold link-color">
                            Sign In
                          </a>
                        </p>

                        <div className="col-md-12 mb-3">
                          <label
                            className="form-label font-14 font-semibold"
                            htmlFor="UserName"
                          >
                            User Name{" "}
                            <span className="mandatory-color">*</span>
                          </label>
                          <input
                            onChange={fieldValidation}
                            value={details.userName}
                            className="form-control custom-form"
                            type="text"
                            id="UserName"
                            name='userName'
                            placeholder="Enter User Name"
                            maxLength={80}
                            onKeyDown={(e) => {
                              if (/[\d.]/.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            onKeyDownCapture={(e: any) => {
                              if (e.key === 'Enter') {
                                onSubmit(e)
                              }
                            }}

                          />
                          <p className="font-12 font-semibold mandatory-color mt-2 mb-0 ">
                            {errorMessage.errorUserName}
                          </p>
                        </div>
                        <div className="col-md-12 mb-3">
                          <label
                            className="form-label font-14 font-semibold"
                            htmlFor="CompanyName"
                          >
                            Company Name{" "}
                            <span className="mandatory-color">*</span>
                          </label>
                          <input
                            onChange={fieldValidation}
                            value={details.companyName}
                            className="form-control custom-form"
                            type="text"
                            id="CompanyName"
                            name='companyName'
                            placeholder="Enter Company Name"
                            maxLength={80}
                            onKeyDownCapture={(e: any) => {
                              if (e.key === 'Enter') {
                                onSubmit(e)
                              }
                            }}

                          />
                          <p className="font-12 font-semibold mandatory-color mt-2 mb-0 ">
                            {errorMessage.errorCompanyName}
                          </p>
                        </div>
                        <div className="col-md-12 mb-3">
                          <label
                            className="form-label font-14 font-semibold"
                            htmlFor="mailIdAddress"
                          >
                            Email Address {" "}
                            <span className="mandatory-color">*</span>
                          </label>
                          <input
                            onChange={fieldValidation}
                            value={details.mailId}
                            disabled={sso}
                            className="form-control custom-form"
                            type="text"
                            id="mailIdAddress"
                            name='mailId'
                            placeholder="Enter Email Address"
                            maxLength={320}
                            onKeyDownCapture={(e: any) => {
                              if (e.key === 'Enter') {
                                onSubmit(e)
                              }
                            }}
                          // onKeyDown={handleKeyDown}

                          />
                          <p className="font-12 font-semibold mandatory-color mt-2 mb-0 ">
                            {errorMessage.errorMailId}
                          </p>
                        </div>
                        <div className="col-md-12 mb-3">
                          <label
                            className="form-label font-14 font-semibold"
                            htmlFor="Password"
                            hidden={sso}

                          >
                            Password        <span className="mandatory-color"> * </span>
                            <Tippy
                              arrow={true}
                              followCursor={false}
                              plugins={[followCursor]}
                              placement='top'
                              content="The password must be between 6 and 20 characters long
                                                      Should contain at least one uppercase letter
                                                      One lowercase letter
                                                      One number, and 
                                                      One special character"
                            >
                              <img
                                src="/images/tooltip.svg"
                                alt="tooltip"
                                width={11}

                              />
                            </Tippy>

                          </label>
                          <div className="position-relative">
                            <input
                              // onChange={fieldValidation}
                              onChange={(e) => {
                                fieldValidation(e);
                                setDetails({
                                  ...details,
                                  password: e.target.value,
                                });
                              }}
                              // value={encryptPassword(details.password)}
                              value={(details.password)}
                              name='password'
                              type={passwordVisibility ? "text" : "password"}
                              className="form-control password-eye-hide custom-form font-14 font-regular py-2"
                              id="Password"
                              placeholder="Enter Password"
                              onKeyDownCapture={(e: any) => {
                                if (e.key === 'Enter') {
                                  onSubmit(e)
                                }
                              }}
                              hidden={sso}
                            // onKeyDown={handleKeyDown}



                            />
                            <img
                              onClick={() => { setPasswordVisibility(!passwordVisibility) }}
                              className={`position-absolute ${passwordVisibility ? 'custom-top' : ''} custom-postion`}
                              src={`/images/${passwordVisibility ? `password-eye-open.svg` : `password-icon.svg`}`}
                              alt="password-icon"
                              hidden={sso}

                            />
                            <p
                              hidden={sso}
                              className="font-12 font-semibold mandatory-color mt-2 mb-0 ">
                              {errorMessage.errorPassword}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-12 mb-3">
                          <label
                            className="form-label font-14 font-semibold"
                            htmlFor="permanentPassword"
                            hidden={sso}

                          >
                            Confirm Password
                            <span className="mandatory-color"> * </span>
                            <Tippy
                              arrow={true}
                              followCursor={false}
                              plugins={[followCursor]}
                              placement='top'
                              content="Please re-enter your password to confirm. Ensure it matches the password you entered above."
                            >
                              <img
                                src="/images/tooltip.svg"
                                alt="tooltip"
                                width={11}

                              />
                            </Tippy>

                          </label>
                          <div className="position-relative">
                            <input
                              onChange={(e) => {
                                fieldValidation(e);
                                setDetails({
                                  ...details,
                                  permanentPassword: e.target.value,
                                });
                              }}
                              value={details.permanentPassword}
                              name='permanentPassword'
                              type={permanentPasswordVisibility ? "text" : "password"}
                              className="form-control password-eye-hide custom-form font-14 font-regular py-2"
                              id="permanentPassword"
                              placeholder="Enter Password"
                              hidden={sso}
                              // onKeyDown={handleKeyDown}
                              onKeyDownCapture={(e: any) => {
                                if (e.key === 'Enter') {
                                  onSubmit(e)
                                }
                              }}


                            />
                            <img
                              onClick={() => { setpermanentPasswordVisibility(!permanentPasswordVisibility) }}
                              className={`position-absolute ${permanentPasswordVisibility ? 'custom-top' : ''} custom-postion`}
                              src={`/images/${permanentPasswordVisibility ? `password-eye-open.svg` : `password-icon.svg`}`}
                              alt="password-icon"
                              hidden={sso}

                            />
                            <p
                              hidden={sso}
                              className="font-12 font-semibold mandatory-color mt-2 mb-0 ">
                              {errorMessage.errorPermanentPassword}

                            </p>
                          </div>
                        </div>
                        <div className="col-md-12 mb-3">
                          <label
                            className="form-label font-14 font-semibold"
                            htmlFor="PhoneNumber"
                          >
                            Phone Number{" "}
                            <span className="mandatory-color"> * </span>
                          </label>
                          <div className="input-group mb-3 input-group mb-3 form-control custom-form rounded p-0 phone-group">
                            <Select
                              options={countryOptions}
                              onChange={handleCountryChange}
                              value={countryOptions.find((option: any) => option.value === details.countryCode)}
                            />
                            <input
                              onChange={fieldValidation}

                              name="phoneNumber"
                              className="form-control custom-form border-0 bg-transparent"
                              value={details.phoneNumber}
                              type="text"
                              id="PhoneNumber"
                              placeholder="Enter Phone Number"
                              minLength={5}
                              maxLength={15}
                              pattern="[0-9]*"
                              onKeyPress={(e) => {
                                if (!/[0-9]/.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              onKeyDownCapture={(e: any) => {
                                if (e.key === 'Enter') {
                                  onSubmit(e)
                                }
                              }}
                            />
                          </div>
                          <p className="font-12 font-semibold mandatory-color mt-2 mb-0 ">
                            {errorMessage.errorPhoneNumber}
                          </p>

                        </div>
                        <p className="mt-4 mb-5 font-14 font-regular text-gray">
                          By signing up, You agree to our <a href='https://www.avamigratron.com/terms-of-use/' className=" font-semibold link-color">
                            Terms of Use
                          </a> and <a href='https://www.avamigratron.com/privacy-policy/' className=" font-semibold link-color">
                            Privacy Policy
                          </a>
                        </p>
                        <button
                          onClick={(e) => { onSubmit(e) }}
                          type="button"
                          className="btn w-100 mb-3 font-14 font-semibold px-4 primary-btn"
                          disabled={!buttonDisable}

                        >
                          Complete Sign Up
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*Left container ends here */}
              {/*Right container starts here */}
              <div className=" col-xl-5 d-xl-block d-none p-0 w-40">
                <div className="login-bg-height position-relative my-0">
                  <div className="login-bg-height login-bg-img text-center">
                    <h5 className="login-header font-bold font-32 pt-5 mb-4">
                      World's No 1 Enterprise-ready
                    </h5>
                    <p className="font-24 font-semibold primary-color">
                      Migration Platform!
                    </p>
                  </div>
                </div>
              </div>
              {loader && <Loader />}
            </div>
            {alert ? <div
              className="modal fade show"
              tabIndex={-1}
              style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content popup-brd-radius">
                  <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
                    <span className="position-absolute">
                      <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />
                    </span>
                  </div>
                  <div className="modal-body border-0 text-center">
                    <h5
                      className="modal-title mb-3 primary-header"
                      id="Failure-popupLabel"
                    >
                      {commonErrorMsgs.generalError}
                    </h5>
                    <p className="primary-data">
                      {alert}
                    </p>
                  </div>
                  <div className="modal-footer border-0 pb-4 justify-content-center">
                    <button
                      type="button"
                      className="btn primary-btn font-14 px-4 font-semibold"
                      onClick={() => { setAlert(false) }}
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div> : null}
          </div>
        </div>
        {sucessPopup && renderSuccessPopup()}
      </div>

    )
  }
  const renderSuccessPopup = () => {
    return (
      <div
        className="modal fade show "
        id="Success-popup"
        // tabIndex="-1"
        aria-labelledby="Success-popupLabel"
        aria-modal="true"
        role="dialog"
        style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content popup-brd-radius">
            <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
              <span className="position-absolute">
                <img
                  src="/images/success-popup-icon.svg"
                  alt="success-popup-icon"
                />
              </span>
            </div>
            <div className="modal-body border-0 text-center">
              <h5
                className="modal-title mb-3 primary-header"
                id="Success-popupLabel"
              >
                Success !
              </h5>
              <p className="primary-data">Registration was Successfull</p>
            </div>
            <div className="modal-footer border-0 justify-content-center pb-4">
              <button
                type="button"
                className="btn primary-btn font-14 px-4 font-semibold"
                data-bs-dismiss="modal"
                onClick={() => { setSucessPopup(false); history.push('/Login') }}

              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };


  return (
    <div>
      {!form && RegistrationPage()}
      {form && RegistrationForm()}
    </div>
  )
}

