// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html, body, div, form, fieldset, legend, label, p, button
{
 margin: 0;
 padding: 0; 
}
table
{
 border-collapse: collapse;
 border-spacing: 0;
}
th, td
{
 text-align: left;
 vertical-align: top;
}
h1, h2, h3, h4, h5, h6, th, td, caption { font-weight:normal; }
img { border: 0; }`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;;CAEC,SAAS;CACT,UAAU;AACX;AACA;;CAEC,yBAAyB;CACzB,iBAAiB;AAClB;AACA;;CAEC,gBAAgB;CAChB,mBAAmB;AACpB;AACA,0CAA0C,kBAAkB,EAAE;AAC9D,MAAM,SAAS,EAAE","sourcesContent":["html, body, div, form, fieldset, legend, label, p, button\n{\n margin: 0;\n padding: 0; \n}\ntable\n{\n border-collapse: collapse;\n border-spacing: 0;\n}\nth, td\n{\n text-align: left;\n vertical-align: top;\n}\nh1, h2, h3, h4, h5, h6, th, td, caption { font-weight:normal; }\nimg { border: 0; }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
