// PS_47, PS_48, PS_49, PS_52, PS_53, PS_54, PS_64, PS_92, PS_93, PS_94 and PS_99.
import { client } from "../Client/Client";
import { ServiceDetails, ResponseInterface } from "../Common/Interface"
import { BE_URL, feedbackEndpoint } from "../Common/Constant";
export async function getFeedbacks(data = {}): Promise<ResponseInterface> {//PS_AD-09


  let Config: ServiceDetails = {//PS_AD-10

    url: BE_URL + feedbackEndpoint.getFeedbacks,

    //url: "http://localhost:8080/feedback/getFeedback",
    headers: {
      "content-type": "application/json",
    },
    method: "post",
    data: data,
  };

  let response: any = await client(Config); //PS_AD-11
  return response;
}

export async function insertFeedbackData(data = {}): Promise<ResponseInterface> {//PS_AD-18

  let Config: ServiceDetails = {//PS_AD-19
    url: BE_URL + feedbackEndpoint.postFeedback,

    // url: "http://localhost:8080/feedback/postFeedback",
    headers: {
      "content-type": "application/json",
    },
    method: "post",
    data: data,
  };

  let response: any = await client(Config); //PS_AD-20
  return response;
}


