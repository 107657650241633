// PS_01, PS_02, PS_03, PS_04
import React, { useState, useEffect } from 'react';
import objCryptoJS from 'crypto-js';
// import commonService from '/commonServices';
import { useLocation, useHistory } from 'react-router-dom';
import Loader from '../Common/Loader';
import PsServiceConfiguration from './psServiceConfigurations';
import NotificationConfiguration from './\NotificationConfig';
import MigrationConfiguration from './MigrationConfig';
import { decodeSessionDetails } from '../../Services/CommonServices';
import { sessionResponse } from '../../Common/Interface';
// import { payloadInterface } from '../Interface'; // Replace any and any with actual interface names as needed
import { insertServiceDataApi, insertServiceAppApi, insertServiceAssDataApi, insertNotificationsApi, insertErrApi, insertAssServiceAppApi, getErrApi, fetchPsDataApi, parallelInstanceCountApi, updateCustomdate, deletePsservicedata, deletenotify, deleteserviceapp, insertMigrationSourceUser, getconfigDate } from "../../Services/psConfigServices";//PS_AD-04
import { insertLastVisitedPageValueApi } from '../../Services/MigrationAndManageMigrationServices';
import jwt from 'jwt-decode'
import { getSession } from '../../Services/manageClientAPI';
interface InterfaceA {
    email: string;
    password: string;
    // ... any other properties
}
interface Props {
    isEdit: boolean;
    taskId: string;
    isOpen: any;
    onToggle: () => void;
    onGeneralConfigSave: () => void;
    updateGeneralConfigSaveStatus: (value: any) => void;
    applicationName: string;
    onToggleAccordian: () => void;
    isEditCheck: boolean;
    setIsEditCheck: React.Dispatch<React.SetStateAction<boolean>>;
}
const MasterAssConfiguration: React.FC<Props> = ({ setIsEditCheck, onToggleAccordian, isOpen, onToggle, onGeneralConfigSave, updateGeneralConfigSaveStatus, applicationName, isEdit }) => {

    // PS_06, PS_09, PS_10, PS_11, PS_12, PS_13, PS_14, PS_15, PS_16, PS_17, PS_18, PS_19, PS_20, PS_21, 
    // PS_22, PS_23, PS_24, PS_25, PS_26, PS_27, PS_28, PS_29, PS_30
    const location: any = useLocation().state;
    const [taskId, setTaskId] = useState<any>(location?.state?.taskId || "");

    const history = useHistory();
    const currentDate: any = new Date();
    const today = new Date().toISOString().split('T')[0];
    const [fromDate, setFromDate] = useState<any>("");
    const [toDate, setTodate] = useState<any>("");
    const formattedCurrentDate = currentDate.toISOString().split("T")[0];
    const [emailValid, setEmailValid] = useState<boolean>(false);
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [sourceAccount, setSourceAccount] = useState<any>({ email: '', password: '' });
    const [deletePsAccount, setdeletePsAccount] = useState<any[]>([]);
    const [deletePsTarAccount, setdeletePsTarAccount] = useState<any[]>([]);
    const [sourceAccounts, setSourceAccounts] = useState<any[]>([]);



    const [targetAccounts, setTargetAccounts] = useState<any[]>([]);
    const [deleteEmail, setDeleteEmail] = useState<string>('');
    const [deleteTab, setDeleteTab] = useState<string>('');
    const [psSourceSAC, setPsSourceSAC] = useState<boolean>(false);
    const [psTargetSAC, setPsTargetSAC] = useState<boolean>(false);
    const [psSource, setpsSource] = useState<boolean>(true);
    const clientId = "2"
    const [index, setIndex] = useState<number>();
    const [DeletePopup, setDeletePopup] = useState<boolean>(false);
    const [roleCheck, setRoleCheck] = useState<string>("");
    const [nestedTab, setNestedTab] = useState<string>('Source');
    const [mainNewTab, setMainNewTab] = useState<string>("assessment");
    // const [applicationName, setApplicationName] = useState<string>('outlook');
    //console.log(nestedTab,mainNewTab,"tocheck the issue");


    const [environment, serEnvironment] = useState<string>(location?.state?.environment.toLowerCase());
    
    const [emailTouched, setEmailTouched] = useState<boolean>(true);
    const [loader, setLoader] = useState<boolean>(false)
    // const isOpen = true;
    const [notiHideTab, setnotiHideTab] = useState<string>("hide");

    const [sourceDataError, setsourceDataError] = useState<any>({
        notificationEmail: '',
        sourceAccountEmail: '',
        sourceAccountPassword: '',
        targetAccountEmail: '',
        targetAccountPassword: '',
        sourceServiceAccount: '',
        targetServiceAccount: '',
        sourceServiceAppId: '',
        sourceServiceAppSecret: '',
        targetServiceAppId: '',
        targetServiceAppSecret: '',
        sourceServiceAccountLength: '',
        targetServiceAccountLength: '',
        sourceServiceAppLength: '',
        targetServiceAppLength: '',
        showPopupMessage: '',
        invalidEmailAddress: 'The Entered Email ID is invalid',
        SourceAzureAppId: '',
        SourceAzureSecretId: "",
        emailMaxLengthError: ""

    });

    const [sourceDataErrorMessage, setsourceDataErrorMessage] = useState({
        notification: 'Email ID cannot be empty',
        sourceAccountEmail: 'Email ID cannot be empty',
        sourceAccountPassword: 'Password cannot be empty',
        targetAccountEmail: 'Email ID cannot be empty',
        targetAccountPassword: 'Password cannot be empty',
        sourceServiceAccount: 'Email ID cannot be empty',
        targetServiceAccount: 'Email ID cannot be empty',
        sourceServiceAppId: 'Azure App Id cannot be empty',
        sourceServiceAppSecret: 'Azure App Secret Value cannot be empty',
        targetServiceAppId: 'Azure App Id cannot be empty',
        targetServiceAppSecret: 'Azure App Secret Value cannot be empty'
    })
    // Component logic goes here, such as effect hooks and handler functions
    // Your existing state variables would be declared here...
    const [value, setValue] = useState<number>(1);
    const [totalParallelCount, setTotalParallelCount] = useState<number>(0);
    const requiredAppLength = Number(value) + 1
    const [SourceAppEnable, setSourceAppEnable] = useState(true);

    const [migrationNestedTabButton, setMigrationNestedTabButton] = useState('Source');
    const [migrationNestedTab, setMigrationNestedTab] = useState('serviceApp');

    const [sourceApp, setSourceApp] = useState<any>({ id: '', secret: '' });
    const [sourceApps, setSourceApps] = useState<any[]>([]);
    const [targetApps, setTargetApps] = useState<any[]>([]);
    const [targetApp, setTargetApp] = useState<any>({ id: '', secret: '' });
    const [deleteSourceApp, setDeleteSourceApp] = useState<any[]>([]);
    const [notification, setNotification] = useState<any>({ email: '' });
    const [notifications, setNotifications] = useState<any[]>([]);
    const [deleteNotifyAccount, setdeleteNotification] = useState<any[]>([]);
    const [alert, setAlert] = useState<boolean>(false);
    const [skipErrorHandling, setSkipErrorHandling] = useState<string>('Yes');
    const [migSourceAppSAC, setMigSourceAppSAC] = useState<boolean>(false);
    const [notificationSAC, setNotificationSAC] = useState<boolean>(false);
    const stage = 2;
    const [ctoken, setCToken] = useState<string>(sessionStorage.getItem('clientToken') || '');
    const [tokenExpiration, setTokenExpiration] = useState<number>(parseInt(sessionStorage.getItem('tokenExpiration') || '0'));
    const [atoken, setAToken] = useState<string>(sessionStorage.getItem('a_at') || '');
    const [isAdmin, setIsAdmin] = useState<boolean>(sessionStorage.getItem('isAdmin') === 'true');
    const [objAdmin, setObjAdmin] = useState({})
    const [objClient, setObjClient] = useState({})
    let [adminName, setAdminName] = useState('');
    let [adminEemailAddress, setAdminEmailAddress] = useState('');
    let [adminrole, setAdminRole] = useState('');
    let sessionDetails = { UserName: "", CompanyName: "", EmailAddress: "", ID: 0, Role: "" }
    let [clientName, setclientName] = useState('');
    let [companyName, setCompanyName] = useState('');
    let [emailAddress, setEmailAddress] = useState('');
    let [role, setRole] = useState('');
    const rn_secretKey = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY || '';
    const a_secretKey = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY || '';
    const c_secretKey = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY || '';
    const [alertMessage, setAlertMessage] = useState<string>('');
    let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
    const [isSessionEpired, setisSessionEpired] = useState(false);
    let environmentType2:any = location?.state?.environment
    //console.log("assessmenttttttttttttttttttttttttttttttt",location?.state);
   
    const [environmentType, setEnvironmentType] = useState<string>(environmentType2);
    // //debugger
    let InActiveTimeout: NodeJS.Timeout;


    const mainComponentType: string = "migration";
    // PS_31, PS_32
    ////console.log(sourceAccounts, "sourceAccounts inside master ass comp");
    ////console.log(sourceAccount, "sourceAcc log inside master ass comp");


    useEffect(() => {
        
        window.scrollTo(0, 0);

        sessionTokenProperties();
        resetTimer();
        gettErr();
        window.addEventListener('keydown', resetTimer);
        window.addEventListener('click', resetTimer);

        return () => {
            window.removeEventListener('click', resetTimer);
            window.removeEventListener('keydown', resetTimer);
        };


    }, []);





    let resetTimer = async () => {
        try {
            // debugger;
            ////console.log("Reset Timer Start");

            let sessionResponse: string = await resetSessionTimer(InActiveTimeout, SessionEpiredDateTime);
            ////console.log("sessionResponse value :", sessionResponse);

            // //debugger
            if (sessionResponse == 'expired') {
                debugger
                sessionStorage.removeItem("c_at");
                setisSessionEpired(true);
                setAlert(true)
                setAlertMessage('Your Session has Expired please login again to continue');
            }
            else if (sessionResponse == 'updateToken') {
                if (emailAddress == "" || emailAddress == undefined) {
                    await sessionTokenProperties();
                }
                debugger
                let token = await getToken()
                let payload = {
                    mailId: emailAddress,
                    isAdmin: false,
                    token: token
                };
                let getsessionToken = await getSession(payload);
                const rn_secretKey: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY
                const cs_secretKey: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;

                const c_byteValue = objCryptoJS.AES.decrypt(getsessionToken?.data?.data.sessionToken, rn_secretKey);
                const c_decryptedValue = c_byteValue.toString(objCryptoJS.enc.Utf8);
                let updatedToken = objCryptoJS.AES.encrypt(c_decryptedValue, cs_secretKey).toString();
                await sessionStorage.removeItem("c_at");
                await sessionStorage.setItem('c_at', updatedToken);
                let updatedobjClient: any = jwt(c_decryptedValue);
                SessionEpiredDateTime = updatedobjClient.CreatedAt;
                setSessionEpiredDateTime(SessionEpiredDateTime);
            }
            ////console.log("Reset Timer End");
        }
        catch (error) {
            ////console.log("Reset Timer Catch");
            setAlert(true)
            setAlertMessage('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
            setLoader(false);
        }
    }

    const resetSessionTimer = async (InActiveTimeout: any, SessionExpirationDateTime: any) => {
        try {
            // debugger
            ////console.log("Reset Session Timer Start");
            // Assuming InActiveTimeout is a number representing the ID of the current timeout

            if (typeof window !== 'undefined') {
                ////console.log("Reset Session Timer Inside");
                clearTimeout(InActiveTimeout);
                const tokenExpiryCheckInterval = Number(process.env.REACT_APP_TABIDLESCENARIO);

                InActiveTimeout = setTimeout(() => {
                    ////console.log("Expired");
                    sessionStorage.removeItem("c_at");
                    ////console.log("removed session");
                    setisSessionEpired(true)
                    setAlert(true)
                    setAlertMessage('Your Session has Expired please login again to continue');
                    // return 'expired'
                }, tokenExpiryCheckInterval);

                ////console.log("Reset Session Timer checking");

                const currentDateTime = new Date().toISOString();
                const sessionExpiredDateTime = new Date(SessionExpirationDateTime).toISOString();
                ////console.log("sessionExpiredDateTime value :", sessionExpiredDateTime);

                const diffInMilliseconds = Math.abs(new Date(sessionExpiredDateTime).getTime() - new Date(currentDateTime).getTime());
                ////console.log("diffInMilliSeconds values:", diffInMilliseconds);

                const minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
                ////console.log("minutes : ", minutes);

                const sessionUpdateTimeGap = Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP);
                ////console.log("Reset Session Timer Differece : ", sessionUpdateTimeGap);

                if (minutes > sessionUpdateTimeGap) {

                    ////console.log("Reset Session Timer need to update");
                    return 'updateToken'
                }

                ////console.log("Reset Session Timer End");
                return 'NoActionRequired'
            }
            return 'NoActionRequired'
        }
        catch (ex) {
            ////console.log("Reset Session Timer Catch");
            throw ex
        }
    };

    // PS_33, PS_34, PS_35, PS_36, PS_37, PS_38, PS_39

    let sessionTokenProperties = async () => {
        try {
            ////console.log("inside sessiontokenprops");

            const tokenResponse: any = await decodeSessionDetails();
            debugger
            if (tokenResponse.adminDetails) {
                if (tokenResponse.adminDetails) {
                    setObjAdmin(tokenResponse.adminDetails)
                    setAdminName(tokenResponse.adminDetails.Name);
                    setAdminEmailAddress(tokenResponse.adminDetails.Email);
                    setRoleCheck(tokenResponse.adminDetails.Role)
                    setAdminRole(tokenResponse.adminDetails.Role);
                }
                else {
                    if (window.location.pathname.toString().toLowerCase().includes("admin")) {
                        history.push("/Admin/Login")
                    }
                }
            }
            else {
                if (tokenResponse.clientDetails) {
                    setObjClient(tokenResponse.clientDetails)
                    setclientName(tokenResponse.clientDetails.Name);
                    setCompanyName(tokenResponse.clientDetails.CompanyName)
                    emailAddress = tokenResponse.clientDetails.Email;
                    setEmailAddress(emailAddress);
                    setRole(tokenResponse.clientDetails.Role);
                    setRoleCheck(tokenResponse.clientDetails.Role);
                    setSessionEpiredDateTime(tokenResponse.clientDetails.CreatedAt);
                }
                else {
                    history.push("/Login")
                }
            }
            //#endregion
        } catch (error) {
            setAlert(true);
            setLoader(false)
            console.error('Failed in main method', error);
        }
    };


    const getToken = async () => {
        let clientToken: string;
        let adminToken: string;
        let token: { clientToken?: string; adminToken?: string } = {};
        debugger
        try {
            if (objClient != undefined) {
                let sessionToken: any = sessionStorage.getItem('c_at')
                if (sessionToken) {
                    sessionToken = objCryptoJS.AES.decrypt(sessionToken, c_secretKey)
                    var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
                    clientToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
                    token['clientToken'] = clientToken

                }


            }
            if (objAdmin !== undefined) {
                let sessionToken: any = sessionStorage.getItem('a_at')
                if (sessionToken) {
                    sessionToken = objCryptoJS.AES.decrypt(sessionToken, a_secretKey)
                    var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
                    adminToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();

                    token['adminToken'] = adminToken

                }
            }
            return token
        } catch (error) {
            setAlert(true);
            setLoader(false)
            throw error
        }
    }

    // PS_44, PS_45, PS_46, PS_47
    const additionalAccountsRequiredSource = requiredAppLength - sourceApps.length
    ////console.log(additionalAccountsRequiredSource, "adddwddd");

    const additionalAccountsRequiredTarget = requiredAppLength - targetApps.length


    const fetchUser = async () => {

        try {
            let token = await getToken()
            let payload = {
                token: token,
                taskId: taskId
            }
            if (location?.state?.taskId && location?.state?.isEdit) {
                setLoader(false);
                history.push('/Assessment', {
                    state: {
                        ...location.state,
                        pageType: "export",
                        isEdit: true, taskId: location?.state?.taskId, taskName: location?.state?.taskName,

                        // stage: ((location?.state?.stage) && (location?.state?.stage > stage)) ? location?.state?.stage : stage
                    }
                })


            }
            else {
                setLoader(true)
                const userlistPromise: any = await insertMigrationSourceUser(payload);
                // setAlertMessage('Please wait , it will take some time , fetching user details....')
                const [userlist] = await Promise.all([userlistPromise]);
                ////console.log(userlistPromise, "response of userlisttttt");
                ////console.log(userlist.data.statusCode, "response of usersssssss");

                if (userlist.data.statusCode === 200) {
                    setLoader(false)
                    history.push('/Assessment', { state: { ...location.state, taskId: location.state.taskId, taskName: location.state.taskName, pageType: "export" } });

                } else {
                    ////console.log('At least one API call failed');
                }
            }
        } catch (error) {
            setLoader(false);
            setAlert(true)
            console.error("Error in API calls:", error);
        }
    };


    const fetchDataPs = async () => {
        try {
            let token = await getToken()

            setLoader(true)
            const payload = {
                token: token,
                taskId: location.state.taskId,
            }
            const response = await fetchPsDataApi(payload);
            let result = response.data.data;
            if (result) {
                ////console.log("inside if");

                // Separate accounts based on connectionType
                const sourceData = result.filter((account: { connectionType: string; }) => account.connectionType === 'source');
                ////console.log(sourceData, "sourcedata");

                const sourceAccountsData = sourceData.map((account: { serviceAccountMailId: any; password: any; }) => ({
                    email: account.serviceAccountMailId,
                    password: account.password,
                    isNew: false
                }));
                ////console.log(sourceAccountsData, "sourceAccountsData");

                setSourceAccounts(sourceAccountsData);

                const targetData = result.filter((account: { connectionType: string; }) => account.connectionType === 'target');
                const targetAccountsData = targetData.map((account: { serviceAccountMailId: any; password: any; }) => ({
                    email: account.serviceAccountMailId,
                    password: account.password,
                    isNew: false
                }));
                setTargetAccounts(targetAccountsData);
                setLoader(false)

                // const sourceServiceData = result.filter((account: { connectionType: string; isPSAccount: boolean; }) => account.connectionType === 'source' && account.isPSAccount === false);
                //     const sourceServiceAccountsData = sourceServiceData.map((account: { serviceAccountMailId: any; }) => ({
                //         email: account.serviceAccountMailId,
                //         isNew: false
                //     }));
                //     setSourceAccounts(sourceServiceAccountsData);

                //     const targetServiceData = result.filter((account: { connectionType: string; isPSAccount: boolean; }) => account.connectionType === 'target' && account.isPSAccount === false);
                //     const targetServiceAccountsData = targetServiceData.map((account: { serviceAccountMailId: any; }) => ({
                //         email: account.serviceAccountMailId,
                //         isNew: false
                //     }));

                // setTargetAccounts(targetServiceAccountsData);
                // if (targetData?.length == 0 || sourceData?.length == 0) {
                //     handleEditCheck()
                // }

            }
            return result;
        } catch (error: any) {
            setAlert(true);
            setLoader(false)
            ////console.log("Assessment Master Component : ", error);
            ////console.log("error in fetch ps");

        }
    }

    // PS_135, PS_136, PS_137, PS_138, PS_139
    const insertServiceData = async (assPayload: any) => {
        try {
            let token = await getToken()

            debugger;
            // const payload = {
            //     token: token,
            //     taskId: location.state.taskId,
            //     connectionType: "source",
            //     isPSAccount: 1,
            //     data: sourceAccounts,
            //     mainComponentType: "migration"
            // };

            // ////console.log(payload, "wed s payload");
            setLoader(true)
            // Insert only newly entered records into the database

            const response = await insertServiceAssDataApi(assPayload);
            ////console.log(response, "ressytsdhdhtdhdthd");
            setLoader(false)
            if (!response) {
                console.error('The values failed to insert');
            } else {
                setpsSource(false)
                ////console.log('New records inserted ps service successfully');
                fetchDataPs();
            }
            setLoader(false)

        } catch (error) {
            setAlert(true);
            setLoader(false)

            ////console.log('Error inserting records:', error);
        }
    };




    const insertServiceDataTarget = async () => {
        try {
            let token = await getToken()

            const payload = {
                token: token,
                taskId: location.state.taskId,
                connectionType: "target",
                isPSAccount: 1,
                data: targetAccounts,
                mainComponentType: mainComponentType
            };

            ////console.log(payload, "wed payload");

            // Insert only newly entered records into the database
            const response = await insertServiceDataApi(payload);

            if (!response) {
                console.error('The values failed to insert');
            } else {
                ////console.log('New records inserted ps service successfully');

            }
        } catch (error) {
            setAlert(true);
            setLoader(false)

            ////console.log('Error inserting records:', error);
        }
    };

    // PS_117, PS_118, PS_119, PS_120, PS_121, PS_122, PS_123, PS_124, PS_125, PS_126
    const insertServiceApps = async (tab: string, payload: any) => {
        try {
            debugger
            // let token = await getToken()
            setLoader(true)
            ////console.log("insideeeee service if");
            ////console.log(payload, "service payload");

            // Insert only newly entered records into the database
            const response = await insertAssServiceAppApi(payload);
            setLoader(false)
            if (!response) {
                console.error('The values failed to insert');
            } else {
                if (payload.connectionType === "source") {
                    setSourceAppEnable(false)
                }
                ////console.log('New records inserted ps service successfully');

            }
        } catch (error) {
            setAlert(true);
            setLoader(false)

            ////console.log('Error inserting records:', error);
        }
    };

    // // PS_168, PS_169, PS_170, PS_171, PS_172
    const insertNotifications = async () => {
        try {
            let token = await getToken()
            debugger
            setLoader(true)
            const payload = {
                emailId: notifications,
                token: token,
                taskId: location.state.taskId,
                mainComponentType: mainComponentType
            };

            ////console.log(payload, 'notific');

            // Insert only newly entered records into the database
            const response = await insertNotificationsApi(payload);
            setLoader(false)
            if (!response) {
                console.error('The values failed to insert');
            } else {
                ////console.log('New records inserted successfully');
            }
        } catch (error) {
            setAlert(true);
            setLoader(false)

            console.error('Error inserting records:', error);
        }
    };


    const gettErr = async () => {
        try {
            let token = await getToken()

            setLoader(true)
            const payload = {
                token: token,
                taskId: location.state.taskId,
            };
            const response = await getErrApi(payload);
            ////console.log(response, "error response");

            ////console.log(response.data.result[0]?.isErrorHandlingSelected, "SAndy Is a Mass");
            if (response?.data?.status === 200) {
                // setSkipErrorHandling(response.data.result[0]?.isErrorHandlingSelected)
                if (response?.data?.result[0]?.isErrorHandlingSelected === true) {
                    setSkipErrorHandling("Yes")
                }
                else {
                    setSkipErrorHandling("No")
                }
            } else {
                ////console.log('New records inserted successfully');
            }

            const configDate = await getconfigDate(payload);
            let result = configDate.data.data

            //console.log(result, "ps data")

            if (result.customStartDate || result.customEndDate) {
                //console.log("inside if",  result);

                setFromDate(new Date(result.customStartDate).toISOString().split("T")[0])
                setTodate(new Date(result.customEndDate).toISOString().split("T")[0])

            }

            setLoader(false)

        } catch (error) {
            setAlert(true);
            setLoader(false)

            console.error('Error inserting records:', error);
        }
    };


    ////console.log(notifications, "nnnnnnnnnnnnnnn");


    const handleSaveAndContinueAndInsert = async (payload: any) => {

        insertServiceData(payload);
        // updateGeneralConfigSaveStatus(true)

    }

    const handleSaveAndContinueAndInsertTarget = () => {

        setNestedTab('Source');
        insertServiceDataTarget();
        // updateGeneralConfigSaveStatus(true)

    }

    const handleSaveAndContinueNotification = () => {
        insertNotifications();
        // updateGeneralConfigSaveStatus(true)

    }



    ////console.log(requiredAppLength, "requiredAppLength");

    const handleSaveAndContinueSourceSercviceApp = async (payload: any) => {
        try {
            debugger

            insertServiceApps('sourceApp', payload);
            // setMainNewTab('Notification')


        } catch (error: any) {
            setAlert(true);
            setLoader(false)

        }
    }


    const handleSaveAndContinueSourceSercviceAccount: any = () => {
        try {
            // //console.log(sourceApps.length, "lengthtttttt");
            // //console.log(requiredAppLength, "requiredddddddd");

            if (sourceAccounts.length >= requiredAppLength) {
                setsourceDataError({
                    ...sourceDataError,
                    sourceServiceAccountLength: "",
                });
                setMigrationNestedTabButton('source');
                setMigrationNestedTab('serviceApp');

                insertServiceAccounts('source')
            } 
            else {
                setsourceDataError({
                    ...sourceDataError,
                    sourceServiceAccountLength: ` Additional ${additionalAccountsRequiredSource} accounts are required `,
                });

                return;
            }
        } catch (error: any) {
            setAlert(true);
            setLoader(false)

        }
    }


    const insertServiceAccounts = async (tab: string) => {
        try {
            let token = await getToken()

            let payload;
            setLoader(true)
            if (tab === "source") {
                ////console.log("insideeeee service if");

                payload = {
                    token: token,
                    taskId: taskId,
                    connectionType: "source",
                    isPSAccount:0,
                    data: sourceAccounts
                };

            } else {

                payload = {
                    token: token,
                    taskId: taskId,
                    connectionType: "target",
                    data: targetApps,
                    mainComponentType: mainComponentType
                };

            }

            ////console.log(payload, "service payload");

            // Insert only newly entered records into the database
            const response = await insertServiceDataApi(payload);
            setLoader(false)
            if (!response) {
                console.error('The values failed to insert');
            } else {
                if (payload.connectionType === "source") {
                    setSourceAppEnable(false)
                }
                ////console.log('New records inserted ps service successfully');

            }
        } catch (error) {
            setAlert(true);
            setLoader(false)

            ////console.log('Error inserting records:', error);
        }
    };

    const handleSaveAndContinueTargetSercviceApp = () => {
        try {
            if (targetApps.length >= requiredAppLength) {
                setsourceDataError({
                    ...sourceDataError,
                    targetServiceAppLength: '',
                });
                ////console.log("MM");
                setMainNewTab('Notifications')
                insertServiceApps('targerApp', '')
            } else {
                setsourceDataError({
                    ...sourceDataError,
                    targetServiceAppLength: ` Additional ${additionalAccountsRequiredTarget} accounts are required `,
                });

                return;
            }
        } catch (error: any) {
            setAlert(true);
            setLoader(false)

        }
    }

    const insertParallelCount = async () => {
        setLoader(true)
        try {
            let token = await getToken()

            const payload = {
                token: token,
                taskId: location.state.taskId,
                parallelInstanceCount: value

            };
            const response = await parallelInstanceCountApi(payload);

            if (!response) {
                console.error('The values failed to insert');
            } else {
                ////console.log('New records inserted successfully');
            }
            setLoader(false)

        } catch (error) {
            setAlert(true);

            console.error('Error inserting records:', error);
        }
    };

    const updateCustomDate = async () => {
        debugger;
        try {
            let token = await getToken()

            const payload = {
                emailId: notifications,
                taskId: location.state.taskId,
                token: token,
                // taskId: location?.state?.taskId,
                // clientId: sessionStorage.getItem("m_c_i"),
                from: fromDate === "" ? "1900-01-01" : fromDate,
                to: toDate === "" ? formattedCurrentDate : toDate,
            };
            // setLoader(true)
            const response = await updateCustomdate(payload);
            // setLoader(false)
            if (!response) {
                console.error("The values failed to insert");
            } else {
                ////console.log("New records inserted successfully");
                setFromDate("");
                setTodate("");
            }
        } catch (error) {
            setAlert(true);
            setLoader(false)

            console.error("Error inserting records:", error);
        }
    };



    const handleDeleteAccount = async (index: any, tab: string, email: string) => {
        try {
            let token = await getToken()

            if (tab === 'Source') {
                setDeletePopup(false)
                if (sourceAccounts.length === 1) {
                    setDeletePopup(false)
                    setLoader(true)
                    const payload = {
                        token: token,
                        taskId: location.state.taskId,
                        connectionType: "source",
                        isPSAccount: 1,
                        mailId: email
                    };
                    const response = await deletePsservicedata(payload);
                    setpsSource(true)
                }
                const updatedAccounts = [...sourceAccounts];
                updatedAccounts.splice(index, 1);
                setSourceAccounts(updatedAccounts);
                setdeletePsAccount([...deletePsAccount, { email, connectionType: tab }])
                setLoader(false)

            } else if (tab === 'Target') {
                setDeletePopup(false)
                if (targetAccounts.length === 1) {
                    setDeletePopup(false)
                    setLoader(true)
                    const payload = {
                        token: token,
                        taskId: location.state.taskId,
                        connectionType: "target",
                        isPSAccount: 1,
                        mailId: email
                    };
                    const response = await deletePsservicedata(payload);
                    setLoader(false)
                }
                const updatedAccounts = [...targetAccounts];
                updatedAccounts.splice(index, 1);
                setTargetAccounts(updatedAccounts);
                setdeletePsTarAccount([...deletePsTarAccount, { email, connectionType: tab }])

            } else if (tab === 'Notifications') {
                setDeletePopup(false)
                if (notifications?.length === 1) {
                    setDeletePopup(false)
                    setLoader(true)
                    const payload = {
                        emailId: email,
                        token: token,
                        taskId: location.state.taskId,
                    };
                    const response = await deletenotify(payload);
                    setLoader(false)

                }
                const updatedAccounts = [...notifications];
                updatedAccounts.splice(index, 1);
                setNotifications(updatedAccounts);
                setdeleteNotification([...deleteNotifyAccount, { email }]);
            } else if (tab === 'sourceApp') {
                setDeletePopup(false)

                const payload = {
                    token: token,
                    taskId: location.state.taskId,
                    connectionType: "source",
                    appId: email
                };
                const response = await deleteserviceapp(payload);
                setSourceAppEnable(true)
                setLoader(false)

                const updatedAccounts = [...sourceApps];
                updatedAccounts.splice(index, 1);
                setSourceApps(updatedAccounts);
                setDeleteSourceApp([...deleteSourceApp, { email }]);
            }

        } catch (error: any) {
            setAlert(true);
            setLoader(false)

        }
    };

    const insertLastVisitedPageValue = async (data: any) => {
        try {
            setLoader(true)
            let token = await getToken()

            const payload = {
                token: token,
                taskId: taskId,
                lastVisitedValue: data
            };

            ////console.log(payload, 'last visited');

            // Insert only newly entered records into the database
            const response: any = await insertLastVisitedPageValueApi(payload);
            if (!response) {
                ////console.log('The current page value failed to insert');
            } else {
                ////console.log('The current page value inserted successfully');

            }
        } catch (error) {
            console.error('Error inserting records:', error);
        }
    };


    //console.log( "notifications--------------------", notifications)

    const handleNextBtnDisability=():boolean=>{
        try{
            let disableBtn=false;
            if(mainNewTab.toLowerCase()=='notifications'){
                disableBtn = !notifications.length?true :false ;
            }
            else if((environment.toLowerCase()=='tenant' && sourceAccounts?.length === 0) || sourceApps?.length === 0){
                disableBtn = true
            }
            return disableBtn;
        }catch(err:any){
            console.error('Error in handleNextBtnDisability',err)
            return true
        }
    }

    return (
        <>
            <div className="container-fluid container-height">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card px-4 py-4 mt-3 mb-3 border-0 rounded-3">
                            {/* bread crumb starts here */}
                            <ul className="breadcrumb-item mb-4 ps-0">
                                <li>
                                    <a
                                        href="/task"
                                        className="pe-0"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            history.push("/task", {
                                                state: {
                                                    ...location?.state,
                                                },
                                            });
                                        }}
                                    >
                                    Task
                                    </a>
                                </li>
                                <li className="pe-2">{location?.state?.taskName}</li>
                            </ul>
                            {/* bread crumb ends here */}
                            <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap gap-3 mt-2">
                                <h5 className="primary-header mb-0 d-flex align-items-center">
                                    <a
                                        href="/task"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            history.push("/task");
                                        }}
                                    >
                                        <img
                                            src="/images/head-back-arrow.svg"
                                            alt="back-arrow"
                                            width={19}
                                            className="me-2"
                                        />
                                    </a>
                                    {location?.state?.taskName}
                                    - Assessment
                                    <img
                                        src="/images/Actual-user-migration-bg.svg"
                                        alt="User migration"
                                        width={23}
                                        className="ms-2"
                                    />
                                </h5>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card p-md-4 border-0 rounded-3">
                                        <div className="row justify-content-center">
                                            {/* Wizard structure starts here */}
                                            <div className="col-md-7">
                                                <div className="d-flex justify-content-between align-items-center step-wizard">
                                                    <div className="d-flex align-items-center gap-1 bg-white z-index-1 cursor-pointer"
                                                        onBlur={(e) => {
                                                            e.stopPropagation();
                                                        }}
                                                        onMouseDown={() => {
                                                            if (location?.state?.taskId && location?.state?.isEdit) {
                                                                return history.push('/SelectApps', {
                                                                    state: {
                                                                        ...location?.state,
                                                                        pageType: 'selectapps',
                                                                        isEdit: true,
                                                                        stage: ((location?.state?.stage) && (location?.state?.stage > stage)) ? location?.state?.stage : stage
                                                                    }
                                                                })

                                                            }
                                                            else {

                                                                history.push("/SelectApps", {
                                                                    state: {
                                                                        // applicationName: location?.state?.applicationName,
                                                                        // applicationId: location?.state?.applicationId,
                                                                        ...location?.state,
                                                                        isEdit: true,
                                                                        pageType: 'selectapps',
                                                                        stage: ((location?.state?.stage) && (location?.state?.stage > stage)) ? location?.state?.stage : stage
                                                                    },
                                                                });
                                                            }
                                                        }
                                                        }>
                                                        <div className="wizard-step-num d-flex justify-content-center align-items-center font-bold font-14"
                                                        >
                                                            1
                                                        </div>
                                                        <span className="bg-white me-1 font-14 font-semibold d-none d-xl-block wizard-step-text">
                                                            Select App
                                                        </span>
                                                    </div>



                                                    <div className="d-flex align-items-center gap-1 bg-white z-index-1 cursor-pointer">
                                                        <div className="wizard-step-num d-flex justify-content-center align-items-center font-bold font-14"
                                                            onClick={() => {
                                                                history.push('/Connectors', {
                                                                    state: {
                                                                        ...location?.state,
                                                                        isEdit: true,
                                                                        // taskType: 'assessment',
                                                                        stage: (location?.state?.stage && location?.state?.stage > stage) ? location?.state?.stage : stage
                                                                    }
                                                                });
                                                            }}
                                                        >
                                                            2
                                                        </div>
                                                        <span className="bg-white me-1 font-14 font-semibold d-none d-xl-block wizard-step-text"
                                                            onClick={() => {
                                                                history.push('/Connectors', {
                                                                    state: {
                                                                        isEdit: true,
                                                                        ...location?.state,
                                                                        // taskType: 'assessment',
                                                                        stage: (location?.state?.stage && location?.state?.stage > stage) ? location?.state?.stage : stage
                                                                    }
                                                                });
                                                            }}
                                                        >
                                                            Connectors
                                                        </span>
                                                    </div>
                                                    <div className="d-flex align-items-center gap-1 bg-white z-index-1">
                                                        <div className="wizard-step-num d-flex justify-content-center align-items-center font-bold font-14 active">
                                                            3
                                                        </div>
                                                        <span className="bg-white me-1 font-14 font-semibold d-none d-xl-block wizard-step-text active">
                                                            Configurations
                                                        </span>
                                                    </div>
                                                    <div className="d-flex align-items-center gap-1 bg-white z-index-1 cursor-pointer"
                                                        onClick={() => {
                                                            if (location?.state?.taskId && location?.state?.isEdit && location?.state?.stage >= 3) {
                                                                if (location?.state?.stage === 3) {
                                                                    history.push('/selectassessmentapp', {
                                                                        state: {
                                                                            ...location.state,
                                                                            isEdit: true,

                                                                            stage: (location?.state?.stage && location?.state?.stage > stage) ? location?.state?.stage : stage
                                                                        }
                                                                    });
                                                                } else if (location?.state?.stage === 4) {
                                                                    history.push('/Inprogress', {
                                                                        state: {
                                                                            ...location.state,
                                                                            isEdit: true,

                                                                            stage: (location?.state?.stage && location?.state?.stage > stage) ? location?.state?.stage : stage
                                                                        }
                                                                    })
                                                                }
                                                            }
                                                        }

                                                        }
                                                    >
                                                        <div className="wizard-step-num d-flex justify-content-center align-items-center font-bold font-14 ">
                                                            4
                                                        </div>
                                                        <span className="bg-white me-1 font-14 font-semibold d-none d-xl-block wizard-step-text">
                                                            Assessment
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Wizard structure ends here */}
                                            <div className="col-md-9 mt-4 mt-md-5">
                                                <h3 className="m-0 font-22 font-bold pb-2">
                                                    Configurations
                                                </h3>
                                                <p className="pb-3 font-semibold font-16 secondary-font-color">
                                                    Configuring these will ensure seamless continuity and data
                                                    integrity.
                                                </p>
                                                {/* tab structure starts here */}
                                                <ul
                                                    className="nav nav-pills mb-2 tab-parent"
                                                    id="pills-tab"
                                                    role="tablist"
                                                >
                                                    <li className="nav-item" role="presentation">
                                                        <button
                                                            className={mainNewTab === 'assessment' ? "nav-link theme-tab active font-16" : "nav-link theme-tab font-16"}
                                                            data-bs-toggle="pill"
                                                            data-bs-target="#soureTab"
                                                            type="button"
                                                            role="tab"
                                                            aria-selected="true"
                                                            onClick={() => {
                                                                setMainNewTab('assessment')
                                                                setNestedTab('Source')
                                                                setsourceDataError({
                                                                    pssourceAccountEmail: '',
                                                                    pssourceAccountPassword: '',
                                                                    serviceAccount: '',
                                                                    serviceAppId: '',
                                                                    serviceAppSecret: ''
                                                                })
                                                                setNotification({ email: '' });
                                                                setSourceApp({ id: '', secret: '' });
                                                                setSourceAccount({ id: '', email: '', password: '' });

                                                            }}


                                                        >
                                                            Assessment
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button
                                                            className={mainNewTab == 'Notifications' ? "nav-link theme-tab active font-16" : "nav-link theme-tab font-16"}
                                                            data-bs-toggle="pill"
                                                            data-bs-target="#targetTab"
                                                            type="button"
                                                            role="tab"
                                                            aria-selected="false"
                                                            onClick={() => {
                                                                setMainNewTab('Notifications');
                                                                setsourceDataError({
                                                                    notificationEmail: ''
                                                                })
                                                                setNotification({ email: '' });
                                                                setSourceApp({ id: '', secret: '' });
                                                                setSourceAccount({ id: '', email: '', password: '' });
                                                            }}

                                                        >
                                                            Notifications
                                                        </button>
                                                    </li>
                                                </ul>
                                                {mainNewTab === 'assessment' ?
                                                    <div className="tab-content" id="pills-tabContent">
                                                        <div
                                                            className="tab-pane fade show active"
                                                            id="soureTab"
                                                            role="tabpanel"
                                                        >
                                                            {/* Source tab content starts here */}
                                                            <div className="col-md-6 pt-3 pe-md-5 mb-3 pb-md-3">
                                                                <label className="form-label font-14 font-semibold label-color label-color">
                                                                    Assessment Customization Dates
                                                                </label>
                                                                {/* Mapping Dropdown starts here */}
                                                                <div className="row">
                                                                    <div className="col-md-6 col-12 position-relative">
                                                                        <label
                                                                            className="font-14 font-semibold label-color w-100 mb-2"
                                                                         htmlFor="fromDate"
                                                                        >
                                                                            From
                                                                        </label>
                                                                        <input
                                                                          
                                                                            className="form-control custom-form "
                                                                             type="date"
                                                                            id="fromDate"
                                                                            name="fromDate"
                                                                            max={today}
                                                                            value={fromDate}
                                                                            onChange={(e) => {
                                                                                const newFromDate = e.target.value;
                                                                                setFromDate(newFromDate);

                                                                                // Ensure toDate is not earlier than fromDate
                                                                                if (toDate && newFromDate > toDate) {
                                                                                    setTodate(newFromDate);
                                                                                }
                                                                            }}
                                                                            disabled={roleCheck.toLowerCase().includes('viewer')}

                                                                        />
                                                                        <img src="/images/date-icon.svg" alt="date-icon" className="position-absolute custom-input-icon"/>
                                                                    </div>
                                                                    <div className="col-md-6 col-12 position-relative">
                                                                        <label
                                                                            className="font-14 font-semibold label-color w-100 mb-2"
                                                                            htmlFor="toDate"
                                                                        >
                                                                            To
                                                                        </label>
                                                                        <input
                                                                            
                                                                            className="form-control custom-form "
                                                                         type="date"
                                                                            id="toDate"
                                                                            name="toDate"
                                                                            min={fromDate}
                                                                            max={today}
                                                                            value={toDate}
                                                                            onChange={(e) => setTodate(e.target.value)}
                                                                            disabled={roleCheck.toLowerCase().includes('viewer')}

                                                                        />
                                                                        <img src="/images/date-icon.svg" alt="date-icon" className="position-absolute custom-input-icon"></img>
                                                                    </div>
                                                                </div>
                                                                {/* Mapping Dropdown ends here */}
                                                            </div>
                                                            <div>
                                                            {nestedTab === 'Source' && environmentType!=="googleworkspace" && (mainNewTab === 'assessment') ? (
                                                                    <PsServiceConfiguration
                                                                        taskId={taskId}
                                                                        roleCheck={roleCheck}
                                                                        setRoleCheck={setRoleCheck}
                                                                        setSourceAccount={setSourceAccount}
                                                                        sourceAccount={sourceAccount}
                                                                        sourceAccounts={sourceAccounts}
                                                                        setSourceAccounts={setSourceAccounts}
                                                                        targetAccounts={targetAccounts}
                                                                        setTargetAccounts={setTargetAccounts}
                                                                        sourceDataError={sourceDataError}
                                                                        setsourceDataError={setsourceDataError}
                                                                        emailTouched={emailTouched}
                                                                        setEmailTouched={setEmailTouched}
                                                                        emailValid={emailValid}
                                                                        setEmailValid={setEmailValid}
                                                                        setPsSourceSAC={setPsSourceSAC}
                                                                        psSourceSAC={psSourceSAC}
                                                                        setPsTargetSAC={setPsTargetSAC}
                                                                        psTargetSAC={psTargetSAC}
                                                                        handleSaveAndContinueAndInsert={handleSaveAndContinueAndInsert}
                                                                        handleSaveAndContinueAndInsertTarget={handleSaveAndContinueAndInsertTarget}
                                                                        nestedTab={nestedTab}
                                                                        mainNewTab={mainNewTab}
                                                                    />
                                                                ) : null}
                                                            </div>

                                                            <div className="tab-content" id="pills2-tabContent">



                                                                {mainNewTab === 'assessment' ? (
                                                                    <MigrationConfiguration
                                                                        taskId={taskId}
                                                                        value={value}
                                                                        setValue={setValue}
                                                                        mainNewTab={mainNewTab}
                                                                        roleCheck={roleCheck}
                                                                        totalParallelCount={totalParallelCount}
                                                                        setTotalParallelCount={setTotalParallelCount}
                                                                        migrationNestedTabButton={migrationNestedTabButton}
                                                                        setMigrationNestedTabButton={setMigrationNestedTabButton}
                                                                        migrationNestedTab={migrationNestedTab}
                                                                        setMigrationNestedTab={setMigrationNestedTab}
                                                                        sourceApp={sourceApp}
                                                                        setSourceApp={setSourceApp}
                                                                        sourceApps={sourceApps}
                                                                        setSourceApps={setSourceApps}
                                                                        targetApp={targetApp}
                                                                        setTargetApp={setTargetApp}
                                                                        targetApps={targetApps}
                                                                        setTargetApps={setTargetApps}
                                                                        migSourceAppSAC={migSourceAppSAC}
                                                                        handleSaveAndContinueSourceSercviceApp={handleSaveAndContinueSourceSercviceApp}
                                                                        handleSaveAndContinueTargetSercviceApp={handleSaveAndContinueTargetSercviceApp}
                                                                        handleSaveAndContinueSourceSercviceAccount={handleSaveAndContinueSourceSercviceAccount}
                                                                        handleSaveAndContinueTargetSercviceAccount={handleSaveAndContinueSourceSercviceAccount}
                                                                        insertParallelCount={insertParallelCount}
                                                                        setMigSourceAppSAC={setMigSourceAppSAC}
                                                                        sourceDataError={sourceDataError}
                                                                        setsourceDataError={setsourceDataError}
                                                                        sourceDataErrorMessage={sourceDataErrorMessage}
                                                                        setsourceDataErrorMessage={setsourceDataErrorMessage}
                                                                        onToggleAccordian={onToggleAccordian} sourceServiceAccounts={undefined} setsourceServiceAccounts={undefined}
                                                                    />
                                                                ) : null}
                                                            </div>

                                                            <div className="d-md-flex d-grid w-100 gap-3 align-items-center justify-content-unset justify-content-md-between mt-5 ">
                                                                {/* <button className="btn primary-outline-btn font-14 w-md-100 font-semibold flex-nowrap d-flex align-items-center justify-content-center order-2 order-md-0"
                                                                    onClick={() => {
                                                                        history.push('/Connectors', {
                                                                            state: {
                                                                                isEdit: true,
                                                                                ...location?.state,
                                                                                stage: (location?.state?.stage && location?.state?.stage > stage) ? location?.state?.stage : stage
                                                                            }
                                                                        })
                                                                        setsourceDataError({
                                                                            pssourceAccountEmail: '',
                                                                            pssourceAccountPassword: '',
                                                                            serviceAccount: '',
                                                                            serviceAppId: '',
                                                                            serviceAppSecret: ''
                                                                        })
                                                                        setNestedTab('serviceApp')
                                                                        setSourceAccount({ id: '', email: '', password: '' });
                                                                        setSourceApp({ id: '', secret: '' });

                                                                    }}
                                                                >
                                                                    <img
                                                                        src="/images/left-arw-blue.svg"
                                                                        alt="back"
                                                                        width={12}
                                                                    /><span className="ms-1">Back</span>
                                                                </button> */}
                                                                {/* <div className="d-md-flex d-grid gap-3">
                                                                    <button className="btn secondary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center me-3 order-2 order-md-0"
                                                                        onClick={() => {
                                                                            history.push("/task", {
                                                                                state: {
                                                                                    ...location?.state
                                                                                },
                                                                            });
                                                                        }}
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                    <button className="btn primary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center"
                                                                        onClick={() => { setMainNewTab('Notifications'); updateCustomDate() }}
                                                                        disabled={sourceAccount.length === 0}
                                                                    >
                                                                        Next
                                                                        <img src="/images/right-arw.svg" alt="next" />
                                                                    </button>
                                                                </div> */}
                                                            </div>
                                                            {/* Source tab content ends here */}
                                                        </div>
                                                    </div> : null}
                                                {/* Render Notifications tab content */}
                                                <div>
                                                    {mainNewTab.toLowerCase() === 'notifications' && (notiHideTab === 'hide') ? (
                                                        <NotificationConfiguration
                                                            taskId={taskId}
                                                            notification={notification}
                                                            setNotification={setNotification}
                                                            emailTouched={emailTouched}
                                                            setEmailTouched={setEmailTouched}
                                                            sourceDataError={sourceDataError}
                                                            roleCheck={roleCheck}
                                                            notifications={notifications} // Pass notifications as prop
                                                            setNotifications={setNotifications}
                                                            setsourceDataError={setsourceDataError}
                                                            notificationSAC={notificationSAC}
                                                            setNotificationSAC={setNotificationSAC}
                                                            sourceDataErrorMessage={sourceDataErrorMessage}
                                                            setsourceDataErrorMessage={setsourceDataErrorMessage}
                                                            handleSaveAndContinueNotification={handleSaveAndContinueNotification}
                                                            nestedTab={nestedTab}
                                                            notiHideTab={notiHideTab}
                                                        />

                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="d-md-flex d-grid w-100 gap-3 align-items-center justify-content-unset justify-content-md-between mt-5 ">
                                                <button className="btn primary-outline-btn font-14 w-md-100 font-semibold flex-nowrap d-flex align-items-center justify-content-center order-2 order-md-0"
                                                    onClick={() => {
                                                        history.push('/Connectors', {
                                                            state: {
                                                                isEdit: true,
                                                                ...location?.state,
                                                                stage: (location?.state?.stage && location?.state?.stage > stage) ? location?.state?.stage : stage
                                                            }
                                                        })
                                                        setsourceDataError({
                                                            pssourceAccountEmail: '',
                                                            pssourceAccountPassword: '',
                                                            serviceAccount: '',
                                                            serviceAppId: '',
                                                            serviceAppSecret: ''
                                                        })
                                                        setNestedTab('serviceApp')
                                                        setSourceAccount({ id: '', email: '', password: '' });
                                                        setSourceApp({ id: '', secret: '' });

                                                    }}
                                                >
                                                    <img
                                                        src="/images/left-arw-blue.svg"
                                                        alt="back"
                                                        width={12}
                                                    /><span className="ms-1">Back</span>
                                                </button>
                                                <div className="d-md-flex d-grid gap-3">
                                                    <button className="btn secondary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center me-3 order-2 order-md-0"
                                                        onClick={() => {
                                                            insertLastVisitedPageValue('assessmentConfigurations');
                                                            history.push("/task", {
                                                                state: {
                                                                    ...location.state,
                                                                    applicationName: location?.state?.applicationName,
                                                                    applicationId: location?.state?.applicationId,
                                                                    taskName: location?.state?.taskName,
                                                                    taskType: location?.state?.taskType,
                                                                },
                                                            });
                                                            setsourceDataError({
                                                                pssourceAccountEmail: '',
                                                                pssourceAccountPassword: '',
                                                                serviceAccount: '',
                                                                serviceAppId: '',
                                                                serviceAppSecret: ''
                                                            });
                                                            setSourceAccount({ id: '', email: '', password: '' });
                                                            setSourceApp({ id: '', secret: '' });

                                                        }}
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button className="btn primary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center"
                                                        onClick={() => {
                                                            debugger
                                                            // {
                                                            //     debugger
                                                            //     roleCheck.toLowerCase() === 'viewer' ? history.push("/Assessment", {
                                                            //         state: {
                                                            //             applicationName: location?.state?.applicationName,
                                                            //             applicationId: location?.state?.applicationId,
                                                            //             taskName: location?.state?.taskName,
                                                            //             taskType: location?.state?.taskType,
                                                            //         },
                                                            //     }) : mainNewTab == 'Notifications' ? insertLastVisitedPageValue('selectAssessmentApp') : insertLastVisitedPageValue('taskassessmentconfig')
                                                            // };
                                                            if (location.state.lastVisited && !(location.state.lastVisited.toLowerCase() == 'assessmentconfigurations')) {
                                                                history.push(`/${location.state.lastVisited}`, {
                                                                    state: {
                                                                        ...location.state,
                                                                        pageType: "export",
                                                                    }
                                                                })
                                                            }
                                                            else {
                                                                updateCustomDate()
                                                                fetchUser();
                                                                insertLastVisitedPageValue('assessment')

                                                            }

                                                        }}
                                                        disabled={handleNextBtnDisability()}
                                                    >
                                                        Next
                                                        <img src="/images/right-arw.svg" alt="next" />
                                                    </button>
                                                </div>
                                            </div>
                                            {/* Source tab content ends here */}
                                        </div>
                                    </div>
                                    {/* tab structure ends here */}
                                    {loader && <Loader />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showPopup === true ? (
                <div className="modal fade show" id="Failure-popup" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="Failure-popupLabel" aria-modal="true" role="dialog" style={{ display: "block", backgroundColor: "rgba(0,0,0,0.8)" }}>

                    <div className="modal-dialog modal-dialog-centered">

                        <div className="modal-content popup-brd-radius">

                            <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">

                                <span className="position-absolute">

                                    <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />

                                </span>

                            </div>

                            <div className="modal-body border-0 text-center">

                                <h5 className="modal-title mb-3 primary-header" id="Failure-popupLabel">Failure</h5>

                                <p className="primary-data">{sourceDataError.showPopupMessage}</p>

                            </div>

                            <div className="modal-footer border-0 pb-4 justify-content-center">

                                <button type="button" onClick={() => setShowPopup(false)} className="btn primary-btn font-14 px-4 font-semibold" data-bs-dismiss="modal">Retry</button>

                            </div>

                        </div>

                    </div>

                </div>) : null}
            {DeletePopup === true ? (
                <div className="modal fade show" id="Failure-popup" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="Failure-popupLabel" aria-modal="true" role="dialog" style={{ display: "block", backgroundColor: "rgba(0,0,0,0.8)" }}>

                    <div className="modal-dialog modal-dialog-centered">

                        <div className="modal-content popup-brd-radius">

                            <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">

                                <span className="position-absolute">

                                    <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />

                                </span>

                            </div>

                            <div className="modal-body border-0 text-center">

                                <h5 className="modal-title mb-3 primary-header" id="Failure-popupLabel">Alert</h5>

                                <p className="primary-data">Are you sure you want to Delete?</p>

                            </div>

                            <div className="modal-footer border-0 pb-4 justify-content-center">

                                <button type="button" onClick={() => {
                                    handleDeleteAccount(index, deleteTab, deleteEmail)
                                }}
                                    className="btn primary-btn font-14 px-4 font-semibold" data-bs-dismiss="modal">Yes</button>
                                <button type="button" onClick={() => {
                                    setDeletePopup(false)

                                }}
                                    className="btn primary-btn font-14 px-4 font-semibold" data-bs-dismiss="modal">No</button>

                            </div>

                        </div>

                    </div>

                </div>) : null}
            {alert ? <div
                className="modal fade show"
                tabIndex={-1}
                style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content popup-brd-radius">
                        <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
                            <span className="position-absolute">
                                <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />
                            </span>
                        </div>
                        <div className="modal-body border-0 text-center">
                            <h5
                                className="modal-title mb-3 primary-header"
                                id="Failure-popupLabel"
                            >
                                {alertMessage}
                            </h5>
                            <p className="primary-data">
                                {alert}
                            </p>
                        </div>
                        <div className="modal-footer border-0 pb-4 justify-content-center">
                            <button
                                type="button"
                                className="btn primary-btn font-14 px-4 font-semibold"
                                onClick={() => {
                                    if (isSessionEpired) {
                                        history.push('/Login')
                                    }
                                    setAlertMessage('')
                                    setAlert(false)
                                }}
                            >
                                Ok
                            </button>
                        </div>
                    </div>
                </div>
            </div> : null}
            {/*Failure Popup ends here */}
        </>

    )

};

export default MasterAssConfiguration;