import { useLocation } from "react-router-dom";

export default function Footer() {
    const { pathname } = useLocation();
    if (!pathname.toLowerCase().includes('/registration') &&
        !pathname.toLowerCase().includes('/login') &&
        !pathname.toLowerCase().includes('/admin/login') &&
        !pathname.toLowerCase().includes('/forgotpassword')) {
        return (
            <div className="bg-white shadow py-2 d-flex align-items-center justify-content-center">
                <p className="primary-color mb-0 font-semibold font-12">
                    © Avasoft Inc 2024
                </p>
            </div>
        )
    } else {
        return null;
    }
}
