import { client } from "../Client/Client";
import { BE_URL, viewMigration } from "../Common/Constant";



export async function OutlookTypeGrid(data:object) {
    const response = await client({
      method: 'POST',
      // url:'http://localhost:8084/viewMigration/getMigrationTypeForOutlookUser',
      url: BE_URL + viewMigration.getlook,
      data: data
    });
    return response;
  }

  export async function updateMailUserAction(data:any) {
    const response = await client({
      method: 'POST',
      // url:'http://localhost:8084/Migration/updateAction',
      url: BE_URL + viewMigration.userActions,
      data: data
    });
    return response;
  }

  export async function getOutlookData(data:any) {
    const response = await client({
      method: 'POST',
      // url:'http://localhost:8080/viewMigration/fetchoutlookData',
      url: BE_URL + viewMigration.fetchoutlook,
      data: data
    });
    return response;
  }

  export async function getViewMigrationData(data:any) {
    const response = await client({
      method: 'POST',
      // url:'http://localhost:8080/viewMigration/getViewMigrationDetails',
      // url: BE_URL + viewMigration.fetchoutlook,
      url: BE_URL + viewMigration.allApps,

      data: data
    });
    return response;
  }
  


  export async function invokeOutlookReportApi(data:object) {
    const response = await client({
      method: 'POST',
      // url:'http://localhost:8080/viewMigration/generateOutlookMigrationReport',
      url: BE_URL + viewMigration.getReport,
      data: data
    });
    return response;
  }