import { client } from "../Client/Client";
import { BE_URL, contactus } from "../Common/Constant";


/**PS_EX_27 to PS_EX_29 will be executed*/
export async function fetchCategory(data:object) {
    let config = {
        method: 'POST',
        url: BE_URL + contactus.getCategory,
        // url: "http://localhost:8080/contactus/getCategory",
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    const response = await client(config);
    return response;
};


export async function fetchAllFaq(data:object) {
    let config = {
        method: 'POST',
        url: BE_URL + contactus.getAllFaq,
        // url: "http://localhost:8080/contactus/getAllFaq",
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    const response = await client(config);
    return response;
};

export async function addCommentDetails(data:object) {
    let config = {
        method: 'POST',
        url: BE_URL + contactus.addComments,
        // url: "http://localhost:8080/contactus/addComments",
        // url: BE_URL + commonEndpoint.addCommentUrl,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    const response = await client(config);
    ////console.log("response from Api", response);
    return response
};

export async function insertFaq(data:object) {

    let config = {
        method: 'POST',
        url: BE_URL + contactus.addQuestions,
        // url: "http://localhost:8080/contactus/addQuestions",
        // url: ADMIN_BE_URL + commonEndpoint.addQuestionUrl,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    const response = await client(config);
    ////console.log("response from Api", response);
    // 41 : Returning the response
    return response
};


export async function deleteFaq(data:object) {
    let config = {
        method: 'POST',
        url: BE_URL + contactus.deleteQuestions,
        // url: "http://localhost:8080/contactus/deleteQuestions",
        // url: ADMIN_BE_URL + commonEndpoint.deleteQuestion,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    const response = await client(config);
    return response
};

