import axios from 'axios'
 
let flag = false
 
//PS_55, PS_56, PS_57, PS_58, PS_59, PS_60, PS_61, PS_62, PS_63, PS_64, PS_65, PS_66, PS_67

export async function client(Request:any) {
    try {
        let apiResponse = await axios(Request);
        if (apiResponse.data.statusMessage != undefined &&  apiResponse?.data?.statusMessage.toLowerCase().includes('server is down') && !(window.location.pathname.toString().toLowerCase().includes("/serverdown"))) {
            flag = true
            sessionStorage.removeItem('c_at')
            window.location.pathname = "/ServerDown"; 
        }
        else if (apiResponse.data.statusMessage != undefined && apiResponse?.data?.statusMessage.toLowerCase().includes('user token is invalid') && !(window.location.pathname.toString().toLowerCase().includes("/login"))){
            flag = true
            sessionStorage.removeItem('c_at')
            window.location.pathname = "/Login";
        }
        else {
            if (flag) {
                flag = false
                window.history.back()
                //console.log('in else' ,flag);

            }
            return apiResponse
        }
    } catch (error:any) {
        //console.log("API call failed:", error.message)
    }
}

