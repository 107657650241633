import { client } from "../Client/Client";
import { BE_URL, overallAssessment } from "../Common/Constant";


export async function getAssessmentSummary(data:object) {//PS_AD-43
    try{
        let config = {//PS_AD-44
            method: 'POST',
           url: BE_URL + overallAssessment.getAssessmentSummary,
        //   url: 'http://localhost:8080/assessment/getAssessmentSummary',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        }
        const response = await client(config);//PS_AD-45
        return response;//PS_AD-50
    }
    catch(error:any){
        ////console.log(error.message);
        
    }
  
};