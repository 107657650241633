import React, { useState, useEffect } from 'react';
import { commonErrorMsgs } from '../../Common/Constant';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/material.css';
import { followCursor } from 'tippy.js';
import { useHistory } from 'react-router-dom';
import Loader from '../Common/Loader';
import { ViewMigrationGridProps } from '../../Common/Interface';
import Tippy from '@tippyjs/react';
import moment from 'moment';
import { getOutlookData, getViewMigrationData, updateMailUserAction } from '../../Services/MigrationReportServices';
import * as XLSX from 'xlsx';
import { decodeSessionDetails } from '../../Services/CommonServices';
import objCryptoJS from "crypto-js";

const ViewMigrationGrid: React.FC<ViewMigrationGridProps> = ({
  filterAction,
  searchAction,
  exportAction,
  setShouldExport,
  taskId,
  taskName,
  updateItemCount,
  role,
  adminRole,
  applicationName,
  resourceType,
  render
}) => {
  /**PS_40 to PS_70 will be executed*/
  const history = useHistory()

  /**PS_01 to PS_21 will be executed*/
  const rn_secretKey = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY || '';
  const a_secretKey = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY || '';
  const c_secretKey = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY || '';

  const [objAdmin, setObjAdmin] = useState({})
  const [objClient, setObjClient] = useState({})

  const [alert, setAlert] = useState<boolean>(false)
  const [alertMessage, setAlertMessage] = useState<string>('')

  const [sortBy, setSortBy] = useState(
    applicationName === 'outlook' ? 'uds.displayName' :
      applicationName === 'teamschannel' ? 'ud_source.SourceChannelName' :
        applicationName === 'sharepoint' ? 'ud_source.SourceSPSiteName' :
          'ud_source.displayName'
  );

  const [sortOrder, setSortOrder] = useState("asc");
  const [sortHide, setSortHide] = useState<string>('');

  const [totalDataCount, setTotalDataCount] = useState<number>(0);
  const [isFetching, setIsFetching] = useState(false)

  const [page, setPage] = useState<number>(10);
  const [loader, setLoader] = useState<boolean>(false);

  const [getData, setGetData] = useState<any[]>([]);
  const [noRecords, setNoRecords] = useState<string>('No Records Found');

  const [lastExportTimestamp, setLastExportTimestamp] = useState(0);

  let bindingObject: any = {
    outlook: [
      {
        header: "Source User Name",
        value: "SourceDisplayName",
        sort: "uds.displayName"
      },
      {
        header: "Target User Name",
        value: "TargetDisplayName",
        sort: "udt.displayName"
      },
      {
        header: "Overall Progress",
        value: "totalCount",
        sort: "os.totalCount",
      },
      {
        header: "Overall Status",
        value: "statusText",
        sort: "umm.statusText",
      },
      {
        header: "Action",
        value: "Action",
        sort: null,
      },
    ],
    sharepoint: [
      {
        header: "Source Site Name",
        value: "sourceDisplayName",
        sort: "ud_source.SourceSPSiteName"
      },
      {
        header: "Source Site URL",
        value: "sourceSiteURL",
        sort: "ud_source.SourceSPSiteURL"
      },
      {
        header: "Target Site Name",
        value: "targetDisplayName",
        sort: "ud_target.targetSPSiteName"
      },
      {
        header: "Target Site URL",
        value: "targetSiteURL",
        sort: "ud_target.TargetSPSiteURL"
      },
      {
        header: "Overall Progress",
        value: "migratedCount",
        sort: "ms.migratedCount"
      },
      {
        header: "Overall Status",
        value: "statusText",
        sort: "ms.statusText",
      },
      {
        header: "Action",
        value: "Action",
        sort: null,
      },
    ],
    teamschannel: [
      {
        header: "Source Team Name",
        value: "sourceTeamName",
        sort: "ud_source.SourceTeamName"
      },
      {
        header: "Source Channel Name",
        value: "sourceChannelName",
        sort: "ud_source.SourceChannelName"
      },
      {
        header: "Target Team Name",
        value: "targetTeamName",
        sort: "ud_target.TargetTeamName"
      },
      {
        header: "Target Channel Name",
        value: "targetChannelName",
        sort: "ud_target.TargetChannelName"
      },
      {
        header: "Overall Progress",
        value: "migratedCount",
        sort: "ms.migratedCount"
      },
      {
        header: "Overall Status",
        value: "statusText",
        sort: "ms.statusText",
      },
      {
        header: "Action",
        value: "Action",
        sort: null,
      },
    ],
    gmail: [
      {
        header: "Source User Name",
        value: "sourceDisplayName",
        sort: "ud_source.displayName"
      },
      {
        header: "Target User Name",
        value: "targetDisplayName",
        sort: "ud_target.displayName"
      },
      {
        header: "Overall Progress",
        value: "migratedCount",
        sort: "ms.migratedCount",
      },
      {
        header: "Overall Status",
        value: "statusText",
        sort: "ms.statusText",
      },
      {
        header: "Action",
        value: "Action",
        sort: null,
      },
    ],
    mydrive: [
      {
        header: "Source User Name",
        value: "SourceDisplayName",
        sort: "ud_source.displayName"
      },
      {
        header: "Target User Name",
        value: "TargetDisplayName",
        sort: "ud_target.displayName"
      },
      {
        header: "Overall Progress",
        value: "migratedCount",
        sort: "ms.migratedCount",
      },
      {
        header: "Overall Status",
        value: "statusText",
        sort: "ms.statusText",
      },
      {
        header: "Action",
        value: "Action",
        sort: null,
      },
    ],
    onedrive: [
      {
        header: "Source User Name",
        value: "SourceDisplayName",
        sort: "ud_source.displayName"
      },
      {
        header: "Target User Name",
        value: "TargetDisplayName",
        sort: "ud_target.displayName"
      },
      {
        header: "Overall Progress",
        value: "migratedCount",
        sort: "ms.migratedCount",
      },
      {
        header: "Overall Status",
        value: "statusText",
        sort: "ms.statusText",
      },
      {
        header: "Action",
        value: "Action",
        sort: null,
      },
    ],
    groups: [
      {
        header: "source Group Name",
        value: "sourceGroupName",
        sort: "sourceGroupName",
      },
      {
        header: "target Group Name",
        value: "targetGroupName",
        sort: "targetGroupName",
      },
      {
        header: "Overall Progress",
        value: "overAllProgress",
        sort: "overAllProgress",
      },
      {
        header: "Overall Status",
        value: "userMappingActions",
        sort: "userMappingActions",
      },
      {
        header: "Action",
        value: "action",
        sort: null,
      },
    ],
  };

  const getApplicationDisplayName = (applicationName: string): string => {
    const nameMap: { [key: string]: string } = {
      outlook: "User Mailbox",
      groups: "Groups",
      sharedmailbox: "Shared Mailbox",
      roomsandequipments: "Rooms & Equipment's",
      teamschannel: "Teams Channel",
      teamschat: "Teams Chat",
      onedrive: "One Drive",
      sharepoint: "SharePoint",
      gmail: "Gmail",
      mydrive: "My Drive",
      shareddrive: "Shared Drive",
      ggroups: "Groups",
      sites: "Sites",
      chats: "Chats",
      sharedDrive: "Shared Drive"
    };

    return nameMap[applicationName.toLowerCase()] || applicationName;
  };

  useEffect(() => {
    if (searchAction.value.length === 0) {
      //console.log("enter 1");

      fetchData()
    }
    else if (searchAction.value.length > 0 && searchAction?.value.trim() != '') {
      //console.log("enter 2");

      fetchData()
    }

    if (!isFetching) {
      const intervalId = setInterval(() => {
        fetchData()
      },
       100000000
      );

      return () => clearInterval(intervalId); // Cleanup on unmount
    }
  }, [sortBy, sortOrder, sortHide, page, filterAction, searchAction, render]);

  useEffect(() => {
    if (exportAction) {
      setShouldExport(false)
      handleExport(); 
    }
  }, [exportAction]);

  // See steps 1.22 to 1.38 for the actual logic to be run on page load and component rendering
  const getStatusLabel = (action: any) => {
    switch (action) {
      case "0":
        return "Warning";
      case "1":
        return "Paused";
      case "2":
        return "Cancelled";
      case "3":
        return "Scheduled";
      case "4":
        return "Queue";
      case "5":
        return "In Progress";
      case "6":
        return "Completed";
      case "7":
        return "Failed";
      default:
        return "Unknown";
    }
  };
  

  /**PS_80 to PS_88 will be executed */
  function formatSize(sizeInBytes: number): string {
    let sizeInBytesProper: number = sizeInBytes * 1024 * 1024 * 1024;

    const units: string[] = ["B", "KB", "MB", "GB", "TB"];
    let size: number = sizeInBytesProper;
    let unitIndex: number = 0;

    while (size >= 1024 && unitIndex < units.length - 1) {
      size /= 1024;
      unitIndex++;
    }

    let formattedSize: string = size.toFixed(2);

    let unit: string = units[unitIndex];

    return `${formattedSize} ${units[unitIndex]}`;
  }

  /*PS_135 to PS_139 will be executed*/
  const handleSort = (e: any) => {
    try {
      debugger;
      let sortID: string = (e.target as HTMLElement).id;
      let sortval: string[] = sortID.split("@");
      setSortBy(sortval[0]);
      setSortOrder(sortval[1]);
      setSortHide(sortID);
      //setSearchValue("");
    } catch (e: any) {
      ////console.log(e.message);
    }
  };

  const handleExport = () => {
    try {
      setLoader(true);

      const bindingConfig = bindingObject[applicationName.toLowerCase()];
      if (!bindingConfig) {
        throw new Error(`No configuration found for application: ${applicationName}`);
      }

      const filteredData = getData.map((item: any) => {
        const rowData: { [key: string]: any } = {};
        bindingConfig.forEach((config: any) => {
          if (config.header !== "Action") {
            let value = item;
            config.value.split('.').forEach((key: string) => {
              value = value && value[key];
            });
            rowData[config.header] = value;
          }
        });
        return rowData;
      });

      const worksheet = XLSX.utils.json_to_sheet(filteredData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Migration Summary");

      const displayName = getApplicationDisplayName(applicationName);
      const fileName = `${displayName}_Migration_Report.xlsx`;
      XLSX.writeFile(workbook, fileName);
    } catch (error: any) {
      setAlert(true);
      setAlertMessage('Something went wrong while generating the report. Please try again or reach AVAMIGRATRON Support.');
      console.error("Error exporting data:", error.message);
    } finally {
      setLoader(false);
    }
  };

  const getToken = async () => {
    let clientToken: string;
    let adminToken: string;
    let token: { clientToken?: string; adminToken?: string } = {};
    try {
      if (objClient != undefined) {
        let sessionToken: any = sessionStorage.getItem('c_at')
        if (sessionToken) {
          sessionToken = objCryptoJS.AES.decrypt(sessionToken, c_secretKey)
          var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
          clientToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
          token['clientToken'] = clientToken
        }
      }

      if (objAdmin != undefined) {
        let sessionToken: any = sessionStorage.getItem('a_at')
        if (sessionToken) {
          sessionToken = objCryptoJS.AES.decrypt(sessionToken, a_secretKey)
          var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
          adminToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();

          token['adminToken'] = adminToken
        }
      }

      return token
    } catch (error) {
      throw error
    }
  }

  async function fetchData() {
    try {
      let sessionToken = await getToken();

      if (applicationName === 'outlook' && (resourceType=='user' || resourceType=='shared')) {
        const payload = {
          token: sessionToken,
          taskId: taskId,
          status: filterAction.value != '' ? Number(filterAction) : 100,
          searchValue: searchAction.value.trim(),
          sortBy: sortBy,
          sortOrder: sortOrder,
          pageSize: page,
          resourceType: resourceType
        };

        const result: any = await getOutlookData(payload)
        //console.log(result, "this is the outlook result");

        if (result?.data?.statusCode == 200) {
          setTotalDataCount(result?.data?.data?.totalDataCount)
          setGetData(result?.data?.data?.bindingData)

          updateItemCount(result?.data?.data?.totalDataCount);
        }
        else {
          setAlert(true)
          setAlertMessage(commonErrorMsgs.generalError)
        }
      }
      else {
        const payload = {
          token: sessionToken,
          taskId: taskId,
          applicationName: applicationName=='outlook' ?  resourceType : applicationName,
          searchValue: searchAction.value,
          sortBy: sortBy,
          sortOrder: sortOrder,
          filterValue: filterAction.value,
          pageSize: page,
          offset: 0
        };

        const result: any = await getViewMigrationData(payload)

        //console.log(result, "this is the other result");

        //console.log(result?.data?.data.slice(1), "this is the other result");

        if (result?.data?.statusCode == 200) {
          setTotalDataCount(result?.data?.data[0].totalDataCount)
          setGetData(result?.data?.data.slice(1));
          updateItemCount(result?.data?.data[0].totalDataCount);

          if (result?.data?.statusMessage === 'No Result Found') {
            setNoRecords('No Result Found')
          }
        }
        else {
          setAlert(true)
          setAlertMessage(commonErrorMsgs.generalError)
        }
      }
    }
    catch (error) {
      //console.log(error, "Error in fetching data")
      setAlert(true)
      setAlertMessage(commonErrorMsgs.generalError)
    }
  }

  async function handleActionButtonClick(item: any, action: any, isAdmin = false) {
    // debugger
    try {
        let token = await getToken();

        if (action === '2' && action === '3' && item?.scheduleMigrationDateTime && !isAdmin) {
            ////console.log("Hello there");
            let currentDateTimeUTC = moment.utc(); // Current UTC time
            ////console.log("🚀 ~ item?.scheduleMigrationDateTime:", item?.scheduleMigrationDateTime)
            ////console.log("🚀 ~ handleActionButtonClick ~ currentDateTimeUTC:", currentDateTimeUTC)

            // Convert scheduleMigrationDateTime to moment object if it's not already
            let scheduledTime = moment.utc(item.scheduleMigrationDateTime);

            // Check if the difference is within a tolerance (e.g., 5 seconds)
            if (scheduledTime.diff(currentDateTimeUTC, 'minutes') <= 5) {
                setLoader(false)
            }
            else {
                ////console.log('lets send api')
                debugger
                let mapping = {
                    mappingId: item.userMigrationMappingId
                }
                let payload = {
                    taskId: taskId,
                    token: token,
                    data: mapping,
                    applicationName: `${applicationName}`,
                    Action: action,
                    // isAdmin: isAdmin,
                    statusText: getStatusLabel(action)
                }
                setLoader(true)
                const response: any = await updateMailUserAction(payload);
                ////console.log("mappedMailboxes", response);
                if (response.data?.Status_code === 409) {
                    // setAlert(true)
                    setAlertMessage(response.data.message)
                    fetchData()
                    setLoader(false)
                }
                else {
                    fetchData()
                    setLoader(false)
                }
            }
        }
        else {
            ////console.log('lets send api')
            let mapping = {
                mappingId: item.userMigrationMappingId
            }
            let payload = {
                taskId: taskId,
                token: token,
                data: mapping,
                applicationName: `${applicationName}`,
                Action: action,
                // isAdmin: isAdmin,
                statusText: getStatusLabel(action)
            }
            setLoader(true)
            const response: any = await updateMailUserAction(payload);
            ////console.log("mappedMailboxes", response);
            if (response.data?.Status_code === 409) {
                setLoader(false)
                //   setAlert(true)
                setAlertMessage(response.data.message)
                fetchData()
            }
            else {
                fetchData()
                setLoader(false)
            }
        }


    }
    catch (error) {
        console.error("Error updating user Action data:", error);
    } finally {
        // setLoading(false)
    }
}

  return (
    <>
      <div className="table-responsive theme-table bg-white ">
        <table className="table table-borderless mb-0">
          <thead>
            <tr>
              {bindingObject[applicationName.toLowerCase().trim()].map(
                ({ header, sort }: { header: string; sort: string }, index: number) => (
                  <th
                    key={index}
                    className={`text-nowrap ${header === "Action" ? "text-center" : ""
                      }`}
                  >
                    {header}
                    {header !== "Action" && (
                      <span className="ms-1 cursor-pointer">
                        <img
                          src={`/images/sort-arrow-up.svg`}
                          alt="sort-arrow"
                          style={{ cursor: "pointer" }}
                          id={`${sort}@ASC`}
                          hidden={
                            sortHide === `${sort}@DESC` || "" ? false : true
                          }
                          onClick={(e) => {
                            handleSort(e);
                          }}
                        />
                        <img
                          src={`/images/sort-arrow-down.svg`}
                          alt="sort-arrow"
                          id={`${sort}@DESC`}
                          style={{ cursor: "pointer" }}
                          hidden={
                            sortHide === `${sort}@DESC` || "" ? true : false
                          }
                          onClick={(e) => {
                            handleSort(e);
                          }}
                        />
                      </span>
                    )}
                  </th>
                )
              )}
            </tr>
          </thead>
          <tbody>
            {getData?.length > 0 ? (
              getData?.map((item, index) => (
                <tr key={index}>
                  {bindingObject[applicationName?.toLowerCase().trim()].map(
                    ({ header, value }: { header: string; value: string }, index: number) => {
                      if (Object.is(value, "totalCount")) {
                        return (
                          <td className="d-flex align-items-right justify-content-right">
                            {item["totalSum"] ? item["totalSum"] : 0 }/{item["totalCount"]}
                            <Tippy
                              delay={500}
                              arrow={true}
                              plugins={[followCursor]}
                              placement="right"
                              content={
                                <div
                                  style={{
                                    color: "white",
                                    padding: "5px",
                                  }}
                                >
                                  Count {item["totalSum"]}/
                                  {item["totalCount"] !== undefined
                                    ? item["totalCount"]
                                    : 0}{" "}
                                  <br />
                                  Size{" "}
                                  {formatSize(
                                    parseFloat(item["totalSizeAppDetails"])
                                  )}
                                  /{formatSize(parseFloat(item["totalSize"]))}{" "}
                                </div>
                              }
                            >
                              <img
                                src="/images/tooltip.svg"
                                alt="tooltip"
                                className="ms-1 mb-1"
                                data-bs-placement="right"
                                width={11}
                              />
                            </Tippy>
                          </td>
                        );
                      }

                      if (Object.is(value, "Action")) {
                        return (
                          <td className="d-flex align-items-center justify-content-center">
                            <span className="d-inline-flex gap-3">
                              <button
                                disabled={
                                  role?.toLowerCase() === "viewer" ||
                                  adminRole?.toLowerCase() === "adminviewer"
                                }
                                className="btn action-rounded-btn view-action d-flex align-items-center justify-content-center"
                                data-bs-toggle="tooltip"
                                data-bs-placement="bottom"
                                data-bs-title="View"
                                title="View"
                                
                                onClick={() => {
                                  debugger;
                                  history.push("/MigrationReport", {
                                    state: {
                                      taskId: taskId,
                                      taskName: taskName,
                                      userMappingId: item.userMigrationMappingId,
                                      sourceDisplayName: item.SourceDisplayName,
                                      targetDisplayName: item.TargetDisplayName,
                                      sourceMailId: item.SourceMailID,
                                      targetMailId: item.TargetMailID,
                                      applicationName: applicationName,
                                      resourceType: resourceType
                                    },
                                  });
                                }}
                              >
                                <img
                                  src="/images/view-icon-filled.svg"
                                  alt="View"
                                />
                              </button>
                              {(item[value] == "1" || item[value] == "5") && adminRole.toLowerCase().includes("primary") ? (
                                <button
                                  className="btn action-rounded-btn cancel-action d-flex align-items-center justify-content-center"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="bottom"
                                  data-bs-title="Cancel"
                                  title="Cancel"
                                  onClick={() =>
                                    handleActionButtonClick(item, "2")
                                  }
                                >
                                  <img
                                    src="/images/cancel-action-icon.svg"
                                    alt="Cancel"
                                  />
                                </button>
                              )
                                :
                                null}
                              {item[value] == "6"
                                ? (<div></div>)
                                : (
                                  <button
                                    disabled={
                                      role?.toLowerCase() === "viewer"
                                    }
                                    className="btn action-rounded-btn in-progress-action d-flex align-items-center justify-content-center"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="bottom"
                                    data-bs-title="Pause"
                                    title="Pause"
                                    onClick={() =>
                                      handleActionButtonClick(
                                        item,
                                        (item[value] == "3" || item[value] == "4") ? "2"
                                          : (item[value] == "1" || item[value] == "2" || item[value] == "7" || item[value] == "0" ? '5' : '1')
                                      )
                                    }
                                  >
                                    <img
                                      src={
                                        item[value] == "3" ||
                                          item[value] == "4" ||
                                          item[value] == "0"
                                          ? "/images/refresh-icon-filled.svg"
                                          : item[value] == "1"
                                            ? "/images/play-icon-filled.svg"
                                            : item[value] == "2" ||
                                              item[value] == "7"
                                              ? "/images/refresh-icon-filled.svg"
                                              : "/images/pause-icon.svg"
                                      }
                                      alt="pause"
                                    />
                                  </button>
                                )}
                            </span>
                          </td>
                        );
                      }

                      return (
                        <td
                          style={{
                            textTransform:
                              header?.toLowerCase() === "resourcetype"
                                ? "capitalize"
                                : "none",
                          }}
                        >
                          {item[value]?.length > 21 ? (
                            <span
                              data-bs-toggle="tooltip"
                              data-bs-placement="right"
                              title={item[value]}
                            >
                              {item[value].slice(0, 21) + "..."}
                            </span>
                          ) : (
                            item[value]
                          )}
                        </td>
                      );
                    }
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6}>
                  <div className="text-center">
                    <div className="pt-4 pb-3 text-center">
                      <img
                        src="/images/no-data-found-img.svg"
                        alt="no-data-found-img"
                        className="mb-3"
                      />
                      <h5 className="font-12 font-bold color-no-rec">
                        {noRecords}
                      </h5>
                      <p className="font-10 font-regular color-no-rec">
                        No Matching Results Found
                      </p>
                    </div>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <p className="text-start mb-3" style={{ color: 'black' }}>
        # Records of :<span style={{ fontWeight: 'bold' }}>{getData.length}</span> out of <span style={{ fontWeight: 'bold' }}>{totalDataCount}</span>
      </p>
      <div className="d-flex justify-content-center mt-5">
        <button
          className="btn primary-outline-btn font-14 font-semibold"
          onClick={() => {
            setPage(page + 10);
          }}
          hidden={totalDataCount < page}>
          Load More
        </button>
      </div>

      {loader && <Loader />}

      {alert ?
        <div
          className="modal fade show"
          tabIndex={-1}
          style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content popup-brd-radius">
              <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
                <span className="position-absolute">
                  <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />
                </span>
              </div>
              <div className="modal-body border-0 text-center">
                <h5
                  className="modal-title mb-3 primary-header"
                  id="Failure-popupLabel"
                >
                  {alertMessage}
                </h5>
                <p className="primary-data">
                  {alert}
                </p>
              </div>
              <div className="modal-footer border-0 pb-4 justify-content-center">
                <button
                  type="button"
                  className="btn primary-btn font-14 px-4 font-semibold"
                  onClick={() => { setAlert(false) }}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
        : null
      }
    </>
  );
};

export default ViewMigrationGrid;