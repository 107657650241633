// PS_01, PS_02, PS_03, PS_04
import React, { useState, useEffect } from 'react';
import objCryptoJS from 'crypto-js';
import Tippy from '@tippyjs/react';
import { followCursor } from 'tippy.js';
// import commonService from '/commonServices';
import { useLocation, useHistory } from 'react-router-dom';
import Loader from '../Common/Loader';
import PsServiceConfiguration from './psServiceConfigurations';
import NotificationConfiguration from './NotificationConfig';
import MigrationConfiguration from './MigrationConfig';
import { decodeSessionDetails } from '../../Services/CommonServices';
import { sessionResponse } from '../../Common/Interface';
import jwt from 'jwt-decode'
import { getSession } from '../../Services/manageClientAPI';
import { insertLastVisitedPageValueApi } from '../../Services/MigrationAndManageMigrationServices';
// import { payloadInterface } from '../Interface'; // Replace any and any with actual interface names as needed
import { insertServiceDataApi, insertServiceAppApi, insertNotificationsApi, insertErrApi, getErrApi, parallelInstanceCountApi, fetchPsDataApi } from "../../Services/psConfigServices";//PS_AD-04
import { fetchPreferencesData, postPreferencesData } from '../../Services/AppSpecificServices';

interface MasterConfigProps {
    // Define props here if needed
}
interface InterfaceA {
    email: string;
    password: string;
}
interface Props {
    isEdit: boolean;
    taskId: string;
    isOpen: any;
    onToggle: () => void;
    onGeneralConfigSave: () => void;
    updateGeneralConfigSaveStatus: (value: any) => void;
    applicationName: any;
    onToggleAccordian: () => void;
    isEditCheck: boolean;
    setIsEditCheck: React.Dispatch<React.SetStateAction<boolean>>;
    enableUserMappingToggle: any
}
const MasterMigConfiguration: React.FC<Props> = ({ setIsEditCheck, onToggleAccordian, taskId, isOpen, onToggle, onGeneralConfigSave, updateGeneralConfigSaveStatus,
    applicationName,
    isEdit, enableUserMappingToggle }) => {

    //console.log(applicationName, "appname in main1");
    //console.log(taskId, 'taskkkkk');

    const location: any = useLocation().state;
    //console.log(location.state, 'migmaster location');

    const [environment, serEnvironment] = useState<string>(location?.state?.environment?.toLowerCase());


    // const applicationName: any = 'sharepoint'
    // PS_01, PS_02, PS_03, PS_04, PS_05, PS_06, PS_09, PS_10, PS_11, PS_12, PS_13, PS_14, PS_15, PS_16, PS_17, PS_18, PS_19, PS_20, PS_21, 
    // PS_22, PS_23, PS_24, PS_25, PS_26, PS_27, PS_28, PS_29, PS_30
    

    const [emailValid, setEmailValid] = useState<boolean>(false);
    const [showPopup, setShowPopup] = useState<boolean>(false);
    // //console.log(location, "locationraaaaaa");
    let lastVisitedPage = location?.state?.lastVisited;
    const migrationPart = lastVisitedPage?.split('-')[1];
    // //console.log(migrationPart, "idhe raaaaaaaaaaaaaa");

    const [sourceAccount, setSourceAccount] = useState<any>({ email: '', password: '' });
    const [sourceAccounts, setSourceAccounts] = useState<any[]>([]);
    const [sourceServiceAccounts, setsourceServiceAccounts] = useState<any[]>([]);
    const [targetAccounts, setTargetAccounts] = useState<any[]>([]);
    const [targetServiceAccounts, setTargetServiceAccounts] = useState<any[]>([]);
    const [psSourceSAC, setPsSourceSAC] = useState<boolean>(false);
    const [psTargetSAC, setPsTargetSAC] = useState<boolean>(false);
    const [psSource, setpsSource] = useState<boolean>(true);
    // const clientId = "2"
    const [roleCheck, setRoleCheck] = useState<string>("");
    const [mainNewTab, setMainNewTab] = useState<any>(migrationPart ? migrationPart : 'psservice');
    // const [applicationName, setApplicationName] = useState<any>('gdrive');
    //console.log(applicationName, "in master component 1");

    const [emailTouched, setEmailTouched] = useState<boolean>(true);
    const [loader, setLoader] = useState<boolean>(false)
    // const isOpen = true;
    const [notiHideTab, setnotiHideTab] = useState<string>("show");
    const [alertMessage, setAlertMessage] = useState<string>('');
    let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
    const [isSessionEpired, setisSessionEpired] = useState(false);
    let environmentType2:any = location?.state?.environment
    const [environmentType, setEnvironmentType] = useState<string>(environmentType2);
    let tab= location?.state?.environment=='googleworkspace'?'Target':'Source'
    const [nestedTab, setNestedTab] = useState<string>(tab);
    //console.log(nestedTab,"tttttttttttttttt123")
    // //debugger
    const [files, setFiles] = useState<string>("");
    const [limit, setLimit] = useState<string>('');
    const [version, setVersion] = useState<any>('false');
    const [saveAndContinueCheck, setSaveAndContinueCheck] = useState<boolean>(false);


    let InActiveTimeout: NodeJS.Timeout;

    let history = useHistory();

    const [sourceDataError, setsourceDataError] = useState({
        notification: '',
        sourceAccountEmail: '',
        sourceAccountPassword: '',
        targetAccountEmail: '',
        targetAccountPassword: '',
        sourceServiceAccount: '',
        targetServiceAccount: '',
        sourceServiceAppId: '',
        sourceServiceAppSecret: '',
        targetServiceAppId: '',
        targetServiceAppSecret: '',
        sourceServiceAccountLength: '',
        targetServiceAccountLength: '',
        sourceServiceAppLength: '',
        targetServiceAppLength: '',
        showPopupMessage: '',
        invalidEmailAddress: 'The Entered Email ID is invalid',
        SourceAzureAppId: '',
        SourceAzureSecretId: "",
        emailMaxLengthError: ""

    });

    const [sourceDataErrorMessage, setsourceDataErrorMessage] = useState({
        notification: 'Email ID cannot be empty',
        sourceAccountEmail: 'Email ID cannot be empty',
        sourceAccountPassword: 'Password cannot be empty',
        targetAccountEmail: 'Email ID cannot be empty',
        targetAccountPassword: 'Password cannot be empty',
        sourceServiceAccount: 'Email ID cannot be empty',
        targetServiceAccount: 'Email ID cannot be empty',
        sourceServiceAppId: 'Azure App Id cannot be empty',
        sourceServiceAppSecret: 'Azure App Secret Value cannot be empty',
        targetServiceAppId: 'Azure App Id cannot be empty',
        targetServiceAppSecret: 'Azure App Secret Value cannot be empty'
    })
    const [value, setValue] = useState<number>(1);
    const [totalParallelCount, setTotalParallelCount] = useState<number>(0);
    const requiredAppLength = Number(value) + 1
    const [SourceAppEnable, setSourceAppEnable] = useState(true);

    const [migrationNestedTabButton, setMigrationNestedTabButton] = useState('Source');
    const [migrationNestedTab, setMigrationNestedTab] = useState('serviceApp');

    const [sourceApp, setSourceApp] = useState<any>({ id: '', secret: '', serviceAccountKey:'' });
    const [targetApp, setTargetApp] = useState<any>({ id: '', secret: '' });
    const [sourceApps, setSourceApps] = useState<any[]>([]);
    const [targetApps, setTargetApps] = useState<any[]>([]);
    const [notification, setNotification] = useState<any>({ email: '' });
    const [notifications, setNotifications] = useState<any[]>([]);

    const [alert, setAlert] = useState<boolean>(false);

    const [skipErrorHandling, setSkipErrorHandling] = useState<string>('Yes');
    const [migSourceAppSAC, setMigSourceAppSAC] = useState<boolean>(false);
    const [notificationSAC, setNotificationSAC] = useState<boolean>(false);
    // const[isEditCheck, setIsEditCheck] = useState<boolean>(false)
    // ... rest of state variables, assumed as per previous sections
    const [ctoken, setCToken] = useState<string>(sessionStorage.getItem('clientToken') || '');
    const [tokenExpiration, setTokenExpiration] = useState<number>(parseInt(sessionStorage.getItem('tokenExpiration') || '0'));
    const [atoken, setAToken] = useState<string>(sessionStorage.getItem('a_at') || '');
    const [isAdmin, setIsAdmin] = useState<boolean>(sessionStorage.getItem('isAdmin') === 'true');
    const [objAdmin, setObjAdmin] = useState({})
    const [objClient, setObjClient] = useState({})
    let [adminName, setAdminName] = useState('');
    let [adminEemailAddress, setAdminEmailAddress] = useState('');
    let [adminrole, setAdminRole] = useState('');
    let sessionDetails = { UserName: "", CompanyName: "", EmailAddress: "", ID: 0, Role: "" }
    let [clientName, setclientName] = useState('');
    let [companyName, setCompanyName] = useState('');
    let [emailAddress, setEmailAddress] = useState('');
    let [role, setRole] = useState('');
    const rn_secretKey = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY || '';
    const a_secretKey = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY || '';
    const c_secretKey = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY || '';
    // let lastVisited = location?.state.lastVisited;
    const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
    const [fileTypes, setFileTypes] = useState<string[]>([]);
    const mainComponentType: string = "migration";

    // PS_31, PS_32
    useEffect(() => {
        window.scrollTo(0, 0);
        sessionTokenProperties();
        resetTimer();
        gettErr()
        if (applicationName !== 'outlook' || applicationName !== 'gmail') {
            fetchPreferenceData();
        }
        window.addEventListener('keydown', resetTimer);
        window.addEventListener('click', resetTimer);

        return () => {
            window.removeEventListener('click', resetTimer);
            window.removeEventListener('keydown', resetTimer);
        };
    }, []);


    let resetTimer = async () => {
        try {
            // debugger;
            ////console.log("Reset Timer Start");

            let sessionResponse: string = await resetSessionTimer(InActiveTimeout, SessionEpiredDateTime);
            ////console.log("sessionResponse value :", sessionResponse);

            // //debugger
            if (sessionResponse == 'expired') {
                debugger
                sessionStorage.removeItem("c_at");
                setisSessionEpired(true)
                setAlert(true)
                setAlertMessage('Your Session has Expired please login again to continue');
            }
            else if (sessionResponse == 'updateToken') {
                if (emailAddress == "" || emailAddress == undefined) {
                    await sessionTokenProperties();
                }
                debugger
                let token = await getToken()
                let payload = {
                    mailId: emailAddress,
                    isAdmin: false,
                    token: token
                };
                let getsessionToken = await getSession(payload);
                const rn_secretKey: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY
                const cs_secretKey: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;

                const c_byteValue = objCryptoJS.AES.decrypt(getsessionToken?.data?.data.sessionToken, rn_secretKey);
                const c_decryptedValue = c_byteValue.toString(objCryptoJS.enc.Utf8);
                let updatedToken = objCryptoJS.AES.encrypt(c_decryptedValue, cs_secretKey).toString();
                await sessionStorage.removeItem("c_at");
                await sessionStorage.setItem('c_at', updatedToken);
                let updatedobjClient: any = jwt(c_decryptedValue);
                SessionEpiredDateTime = updatedobjClient.CreatedAt;
                setSessionEpiredDateTime(SessionEpiredDateTime);
            }
            ////console.log("Reset Timer End");
        }
        catch (error) {
            ////console.log("Reset Timer Catch");
            setAlert(true)
            setAlertMessage('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
            setLoader(false);
        }
    }

    const resetSessionTimer = async (InActiveTimeout: any, SessionExpirationDateTime: any) => {
        try {
            // debugger
            ////console.log("Reset Session Timer Start");
            // Assuming InActiveTimeout is a number representing the ID of the current timeout

            if (typeof window !== 'undefined') {
                ////console.log("Reset Session Timer Inside");
                clearTimeout(InActiveTimeout);
                const tokenExpiryCheckInterval = Number(process.env.REACT_APP_TABIDLESCENARIO);

                InActiveTimeout = setTimeout(() => {
                    ////console.log("Expired");
                    sessionStorage.removeItem("c_at");
                    ////console.log("removed session");
                    setisSessionEpired(true);
                    setAlert(true)
                    setAlertMessage('Your Session has Expired please login again to continue');
                    // return 'expired'
                }, tokenExpiryCheckInterval);

                ////console.log("Reset Session Timer checking");

                const currentDateTime = new Date().toISOString();
                const sessionExpiredDateTime = new Date(SessionExpirationDateTime).toISOString();
                ////console.log("sessionExpiredDateTime value :", sessionExpiredDateTime);

                const diffInMilliseconds = Math.abs(new Date(sessionExpiredDateTime).getTime() - new Date(currentDateTime).getTime());
                ////console.log("diffInMilliSeconds values:", diffInMilliseconds);

                const minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
                ////console.log("minutes : ", minutes);

                const sessionUpdateTimeGap = Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP);
                ////console.log("Reset Session Timer Differece : ", sessionUpdateTimeGap);

                if (minutes > sessionUpdateTimeGap) {

                    ////console.log("Reset Session Timer need to update");
                    return 'updateToken'
                }

                ////console.log("Reset Session Timer End");
                return 'NoActionRequired'
            }
            return 'NoActionRequired'
        }
        catch (ex) {
            ////console.log("Reset Session Timer Catch");
            throw ex
        }
    };

    // PS_33, PS_34, PS_35, PS_36, PS_37, PS_38, PS_39
    let sessionTokenProperties = async () => {
        try {
            const tokenResponse: any = await decodeSessionDetails();
            debugger
            if (tokenResponse.adminDetails) {
                if (tokenResponse.adminDetails) {
                    setObjAdmin(tokenResponse.adminDetails)
                    setAdminName(tokenResponse.adminDetails.Name);
                    setAdminEmailAddress(tokenResponse.adminDetails.Email);
                    setAdminRole(tokenResponse.adminDetails.Role);
                    setRoleCheck(tokenResponse.adminDetails.Role)
                }
                else {
                    if (window.location.pathname.toString().toLowerCase().includes("admin")) {
                        history.push("/Admin/Login")
                    }
                }
            }
            else {
                if (tokenResponse.clientDetails) {
                    setObjClient(tokenResponse.clientDetails)
                    setclientName(tokenResponse.clientDetails.Name);
                    setCompanyName(tokenResponse.clientDetails.CompanyName)
                    emailAddress = tokenResponse.clientDetails.Email;
                    setEmailAddress(emailAddress);
                    setRole(tokenResponse.clientDetails.Role);
                    setRoleCheck(tokenResponse.clientDetails.Role);
                    setSessionEpiredDateTime(tokenResponse.clientDetails.CreatedAt);
                }
                else {
                    history.push("/Login")
                }
            }
            //#endregion
        } catch (error) {
            setAlert(true);
            setLoader(false)

            console.error('Failed in main method', error);
        }
    };

    const getToken = async () => {
        let clientToken: string;
        let adminToken: string;
        let token: { clientToken?: string; adminToken?: string } = {};
        debugger
        try {
            if (objClient !== undefined) {
                let sessionToken: any = sessionStorage.getItem('c_at')
                if (sessionToken) {
                    sessionToken = objCryptoJS.AES.decrypt(sessionToken, c_secretKey)
                    var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
                    clientToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
                    token['clientToken'] = clientToken

                }


            }
            if (objAdmin !== undefined) {
                let sessionToken: any = sessionStorage.getItem('a_at')
                if (sessionToken) {
                    sessionToken = objCryptoJS.AES.decrypt(sessionToken, a_secretKey)
                    var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
                    adminToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();

                    token['adminToken'] = adminToken

                }
            }
            return token
        } catch (error) {
            setAlert(true);
            setLoader(false)

            throw error
        }
    }

    // PS_44, PS_45, PS_46, PS_47
    const additionalAccountsRequiredSource = requiredAppLength - sourceApps.length
    ////console.log(additionalAccountsRequiredSource, "adddwddd");

    const additionalAccountsRequiredTarget = requiredAppLength - targetApps.length


    function handleEditCheck() {
        try {
            if (sourceAccounts.length > 0 && targetAccounts.length > 0 && sourceApps.length > 0 && targetApps.length > 0) {
                ////console.log("santhosh ");
                setIsEditCheck(false)
            }
            else {
                setIsEditCheck(true)
            }
        } catch (error: any) {
            setAlert(true);
            setLoader(false)

        }
    }

    // PS_38 PS_39 PS_40 PS_41 PS_42 PS_43 PS_44 
    const fetchPreferenceData = async () => {
        try {
            let token = await getToken()
            let payload = {
                token: token,
                taskId: Number(taskId),
                applicationName: applicationName,
            }
            setLoader(true);
            // Fetch data from the API
            const response: any = await fetchPreferencesData(payload);

            // Process the response data
            setFileTypes(response.data.data.fileDropDown);

            if (response.data.data.versionCount !== null && response.data.data.versionCount > 0) {
                setVersion('true');
                setLimit(response.data.data.versionCount.toString());
            }
            if (response.data.data.fileType !== null) {
                setSelectedFiles([response.data.data.fileType]);
            }
            if (response.data.data.fileType) {
                setSelectedFiles(response.data.data.fileType.split(',').filter((type: any) => type && type !== 'Select'));
            }

            // All data processing is complete, now we can stop the loader
            setLoader(false);
        } catch (error) {
            console.error("Error fetching user options:", error);
            setLoader(false); // Make sure to stop the loader even if there's an error
        }
    };
    // PS_135, PS_136, PS_137, PS_138, PS_139
    const insertServiceData = async () => {
        try {
            ////console.log("inside insert service dataaaaaaa");

            let token = await getToken()

            const payload = {
                token: token,
                taskId: taskId,
                connectionType: "source",
                isPSAccount: 1,
                data: sourceAccounts,
                mainComponentType: "migration"
            };

            ////console.log(payload, "wed s payload");
            setLoader(true)
            // Insert only newly entered records into the database
            const response = await insertServiceDataApi(payload);
            ////console.log(response, "ressytsdhdhtdhdthd");
            setLoader(false)
            if (!response) {
                console.error('The values failed to insert');
            } else {
                setLoader(false)
                setpsSource(false)
                ////console.log('New records inserted ps service successfully');

            }
        } catch (error) {
            setAlert(true);
            setLoader(false)

            ////console.log('Error inserting records:', error);
        }
    };

    // // PS_151, PS_152, PS_153, PS_154
    const insertServiceDataTarget = async () => {
        try {
            let token = await getToken()

            const payload = {
                token: token,
                taskId: taskId,
                connectionType: "target",
                isPSAccount: 1,
                data: targetAccounts,
                mainComponentType: 'migration'
            };

            ////console.log(payload, "wed payload");

            // Insert only newly entered records into the database
            const response = await insertServiceDataApi(payload);

            if (!response) {
                console.error('The values failed to insert');
            } else {
                ////console.log('New records inserted ps service successfully');

            }
        } catch (error) {
            setAlert(true);
            setLoader(false)

            ////console.log('Error inserting records:', error);
        }
    };

    // PS_156, PS_157, PS_158, PS_159, PS_160
    const insertServiceApps = async (tab: string) => {
        try {
            let token = await getToken()
            debugger
            let payload;
            setLoader(true)
            if (tab === "sourceApp") {
                payload = {
                    token: token,
                    taskId: taskId,
                    connectionType: "source",
                    data: sourceApps,
                    mainComponentType: mainComponentType
                };
            } 
            else {
                payload = {
                    token: token,
                    taskId: taskId,
                    connectionType: "target",
                    data: targetApps,
                    mainComponentType: mainComponentType
                };

            }

            ////console.log(payload, "service payload");

            // Insert only newly entered records into the database
            const response = await insertServiceAppApi(payload);
            setLoader(false)
            if (!response) {
                console.error('The values failed to insert');
            } else {
                if (payload.connectionType === "source") {
                    setSourceAppEnable(false)
                }
                ////console.log('New records inserted ps service successfully');

            }
        } catch (error) {
            setAlert(true);
            setLoader(false)

            ////console.log('Error inserting records:', error);
        }
    };


    const insertServiceAccounts = async (tab: string) => {
        try {
            let token = await getToken()

            let payload;

            debugger;

            setLoader(true)
            if (tab === "source") {
                ////console.log("insideeeee service if");

                payload = {
                    token: token,
                    taskId: taskId,
                    connectionType: "source",
                    isPSAccount:0,
                    data: sourceServiceAccounts
                };

            } else {

                payload = {
                    token: token,
                    taskId: taskId,
                    connectionType: "target",
                    isPSAccount:0,
                    data: targetServiceAccounts
                };

            }

            ////console.log(payload, "service payload");

            // Insert only newly entered records into the database
            const response = await insertServiceDataApi(payload);
            setLoader(false)
            if (!response) {
                console.error('The values failed to insert');
            } else {
                if (payload.connectionType === "source") {
                    setSourceAppEnable(false)
                }
                ////console.log('New records inserted ps service successfully');

            }
        } catch (error) {
            setAlert(true)
            setAlertMessage('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
            setLoader(false)

            ////console.log('Error inserting records:', error);
        }
    };


    // PS_168, PS_169, PS_170, PS_171, PS_172
    const insertNotifications = async () => {
        try {
            let token = await getToken()

            debugger;

            setLoader(true)
            const payload = {
                emailId: notifications,
                token: token,
                taskId: taskId,
                mainComponentType: mainComponentType
            };

            ////console.log(payload, 'notific');

            // Insert only newly entered records into the database
            const response = await insertNotificationsApi(payload);
            setLoader(false)
            if (!response) {
                console.error('The values failed to insert');
            } else {
                ////console.log('New records inserted successfully');
            }
        } catch (error) {
            setAlert(true);
            setLoader(false)

            console.error('Error inserting records:', error);
        }
    };

    // PS_186, PS_187, PS_188, PS_189, PS_190, PS_202, PS_203
    const insertErr = async () => {
        try {
            let token = await getToken()
            debugger
            setLoader(true)
            const payload = {
                token: token,
                taskId: taskId,
                isErrorHandlingSelected: skipErrorHandling.toLowerCase()

            };


            const response = await insertErrApi(payload);
            debugger;
            // //console.log(targetApps.length, targetAccounts.length, "lenghtsttstststst");
            if (applicationName === 'outlook'|| applicationName == 'gmail') {
                if (environment.toLowerCase()=='tenant' && targetApps.length > 0 && ((environment.toLowerCase()=='tenant' && applicationName !='teamschannel') || targetServiceAccounts.length > 0)) {
                    handleNext();
                }
            }
            else{
                setMainNewTab('preferences');
            }
            setLoader(false)
            if (!response) {
                console.error('The values failed to insert');
            } else {
                ////console.log('New records inserted successfully');
            }
        } catch (error) {
            setAlert(true);
            setLoader(false)

            console.error('Error inserting records:', error);
        }
    };

    // PS_204, PS_205, PS_206, PS_207
    const gettErr = async () => {
        try {
            let token = await getToken()

            setLoader(true)
            const payload = {
                token: token,
                taskId: taskId,
            };
            debugger
            const response = await getErrApi(payload);
            ////console.log(response, "error response");

            ////console.log(response.data.result[0]?.isErrorHandlingSelected, "SAndy Is a Mass");
            if (response?.data?.status === 200) {
                // setSkipErrorHandling(response.data.result[0]?.isErrorHandlingSelected)
                if (response?.data?.result[0]?.isErrorHandlingSelected === true) {
                    setSkipErrorHandling("Yes")
                }
                else {
                    setSkipErrorHandling("No")
                }
            } else {
                ////console.log('New records inserted successfully');
            }
            setLoader(false)

        } catch (error) {
            setAlert(true);
            setLoader(false)

            console.error('Error inserting records:', error);
        }
    };

    // PS_51

    const handleSaveAndContinueAndInsert = async (data: any) => {
        try {
            setNestedTab('Target');

            insertServiceData();
            handleEditCheck();
            // updateGeneralConfigSaveStatus(true)
        } catch (error: any) {
            setAlert(true);
            setLoader(false)

        }

    }
    // PS_49

    const handleSaveAndContinueAndInsertTarget = () => {
        try {
            debugger
            setMainNewTab('Migration');
            setNestedTab('Source');
            insertServiceDataTarget();
            // updateGeneralConfigSaveStatus(true)
        } catch (error: any) {
            setAlert(true);
            setLoader(false)

        }

    }

    // PS_51
    const handleSaveAndContinueNotification = () => {
        try {
            setMainNewTab('errorHandling');
            insertNotifications();
            // updateGeneralConfigSaveStatus(true)
        } catch (error: any) {
            setAlert(true);
            setLoader(false)

        }

    }



    ////console.log(requiredAppLength, "requiredAppLength");
    // PS_52
    const handleSaveAndContinueSourceSercviceApp: any = () => {
        try {
            // //console.log(sourceApps.length, "lengthtttttt");
            // //console.log(requiredAppLength, "requiredddddddd");

            if (sourceApps.length >= requiredAppLength)
               {
                setsourceDataError({
                    ...sourceDataError,
                    sourceServiceAppLength: "",
                });
                setMigrationNestedTabButton('Target');
                if(applicationName.includes('teamschannel')){
                    setMigrationNestedTab('serviceAccount');
                }
                insertServiceApps('sourceApp');
            } 
            else {
                setsourceDataError({
                    ...sourceDataError,
                    sourceServiceAppLength: ` Additional ${additionalAccountsRequiredSource} accounts are required `,
                });

                return;
            }
        } catch (error: any) {
            setAlert(true);
            setLoader(false)

        }
    }
    // PS_53


    const handleSaveAndContinueSourceSercviceAccount: any = () => {
        try {
            // //console.log(sourceApps.length, "lengthtttttt");
            //console.log( "handleSaveAndContinueSourceSercviceAccount    ==== requiredddddddd");
            debugger;
            if (sourceAccounts.length >= requiredAppLength)
                {
                setsourceDataError({
                    ...sourceDataError,
                    sourceServiceAccountLength: "",
                });
                setMigrationNestedTab('serviceApp');

                insertServiceAccounts('source')
            } 
            else {
                setsourceDataError({
                    ...sourceDataError,
                    sourceServiceAccountLength: ` Additional ${additionalAccountsRequiredSource} accounts are required `,
                });

                return;
            }
        } catch (error: any) {
            setAlert(true);
            setLoader(false)

        }
    }

    const handleSaveAndContinueTargetSercviceAccount: any = () => {
        try {
            // //console.log(sourceApps.length, "lengthtttttt");
            //console.log( "handleSaveAndContinueSourceSercviceAccount    ==== requiredddddddd");
            debugger;
            if (targetServiceAccounts.length >= requiredAppLength)
                 {
                setsourceDataError({
                    ...sourceDataError,
                    targetServiceAccountLength: "",
                });
                setMigrationNestedTab('serviceApp');
                insertServiceAccounts('target')
            } 
            else {
                setsourceDataError({
                    ...sourceDataError,
                    targetServiceAccountLength: ` Additional ${additionalAccountsRequiredSource} accounts are required `,
                });

                return;
            }
        } catch (error: any) {
            setAlert(true);
            setLoader(false)

        }
    }

    const handleSaveAndContinueTargetSercviceApp = () => {
        try {
            debugger
            ////console.log("requiredAppLength : ", requiredAppLength);
            ////console.log("value : ", value);
            ////console.log("targetApps : ", targetApps);


            if (targetApps.length >= requiredAppLength)
                 {
                setsourceDataError({
                    ...sourceDataError,
                    targetServiceAppLength: '',
                });
                ////console.log("MM");
                setMainNewTab('Notifications')
                insertServiceApps('targerApp')
            } else {
                setsourceDataError({
                    ...sourceDataError,
                    targetServiceAppLength: ` Additional ${additionalAccountsRequiredTarget} accounts are required `,
                });

                return;
            }
        } catch (error: any) {
            setAlert(true);
            setLoader(false)

        }
    }

    // PS_223, PS_224, PS_225, PS_226, PS_227    
    const insertParallelCount = async () => {
        setLoader(true)
        try {
            let token = await getToken()

            const payload = {
                token: token,
                taskId: taskId,
                parallelInstanceCount: value

            };
            const response = await parallelInstanceCountApi(payload);

            if (!response) {
                console.error('The values failed to insert');
            } else {
                ////console.log('New records inserted successfully');
            }
            setLoader(false)

        } catch (error) {
            setAlert(true);
            setLoader(false)

            console.error('Error inserting records:', error);
        }
    };

    const handleCheckboxClick = (fileType: any) => {
        setSelectedFiles(prevSelected => {
            if (prevSelected.includes(fileType)) {
                return prevSelected.filter(type => type !== fileType);
            } else {
                return [...prevSelected, fileType];
            }
        });
    };

    // PS_23 PS_24 PS_25 PS_26 
    const handleLimitChange = (e: any) => {
        const value = e.target.value;
        // Only update if the value contains only digits
         if (/^\d{0,3}$/.test(value)) {
            setLimit(value);
        }
    };

    // if (sourceAccounts.length > 0 && targetAccounts.length > 0 && sourceApps.length > 0 && targetApps.length > 0) {
    //     setIsEditCheck(false)
    //     onToggleAccordian()
    // }
    // else {
    //     setIsEditCheck(true)
    // }
    // PS_40, PS_41, PS_42
    const insertLastVisitedPageValue = async (page: string) => {
        try {
            // //console.log(page, "This is the last inserted pageeeee");

            let token = await getToken()

            const payload = {
                token: token,
                taskId: taskId,
                lastVisitedValue: page
            };

            ////console.log(payload, 'last visited');

            // Insert only newly entered records into the database
            const response: any = await insertLastVisitedPageValueApi(payload);

            if (!response) {
                ////console.log('The current page value failed to insert');
            } else {
                ////console.log('The current page value inserted successfully');

            }
        } catch (error) {
            console.error('Error inserting records:', error);
        }
    };


    const handleNext = async () => {
        debugger;
        onGeneralConfigSave();
        updateGeneralConfigSaveStatus(true);
        enableUserMappingToggle();
        insertLastVisitedPageValue("userMapping");
    }

    // PS_41 PS_42 PS_43 PS_44
    const insertPreferences = async () => {
        try {
            setLoader(true);
            let token = await getToken()

            const versionCount = version === 'true' && limit ? parseInt(limit, 10) : null;

            // Join the selected files into a comma-separated string
            const fileTypeString = selectedFiles.join(',');

            const payload = {
                token: token,
                taskId: taskId,
                versionCount: versionCount,
                fileType: fileTypeString,
                applicationName: applicationName,
            };

            //console.log(payload, "preferencesPayload");


            const response = await postPreferencesData(payload);

            if (!response) {
                console.error('The values failed to insert');
            } else {
                //console.log('New records inserted successfully');

            }
            if (applicationName !== 'outlook'|| applicationName !==  'gmail') {
                if (environment.toLowerCase()=='tenant' && targetApps.length > 0 && ((environment.toLowerCase()=='tenant' && applicationName !='teamschannel') || targetServiceAccounts.length > 0)) {
                    handleNext();
                }
            }
            
            setLoader(false);
            // Close the accordion

        } catch (error) {
            console.error('Error inserting records:', error);
        }
    };

    //console.log('mainNewTab -----------', mainNewTab)

    //console.log('mainNewTab -----------', targetApps)

    //console.log('mainNewTab -----------', targetAccounts)

    return (
        <div className="col-md-12 mt-4 mt-md-5">
            <div className="table theme-table bg-white mb-3">
                <table className="table table-borderless table-shadow mb-0">
                    <thead>
                        <tr>
                            <th className="text-nowrap table-accordion-head">
                                <span className="d-flex align-items-center gap-3">
                                    <img
                                        src="/images/table-accordion-arrow.svg"
                                        alt="table-accordion-arrow"
                                        className={`cursor-pointer table-accordion-arrow ${isOpen ? "" : "collapsed"}`}
                                        data-bs-toggle="collapse"
                                        data-bs-target="#general-config"
                                        onClick={onToggle}
                                    />
                                    <span
                                        className="ac-heading cursor-pointer"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#general-config"
                                        onClick={onToggle}
                                    >
                                        General Configuration
                                    </span>
                                </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className={`collapse ${isOpen ? "show" : ""}`} id="general-config">
                            <td colSpan={2}>
                                {/* <div class="d-flex align-items-start"> */}
                                <div className="row mt-2">
                                    <div className="col-md-3 d-none d-md-block">
                                        <div
                                            className="nav flex-column nav-pills me-3 border border-radius-8 color-white-v1 p-2"
                                            id="v-pills-tab"
                                            role="tablist"
                                            aria-orientation="vertical"
                                        >
                                            <button
                                                // className={`nav-link custom-tab-nav-link p-3 border-bottom rounded-0 font-14 font-semibold tab-grey-color text-start active`}
                                                className={`nav-link custom-tab-nav-link p-3 border-bottom rounded-0 font-14 font-semibold tab-grey-color text-start  ${mainNewTab === 'psservice' ? 'active' : ''} `}
                                                id="v-pills-psservice-tab"
                                                data-bs-toggle="pill"
                                                data-bs-target="#v-pills-psservice"
                                                type="button"
                                                role="tab"
                                                aria-controls="v-pills-psservice"
                                                aria-selected="true"
                                                onClick={() => {
                                                    debugger
                                                    setMainNewTab('psservice'); // Sets the active tab to 'Migration'
                                                    setNestedTab(location?.state?.environment=='googleworkspace'?'Target':'Source'); // Sets the main tab to 'Migration'
                                                }}
                                            >
                                                PS Service Account
                                            </button>
                                            <button
                                                // className={` nav-link theme-tab font-16 ${activeTab === 'Migration' ? 'active' : ''} `}
                                                className={`nav-link custom-tab-nav-link p-3 border-bottom rounded-0 font-14 font-semibold tab-grey-color text-start  ${mainNewTab === 'Migration' ? 'active' : ''} `}
                                                id="v-pills-migration-tab"
                                                data-bs-toggle="pill"
                                                data-bs-target="#v-pills-migration"
                                                type="button"
                                                role="tab"
                                                aria-controls="v-pills-migration"
                                                aria-selected="false"
                                                onClick={() => { setMainNewTab('Migration') }}
                                            disabled={true ? sourceAccounts.length === 0 || targetAccounts.length === 0 : false}
                                            >
                                                Migration
                                            </button>
                                            <button
                                                className={`nav-link custom-tab-nav-link p-3 border-bottom rounded-0 font-14 font-semibold tab-grey-color text-start  ${mainNewTab === 'Notifications' ? 'active' : ''} `}
                                                id="v-pills-notifications-tab"
                                                data-bs-toggle="pill"
                                                data-bs-target="#v-pills-notifications"
                                                type="button"
                                                role="tab"
                                                aria-controls="v-pills-notifications"
                                                aria-selected="false"
                                                onClick={() => { setMainNewTab('Notifications') }}
                                            disabled={true ? sourceApps.length === 0 || targetApps.length === 0 || requiredAppLength != sourceApps.length || requiredAppLength != targetApps.length : false}
                                            >
                                                Notifications
                                            </button>
                                            <button
                                                onClick={() => { setMainNewTab('errorHandling') }}
                                                className={`nav-link custom-tab-nav-link p-3 rounded-0 font-14 font-semibold tab-grey-color ${applicationName?.toLowerCase() == "outlook"||applicationName?.toLowerCase() == "gmail" ?``:`border-bottom`} text-start  ${mainNewTab === 'errorHandling' ? 'active' : ''} `}
                                                id="v-pills-errorhandling-tab"
                                                data-bs-toggle="pill"
                                                data-bs-target="#v-pills-errorhandling"
                                                type="button"
                                                role="tab"
                                                aria-controls="v-pills-errorhandling"
                                                aria-selected="false"
                                                disabled={
                                                            true ?
                                                                sourceAccounts.length === 0 || targetAccounts.length === 0 ||
                                                                sourceApps.length === 0 || targetApps.length === 0 ||
                                                                requiredAppLength != sourceApps.length || requiredAppLength != targetApps.length
                                                                : false
                                                            }
                                            >
                                                Error Handling
                                            </button>
                                            {
                                                applicationName == "outlook" || applicationName == 'gmail'? <div></div>
                                                    :
                                                    <div>
                                                        <button
                                                            className={`nav-link custom-tab-nav-link p-3 rounded-0 font-14 font-semibold tab-grey-color text-start  ${mainNewTab === 'preferences' ? 'active' : ''} `}
                                                                // onClick={() => {setActiveTab('Preferences')
                                                                // setMainTab('Preferences')
                                                            // }}
                                                            onClick={() => { setMainNewTab('preferences') }}
                                                            id="v-pills-preferences-tab"
                                                            data-bs-toggle="pill"
                                                            data-bs-target="#v-pills-preferences"
                                                            type="button"
                                                            role="tab"
                                                            aria-controls="v-pills-preferences"
                                                            aria-selected="false"
                                                            disabled={
                                                                true ?
                                                                    sourceAccounts.length === 0 || targetAccounts.length === 0 ||
                                                                    sourceApps.length === 0 || targetApps.length === 0 ||
                                                                    requiredAppLength != sourceApps.length || requiredAppLength != targetApps.length
                                                                    : false
                                                            }
                                                        // disabled=
                                                        // {
                                                        //     true ?
                                                        //         targetAccounts.length === 0 || targetApps.length === 0 ||
                                                        //         requiredAppLength != sourceApps.length || requiredAppLength != targetApps.length
                                                        //         : false
                                                        // }
                                                        >
                                                            Preferences
                                                        </button>
                                                    </div>}

                                        </div>
                                    </div>
                                    <div className="d-flex d-md-none mb-3 cust-mobile-tab">
                                        <ul
                                            className="nav nav-pills cust-mobile-tab-list pb-2"
                                            id="pills-tab"
                                            role="tablist"
                                        >
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    onClick={() => setMainNewTab('psservice')}
                                                    className={`nav-link custom-tab-nav-link-mobile p-3 border-bottom rounded-0 font-14 font-semibold tab-grey-color text-start ${mainNewTab=='psservice'?`active`:''}`}
                                                    id="v-pills-psservice-tab"
                                                    data-bs-toggle="pill"
                                                    data-bs-target="#v-pills-psservice"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="v-pills-psservice"
                                                    aria-selected="true"
                                                >
                                                    PS Service Account
                                                </button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    disabled={true ? sourceAccounts.length === 0 || targetAccounts.length === 0 : false}
                                                    onClick={() => setMainNewTab('Migration')}
                                                    className={`nav-link custom-tab-nav-link-mobile p-3 border-bottom rounded-0 font-14 font-semibold tab-grey-color text-start ${mainNewTab?.toLowerCase()=='migration'?`active`:''}`}
                                                    id="v-pills-migration-tab"
                                                    data-bs-toggle="pill"
                                                    data-bs-target="#v-pills-migration"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="v-pills-migration"
                                                    aria-selected="false"
                                                >
                                                    Migration
                                                </button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    onClick={() => setMainNewTab('Notifications')}
                                                    className={`nav-link custom-tab-nav-link-mobile p-3 border-bottom rounded-0 font-14 font-semibold tab-grey-color text-start ${mainNewTab=='Notifications'?`active`:''}`}
                                                    id="v-pills-notifications-tab"
                                                    data-bs-toggle="pill"
                                                    data-bs-target="#v-pills-notifications"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="v-pills-notifications"
                                                    aria-selected="false"
                                                    disabled={true ? sourceApps.length === 0 || targetApps.length === 0 || requiredAppLength != sourceApps.length || requiredAppLength != targetApps.length : false}
                                                >
                                                    Notifications

                                                </button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    onClick={() => setMainNewTab('errorHandling')}
                                                    className={`nav-link custom-tab-nav-link-mobile p-3 border-bottom rounded-0 font-14 font-semibold tab-grey-color text-start ${mainNewTab=='errorHandling'?`active`:''}`}
                                                    id="v-pills-errorhandling-tab"
                                                    data-bs-toggle="pill"
                                                    data-bs-target="#v-pills-errorhandling"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="v-pills-errorhandling"
                                                    aria-selected="false"
                                                    disabled={
                                                        true ?
                                                            sourceAccounts.length === 0 || targetAccounts.length === 0 ||
                                                            sourceApps.length === 0 || targetApps.length === 0 ||
                                                            requiredAppLength != sourceApps.length || requiredAppLength != targetApps.length
                                                            : false
                                                    }
                                                >
                                                    Error Handling
                                                </button>
                                            </li>
                                            {
                                                applicationName == "outlook" || applicationName ==  'gmail'? <div></div>
                                                    :
                                                    <li>
                                                        <button
                                                            className={`nav-link custom-tab-nav-link-mobile p-3 border-bottom rounded-0 font-14 font-semibold tab-grey-color text-start  ${mainNewTab === 'preferences' ? 'active' : ''} `}
                                                            //     onClick={() => {setActiveTab('Preferences')
                                                            //     setMainTab('Preferences')
                                                            // }}
                                                            onClick={() => { setMainNewTab('preferences') }}
                                                            id="v-pills-preferences-tab"
                                                            data-bs-toggle="pill"
                                                            data-bs-target="#v-pills-preferences"
                                                            type="button"
                                                            role="tab"
                                                            aria-controls="v-pills-preferences"
                                                            aria-selected="false"
                                                        // disabled=
                                                        // {
                                                        //     true ?
                                                        //         targetAccounts.length === 0 || targetApps.length === 0 ||
                                                        //         requiredAppLength != sourceApps.length || requiredAppLength != targetApps.length
                                                        //         : false
                                                        // }
                                                        disabled={
                                                            true ?
                                                                sourceAccounts.length === 0 || targetAccounts.length === 0 ||
                                                                sourceApps.length === 0 || targetApps.length === 0 ||
                                                                requiredAppLength != sourceApps.length || requiredAppLength != targetApps.length
                                                                : false
                                                        }
                                                        >
                                                            Preferences
                                                        </button>
                                                    </li>
                                            }
                                        </ul>
                                    </div>
                                    <div className="col-md-9">
                                        <div
                                            className="tab-content"
                                            id="v-pills-tabContent"
                                        >
                                            {/*First Tab - PS Service Account Starts here*/}
                                            {mainNewTab?.toLowerCase() === 'psservice' ? <div
                                                className="tab-pane fade show active" id="v-pills-psservice" role="tabpanel" aria-labelledby="v-pills-psservice-tab">
 
 
                                                {environmentType!='googleworkspace'?<ul
                                                    className="nav nav-pills mb-4"
                                                    id="pills-tab"
                                                    role="tablist"
                                                >
                                                    <li
                                                        className="nav-item"
                                                        role="presentation"
                                                    >
                                                   <button
                                                            className={` nav-link theme-tab font-16 ${nestedTab === 'Source' ? 'active' : ''} `}
                                                            data-bs-toggle="pill"
                                                            data-bs-target="#Source"
                                                            type="button"
                                                            role="tab"
                                                            aria-selected="true"
                                                            onClick={() => setNestedTab('Source')}
                                                        >
                                                            Source
                                                        </button>
                                                    </li>
                                                    <li
                                                        className="nav-item"
                                                        role="presentation"
                                                    >
                                                        <button
                                                            onClick={() => setNestedTab('Target')}
                                                            className={` nav-link theme-tab font-16 ${nestedTab === 'Target'||environmentType=='googleworkspace' ? 'active' : ''} `}
                                                            data-bs-toggle="pill"
                                                            data-bs-target="#Target"
                                                            type="button"
                                                            role="tab"
                                                            aria-selected="true" disabled={sourceAccounts.length > 0 ||environmentType=='googleworkspace' ? false : psSource}
                                                        >
                                                            Target
                                                        </button>
                                                    </li>
                                                </ul>:null }
                                                <div className="tab-content" id="pills-tabContent">


                                                    {nestedTab === 'Source' && mainNewTab === 'psservice' ? (
                                                        <PsServiceConfiguration
                                                            taskId={taskId}
                                                            roleCheck={roleCheck}
                                                            setRoleCheck={setRoleCheck}
                                                            setSourceAccount={setSourceAccount}
                                                            sourceAccount={sourceAccount}
                                                            sourceAccounts={sourceAccounts}
                                                            setSourceAccounts={setSourceAccounts}
                                                            targetAccounts={targetAccounts}
                                                            setTargetAccounts={setTargetAccounts}
                                                            sourceDataError={sourceDataError}
                                                            setsourceDataError={setsourceDataError}
                                                            emailTouched={emailTouched}
                                                            setEmailTouched={setEmailTouched}
                                                            emailValid={emailValid}
                                                            setEmailValid={setEmailValid}
                                                            setPsSourceSAC={setPsSourceSAC}
                                                            psSourceSAC={psSourceSAC}
                                                            setPsTargetSAC={setPsTargetSAC}
                                                            psTargetSAC={psTargetSAC}
                                                            handleSaveAndContinueAndInsert={handleSaveAndContinueAndInsert}
                                                            handleSaveAndContinueAndInsertTarget={handleSaveAndContinueAndInsertTarget}
                                                            nestedTab={nestedTab}
                                                            mainNewTab={mainNewTab}
                                                        />
                                                    ) : null}
                                                    {nestedTab === 'Target' && mainNewTab.toLowerCase() === 'psservice' ? (
                                                        <PsServiceConfiguration
                                                            taskId={taskId}
                                                            roleCheck={roleCheck}
                                                            setRoleCheck={setRoleCheck}
                                                            setSourceAccount={setSourceAccount}
                                                            sourceAccount={sourceAccount}
                                                            sourceAccounts={sourceAccounts}
                                                            setSourceAccounts={setSourceAccounts}
                                                            targetAccounts={targetAccounts}
                                                            setTargetAccounts={setTargetAccounts}
                                                            sourceDataError={sourceDataError}
                                                            setsourceDataError={setsourceDataError}
                                                            emailTouched={emailTouched}
                                                            setEmailTouched={setEmailTouched}
                                                            emailValid={emailValid}
                                                            setEmailValid={setEmailValid}
                                                            setPsSourceSAC={setPsSourceSAC}
                                                            psSourceSAC={psSourceSAC}
                                                            setPsTargetSAC={setPsTargetSAC}
                                                            psTargetSAC={psTargetSAC}
                                                            handleSaveAndContinueAndInsert={handleSaveAndContinueAndInsert}
                                                            handleSaveAndContinueAndInsertTarget={handleSaveAndContinueAndInsertTarget}
                                                            nestedTab={nestedTab}
                                                            mainNewTab={mainNewTab}
                                                        />
                                                    ) : null}
                                                </div>
                                            </div> : null}
                                            {/* Render Notifications tab content */}
                                            {mainNewTab.toLowerCase() === 'notifications' ? (
                                                <NotificationConfiguration
                                                    taskId={taskId}
                                                    notification={notification}
                                                    setNotification={setNotification}
                                                    emailTouched={emailTouched}
                                                    setEmailTouched={setEmailTouched}
                                                    sourceDataError={sourceDataError}
                                                    roleCheck={roleCheck}
                                                    notifications={notifications} // Pass notifications as prop
                                                    setNotifications={setNotifications}
                                                    setsourceDataError={setsourceDataError}
                                                    notificationSAC={notificationSAC}
                                                    setNotificationSAC={setNotificationSAC}
                                                    sourceDataErrorMessage={sourceDataErrorMessage}
                                                    setsourceDataErrorMessage={setsourceDataErrorMessage}
                                                    handleSaveAndContinueNotification={handleSaveAndContinueNotification}
                                                    nestedTab={nestedTab}
                                                    notiHideTab={notiHideTab}
                                                />

                                            ) : null}



                                            {mainNewTab.toLowerCase() === 'migration' && (
                                                <MigrationConfiguration
                                                    taskId={taskId}
                                                    value={value}
                                                    setValue={setValue}
                                                    mainNewTab={mainNewTab}
                                                    roleCheck={roleCheck}
                                                    totalParallelCount={totalParallelCount}
                                                    setTotalParallelCount={setTotalParallelCount}
                                                    migrationNestedTabButton={migrationNestedTabButton}
                                                    setMigrationNestedTabButton={setMigrationNestedTabButton}
                                                    migrationNestedTab={migrationNestedTab}
                                                    setMigrationNestedTab={setMigrationNestedTab}
                                                    sourceApp={sourceApp}
                                                    setSourceApp={setSourceApp}
                                                    sourceApps={sourceApps}
                                                    setSourceApps={setSourceApps}
                                                    sourceServiceAccounts = {sourceServiceAccounts}
                                                    setsourceServiceAccounts = {setsourceServiceAccounts}
                                                    targetServiceAccounts = {targetServiceAccounts}
                                                    setTargetServiceAccounts = {setTargetServiceAccounts}
                                                    targetApp={targetApp}
                                                    setTargetApp={setTargetApp}
                                                    targetApps={targetApps}
                                                    setTargetApps={setTargetApps}
                                                    migSourceAppSAC={migSourceAppSAC}
                                                    handleSaveAndContinueSourceSercviceApp={handleSaveAndContinueSourceSercviceApp}
                                                    handleSaveAndContinueTargetSercviceApp={handleSaveAndContinueTargetSercviceApp}
                                                    handleSaveAndContinueSourceSercviceAccount={handleSaveAndContinueSourceSercviceAccount}
                                                    handleSaveAndContinueTargetSercviceAccount={handleSaveAndContinueTargetSercviceAccount}
                                                    insertParallelCount={insertParallelCount}
                                                    setMigSourceAppSAC={setMigSourceAppSAC}
                                                    sourceDataError={sourceDataError}
                                                    setsourceDataError={setsourceDataError}
                                                    sourceDataErrorMessage={sourceDataErrorMessage}
                                                    setsourceDataErrorMessage={setsourceDataErrorMessage}
                                                    onToggleAccordian={onToggleAccordian}

                                                />
                                            )}
                                            {/*Fourth Tab - Error Handling Starts here*/}
                                            {mainNewTab.toLowerCase() === 'errorhandling' ? <div
                                                className="tab-pane fade show active"
                                                id="v-pills-errorhandling"
                                                role="tabpanel"
                                                aria-labelledby="v-pills-errorhandling-tab"
                                            >
                                                <h5 className="font-16 font-bold mb-2">
                                                    Error Handling
                                                </h5>
                                                <p className="font-14 font-regular">
                                                    The "Skip Error/Warning" option lets you decide whether to continue or halt migration when errors or warnings occur. Choose 'Yes' to proceed, ignoring problematic items, or 'No' to stop the process.
                                                </p>
                                                <div className="col-md-12 mt-3">
                                                    <label className="font-15 font-semibold label-color w-100 mb-2 pb-1">
                                                        Do you want to skip and proceed if any
                                                        warnings or blockers occur in migration
                                                        ?
                                                    </label>
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            className="form-check-input custom-radio-input"
                                                            style={{backgroundSize:'7px'}}
                                                            type="radio"
                                                            name="skip"
                                                            id="skipyes"
                                                            value="Yes"
                                                            checked={skipErrorHandling === 'Yes'}
                                                            onChange={() => setSkipErrorHandling('Yes')} // Use onChange to update skipErrorHandling
                                                            disabled={roleCheck.toLowerCase().includes('viewer')}
                                                        />
                                                        <label
                                                            className="form-check-label font-14 font-regular"
                                                            htmlFor="skipyes"
                                                        >
                                                            Yes
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            className="form-check-input custom-radio-input"
                                                            style={{backgroundSize:'7px'}}
                                                            type="radio"
                                                            name="skip"
                                                            id="skipno"
                                                            value="No"
                                                            checked={skipErrorHandling === 'No'} // Check if skipErrorHandling is 'No'
                                                            onChange={() => setSkipErrorHandling('No')} // Use onChange to update skipErrorHandling
                                                            disabled={roleCheck.toLowerCase().includes('viewer')}
                                                        />
                                                        <label
                                                            className="form-check-label font-14 font-regular"
                                                            htmlFor="skipno"
                                                        >
                                                            No
                                                        </label>
                                                    </div>

                                                </div>
                                                <div className="d-flex justify-content-end mt-3">
                                                    <button
                                                        disabled={roleCheck.toLowerCase().includes('viewer')} onClick={() => {
                                                            insertErr();
                                                            //onToggleAccordian()
                                                            
                                                            // handleEditCheck();
                                                            
                                                        }}
                                                        className="btn primary-btn text-nowrap font-semibold text-white font-14 d-flex gap-2 justify-content-center align-items-center">
                                                        Save &amp; Continue
                                                    </button>
                                                </div>
                                            </div>
                                                :
                                                // applicationName.toLowerCase() == "outlook" ? <div></div> : 
                                                mainNewTab.toLowerCase() === 'preferences' ? <div
                                                    className="tab-pane fade show active"
                                                    id="v-pills-preferences"
                                                    role="tabpanel"
                                                    aria-labelledby="v-pills-preferences-tab"
                                                >
                                                    <h5 className="font-16 font-bold mb-2">Preferences</h5>
                                                    <p className="font-14 font-regular">
                                                        Configurations made under the Preferences tab will be skipped/not migrated during the migration process. Ensure critical settings are configured elsewhere to avoid any disruptions.
                                                    </p>
                                                    <div className="row" style={{marginTop:'2rem'}}>
                                                        <div className="col-md-4 mb-2">
                                                            <label className="font-14 gap-2 font-semibold label-color text-nowrap mb-2">
                                                                File Type{" "}
                                                                <Tippy
                                                                    delay={[500, 0]}
                                                                    arrow={true}
                                                                    followCursor={'horizontal'}
                                                                    plugins={[followCursor]}
                                                                    placement='top'
                                                                    content="Select the file extension types to be excluded from the migration process."
                                                                >
                                                                    <img
                                                                        src="images/tooltip.svg"
                                                                        alt="tooltip"
                                                                        width={11}
                                                                    />
                                                                </Tippy>
                                                            </label>
                                                            <p></p>
                                                            <div className="input-group input-search" style={{ width: '320px' }}> {/* Set a fixed width for the entire container */}
                                                                <div className="dropdown dd-flex-1" style={{ width: '100%' }}>
                                                                    <button
                                                                        disabled={roleCheck.toLowerCase().includes('viewer')}
                                                                        className="bg-white rounded-2 py-2 color-black font-14 w-100 text-start dpd-field d-flex justify-content-between align-items-center"
                                                                        type="button"
                                                                        data-bs-toggle="dropdown"
                                                                        aria-expanded="false"
                                                                        style={{
                                                                            width: '100%',
                                                                            overflow: 'hidden',
                                                                        }}
                                                                    >
                                                                        <span className="font-13 font-regular color-gray-v4" style={{
                                                                            display: 'block',
                                                                            maxWidth: 'calc(100% - 20px)', // Adjust based on the width of your dropdown icon
                                                                            overflow: 'hidden',
                                                                            textOverflow: 'ellipsis',
                                                                            whiteSpace: 'nowrap'
                                                                        }}>
                                                                            {selectedFiles.length === 0 || (selectedFiles.length === 1 && selectedFiles[0] === 'Select')
                                                                                ? 'Select'
                                                                                : selectedFiles.filter(file => file !== 'Select').join(', ')}
                                                                        </span>
                                                                        <img
                                                                            src="images/down-chevron-arw.svg"
                                                                            alt="dropdown"
                                                                            style={{ flexShrink: 0, marginLeft: '5px' }}
                                                                        />
                                                                    </button>
                                                                    <ul className="dropdown-menu rounded-start" style={{ width: '100%', maxHeight: '140px', overflowY: 'auto' }}>
                                                                        {fileTypes?.length > 0 ? (
                                                                            fileTypes.map((fileType, index) => (
                                                                                <li key={index} className="dropdown-item py-0 cursor-pointer py-2">
                                                                                    <div className="align-items-center">
                                                                                        <input
                                                                                            className="form-check-input cus-check-input me-2 mt-0"
                                                                                            type="checkbox"
                                                                                            id={`file-${index}`}
                                                                                            onChange={() => handleCheckboxClick(fileType)}
                                                                                            checked={selectedFiles.includes(fileType)}
                                                                                        />
                                                                                        <label
                                                                                            className="form-check-label"
                                                                                            htmlFor={`file-${index}`}
                                                                                            style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                                                                                        >
                                                                                            {fileType}
                                                                                        </label>
                                                                                    </div>
                                                                                </li>
                                                                            ))
                                                                        ) : (
                                                                            <li className="dropdown-item">No file types available</li>
                                                                        )}
                                                                    </ul>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="col-md-4 mb-2">
                                                            <label className="font-14 gap-2 font-semibold label-color text-nowrap mb-2">
                                                                Version <Tippy
                                                                    delay={[500, 0]}
                                                                    arrow={true}
                                                                    followCursor={'horizontal'}
                                                                    plugins={[followCursor]}
                                                                    placement='top'
                                                                    content="By selecting the checkbox, you can limit the number of latest versions to be considered, as specified in the 'Limit' field."
                                                                >
                                                                    <img
                                                                        src="images/tooltip.svg"
                                                                        alt="tooltip"
                                                                        width={11}
                                                                    />
                                                                </Tippy>
                                                            </label>
                                                            <div className="form-check">
                                                                <input
                                                                    className="form-check-input cus-check-input"
                                                                    type="checkbox"
                                                                    id="latestversions"
                                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                        setVersion(e.target.checked ? 'true' : 'false');
                                                                        if (!e.target.checked) {
                                                                            setLimit('');
                                                                        }
                                                                    }}
                                                                    checked={version === 'true'}
                                                                    disabled={roleCheck.toLowerCase() === 'viewer'}
                                                                />
                                                                <label
                                                                    className="form-check-label font-regular font-14 color-gray-v4 ms-2"
                                                                    htmlFor="latestversions"
                                                                >
                                                                    Check if you need limit to the latest versions
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 mb-2">
                                                            <label
                                                                className="font-14 font-semibold label-color text-nowrap mb-2"
                                                                htmlFor="limit"
                                                            >
                                                                Limit
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control custom-form font-14 font-regular"
                                                                id="limit"
                                                                placeholder="Enter Limit"
                                                                value={limit}
                                                                disabled={roleCheck.toLowerCase() === 'viewer' || version === 'false'}
                                                                onChange={handleLimitChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-end" style={{marginTop:'2rem'}}>
                                                        <button
                                                            disabled={roleCheck.toLowerCase().includes('viewer')}
                                                            onClick={() => {
                                                                insertPreferences();
                                                                //if (saveAndContinueCheck) {
                                                                    updateGeneralConfigSaveStatus(true);
                                                                    onGeneralConfigSave();
                                                                    onToggle();
                                                                    onToggleAccordian();
                                                                   //setSaveAndContinueCheck(false);
                                                                //}
                                                            }}
                                                            className="btn primary-btn text-nowrap font-semibold text-white font-14 d-flex gap-2 justify-content-center align-items-center"
                                                        >
                                                            Save &amp; Continue
                                                        </button>


                                                    </div>
                                                </div> : null}
                                            {/*Fourth Tab - Error Handling Starts here*/}
                                        </div>
                                    </div>
                                </div>
                                {/* </div> */}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {loader && (
                <div className="overlay overlay-front">
                    <div className="position-absolute top-50 start-50 translate-middle">
                        <div
                            className="spinner-border Loader loader-color align-center "
                            role="status"
                        >
                            <span>
                                <span className="loader-inner-circle" />
                            </span>
                        </div>
                        <p className="loading-text font-16 font-semibold color-white-v2 mt-2">
                            Loading..
                        </p>
                    </div>
                </div>)}

            {showPopup === true ? (
                <div className="modal fade show" id="Failure-popup" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="Failure-popupLabel" aria-modal="true" role="dialog" style={{ display: "block", backgroundColor: "rgba(0,0,0,0.8)" }}>

                    <div className="modal-dialog modal-dialog-centered">

                        <div className="modal-content popup-brd-radius">

                            <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">

                                <span className="position-absolute">

                                    <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />

                                </span>

                            </div>

                            <div className="modal-body border-0 text-center">

                                <h5 className="modal-title mb-3 primary-header" id="Failure-popupLabel">Failure</h5>

                                <p className="primary-data">{sourceDataError.showPopupMessage}</p>

                            </div>

                            <div className="modal-footer border-0 pb-4 justify-content-center">

                                <button type="button" onClick={() => setShowPopup(false)} className="btn primary-btn font-14 px-4 font-semibold" data-bs-dismiss="modal">Retry</button>

                            </div>

                        </div>

                    </div>

                </div>) : null}
            {alert ? <div
                className="modal fade show"
                tabIndex={-1}
                style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content popup-brd-radius">
                        <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
                            <span className="position-absolute">
                                <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />
                            </span>
                        </div>
                        <div className="modal-body border-0 text-center">
                            <h5
                                className="modal-title mb-3 primary-header"
                                id="Failure-popupLabel"
                            >
                                {alertMessage}
                            </h5>
                            <p className="primary-data">
                                {alert}
                            </p>
                        </div>
                        <div className="modal-footer border-0 pb-4 justify-content-center">
                            <button
                                type="button"
                                className="btn primary-btn font-14 px-4 font-semibold"
                                onClick={() => {
                                    if (isSessionEpired) {
                                        history.push('/Login')
                                    }
                                    setAlertMessage('')
                                    setAlert(false)
                                }}
                            >
                                Ok
                            </button>
                        </div>
                    </div>
                </div>
            </div> : null}
            {/*Failure Popup ends here */}

        </div>

    )

};

export default MasterMigConfiguration;